import produce from 'immer';

const INITIAL_STATE = {
  query: '',
  students: [],
  loading: true,
};

export default function students(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@students/GET_STUDENTS_BY_COURSE_AND_CLASS_REQUEST': {
        draft.query = action.payload;
        break;
      }
      case '@students/GET_STUDENTS_BY_COURSE_AND_CLASS_SUCCESS': {
        draft.loading = false;
        draft.students = action.payload;
        break;
      }
      case '@students/GET_STUDENTS_BY_COURSE_AND_CLASS_FAILURE': {
        draft.loading = false;
        break;
      }
      default:
    }
  });
}
