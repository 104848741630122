import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import { Row, Col, Card, Table } from 'react-bootstrap';

import history from '~/services/history';

import Loading from '~/components/Loading';
import Select from '~/components/InputSelect';
import ButtonCheckin from '~/components/ButtonCheckin';

import * as CoursesActions from '~/store/modules/courses/actions';
import * as ClassesActions from '~/store/modules/classes/actions';
import * as StudentsActions from '~/store/modules/students/actions';

import { buildQueryString } from '~/util';

function MyStudents() {
  const dispatch = useDispatch();
  const courses = useSelector(state => state.course.courses);
  const classes = useSelector(state => state.classes.classes);
  const students = useSelector(state => state.students.students);

  const loadingClass = useSelector(state => state.course.loading);
  const loadingCourse = useSelector(state => state.course.loading);

  const [handleClass, setHandleClass] = useState(false);
  const [handleCourse, setHandleCourse] = useState(0);

  const loadCourses = useCallback(() => {
    dispatch(CoursesActions.coursesListRequest());
  }, [dispatch]);

  useEffect(() => {
    loadCourses();
  }, [loadCourses]);

  const getOptionsCourses = useCallback(() => {
    const options = courses.map(item => {
      return {
        value: item.cod_evento,
        label: item.titulo_eve,
      };
    });
    return options;
  }, [courses]);

  const handleChangeCurso = useCallback(
    data => {
      setHandleClass(false);
      if (data) {
        setHandleCourse(data.value);
        dispatch(ClassesActions.classesGetByCourseRequest(data.value));
      } else {
        setHandleCourse(0);
        toast.info('Caro usuário, por gentileza selecione um curso!');
      }
    },
    [dispatch]
  );

  const getOptionsClasses = useCallback(() => {
    const options = classes.map(item => {
      return {
        value: item.cod_turma_curso,
        label: item.nome_turma,
      };
    });
    return options;
  }, [classes]);

  const handleChangeTurma = useCallback(
    data => {
      if (data) {
        const query = buildQueryString({
          cod_evento: handleCourse || data.cod_evento,
          cod_turma_curso: data.value,
        });
        setHandleClass(true);
        dispatch(StudentsActions.studentsGetByCourseAndClassRequest(query));
      }
    },
    [dispatch, handleCourse]
  );

  const loadStudents = useCallback(() => {
    if (Object.keys(courses).length) {
      const { cod_evento } = courses[courses.length - 1];
      handleChangeCurso({ value: cod_evento });
      handleChangeTurma({ cod_evento });
    }
    // eslint-disable-next-line
  }, [courses]);

  useEffect(() => {
    loadStudents();
  }, [loadStudents]);

  return (
    <div className="content padding-tab-top-post">
      <Row>
        <Col>
          <div className="d-flex justify-content-between align-items-center">
            <div className="mb-3">
              <h3 className="mb-0 font-weight-semibold">
                <i
                  role="presentation"
                  style={{ fontSize: '1.5rem' }}
                  onClick={() => history.goBack()}
                  className="icon-arrow-left52 mr-2 c-pointer"
                ></i>
                Meus Alunos
              </h3>
              <span className="text-muted d-block">
                Gerenciar aluno(s) da(s) turma(s) de cada curso.
              </span>
            </div>
          </div>
        </Col>
        <Col xl={12}>
          <Card>
            <Card.Body>
              <Form
                initialData={{
                  cod_evento:
                    courses.length > 0 &&
                    courses[courses.length - 1].cod_evento,
                }}
              >
                {loadingCourse ? (
                  <Loading />
                ) : (
                  <Col xl={6}>
                    <label htmlFor="cod_evento">
                      <strong>Cursos</strong>
                    </label>
                    <Select
                      id="cod_evento"
                      name="cod_evento"
                      placeholder="-- Por favor, selecione um curso --"
                      onChange={handleChangeCurso}
                      options={getOptionsCourses()}
                    />
                  </Col>
                )}

                {loadingClass && <Loading />}
                {handleCourse > 0 && (
                  <Col xl={6} className="mt-3">
                    <div className="mb-1">
                      <label htmlFor="cod_evento">
                        <strong>Turmas</strong>
                      </label>
                      <Select
                        id="cod_turma_curso"
                        name="cod_turma_curso"
                        isClearable
                        placeholder="-- Por favor, selecione uma turma --"
                        onChange={handleChangeTurma}
                        options={getOptionsClasses()}
                      />
                    </div>
                  </Col>
                )}

                {handleClass && (
                  <Col xl={12} className="mt-4">
                    {Object.keys(classes).length === 0 ? (
                      <div className="alert alert-info border-0 alert-dismissible mb-0">
                        <i className="icon-info3 border-2 rounded-round p-1 mr-2"></i>
                        <span>
                          Você ainda não possui turmas para o curso em questão!
                        </span>
                      </div>
                    ) : (
                      <Table striped hover>
                        <tbody>
                          {Object.keys(students).length === 0 ? (
                            <tr>
                              <td colSpan="6" className="p-0">
                                <div className="alert alert-info border-0 alert-dismissible mb-0">
                                  <i className="icon-info3 border-2 rounded-round p-1 mr-2"></i>
                                  <span>
                                    A turma escolhida ainda não possui alunos
                                    matriculados!
                                  </span>
                                </div>
                              </td>
                            </tr>
                          ) : (
                            students.map(item => (
                              <tr key={item.cod_evento_cad_part}>
                                <td>
                                  <div className="d-flex flex-column">
                                    <a href="#/">{item.nome_part}</a>
                                    <small className="text-muted">
                                      {item.email_part}
                                    </small>
                                  </div>
                                </td>
                                <td>
                                  <div className="d-flex flex-column">
                                    <small className="text-muted">
                                      Tipo do ingresso
                                    </small>
                                    {item.nome_ing}
                                  </div>
                                </td>
                                <td>
                                  <div className="d-flex flex-column">
                                    <small className="text-muted">
                                      N° do ingresso
                                    </small>
                                    {item.nro_ingresso}
                                  </div>
                                </td>
                                <td>
                                  <div className="d-flex flex-column">
                                    <small className="text-muted">
                                      Comprador
                                    </small>
                                    {item.nome_membro_pagador}
                                  </div>
                                </td>
                                <td>
                                  <div className="d-flex flex-column">
                                    <small className="text-muted">
                                      Comprado em
                                    </small>
                                    {item.comprado_em}
                                  </div>
                                </td>
                                <td>
                                  <ButtonCheckin
                                    data={item}
                                    check={item.checkin}
                                  />
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </Table>
                    )}
                  </Col>
                )}
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default MyStudents;
