import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as Yup from 'yup';

import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import { Button, Modal, Spinner } from 'react-bootstrap';

import Input from '~/components/InputText';
import Select from '~/components/InputSelect';

import api from '~/services/api';

import * as ClassActions from '~/store/modules/classes/actions';

import { objIsNull } from '~/util';

function CreateClass({ show, handleClose }) {
  const dispatch = useDispatch();

  const course = useSelector(state => state.course.course);
  const classCourse = useSelector(state => state.classes.class);

  const formRef = useRef(null);
  const [loading, setLoading] = useState(false);

  async function handleSubmitClass(data) {
    try {
      formRef.current.setErrors({});

      const { cod_evento } = course;
      const schema = Yup.object().shape({
        nome_turma: Yup.string().required(
          'O nome da turma é um campo de preenchimento obrigatório!'
        ),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      setLoading(true);
      let response;
      let message;

      const ehPadrao = data.eh_padrao === 2 ? '0' : '1';
      data.eh_padrao = ehPadrao;

      if (objIsNull(classCourse)) {
        data.cod_turma_curso = classCourse.cod_turma_curso;
        response = await api.put(
          `/evento/salvar-turma-curso/${cod_evento}`,
          data
        );
        message = 'Turma atualizada com sucesso!';
      } else {
        response = await api.post('/evento/salvar-turma-curso', data);
        message = 'Turma cadastrada com sucesso!';
      }

      if (response.data.records.error) {
        setLoading(false);
        toast.error(response.data.records.msg);
      } else {
        setLoading(false);
        toast.success(message);
        setTimeout(() => {
          handleClose();
          dispatch(ClassActions.classesGetByCourseRequest(cod_evento));
        }, 1000);
      }

      return false;
    } catch (err) {
      setLoading(false);
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  function populateForm() {
    if (objIsNull(classCourse)) {
      const p = !parseInt(classCourse.eh_padrao, 10)
        ? 2
        : parseInt(classCourse.eh_padrao, 10);
      return { ...classCourse, eh_padrao: p };
    }
    return {
      eh_padrao: 2,
      cod_evento: course.cod_evento,
    };
  }

  return (
    <Modal
      centered
      className="modal-new-event"
      dialogClassName="modal-event"
      show={show}
      onHide={handleClose}
    >
      <Form
        ref={formRef}
        onSubmit={handleSubmitClass}
        initialData={populateForm()}
      >
        <Modal.Header>
          <div className="media align-items-center align-items-md-start flex-column flex-md-row">
            <span className="text-teal mr-md-3 mb-3 mb-md-0">
              <i className="icon-graduation2 text-warning-400 border-warning-400 border-2 rounded-round p-2 mt-1"></i>
            </span>
            <div className="media-body text-center text-md-left">
              <h3 className="media-title font-weight-semibold">Criar Turma</h3>
              <span className="text-muted">
                Preencha o formulário abaixo para adicionar uma nova turma.
              </span>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label htmlFor="nome_turma">Nome *</label>
            <Input id="cod_evento" name="cod_evento" type="hidden" />
            <Input
              required
              type="text"
              id="nome_turma"
              name="nome_turma"
              placeholder="Entre com o nome da turma..."
            />
          </div>
          <div className="form-group">
            <label htmlFor="eh_padrao">Turma Padrão</label>
            <Select
              id="eh_padrao"
              name="eh_padrao"
              placeholder="-- SELECIONE UMA OPÇÃO --"
              options={[
                { value: 1, label: 'Sim' },
                { value: 2, label: 'Não' },
              ]}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button
            block
            size="lg"
            variant="link"
            className="text-secondary"
            onClick={handleClose}
          >
            <i className="icon-cross2 mr-1 font-weight-light"></i>
            Cancelar
          </Button>
          <Button size="lg" block disabled={!!loading} type="submit">
            {loading ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="mr-1"
                />
                Aguarde
              </>
            ) : (
              'Salvar Turma'
            )}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default CreateClass;
