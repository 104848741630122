export function eventsGetRequest(id) {
  return {
    type: '@events/GET_EVENTS_REQUEST',
    payload: id,
  };
}

export function eventsGetSuccess(data) {
  return {
    type: '@events/GET_EVENTS_SUCCESS',
    payload: data,
  };
}

export function eventsGetFailure() {
  return {
    type: '@events/GET_EVENTS_FAILURE',
  };
}

export function eventsListRequest(data) {
  return {
    type: '@events/LIST_EVENTS_REQUEST',
    payload: data,
  };
}

export function eventsListSuccess(data) {
  return {
    type: '@events/LIST_EVENTS_SUCCESS',
    payload: data,
  };
}

export function eventsListFailure() {
  return {
    type: '@events/LIST_EVENTS_FAILURE',
  };
}

export function eventsPostRequest(data, isNew) {
  return {
    type: '@events/POST_EVENTS_REQUEST',
    payload: data,
    isNew,
  };
}

export function eventsPostSuccess() {
  return {
    type: '@events/POST_EVENTS_SUCCESS',
  };
}

export function eventsPostFailure() {
  return {
    type: '@events/POST_EVENTS_FAILURE',
  };
}

export function eventsGetByTagRequest(tag) {
  return {
    type: '@events/GET_EVENTS_BY_TAG_REQUEST',
    payload: tag,
  };
}

export function eventsGetByTagSuccess(data) {
  return {
    type: '@events/GET_EVENTS_BY_TAG_SUCCESS',
    payload: data,
  };
}

export function eventsGetByTagFailure() {
  return {
    type: '@events/GET_EVENTS_BY_TAG_FAILURE',
  };
}
