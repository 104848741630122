import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
// import { isMobile } from 'react-device-detect';
import { Row, Col, Card, Button } from 'react-bootstrap';

import Config from '~/config';
// import BottomButton from '~/components/BottomButton';

import { buildQueryString } from '~/util';

function Finished({ product, boleto }) {
  const { token } = useSelector(state => state.auth);
  const material = JSON.parse(localStorage.getItem('@psicomart:product'));

  useEffect(() => {
    return function clear() {
      localStorage.removeItem('@psicomart:address');
      localStorage.removeItem('@psicomart:product');
      localStorage.removeItem('@psicomart:products');
    };
  }, []);

  function handleBillet() {
    window.open(boleto);
  }

  function downloadFile(event) {
    event.preventDefault();
    const query = buildQueryString({
      ken: token,
      arq: material.arr_link.arq_prod,
      e: 1,
    });
    window.open(`${Config.URL_APP_DOWNLOAD}${query}`);
  }

  return (
    <Row className="justify-content-md-center">
      <Col xl={12}>
        <Card className="card text-center mt-4">
          <Card.Body className="card-body">
            <i className="icon-checkmark3 icon-4x text-success border-success border-3 rounded-round p-3 mb-3"></i>
            <h4 className="card-title mb-2 font-weight-black">
              Compra Concluída!
            </h4>
            {product.tipo_mkt_place === '1' ? (
              <>
                {boleto ? (
                  <p className="mb-3">
                    Clique no botão abaixo para imprimir o boleto. Enviamos
                    também um link para seu e-mail com o detalhe de sua compra.
                  </p>
                ) : (
                  <p className="mb-3">
                    Clique no botão abaixo para efetuar o download do material.
                    Enviamos também um link para seu e-mail com o detalhe de sua
                    compra.
                  </p>
                )}
                <div className="d-block text-center">
                  {boleto ? (
                    <Button
                      size="sm"
                      variant="info"
                      onClick={handleBillet}
                      style={{ width: '250px' }}
                      className="btn-block font-weight-bold btn-custom text-uppercase m-auto"
                    >
                      Imprimir Boleto
                    </Button>
                  ) : (
                    <Button
                      size="sm"
                      variant="success"
                      onClick={e => downloadFile(e)}
                      style={{ width: '250px' }}
                      className="btn-block font-weight-bold btn-custom text-uppercase m-auto"
                    >
                      Download Material
                    </Button>
                  )}
                </div>
              </>
            ) : (
              <>
                <p className="mb-3">
                  Enviamos também um link para seu e-mail com todos os detalhes
                  de sua compra! =)
                </p>
                {boleto && (
                  <>
                    <br />
                    <Button
                      size="sm"
                      variant="info"
                      onClick={handleBillet}
                      style={{ width: '250px' }}
                      className="btn-block font-weight-bold btn-custom text-uppercase m-auto"
                    >
                      Imprimir Boleto
                    </Button>
                  </>
                )}
              </>
            )}
            <span className="d-block mt-3">
              <span className="mr-1">Obrigado pela sua compra!</span>
              <br />
              <span>
                Caso você não receba a confirmação, verifique sua caixa de spam.
              </span>
            </span>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

Finished.propTypes = {
  boleto: PropTypes.string.isRequired,
  product: PropTypes.shape({
    tipo_mkt_place: PropTypes.string,
  }).isRequired,
};

export default Finished;
