import React, { Component } from 'react';

import { uniqueId } from 'lodash';
import { toast } from 'react-toastify';

import filesize from 'filesize';

import api from '~/services/api';
import Config from '~/config';

import { Content } from './styles';
import { buildQueryString } from '~/util';

import Upload from '~/components/Upload';
import FileArq from '~/components/FileArq';

class UploadContentModuleFiles extends Component {
  state = {
    uploadedFiles: [],
  };

  async componentDidMount() {
    const { auth } = JSON.parse(localStorage.getItem('persist:psico.in'));
    const { token } = JSON.parse(auth);
    const { idCourse, idModule, download } = this.props;

    console.log(download);

    if (idCourse && idModule) {
      const response = await api.get(
        `/evento/get-modulo-curso/?q=(cod_evento:${idCourse},cod_modulo_curso:${idModule})`
      );

      console.log(response.data.records.arr_arq_extra);

      this.setState({
        uploadedFiles: response.data.records.arr_arq_extra.map(file => {
          const url = `http://api.psicomart.com.br/v1/1090/rs/export/get-arq/?q=(ken:${token},arq:${file.cod_evento_arq},e:1)`;
          return {
            id: file.cod_evento_arq,
            name: file.arq_eve,
            preview: file.arq_eve,
            uploaded: true,
            url,
            downloadStatus: download,
            downloadFunction(event) {
              event.preventDefault();
              const query = buildQueryString({
                ken: token,
                arq: file.arq_eve,
                e: 1,
              });
              window.open(`${Config.URL_APP_DOWNLOAD}${query}`);
            },
          };
        }),
      });
    }
  }

  componentWillUnmount() {
    this.state.uploadedFiles.forEach(file => URL.revokeObjectURL(file.preview));
  }

  handleUpload = files => {
    const uploadedFiles = files.map(file => ({
      file,
      id: uniqueId(),
      name: file.name,
      readableSize: filesize(file.size),
      preview: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
      url: null,
    }));

    this.setState({
      uploadedFiles: this.state.uploadedFiles.concat(uploadedFiles),
    });

    uploadedFiles.forEach(this.processUpload);
  };

  updateFile = (id, data) => {
    this.setState({
      uploadedFiles: this.state.uploadedFiles.map(uploadedFile => {
        return id === uploadedFile.id
          ? { ...uploadedFile, ...data }
          : uploadedFile;
      }),
    });
  };

  processUpload = uploadedFile => {
    const data = new FormData();
    const { idCourse, idModule, download } = this.props;

    const { auth } = JSON.parse(localStorage.getItem('persist:psico.in'));
    const { token } = JSON.parse(auth);

    data.append('arquivo1', uploadedFile.file, uploadedFile.name);
    data.append('cod_evento', idCourse);
    data.append('cod_modulo_curso', idModule);

    api
      .post('/evento/enviar-arquivo-extra-evento', data, {
        onUploadProgress: e => {
          const progress = parseInt(Math.round((e.loaded * 100) / e.total));
          this.updateFile(uploadedFile.id, {
            progress,
          });
        },
      })
      .then(response => {
        if (response.data.records.error) {
          this.updateFile(uploadedFile.id, {
            error: true,
          });
          return;
        }
        this.updateFile(uploadedFile.id, {
          uploaded: true,
          id: response.data.records.cod_evento_arq,
          url: response.data.records.arq_eve,
          name: response.data.records.arq_eve,
          downloadStatus: download,
          downloadFunction(event) {
            event.preventDefault();
            const query = buildQueryString({
              ken: token,
              arq: response.data.records.arq_eve,
              e: 1,
            });
            window.open(`${Config.URL_APP_DOWNLOAD}${query}`);
          },
        });
      })
      .catch(() => {
        this.updateFile(uploadedFile.id, {
          error: true,
        });
      });
  };

  handleDelete = async id => {
    const resposta = window.confirm('Deseja remover a mídia escolhida?');
    if (resposta) {
      const { idCourse } = this.props;
      const data = {
        cod_evento: idCourse,
        cod_evento_arq: id,
      };
      const response = await api.put(`/evento/cancelar-evento-arq/${id}`, data);
      if (response.data.records.error) {
        toast.error(response.data.records.msg);
      } else {
        toast.success('Mídia removida com sucesso!');
        this.setState({
          uploadedFiles: this.state.uploadedFiles.filter(
            file => file.id !== id
          ),
        });
      }
    }
  };

  render() {
    const { uploadedFiles } = this.state;
    return (
      <Content>
        <Upload
          upMessage="Arraste um arquivo ou imagem, ou clique aqui para enviar."
          accept="application/*"
          onUpload={this.handleUpload}
        />
        {!!uploadedFiles.length && (
          <FileArq files={uploadedFiles} onDelete={this.handleDelete} />
        )}
      </Content>
    );
  }
}

export default UploadContentModuleFiles;
