import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Table, Card, Badge, Button } from 'react-bootstrap';

import { isBefore, parseISO } from 'date-fns';

import { toast } from 'react-toastify';
import api from '~/services/api';

import CreateTicket from '~/components/CreateTicket';
import EditTicket from '~/components/CreateTicket/edit';

export default function TableTicket({ event }) {
  const [show, setShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [tickets, setTickets] = useState([]);
  const [ticket, setTicket] = useState({});
  const [typeName, setTypeName] = useState('Evento');

  const loadTickets = useCallback(async item => {
    const response = await api.get(
      `/inscricao/listar-ingresso-passo1/?q=(cod_evento:${item.cod_evento})`
    );

    setTickets(response.data.records);
  }, []);

  const chooseType = () => {
    if (event.status_eve === '1') {
      switch (event.tipo_eve) {
        case '1':
          setTypeName('evento');
          break;
        case '2':
          setTypeName('curso');
          break;
        case '3':
          setTypeName('produto');
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    loadTickets(event);
    chooseType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadTickets, event]);

  const delTickets = data => {
    if (event.status_eve === '1') {
      toast.warn(
        `Não é possível apagar ingresso de um ${typeName} publicado. Despublique para apagar.`
      );
    } else {
      api
        .put(`/evento/cancelar-preco/${event.cod_evento}`, {
          cod_evento: event.cod_evento,
          ing: data.ing,
        })
        .then(response => {
          if (response.data.records.error) {
            toast.error(response.data.records.msg);
            return;
          }
          toast.success('Ingressso apagado com sucesso!');
          setTimeout(() => {
            loadTickets(event);
          }, 1000);
        });
    }
  };

  const ediTicket = data => {
    setTicket(data);
    setShowEdit(true);
  };

  const handleClickCreate = () => {
    if (isBefore(parseISO(event.dt_eve_fim), new Date())) {
      toast.error(
        `Não pode ser criado um ingresso após a data final do ${typeName}`
      );
    } else {
      setShow(true);
    }
  };

  return (
    <Card>
      <Card.Body>
        <Card.Title>
          <Button
            size="sm"
            variant="success"
            onClick={() => handleClickCreate()}
          >
            <i className="icon-plus2"></i> Criar ingresso
          </Button>
        </Card.Title>

        <Table striped hover>
          <thead>
            <tr>
              <th>Valor</th>
              <th>Quantidade</th>
              <th>Tipo</th>
              <th className="text-center w-25">Ações</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(tickets).length === 0 ? (
              <tr>
                <td colSpan="4" className="p-0 text-center">
                  <div className="alert alert-info border-0 alert-dismissible mb-0">
                    <i className="icon-info3 border-2 rounded-round p-1 mr-2"></i>
                    <span>Você ainda não criou ingressos!</span>
                  </div>
                </td>
              </tr>
            ) : (
              tickets.map(item => (
                <tr key={item.ing}>
                  <td>
                    <div className="d-flex flex-column">
                      <small className="text-muted">Valor unitário</small>
                      {new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      }).format(item.val)}
                    </div>
                  </td>
                  <td>{item.total_ing}</td>
                  <td>
                    {item.ing_grat === '1' ? (
                      <Badge variant="secondary">Grátis</Badge>
                    ) : (
                      <Badge variant="success">Pago</Badge>
                    )}
                  </td>
                  <td>
                    <div className="d-flex justify-content-center align-items-center">
                      <button
                        type="button"
                        className="btn btn-light"
                        title="Editar"
                        onClick={() => ediTicket(item)}
                      >
                        <i className="icon-pencil5 text-primary"></i>
                      </button>
                      <button
                        type="button"
                        className="btn btn-light ml-2"
                        title="Apagar"
                        onClick={() => delTickets(item)}
                      >
                        <i className="icon-trash text-primary"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </Card.Body>
      <CreateTicket
        tickets={tickets}
        event={event}
        show={show}
        setShow={setShow}
        load={loadTickets}
      />
      <EditTicket
        ticket={ticket}
        event={event}
        show={showEdit}
        setShow={setShowEdit}
        load={loadTickets}
      />
    </Card>
  );
}

TableTicket.propTypes = {
  event: PropTypes.shape().isRequired,
};
