import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

// import history from '~/services/history';
import api from '~/services/api';

import * as ModsActions from './actions';

export function* modsRequest({ payload }) {
  try {
    const response = yield call(
      api.get,
      `/seguidor-mod/membro/?q=(cod_onde_seguir:${payload})`
    );

    if (response.data.records.error) {
      toast.error(response.data.records.msg);
      yield put(ModsActions.modsGroupFailure());
      return;
    }

    yield put(ModsActions.modsGroupSuccess(response.data.records));
  } catch (err) {
    toast.error('Falha ao buscar grupos, contate o administrador!');
    yield put(ModsActions.modsGroupFailure());
  }
}

export function* followersGetRequest({ payload }) {
  try {
    const response = yield call(
      api.get,
      `/seguidor-mod/?q=(cod_onde_seguir:${payload.cod_onde_seguir},cod_item:${payload.cod_item},tipo_retorno:${payload.tipo_retorno})`
    );

    if (response.data.records.error) {
      yield put(ModsActions.followersGetFailure());
      return;
    }

    yield put(ModsActions.followersGetSuccess(response.data.records));
  } catch (err) {
    toast.error('Falha ao buscar seguidores, contate o administrador!');
    yield put(ModsActions.followersGetFailure());
  }
}

export default all([
  takeLatest('@mods/MODS_REQUEST', modsRequest),
  takeLatest('@mods/FOLLOWERS_GET_REQUEST', followersGetRequest),
]);
