import React from 'react';
import PropTypes from 'prop-types';

import { Modal, Button, Row } from 'react-bootstrap';

import { FiCheckCircle } from 'react-icons/fi';

function BoxConfirm(props) {
  const {
    size,
    show,
    onClose,
    title,
    message,
    messageBottomBold,
    aliasBtnYes,
    aliasBtnNo,
    iconSuccess,
    messageTopBold,
  } = props;

  return (
    <Modal
      size={size}
      className="bootbox-modal"
      show={show}
      onHide={onClose}
      centered
    >
      <Modal.Header className="p-2 bg-light" closeButton>
        <span className="font-weight-bold">{title || 'Atenção'}</span>
      </Modal.Header>
      <Modal.Body className="p-2">
        <Row>
          {iconSuccess && (
            <FiCheckCircle size={48} color="green" style={{ marginLeft: 10 }} />
          )}
          {messageTopBold && (
            <h6
              style={{
                fontWeight: 'bold',
                fontSize: 24,
                marginTop: 5,
                marginLeft: 8,
              }}
            >
              {messageTopBold}
            </h6>
          )}
        </Row>
        <h6>{message || 'Tem certeza de que deseja continuar?'}</h6>
        {messageBottomBold && (
          <h6 style={{ fontWeight: 'bold' }}>{messageBottomBold}</h6>
        )}
      </Modal.Body>
      <Modal.Footer className="bootbox-modal-footer p-2">
        <Button variant="light" onClick={() => props.onNoClick()}>
          {aliasBtnNo || 'Cancelar'}
        </Button>
        <Button variant="success" onClick={() => props.onYesClick()}>
          {aliasBtnYes || 'Confirmar'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

BoxConfirm.defaultProps = {
  title: 'Atenção',
  message: 'Tem certeza de que deseja continuar?',
  aliasBtnNo: 'Cancelar',
  aliasBtnYes: 'Confirmar',
};

BoxConfirm.propTypes = {
  title: PropTypes.string,
  show: PropTypes.bool.isRequired,
  size: PropTypes.string.isRequired,
  onYesClick: PropTypes.func.isRequired,
  onNoClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  message: PropTypes.string,
  messageBottomBold: PropTypes.string.isRequired,
  aliasBtnNo: PropTypes.string,
  aliasBtnYes: PropTypes.string,
};

export default BoxConfirm;
