import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import { Form, Input } from '@rocketseat/unform';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import Post from '~/components/Post';
import history from '~/services/history';

import api from '~/services/api';

import {
  casesListRequest,
  casesGetRequest,
} from '~/store/modules/case/actions';

const schema = Yup.object().shape({
  tit_post: Yup.string()
    .min(5, 'Ops! Titulo muito pequeno')
    .required('O titulo é obrigatório'),
  desc_post: Yup.string().required('A descrição é obrigatória'),
});

export default function Cases() {
  const dispatch = useDispatch();
  const loading = useSelector(state => state.cases.loading);
  const { cases } = useSelector(state => state.cases);
  const { selected } = useSelector(state => state.cases);
  const caseSelect = useSelector(state => state.cases.case);

  const [isModalOpen, setModalStatus] = useState(false);

  const select = useCallback(
    post => {
      dispatch(casesGetRequest(post.cod_post));
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(casesListRequest());
  }, [dispatch]);

  useEffect(() => {
    if (selected) {
      select(selected);
    }
  }, [selected, select]);

  const handleSubmit = useCallback(
    async ({ tit_post, desc_post }) => {
      const response = await api.post('/post', {
        tit_post,
        desc_post,
        onde_post: 6,
        perm: '',
      });

      if (response.data.records.error) {
        toast.error(response.data.records.msg);
        return;
      }
      setModalStatus(false);
      toast.success('Cadastrado com sucesso!');
      dispatch(casesListRequest());
    },
    [dispatch, setModalStatus]
  );

  return (
    <div className="content">
      <div className="row padding-tab-top-post">
        <div className="col-xl-12">
          <div className="d-flex justify-content-between align-items-center">
            <div className="mb-3">
              <h3 className="mb-0 font-weight-semibold">
                <i
                  role="presentation"
                  onClick={() => history.goBack()}
                  className="icon-arrow-left52 mr-2 c-pointer"
                ></i>
                Casos Clínicos
              </h3>
              <span className="text-muted d-block">
                Casos que pode te interessar em compartilhar experiências
              </span>
            </div>
            <div>
              <button
                type="button"
                className="btn btn-sm btn-info"
                onClick={() => setModalStatus(true)}
              >
                <i className="icon-checkmark2 mr-1"></i>Novo Caso
              </button>
            </div>
          </div>
        </div>

        <div className="col">
          <div className="card">
            <div className="card-header header-elements-sm-inline">
              <h6 className="card-title">Lista de Casos</h6>
              <div className="header-elements"></div>
            </div>

            <table className="table ">
              <tbody>
                {cases.map(cas => (
                  <tr
                    className={`c-pointer ${cas.active}`}
                    key={cas.cod_post}
                    onClick={() => select(cas)}
                  >
                    <td>
                      <div className="font-weight-semibold">{cas.tit_post}</div>
                      <span className="text-muted">
                        {`${cas.desc_post.substring(0, 90)}...`}
                      </span>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="mr-2">
                          <a
                            href="#1"
                            className="btn bg-teal-400 rounded-round btn-icon btn-sm"
                          >
                            <span className="letter-icon">
                              {cas.nome_membro.substring(0, 1)}
                            </span>
                          </a>
                        </div>
                        <div>
                          <a
                            href="#1"
                            className="text-default font-weight-semibold letter-icon-title"
                          >
                            {cas.nome_membro}
                          </a>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="font-size-sm text-muted line-height-1">
                        {cas.qtd_com}
                        <i className="icon-comment-discussion mt-1"></i>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="col-xl-7">
          <Post post={caseSelect} loading={loading} />
        </div>
      </div>

      <Modal
        className="modal-container"
        show={isModalOpen}
        onHide={() => setModalStatus(false)}
      >
        <Form schema={schema} onSubmit={handleSubmit} className="from-control">
          <Modal.Header closeButton>
            <Modal.Title>Novo Caso</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="form-group">
              <label>Título</label>
              <Input
                type="text"
                name="tit_post"
                className="form-control"
                placeholder="Digite um título"
              />
            </div>
            <div className="form-group">
              <label>Descrição</label>
              <Input
                multiline
                className="form-control"
                rows="7"
                name="desc_post"
                placeholder="Digite uma descrição"
              />
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="danger" onClick={() => setModalStatus(false)}>
              Fechar
            </Button>
            <Button type="submit">Salvar</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
}
