/* eslint-disable no-unused-expressions */
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Modal, Row, Col, Card } from 'react-bootstrap';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { addDays } from 'date-fns';
import { toast } from 'react-toastify';

import { parcels } from '~/util';
import api from '~/services/api';

import Input from '~/components/InputText';
import Select from '~/components/InputSelect';
import Textarea from '~/components/InputTextarea';
import Datepicker from '~/components/DatePicker';
import InputCurrency from '~/components/InputCurrency';

export default function CreateEvent({
  tickets,
  event,
  show,
  setShow,
  load,
  externalPaid,
  setExternalPaid,
  isNewCourse,
  handleExternalClose,
  endDate,
}) {
  const formRef = useRef(null);
  const [paid, setPaid] = useState(externalPaid);
  const [parcel, setParcel] = useState(false);
  const [ident, setIdent] = useState(false);
  const [view, setView] = useState(true);
  const [type, setType] = useState(3);
  const [loading, setLoading] = useState(false);

  const handleClose = () => setShow(false);

  const handleDirectSubmit = () => {
    formRef.current.submitForm();
  };

  async function handleSubmit(data) {
    try {
      setLoading(true);
      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        val: Yup.string().required('O valor é obrigatório'),
        dt_venda_ini: Yup.date()
          .typeError('Data de início é obrigatória')
          .required('Data de início é obrigatória'),
        dt_venda_fim: Yup.date()
          .typeError('Data fim é obrigatória')
          .required('Data fim é obrigatória'),
        total_ing: Yup.number()
          .typeError('Quantidade é obrigatória')
          .positive('Quantidade deve ser difirente de 0')
          .required('Quantidade é obrigatória'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      let canSave = true;
      if (type === 2) {
        const ticketValue = parseFloat(data.val.split('R$ ')[1]);
        if (ticketValue < 2) {
          canSave = false;
          setLoading(false);
          toast.error('O valor mínimo para gerar um ingresso é de R$ 2,00!');
        }
      }

      if (canSave) {
        api
          .put(`/evento/atualizar-preco/${event.cod_evento}`, data)
          .then(response => {
            if (response.data.records.error) {
              toast.error(response.data.records.msg);
              setLoading(false);
              return;
            }
            toast.success('Ingresso criado com sucesso!');
            isNewCourse ? handleExternalClose(data) : load(event);
            setLoading(false);
            handleClose();
          });
      }
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
      setLoading(false);
    }
  }

  const handleTypeTicket = typed => {
    if (typed === 1) {
      setType(1);
      setExternalPaid && setExternalPaid(false);
      setPaid(false);
    } else {
      setType(2);
      setExternalPaid && setExternalPaid(true);
      setPaid(true);
    }
  };

  return (
    <Modal
      className="modal-new-event"
      dialogClassName="modal-event"
      show={show}
      onHide={handleClose}
      centered
    >
      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        initialData={{
          dt_venda_ini: new Date(),
          dt_venda_fim: endDate || addDays(new Date(), 1),
          val: 0,
          forma_parc: '1',
        }}
      >
        <Modal.Body>
          <Modal.Title className="d-flex flex-column justify-content-center align-items-center mb-3">
            <span className="text-teal mr-md-3 mb-3 mb-md-0 d-none">
              <i className="icon-question7 icon-1x text-success-400 border-success-400 border-2 rounded-round p-2"></i>
            </span>
            Qual tipo de ingresso você deseja criar?
          </Modal.Title>

          <Row className="justify-content-md-center mb-4">
            <Col xl={4}>
              <Card
                onClick={() => handleTypeTicket(2)}
                type="button"
                className={`border-light shadow-sm type-ticket ${
                  type === 2 ? 'select-ticket' : ''
                }`}
              >
                <Card.Body className="d-flex flex-column justify-content-center align-items-center">
                  <i className="icon-ticket icon-2x"></i>
                  <p className="mt-2">Ingresso pago</p>
                </Card.Body>
              </Card>
            </Col>
            <Col xl={4}>
              <Card
                onClick={() => handleTypeTicket(1)}
                type="button"
                className={`border-light shadow-sm type-ticket ${
                  type === 1 ? 'select-ticket' : ''
                }`}
              >
                <Card.Body className="d-flex flex-column justify-content-center align-items-center">
                  <i className="icon-ticket icon-2x"></i>
                  <p className="mt-2">Ingresso grátis</p>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          {type < 3 && (
            <>
              <Row>
                <Col xl={6}>
                  <div className="form-group">
                    <label>Início das vendas</label>
                    <Input
                      name="cod_evento"
                      type="hidden"
                      defaultValue={event.cod_evento}
                    />
                    <Input
                      name="ing"
                      type="hidden"
                      defaultValue={tickets.length + 1}
                    />
                    <Input name="ing_grat" type="hidden" defaultValue={type} />
                    <Datepicker
                      name="dt_venda_ini"
                      placeholder="Selecione uma data"
                      minDate={new Date()}
                      maxDate={endDate || null}
                    />
                  </div>
                </Col>
                <Col xl={6}>
                  <div className="form-group">
                    <label>Fim das vendas</label>
                    <Datepicker
                      name="dt_venda_fim"
                      placeholder="Selecione uma data"
                      minDate={new Date()}
                      maxDate={endDate || null}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xl={6}>
                  <div className="form-group">
                    <label>Quantidade disponível para venda</label>
                    <Input name="total_ing" type="number" placeholder="100" />
                  </div>
                </Col>
                <Col xl={6}>
                  {paid ? (
                    <div className="form-group">
                      <label>Valor do ingresso</label>
                      <InputCurrency
                        name="val"
                        onFocus={e => {
                          e.target.select();
                        }}
                      />
                    </div>
                  ) : (
                    <div className="form-group">
                      <label>Valor do ingresso</label>
                      <InputCurrency name="val" value={0} disabled />
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col
                  xl={12}
                  className="d-flex justify-content-center flex-column"
                >
                  <Button
                    variant="link"
                    onClick={() => setParcel(!parcel)}
                    disabled={type === 1}
                  >
                    Opções de parcelamento
                  </Button>
                  {parcel && paid && (
                    <Row>
                      <Col>
                        <div className="form-group">
                          <label>Forma de parcelamento</label>
                          <Select
                            onChange={e => {
                              setView(e.value !== '2');
                            }}
                            name="forma_parc"
                            placeholder="--Selecione--"
                            options={[
                              { value: '1', label: 'Pagamento à vista' },
                              {
                                value: '2',
                                label: 'Parcelado SEM tarifas para meu cliente',
                              },
                            ]}
                          />
                        </div>
                      </Col>
                      <Col>
                        <div className="form-group">
                          <label>Quantidade de parcelas</label>
                          <Select
                            isDisabled={view}
                            name="max_parcela"
                            placeholder="--Selecione--"
                            options={parcels()}
                          />
                        </div>
                      </Col>
                    </Row>
                  )}
                </Col>
                <Col className="d-flex justify-content-center flex-column">
                  <Button variant="link" onClick={() => setIdent(!ident)}>
                    Identificação do ingresso
                  </Button>
                  {ident && (
                    <>
                      <div className="form-group">
                        <label>Nome</label>
                        <Input
                          name="nome_ing"
                          type="text"
                          placeholder="Digite o nome do ingresso"
                        />
                      </div>

                      <div className="form-group">
                        <label>Descrição do ingresso</label>
                        <Textarea
                          name="desc_val"
                          type="text"
                          placeholder="De uma breve explicação aos participantes sobre este ingresso"
                        />
                      </div>
                    </>
                  )}
                </Col>
              </Row>
            </>
          )}
        </Modal.Body>
        {type < 3 && (
          <Modal.Footer className="d-flex justify-content-center">
            <Button
              size="lg"
              block
              className="text-secondary"
              variant="link"
              onClick={handleClose}
            >
              <i className="icon-cross2 mr-1 font-weight-light"></i>
              Cancelar
            </Button>
            <Button
              size="lg"
              block
              onClick={() => handleDirectSubmit()}
              disabled={loading}
            >
              {loading ? 'Aguarde...' : 'Criar ingresso'}
            </Button>
          </Modal.Footer>
        )}
      </Form>
    </Modal>
  );
}

CreateEvent.propTypes = {
  tickets: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  event: PropTypes.shape().isRequired,
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  load: PropTypes.func.isRequired,
};
