import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';

import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import { Row, Col, Card } from 'react-bootstrap';

import api from '~/services/api';
import Select from '~/components/InputSelect';
import Loading from '~/components/Loading';
import LatestNews from '~/components/LatestNews';

const banks = items => {
  const data = items.map(value => {
    return {
      value: value.iugu_accountid,
      label: `${value.bank} - ${value.bank_cc}`,
    };
  });
  return data;
};

export default function Dashboard() {
  const formRef = useRef(null);
  const [dash, setDash] = useState([]);
  const [bank, setBank] = useState([]);
  const [info, setInfo] = useState({});
  const [selected, setSelected] = useState(0);
  const [type, setType] = useState(0);
  const [disp, setDisp] = useState(0);
  const [bloq, setBloq] = useState(0);
  const [total, setTotal] = useState(0);
  const [load, setLoad] = useState(true);
  const [loadResult, setLoadResult] = useState(false);

  const loadDash = useCallback(async () => {
    api.get('/evento/listar-eve-cur-pro').then(response => {
      setDash(() => {
        const { records } = response.data;
        const cod = records.length > 0 ? records[records.length - 1].cod : 0;
        const typed = records.length > 0 ? records[records.length - 1].tipo : 0;
        setSelected(cod);
        setType(typed);
        return records.map(record => {
          switch (record.tipo) {
            case '1':
              record.desc = `Evento - ${record.desc}`;
              break;
            case '2':
              record.desc = `Curso - ${record.desc}`;
              break;
            case '3':
              record.desc = `Produto - ${record.desc}`;
              record.cod = `p${record.cod}`;
              break;
            default:
              break;
          }

          return {
            label: record.desc,
            value: record.cod,
            type: record.tipo,
          };
        });
      });
    });
  }, []);

  const result = useCallback(async data => {
    setLoadResult(true);
    const replace = data.value.toString().replace('p', '');
    await api
      .get(`/evento/get-info-dashboard/?q=(cod:${replace},tipo:${data.type})`)
      .then(response => {
        setInfo(response.data.records);
        setLoadResult(false);
      });
  }, []);

  const loadBank = useCallback(async () => {
    setLoad(true);
    const response = await api.get('/config/get-dados-conta2');

    if (response.data.records.length === 0) {
      setBank([]);
      setLoad(false);
      return;
    }

    setBank(response.data.records);
    setLoad(false);
  }, []);

  useEffect(() => {
    loadDash();
    loadBank();
    result({ value: selected, type });
  }, [loadDash, loadBank, type, result, selected]);

  const handleBank = useCallback(value => {
    api.get(`/config/get-dados-saldo/${value.value}`).then(response => {
      if (response.data.records.error) {
        toast.error(response.data.records.msg);
        return;
      }
      setDisp(response.data.records.disp_saq);
      setBloq(response.data.records.disp_bloq);
      setTotal(response.data.records.total);
    });
  }, []);

  useMemo(() => {
    if (bank.length) {
      handleBank({
        value: bank[bank.length - 1].iugu_accountid,
      });
    }
  }, [handleBank, bank]);

  return (
    <div className="content padding-tab-top-post">
      {load ? (
        <Loading />
      ) : (
        <>
          <Row>
            <Col>
              <Card>
                <Card.Header>
                  <Form ref={formRef} initialData={{ dashboard: selected }}>
                    <Row>
                      <Col xl={5}>
                        <Select
                          name="dashboard"
                          placeholder="Selecione"
                          options={dash}
                          onChange={result}
                        />
                      </Col>
                    </Row>
                  </Form>
                </Card.Header>
                <Card.Body>
                  {loadResult ? (
                    <Loading />
                  ) : (
                    <Row>
                      <Col className="d-flex justify-content-center">
                        <div className="media">
                          <div className="mr-3 align-self-center">
                            <i className="icon-ticket icon-3x text-indigo-400"></i>
                          </div>

                          <div className=" text-center">
                            <h3 className="font-weight-semibold mb-0">
                              {info.qtd1 || 0}
                            </h3>
                            <span className="text-uppercase font-size-sm text-muted">
                              Total de Inscrições
                            </span>
                          </div>
                        </div>
                      </Col>
                      <Col className="d-flex justify-content-center">
                        <div className="media">
                          <div className="mr-3 align-self-center">
                            <i className="icon-coins icon-3x text-success-400"></i>
                          </div>

                          <div className=" text-center">
                            <h3 className="font-weight-semibold mb-0">
                              {new Intl.NumberFormat('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                              }).format(info.soma1 || 0)}
                            </h3>
                            <span className="text-uppercase font-size-sm text-muted">
                              Total de Vendas
                            </span>
                          </div>
                        </div>
                      </Col>
                      <Col className="d-flex justify-content-center">
                        <div className="media">
                          <div className="mr-3 align-self-center">
                            <i className="icon-heart5 icon-3x text-danger-400"></i>
                          </div>

                          <div className=" text-center">
                            <h3 className="font-weight-semibold mb-0">
                              {info.curt || 0}
                            </h3>
                            <span className="text-uppercase font-size-sm text-muted">
                              Curtidas
                            </span>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xl={6}>
              <Card>
                <Card.Header>
                  <Card.Title>Recebimentos</Card.Title>
                  <Row>
                    <Col md="6">
                      <Form
                        initialData={{
                          bank:
                            bank.length && bank[bank.length - 1].iugu_accountid,
                        }}
                      >
                        <Select
                          name="bank"
                          placeholder="Selecione uma conta"
                          options={banks(bank)}
                          onChange={handleBank}
                        />
                        <small>Selecione uma conta para visualizar</small>
                      </Form>
                    </Col>
                  </Row>
                </Card.Header>
                <Card.Body>
                  <div className="media border-bottom pb-2">
                    <div className="align-self-center">
                      <i className="icon-coin-dollar icon-3x text-success mr-2"></i>
                      <span className="text-uppercase font-size-sm">
                        Total disponível para saque
                      </span>
                    </div>

                    <div className="media-body text-right">
                      <h3 className="font-weight-semibold">
                        {new Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        }).format(disp)}
                      </h3>
                    </div>
                  </div>
                  <div className="media border-bottom pb-2">
                    <div className="align-self-center">
                      <i className="icon-coin-dollar icon-3x text-danger mr-2"></i>
                      <span className="text-uppercase font-size-sm">
                        Total ainda bloqueado
                      </span>
                    </div>

                    <div className="media-body text-right">
                      <h3 className="font-weight-semibold mb-0">
                        {new Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        }).format(bloq)}
                      </h3>
                    </div>
                  </div>
                  <div className="media">
                    <div className="mr-3 align-self-center">
                      <i className="icon-coin-dollar icon-3x text-info mr-2"></i>
                      <span className="text-uppercase font-size-sm">
                        Total na conta
                      </span>
                    </div>

                    <div className="media-body text-right">
                      <h3 className="font-weight-semibold mb-0">
                        {new Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        }).format(total)}
                      </h3>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>

            <Col xl={6}>
              <LatestNews />
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}
