/* eslint-disable no-unused-expressions */
/* eslint-disable eqeqeq */
/* eslint-disable no-console */
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Form } from '@unform/web';
import { Modal, Button, Table, Alert } from 'react-bootstrap';
import { toast } from 'react-toastify';

import api from '~/services/api';

import { formatCurrencyValue } from '~/util';

export default function ModalWithdraw({ modalShow, modalClose, data }) {
  const withdrawTaxValue = 3;
  const [withdraws, setWithdraws] = useState([]);
  const [total, setTotal] = useState(0);
  const [selectedWithdraws, setSelectedWithdraws] = useState([]);

  const compareWithdraw = (value1, value2) => {
    return value1.cod_fatura === value2.cod_fatura;
  };

  const handleWithdraw = useCallback(
    value => {
      let sum;
      if (
        selectedWithdraws.filter(v => compareWithdraw(v, value)).length === 0
      ) {
        setSelectedWithdraws([...selectedWithdraws, value]);
        sum = Number(total) + Number(value.valor_prof);
      } else {
        const newSelectedWithdraws = selectedWithdraws.map(function(wd) {
          if (wd.cod_fatura !== value.cod_fatura) {
            return wd;
          }
          return {};
        });
        setSelectedWithdraws(newSelectedWithdraws);
        sum = Number(total) - Number(value.valor_prof);
      }
      setTotal(sum);
    },
    [total, selectedWithdraws]
  );

  const fillTotal = useCallback(withdrawArray => {
    const valuesArray = withdrawArray.map(w => Number(w.valor_prof));
    const t = valuesArray.reduce(function(ttl, numero) {
      return ttl + numero;
    }, 0);
    setTotal(t);
  }, []);

  const loadWithdraw = useCallback(() => {
    if (data !== null) {
      api
        .get(`/config/listar-contas-saque/?q=(iugu_accountid:${data})`)
        .then(response => {
          setWithdraws(response.data.records);
          setSelectedWithdraws(response.data.records);
          fillTotal(response.data.records);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (modalShow) {
      loadWithdraw();
    }
  }, [loadWithdraw, modalShow, data]);

  const handleSubmit = useCallback(() => {
    let codes = selectedWithdraws.map(w => w.cod_fatura);
    codes = codes.filter(w => w !== undefined);
    api
      .post('/config/salvar-realizar-saque', {
        arr_cod_fatura: codes,
        iugu_accountid: data,
        valor_saque: Number.parseFloat(total - withdrawTaxValue).toFixed(2),
      })
      .then(response => {
        if (!response.data.records.error) {
          toast.success(response.data.records.msg);
          modalClose();
        } else {
          toast.error(response.data.records.msg);
        }
      });
  }, [data, modalClose, selectedWithdraws, total]);

  return (
    <Modal size="lg" centered show={modalShow} onHide={modalClose}>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Realizar saque</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert
            variant="info"
            className={`border-0 ${withdraws.length ? '' : 'd-none'}`}
          >
            Marque seus recebimentos que gostaria de enviar para sua conta
            corrente na tabela abaixo.
            <br />
            <b>Obs:</b> Ao final da transação será debitado o valor de R$3,00
            pelo saque.
          </Alert>
          <Table striped hover>
            <thead>
              <tr>
                <th aria-label="actions"></th>
                <th>Cliente</th>
                <th>Descrição</th>
                <th>Pago em</th>
                <th>Valor pago R$</th>
                <th>Valor receber R$</th>
                <th>Forma de pagamento</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(withdraws).length === 0 ? (
                <tr>
                  <td colSpan="7" className="p-0 text-center">
                    <div className="alert alert-info border-0 alert-dismissible mb-0">
                      <i className="icon-info3 border-2 rounded-round p-1 mr-2"></i>
                      Caro usuário, não possui saldo para saque nesta conta
                    </div>
                  </td>
                </tr>
              ) : (
                withdraws.map(withdraw => (
                  <tr key={withdraw.cod_fatura}>
                    <td>
                      <input
                        name="withdraw"
                        type="checkbox"
                        onClick={() => handleWithdraw(withdraw)}
                        defaultChecked
                      />
                    </td>
                    <td>{withdraw.nome_membro_pagador}</td>
                    <td>{withdraw.str_receita}</td>
                    <td>
                      {/* {format(parseISO(withdraw.dt_pagamento), 'dd/MM/yyyy')} */}
                    </td>
                    <td>{formatCurrencyValue(withdraw.valor_pago)}</td>
                    <td>{formatCurrencyValue(withdraw.valor_prof)}</td>
                    <td>
                      {withdraw.forma_pgto === '8' && 'Boleto'}
                      {withdraw.forma_pgto === '3' && 'Cartão de crédito'}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
            {total !== 0 && (
              <tfoot>
                <tr>
                  <td colSpan="7" className="p-0 text-center">
                    <div className="alert alert-warning border-0 alert-dismissible mb-0">
                      {total - withdrawTaxValue >= 0
                        ? 'O valor que será transferido pra sua conta será '
                        : `Deve ser selecionado um valor total de pelo menos R$${withdrawTaxValue},00 para realizar a operação`}
                      {total - withdrawTaxValue >= 0 && (
                        <strong>
                          {formatCurrencyValue(total - withdrawTaxValue)}
                        </strong>
                      )}
                      <p>
                        É cobrada uma taxa de R${withdrawTaxValue},00 por saque,
                        já aplicada no valor acima.
                      </p>
                    </div>
                  </td>
                </tr>
              </tfoot>
            )}
          </Table>
        </Modal.Body>
        <Modal.Footer>
          {total >= withdrawTaxValue && (
            <Button variant="primary" type="submit">
              Transferir
            </Button>
          )}
          <Button variant="secondary" type="button" onClick={modalClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

ModalWithdraw.propTypes = {
  modalShow: PropTypes.bool.isRequired,
  modalClose: PropTypes.func.isRequired,
  data: PropTypes.string.isRequired,
};
