import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { Card, Button } from 'react-bootstrap';

import * as Yup from 'yup';

import api from '~/services/api';
import history from '~/services/history';
import Input from '~/components/InputText';

import * as AuthActions from '~/store/modules/auth/actions';

function Affiliate() {
  const formRef = useRef(null);
  const dispatch = useDispatch();

  const { codigo } = useParams();
  useEffect(() => {
    if (!codigo) {
      history.push('/login');
    }
  }, [codigo]);

  async function handleSubmit(data) {
    try {
      formRef.current.setErrors({});
      const schema = Yup.object().shape({
        nome_membro: Yup.string().required(
          'Nome é um campo de preenchimento obrigatório!'
        ),
        email_membro: Yup.string()
          .email('Insira um e-mail válido!')
          .required('E-mail é um campo de preenchimento obrigatório!'),
        nova_senha: Yup.string()
          .min('6', 'Senha muito curta!')
          .required('Senha é um campo de preenchimento obrigatório!'),
        repetir: Yup.string()
          .oneOf(
            [Yup.ref('nova_senha'), null],
            'Senhas diferentes, por gentileza verifique!'
          )
          .required('Repetir a senha é um campo de preenchimento obrigatório!'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const response = await api.post('/auth/salvar-afiliado', data);
      if (response.data.records.error) {
        toast.error(response.data.records.msg);
      } else {
        toast.success(response.data.records.msg);
        dispatch(
          AuthActions.signInRequest(data.email_membro, data.nova_senha, 1)
        );
      }
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
    return false;
  }

  return (
    <Form ref={formRef} onSubmit={handleSubmit} className="login-form">
      <Card className="mb-0">
        <Card.Body>
          <div className="text-center mb-3">
            <img
              width={180}
              style={{ marginBottom: 20 }}
              src={require('../../assets/img/psicoMart22.png')}
              alt="Afiliado PsicoMart"
            />
            <h5 className="mb-0">Sistema de Afiliados</h5>
            <span className="d-block text-muted">
              Digite seus dados no formulário abaixo:
            </span>
          </div>

          <div className="form-group form-group-feedback form-group-feedback-right">
            <Input id="cod" name="cod" defaultValue={codigo} type="hidden" />
            <Input
              id="nome_membro"
              name="nome_membro"
              type="text"
              placeholder="Entre com seu nome..."
            />
            <div className="form-control-feedback">
              <i className="icon-person text-muted"></i>
            </div>
          </div>

          <div className="form-group form-group-feedback form-group-feedback-right">
            <Input
              id="email_membro"
              name="email_membro"
              type="email"
              placeholder="Entre com seu melhor e-mail..."
            />
            <div className="form-control-feedback">
              <i className="icon-mail5 text-muted"></i>
            </div>
          </div>

          <div className="form-group form-group-feedback form-group-feedback-right">
            <Input
              id="nova_senha"
              name="nova_senha"
              type="password"
              placeholder="Entre com a sua senha..."
            />
            <div className="form-control-feedback">
              <i className="icon-lock2 text-muted"></i>
            </div>
          </div>

          <div className="form-group form-group-feedback form-group-feedback-right">
            <Input
              id="repetir"
              name="repetir"
              type="password"
              placeholder="Repetir sua senha..."
            />
            <div className="form-control-feedback">
              <i className="icon-lock2 text-muted"></i>
            </div>
          </div>

          <Button type="submit" block variant="primary">
            Salvar
          </Button>
        </Card.Body>
      </Card>
    </Form>
  );
}

export default Affiliate;
