import React from 'react';

import { Link } from 'react-router-dom';

function Explorar() {
  return (
    <div className="card">
      <ul className="list-group noborder">
        <li className="list-group-item font-weight-semibold">
          EXPLORAR
          <div className="ml-auto user-like ">
            <a href="#1">
              <i className="icon-plus3  text-dark"></i>
            </a>
          </div>
        </li>
        <Link to="/groups">
          <li
            className="list-group-item list-interesse"
            style={{ cursor: 'pointer' }}
          >
            <i className="icon-grid5 font-size-xs  mr-1"></i> Grupos
          </li>
        </Link>

        <Link to="/eventos">
          <li
            className="list-group-item list-interesse"
            style={{ cursor: 'pointer' }}
          >
            <i className="icon-grid5 font-size-xs  mr-1"></i> Eventos
          </li>
        </Link>

        <Link to="/casos">
          <li
            className="list-group-item list-interesse"
            style={{ cursor: 'pointer' }}
          >
            <i className="icon-grid5 font-size-xs  mr-1"></i> Casos Clínicos
          </li>
        </Link>

        <Link to="/documentos">
          <li
            className="list-group-item list-interesse"
            style={{ cursor: 'pointer' }}
          >
            <i className="icon-grid5 font-size-xs  mr-1"></i> Documentos e
            E-books
          </li>
        </Link>

        <Link to="/shopping">
          <li
            className="list-group-item list-interesse"
            style={{ cursor: 'pointer' }}
          >
            <i className="icon-grid5 font-size-xs  mr-1"></i> Shopping
          </li>
        </Link>

        <Link to="/empregos">
          <li
            className="list-group-item list-interesse"
            style={{ cursor: 'pointer' }}
          >
            <i className="icon-grid5 font-size-xs  mr-1"></i> Empregos
          </li>
        </Link>

        <Link to="/filmes">
          <li
            className="list-group-item list-interesse"
            style={{ cursor: 'pointer' }}
          >
            <i className="icon-grid5 font-size-xs  mr-1"></i> Filmes e Séries
          </li>
        </Link>

        <Link to="/podcasts">
          <li
            className="list-group-item list-interesse"
            style={{ cursor: 'pointer' }}
          >
            <i className="icon-grid5 font-size-xs  mr-1"></i> Podcasts
          </li>
        </Link>

        <Link to="/locacao">
          <li
            className="list-group-item list-interesse"
            style={{ cursor: 'pointer' }}
          >
            <i className="icon-grid5 font-size-xs  mr-1"></i> Sublocação de
            Salas
          </li>
        </Link>
      </ul>
    </div>
  );
}

export default Explorar;
