import React, { useState } from 'react';

import api from '~/services/api';
import BoxConfirm from '~/components/BoxConfirm';

export default function DeleteComment({ id, loadPost }) {
  const [showBox, setShowBox] = useState(false);

  function onClose() {
    setShowBox(false);
  }

  async function deleteComment() {
    const response = await api.put(`/post/cancelar/${id}`);

    if (!response.data.records.error) {
      loadPost();
      onClose();
    }
  }

  return (
    <>
      <li className="list-inline-item">
        <span className="text-muted c-pointer" onClick={() => setShowBox(true)}>
          Apagar
        </span>
      </li>
      <BoxConfirm
        title="Apagar"
        show={showBox}
        size="sm"
        onYesClick={deleteComment}
        onNoClick={onClose}
        onClose={onClose}
        message="Tem certeza de que deseja apagar esse comentário?"
      />
    </>
  );
}
