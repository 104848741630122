import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import crypto from 'crypto';

import Config from '~/config';

import { signInRequest } from '~/store/modules/auth/actions';

function decrypt(password) {
  const decipher = crypto.createDecipher(
    Config.ENCRYPT.algorithm,
    Config.ENCRYPT.secret
  );
  decipher.update(password, Config.ENCRYPT.type);
  return decipher.final('utf8');
}

export default function Login({ match }) {
  const dispatch = useDispatch();
  const { email, password } = match.params;

  useEffect(() => {
    const site = 1;
    const hash = decrypt(password);
    dispatch(signInRequest(email, hash, site));
    // setTimeout(function() {
    //   window.history.back();
    // }, 2000);
  }, [dispatch, email, password]);

  return false;
}
