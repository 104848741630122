import React, { useState, useCallback, useEffect } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import PlacesAutocomplete from 'react-places-autocomplete';
import fetchGoogle from '~/services/google';
import Input from '~/components/InputText';
import Select from '~/components/InputSelect';

export default function LocationEvent({ event, module }) {
  const [location, setLocation] = useState({});
  const [locale, setLocale] = useState('');
  const [online, setOnline] = useState(event.eh_online);
  const [link, setLink] = useState(event.google_eve === '0');

  const handleChange = useCallback(async data => {
    setLocale(data);
  }, []);

  const handleSelect = useCallback(
    async local => {
      const locales = await fetchGoogle(local);
      const loc = {
        ...location,
        local_eve_desc: local,
        cep_eve: locales.code,
        end_eve: locales.address,
        nro_eve: locales.number,
        bairro_eve: locales.neighborhood,
        nome_cidade_eve: locales.city,
        nome_uf_eve: locales.state,
        comp_eve: locales.complement,
        google_eve: locales.place_id,
      };
      setLocation(loc);
      setLocale(local);
    },
    [location]
  );

  const handleOnline = useCallback(e => {
    switch (e.value) {
      case '0':
        setOnline('0');
        break;
      case '1':
        setOnline('1');
        setLink(true);
        break;
      default:
        break;
    }
  }, []);

  useEffect(() => {
    setLocation(event);
  }, [event]);

  return (
    <Card>
      <Card.Body>
        <Card.Title>Onde o {module} vai acontecer?</Card.Title>
        <Row>
          <Col xl={6}>
            <div className="form-group">
              <label>Online ou Presencial*</label>
              <Select
                placeholder="Selecione"
                name="eh_online"
                options={[
                  { value: '0', label: 'Cadastrar endereço - Presencial' },
                  {
                    value: '1',
                    label: `${module.charAt(0).toUpperCase() +
                      module.slice(1)} online`,
                  },
                ]}
                onChange={e => handleOnline(e)}
              ></Select>
            </div>
          </Col>
        </Row>

        {online === '0' && (
          <>
            <Row>
              <Col xl={6}>
                <div className="form-group">
                  <PlacesAutocomplete
                    value={locale}
                    onChange={handleChange}
                    onSelect={handleSelect}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div>
                        <Input
                          name="local_eve_desc"
                          {...getInputProps({
                            placeholder: 'Pesquisar local',
                            className: 'form-control',
                          })}
                        />
                        <div className="autocomplete-dropdown-container">
                          {loading && (
                            <div className="suggestion-item">Loading...</div>
                          )}
                          {suggestions.map(suggestion => {
                            const className = suggestion.active
                              ? 'suggestion-item--active'
                              : 'suggestion-item';
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                })}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                </div>
              </Col>
            </Row>
            <div className="form-group">
              <label>Local do {module}</label>
              <p className="font-weight-bold">{location.local_eve_desc}</p>
            </div>
            <Row>
              <Col xl={2}>
                <div className="form-group">
                  <label>Cep</label>
                  <Input
                    name="google_eve"
                    defaultValue={location.google_eve}
                    type="hidden"
                  />
                  <Input
                    defaultValue={location.cep_eve}
                    className="form-control"
                    name="cep_eve"
                    type="text"
                    placeholder="Cep"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xl={9}>
                <div className="form-group">
                  <label>Rua/Av</label>
                  <Input
                    defaultValue={location.end_eve}
                    className="form-control"
                    name="end_eve"
                    type="text"
                    placeholder="Endereço"
                  />
                </div>
              </Col>
              <Col>
                <div className="form-group">
                  <label>Número</label>
                  <Input
                    className="form-control"
                    name="nro_eve"
                    type="text"
                    placeholder="Número"
                    defaultValue={location.nro_eve}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="form-group">
                  <label>Complemento</label>
                  <Input
                    className="form-control"
                    type="text"
                    name="comp_eve"
                    placeholder="Nome do evento"
                    defaultValue={location.comp_eve}
                  />
                </div>
              </Col>
              <Col>
                <div className="form-group">
                  <label>Bairro</label>
                  <Input
                    className="form-control"
                    name="bairro_eve"
                    type="text"
                    placeholder="Nome do evento"
                    defaultValue={location.bairro_eve}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="form-group">
                  <label>Cidade</label>
                  <Input
                    className="form-control"
                    name="nome_cidade_eve"
                    type="text"
                    placeholder="Nome do evento"
                    defaultValue={location.nome_cidade_eve}
                  />
                </div>
              </Col>
              <Col>
                <div className="form-group">
                  <label>Estado</label>
                  <Input
                    className="form-control"
                    name="nome_uf_eve"
                    type="text"
                    placeholder="Nome do evento"
                    defaultValue={location.nome_uf_eve}
                  />
                </div>
              </Col>
            </Row>
          </>
        )}
        {online === '1' && (
          <Row>
            {module !== 'evento' && (
              <Col xl={6}>
                <div className="form-group">
                  <label>
                    O participante acessará ao PsicoMart para visualizar o{' '}
                    {module}?
                  </label>
                  <Select
                    placeholder="Selecione"
                    name="google_eve"
                    onChange={e =>
                      e.value === '0' ? setLink(true) : setLink(false)
                    }
                    options={[
                      { value: '1', label: 'Sim' },
                      { value: '0', label: 'Não' },
                    ]}
                  />
                </div>
              </Col>
            )}
            {link && (
              <Col xl={6}>
                <div className="form-group">
                  <label>Link de acesso</label>
                  <Input name="local_eve_desc" />
                </div>
              </Col>
            )}
          </Row>
        )}
      </Card.Body>
    </Card>
  );
}
