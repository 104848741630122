import React from 'react';

import { useSelector } from 'react-redux';
import Loader from '~/components/LoaderList';

function Interesses() {
  const loading = useSelector(state => state.interest.loading);
  const { interests } = useSelector(state => state.interest);

  return (
    <div className="card">
      <ul className="list-group noborder">
        <li className="list-group-item font-weight-semibold">
          INTERESSES
          <div className="ml-auto user-like ">
            <a href="#1">
              <i className="icon-plus3  text-dark"></i>
            </a>
          </div>
        </li>
        {loading ? (
          <Loader />
        ) : (
          <>
            {interests.map(interest => (
              <li
                key={interest.cod_interesse}
                className="list-group-item list-interesse"
                style={{ cursor: 'pointer' }}
              >
                <i className="icon-hash font-size-xs  mr-1"></i>
                {interest.nome_interesse}
                {/* <span className="badge badge-pill bg-pink-400 ml-auto">1</span> */}
              </li>
            ))}
          </>
        )}
      </ul>
      <span className="list-group-item font-weight-semibold nopadding-top d-none">
        <a href="#2" className="text-dark">
          Ver Mais &gt;&gt;
        </a>
      </span>
    </div>
  );
}

export default Interesses;
