export function addProduct(product) {
  return {
    type: '@cart/ADD_PRODUCT_IN_CART',
    payload: product,
  };
}

export function setConfigCep(configCep) {
  return {
    type: '@cart/SET_CONFIG_CEP_TO_CART',
    payload: configCep,
  };
}

export function addEvent(event) {
  return {
    type: '@cart/ADD_EVENT_IN_CART',
    payload: event,
  };
}

export function addParticipant(participant) {
  return {
    type: '@cart/ADD_PARTICIPANTS_IN_CART',
    payload: participant,
  };
}

export function addTicket(ticket, amount) {
  return {
    type: '@cart/ADD_TICKET_IN_CART',
    payload: { ticket, amount },
  };
}

export function addStep() {
  return {
    type: '@cart/ADD_STEP_IN_CART',
  };
}

export function addAffiliate(affiliate) {
  return {
    type: '@cart/ADD_AFFILIATE_IN_CART',
    payload: affiliate,
  };
}

export function getCouponRequest(data) {
  return {
    type: '@cart/GET_COUPON_REQUEST',
    payload: data,
  };
}

export function getCouponSuccess(data) {
  return {
    type: '@cart/GET_COUPON_SUCCESS',
    payload: data,
  };
}

export function getCouponFailure() {
  return {
    type: '@cart/GET_COUPON_FAILURE',
  };
}

export function getInstallmentsRequest(data) {
  return {
    type: '@cart/GET_INSTALLMENTS_REQUEST',
    payload: data,
  };
}

export function getInstallmentsSuccess(data) {
  return {
    type: '@cart/GET_INSTALLMENTS_SUCCESS',
    payload: data,
  };
}

export function getInstallmentsFailure() {
  return {
    type: '@cart/GET_INSTALLMENTS_FAILURE',
  };
}

export function getPaymentRequest(data) {
  return {
    type: '@cart/GET_PAYMENT_REQUEST',
    payload: data,
  };
}

export function getPaymentSuccess(data) {
  return {
    type: '@cart/GET_PAYMENT_SUCCESS',
    payload: data,
  };
}

export function getPaymentFailure() {
  return {
    type: '@cart/GET_PAYMENT_FAILURE',
  };
}

export function clearCart() {
  return {
    type: '@cart/CLEAR_CART',
  };
}
