import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import * as FreightsActions from './actions';

export function* freightsGetRequest() {
  try {
    const response = yield call(api.get, `/frete`);
    if (response.data.records.error) {
      toast.error(response.data.records.msg);
      yield put(FreightsActions.freightsGetFailure());
      return;
    }
    yield put(FreightsActions.freightsGetSuccess(response.data.records));
  } catch (err) {
    toast.error('Falha na comunicação com servidor!');
    yield put(FreightsActions.freightsGetFailure());
  }
}

export function* freightGetByIdRequest({ payload }) {
  try {
    const response = yield call(api.get, `/frete/${payload}`);
    if (response.data.records.error) {
      toast.error(response.data.records.msg);
      yield put(FreightsActions.freightGetFailure());
      return;
    }
    yield put(FreightsActions.freightGetSuccess(response.data.records));
  } catch (err) {
    toast.error('Falha na comunicação com servidor!');
    yield put(FreightsActions.freightGetFailure());
  }
}

export function* freightValueGetRequest({ payload }) {
  try {
    const response = yield call(api.get, `/pagina/get-frete/${payload}`);
    if (response.data.records.error) {
      toast.error(response.data.records.msg);
      yield put(FreightsActions.getFreightValueFailure());
      return;
    }
    yield put(FreightsActions.getFreightValueSuccess(response.data.records));
  } catch (err) {
    toast.error('Falha na comunicação com servidor!');
    yield put(FreightsActions.getFreightValueFailure());
  }
}

export default all([
  takeLatest('@freights/GET_FREIGHTS_REQUEST', freightsGetRequest),
  takeLatest('@freights/GET_FREIGHT_REQUEST', freightGetByIdRequest),
  takeLatest('@freights/GET_FREIGHT_VALUE_REQUEST', freightValueGetRequest),
]);
