import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Loading from '~/components/Loading';

export default function Followers({ title, add }) {
  const loading = useSelector(state => state.mods.loading);

  const followers = useSelector(state => state.mods.followers);

  return (
    <div className="card">
      <div className="card-header">
        <h5 className="mb-0">{title}</h5>
      </div>
      <div className="card-body">
        {loading ? (
          <Loading />
        ) : (
          <div className="d-flex flex-wrap align-items-center">
            {followers.map(follower => (
              <Link
                to={`/membro/${follower.cod_membro}`}
                className="mr-1"
                key={follower.cod_membro}
              >
                {follower.img ? (
                  <img
                    src="../global_assets/images/demo/users/face1.jpg"
                    className="rounded-round"
                    width="35"
                    height="35"
                    alt=""
                  />
                ) : (
                  <div className="mr-1">
                    <button
                      title={follower.nome_membro}
                      href="#1"
                      className="btn bg-teal-400 rounded-round btn-icon btn-sm"
                    >
                      <span className="letter-icon">
                        {follower.nome_membro.substring(0, 1)}
                      </span>
                    </button>
                  </div>
                )}
              </Link>
            ))}
            {followers.length > 0 ? '' : 'Nenhum inscrito ainda'}
            <a
              href="#1"
              className={`btn btn-outline bg-grey border-grey text-grey-400 btn-icon rounded-round border-2 ${
                add ? '' : 'd-none'
              }`}
            >
              <i className="icon-plus2"></i>
            </a>
          </div>
        )}
      </div>
    </div>
  );
}
Followers.propTypes = {
  title: PropTypes.string,
  add: PropTypes.bool,
};

Followers.defaultProps = {
  title: 'Seguindo',
  add: false,
};
