/* eslint-disable react/prop-types */
import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
  useRef,
} from 'react';

import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form } from '@unform/web';
import { parseISO } from 'date-fns';
import { toast } from 'react-toastify';
import { Row, Col } from 'react-bootstrap';

import * as CourseActions from '~/store/modules/courses/actions';

import api from '~/services/api';
import history from '~/services/history';

import Loading from '~/components/Loading';
import TabMain from '~/components/TypeCourse/TabMain';
import TabClass from '~/components/TypeCourse/TabClass';
import TableTicket from '~/components/TableTicket';
import ButtonPublish from '~/components/ButtonPublishEvent';
import Affiliates from '~/components/Affiliates';
import DiscountCoupons from '~/components/DiscountCoupons';
import Speakers from '~/components/Speakers';
import BoxConfirm from '~/components/BoxConfirm';
import ModalCreateAccount from '~/components/ModalCreateAccount';

import { formatDateHour } from '~/util/format';
import { replaceSpecialChars, objIsNull } from '~/util';

function Course(props) {
  const dispatch = useDispatch();
  const courseEditRef = useRef(null);

  // props.history.location.state.isNew

  const { match } = props;

  const course = useSelector(state => state.course.course);
  const loading = useSelector(state => state.course.loading);
  const { profile } = useSelector(state => state.user);

  const [banner, setBanner] = useState(null);
  const [action, setAction] = useState('new');
  const [htmlContent, setHtmlContent] = useState('');
  const [affiliate, setAffiliate] = useState(false);
  const [minister, setMinister] = useState(false);
  const [coupon, setCoupon] = useState(false);
  const [classe, setClasse] = useState(false);
  const [ticket, setTicket] = useState(false);
  const [openBoxConfirm, setOpenBoxConfirm] = useState(false);
  const [showCreateAccount, setShowCreateAccount] = useState(false);
  const [isNewCourse, setIsNewCourse] = useState(null);
  const [newCourseTicket, setNewCourseTicket] = useState(null);
  const [showConfirmSaveBox, setShowConfirmSaveBox] = useState(false);
  const [avatarOrganizer, setAvatarOrganizer] = useState(
    course.img_organiz ? course.img_organiz : profile.img_perfil
  );

  const loadCourse = useCallback(
    id => {
      dispatch(CourseActions.coursesGetRequest(id));
      setBanner(null);
      setIsNewCourse(course.is_new);
    },
    [course.is_new, dispatch]
  );

  // eslint-disable-next-line no-shadow
  async function publicEvent(data, action) {
    if (!data.titulo_eve) {
      toast.error('Curso não pode ser publicado sem um título!');
      return;
    }

    if (!data.dt_eve) {
      toast.error('Curso não pode ser publicado sem a data!');
      return;
    }

    const response = await api.put(`/evento/${action}/${course.cod_evento}`);
    if (response.data.records.error) {
      if (response.data.records.error_cc) {
        setOpenBoxConfirm(true);
        return;
      }
      toast.error(response.data.records.msg);
      return;
    }

    toast.success('Curso publicado com sucesso!');
    courseEditRef.current.submitForm();

    setTimeout(() => {
      loadCourse(match.params.id);
    }, 1000);

    // eslint-disable-next-line no-unused-expressions
    isNewCourse && history.push('/cursos');
  }

  const handleExternalClose = () => {
    setShowCreateAccount(false);
    publicEvent(course, 'ativar');
    history.push('/cursos');
  };

  const loadImg = useCallback(data => {
    setBanner(data.img_eve);
  }, []);

  const newCourse = useCallback(() => {
    dispatch(CourseActions.coursesNewCourse());
  }, [dispatch]);

  const handleAvatarOrganizer = useCallback(avatarIMG => {
    const reader = new FileReader();
    reader.readAsDataURL(avatarIMG);
    reader.onload = () => {
      setAvatarOrganizer(reader.result);
    };
    reader.onerror = error => {
      toast.error(error);
    };
  }, []);

  useEffect(() => {
    if (match.params.id === 'novo') {
      setAction('new');
      newCourse();
    } else {
      setAction('edit');
      loadCourse(match.params.id);
    }
  }, [match, newCourse, loadCourse, showCreateAccount]);

  useMemo(() => {
    if (course && objIsNull(course)) {
      setTimeout(() => {
        setAvatarOrganizer(course.img_organiz);
        setHtmlContent(course.desc_eve);
      });
    }
  }, [course, setAvatarOrganizer, setHtmlContent]);

  const renderTitlePage = useCallback(() => {
    const string = isNewCourse ? 'Criar' : 'Editar';

    const renderLink = () => {
      return string === 'Editar' ? (
        <>
          <Link
            to="route"
            target="_blank"
            onClick={e => {
              e.preventDefault();
              window.open(`/curso/${course.url_amig}`);
            }}
            className="ml-1 btn btn-primary"
            variant="primary"
          >
            <i className="icon-earth mr-1"></i>
            Link
          </Link>
        </>
      ) : (
        <>
          <Link
            to="route"
            target="_blank"
            onClick={e => {
              e.preventDefault();
              window.open(`/curso/${course.url_amig}`);
            }}
            className="ml-1 btn disabled btn-secondary"
            variant="primary"
            disabled
          >
            <i className="icon-earth mr-1"></i>
            Link
          </Link>
        </>
      );
    };

    return (
      <>
        <Col xl={6} className="mb-3">
          <h3 className="mb-0 font-weight-semibold">
            <i
              role="presentation"
              style={{ fontSize: '1.5rem' }}
              onClick={() => history.goBack()}
              className="icon-arrow-left52 mr-2 c-pointer"
            ></i>
            {string} Curso
          </h3>
          <span className="text-muted d-block">
            {string} informações do curso.
          </span>
        </Col>
        <Col xl={6}>
          <Row style={{ float: 'right' }}>
            {!loading && (
              <>
                <ButtonPublish
                  event={course}
                  openBoxConfirm={setOpenBoxConfirm}
                  loadEvent={loadCourse}
                  formRef={courseEditRef.current}
                  disabled={string !== 'Editar'}
                />

                {renderLink()}
              </>
            )}
          </Row>
        </Col>
      </>
    );
  }, [course, isNewCourse, loadCourse, loading]);

  async function handleSubmit(data) {
    let response;
    let message;

    data.desc_eve = htmlContent;
    data.url_amig = replaceSpecialChars(data.titulo_eve);

    data.dt_eve = data.dt_eve
      ? formatDateHour(data.dt_eve, data.hora_eve)
      : null;
    data.dt_eve_fim = data.dt_eve_fim
      ? formatDateHour(data.dt_eve_fim, data.hora_eve_fim)
      : null;

    if (data.google_eve === '1') {
      data.local_eve_desc = 'https://app.psicomart.com.br';
    }

    if (avatarOrganizer && avatarOrganizer.indexOf('http') < 0) {
      data.avatar = avatarOrganizer;
      data.img_organiz = avatarOrganizer;
    } else {
      delete data.img_organiz;
    }
    if (data.eh_online === '') {
      toast.error('Onde o evento vai acontecer é obrigatório!');
    }
    if (!isNewCourse) {
      data.cod_evento = course.cod_evento;
      response = await api.put(`/evento/${course.cod_evento}`, data);
      message = 'Curso atualizado com sucesso!';
    } else {
      if (newCourseTicket === null) {
        toast.error('Deve ser cadastrado um ingresso antes de prosseguir.');
        return;
      }
      data.cod_evento = course.cod_evento;
      response = await api.put(`/evento/${course.cod_evento}`, data);
      message = 'Curso cadastrado com sucesso!';
    }

    if (response.data.records.error) {
      toast.error(response.data.records.msg);
    } else {
      toast.success(message);
      setTimeout(() => {
        const idCourse = objIsNull(course)
          ? course.cod_evento
          : response.data.records.cod_evento;
        // eslint-disable-next-line no-unused-expressions
        isNewCourse
          ? setShowConfirmSaveBox(true)
          : history.push(`/cursos/${idCourse}/editar`);
      }, 1000);
    }

    return false;
  }

  function getInitialData() {
    if (action === 'edit') {
      return {
        ...course,
        dt_eve: course.dt_eve ? parseISO(course.dt_eve) : null,
        hora_eve: course.dt_eve ? parseISO(course.dt_eve) : null,
        dt_eve_fim: course.dt_eve_fim ? parseISO(course.dt_eve_fim) : null,
        hora_eve_fim: course.dt_eve_fim ? parseISO(course.dt_eve_fim) : null,
        tel_organiz: course.tel_organiz ? course.tel_organiz : profile.telefone,
        nome_organiz: course.nome_organiz
          ? course.nome_organiz
          : profile.nome_membro,
      };
    }
    return {
      tipo_eve: 2,
      status_eve: 1,
    };
  }

  return (
    <div className="content padding-tab-top-post">
      {loading ? (
        <Loading />
      ) : (
        <Row>
          <Col xl={12}>
            <div className="d-flex justify-content-between align-items-center">
              {renderTitlePage()}
            </div>
          </Col>
          <ModalCreateAccount
            showModal={showCreateAccount}
            handleExternalClose={handleExternalClose}
          />
          <BoxConfirm
            show={openBoxConfirm}
            onClose={() => setOpenBoxConfirm(false)}
            size="sm"
            title="Aviso"
            message="Para poder publicar é preciso cadastrar uma conta corrente."
            messageBottomBold="Deseja cadastrar agora?"
            aliasBtnYes="Cadastrar conta"
            onNoClick={() => setOpenBoxConfirm(false)}
            onYesClick={() => {
              setShowCreateAccount(true);
              setOpenBoxConfirm(false);
            }}
          />
          <BoxConfirm
            title="Sucesso"
            show={showConfirmSaveBox}
            size="sm"
            aliasBtnYes="Publicar"
            onYesClick={() => {
              publicEvent(courseEditRef.current.getData(), 'ativar');
              setShowConfirmSaveBox(false);
            }}
            aliasBtnNo="Complementar"
            onNoClick={() => {
              setShowConfirmSaveBox(false);
              history.push(`/cursos/${course.cod_evento}/editar`);
            }}
            onClose={() => {
              setShowConfirmSaveBox(false);
              history.push('/cursos');
            }}
            messageTopBold="Curso criado com sucesso!"
            message="Seu curso foi criado.Agora você poderá complementá-lo ou publicá-lo para começar a divulgar."
            iconSuccess
          />
          <Col>
            {loading ? (
              <Loading />
            ) : (
              <>
                <ul className="nav nav-tabs mb-0">
                  <li className="nav-item">
                    <a
                      href="#course"
                      data-toggle="tab"
                      className="nav-link rounded-top active"
                    >
                      Principal
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      id="second"
                      href="#price"
                      data-toggle="tab"
                      className={
                        isNewCourse
                          ? 'nav-link disabled rounded-top'
                          : 'nav-link rounded-top'
                      }
                      onClick={() => setTicket(true)}
                    >
                      Preço
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="#coupon"
                      data-toggle="tab"
                      className={
                        isNewCourse
                          ? 'nav-link disabled rounded-top'
                          : 'nav-link rounded-top'
                      }
                      onClick={() => setCoupon(true)}
                    >
                      Cupons de desconto
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      id="third"
                      href="#class"
                      data-toggle="tab"
                      className={
                        isNewCourse
                          ? 'nav-link disabled rounded-top'
                          : 'nav-link rounded-top'
                      }
                      onClick={() => setClasse(true)}
                    >
                      Turmas
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="#minister"
                      data-toggle="tab"
                      className={
                        isNewCourse
                          ? 'nav-link disabled rounded-top'
                          : 'nav-link rounded-top'
                      }
                      onClick={() => setMinister(true)}
                    >
                      Ministrante
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="#affiliate"
                      data-toggle="tab"
                      className={
                        isNewCourse
                          ? 'nav-link disabled rounded-top'
                          : 'nav-link rounded-top'
                      }
                      onClick={() => setAffiliate(true)}
                    >
                      Afiliados
                    </a>
                  </li>
                </ul>
                <div className="tab-content card card-body border-top-0 rounded-top-0 mb-0">
                  <div className="tab-pane fade show active" id="course">
                    <Form
                      onSubmit={handleSubmit}
                      ref={courseEditRef}
                      initialData={getInitialData()}
                    >
                      <TabMain
                        course={course}
                        banner={banner}
                        loadImg={loadImg}
                        avatarOrganizer={avatarOrganizer}
                        htmlContent={htmlContent}
                        setAvatarOrganizer={setAvatarOrganizer}
                        handleAvatarOrganizer={handleAvatarOrganizer}
                        setHtmlContent={setHtmlContent}
                        newCourseTicket={newCourseTicket}
                        setNewCourseTicket={setNewCourseTicket}
                        isNewCourse={isNewCourse}
                        publicEventFunction={publicEvent}
                        formReference={courseEditRef.current}
                      />
                    </Form>
                  </div>
                  <div className="tab-pane fade" id="price">
                    {action === 'new' ? (
                      <div className="alert alert-info border-0 alert-dismissible mb-0">
                        Faça o cadastro de um novo curso primeiramente.
                      </div>
                    ) : (
                      ticket && <TableTicket event={course} />
                    )}
                  </div>
                  <div className="tab-pane fade" id="coupon">
                    {coupon && <DiscountCoupons data={course} />}
                  </div>
                  <div className="tab-pane fade" id="class">
                    {classe && <TabClass course={course} />}
                  </div>
                  <div className="tab-pane fade" id="minister">
                    {minister && <Speakers data={course} course />}
                  </div>
                  <div className="tab-pane fade" id="affiliate">
                    {affiliate && <Affiliates data={course} />}
                  </div>
                </div>
              </>
            )}
          </Col>
        </Row>
      )}
    </div>
  );
}

Course.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

export default Course;
