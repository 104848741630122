import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Row, Col, Table, Button, Container } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { toast } from 'react-toastify';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { MdExpandMore } from 'react-icons/md';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import api from '~/services/api';

import Input from '~/components/InputText';
import InputMask from '~/components/InputMask';
import Select from '~/components/InputSelect';
import InputRadio from '~/components/InputRadio';
import BottomButton from '~/components/BottomButton';

import { formatCurrencyValue, months, years } from '~/util';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: 10,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexShrink: 0,
    paddingLeft: '12px',
    marginBottom: 10,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    marginLeft: 15,
    top: -3,
    position: 'relative',
  },
}));

const join = array => {
  const data = array.map(value => {
    return value.id;
  });

  return data.join('|');
};

export default function Payment({ match }) {
  const classes = useStyles();
  const formRef = useRef(null);
  const { json } = match.params;

  const { participants } = JSON.parse(json);
  const { cod_membro } = JSON.parse(json);
  const { total } = JSON.parse(json);
  const { event } = JSON.parse(json);
  const { tickets } = JSON.parse(json);

  const [load, setLoad] = useState(false);
  const [card, setCard] = useState(true);
  const [method, setMethod] = useState(3);
  const [expanded, setExpanded] = useState('panel1');
  const [installments, setInstallments] = useState([]);
  const [billet, setBillet] = useState(null);

  const handleChange = panel => isExpanded => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleMethod = value => {
    if (value === 3) {
      setCard(true);
      setMethod(value);
    } else {
      setCard(false);
      setMethod(value);
    }
  };

  async function handleSubmit(data) {
    try {
      setLoad(true);
      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        cartao_nro: Yup.string().required(
          'O número do cartão é de preenchimento obrigatório!'
        ),
        cartao_nome: Yup.string().required(
          'O nome do titular é de preenchimento obrigatório!'
        ),
        cartao_mes_exp: Yup.string().required(
          'O mês é de preenchimento obrigatório!'
        ),
        cartao_ano_exp: Yup.string().required(
          'O ano é de preenchimento obrigatório!'
        ),
        cartao_cod_seg: Yup.string().required('*'),
      });

      await schema.validate(data, {
        abortEarly: false,
        recursive: card,
      });

      const dataSend = {
        part: participants,
        qtd_item: participants.length ? participants.length : 1,
        valor_a_pagar: total.soma,
        cod_evento: event.cod_evento,
        cod_membro,
        url_afiliacao: total.afiliado,
        ...data,
      };

      const response = await api.post('/inscricao/inserir-inscricao', dataSend);
      if (response.data.records.error) {
        toast.error(response.data.records.msg);
        setLoad(false);
        return false;
      }
      setLoad(false);

      if (dataSend.forma_pgto === '3') {
        localStorage.setItem('ticket', JSON.stringify(response.data.records));
      } else {
        setBillet(response.data.records.url_boleto);
      }
    } catch (err) {
      setLoad(false);
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }

    return false;
  }

  async function handleBillet() {
    setLoad(true);

    const dataSend = {
      part: participants,
      cod_evento: event.cod_evento,
      qtd_item: participants.length ? participants.length : 1,
      url_afiliacao: total.afiliado,
    };

    const response = await api.post('/inscricao/inserir-inscricao', dataSend);
    if (response.data.records.error) {
      toast.error(response.data.records.msg);
      setLoad(false);
      return false;
    }

    localStorage.setItem('ticket', JSON.stringify(response.data.records));

    setLoad(false);
    return false;
  }

  const loadInstallments = useCallback(async () => {
    const ing = join(tickets);

    api
      .get(
        `/inscricao/montar-combo-parcelamento/?q=(cod_evento:${event.cod_evento},ing:${ing},total:${total.soma},tipo_eve:${event.tipo_eve})`
      )
      .then(response => {
        const { records } = response.data;
        setInstallments(() => {
          return records.map(value => {
            return { value: value.id, label: value.desc };
          });
        });
      });
  }, [event, tickets, total]);

  useEffect(() => {
    loadInstallments();
  }, [loadInstallments]);

  return (
    <Container className="mt-2">
      <Row className="d-flex justify-content-center">
        <Col md={12}>
          <h5 className="font-weight-bold text-muted">DETALHES DO PAGAMENTO</h5>
          <Table striped hover responsive className="table-bordered">
            <thead>
              <tr>
                <th>Ingresso</th>
                <th>Quantidade</th>
                <th className="text-right">Valor</th>
                <th className="text-right">Taxa</th>
                <th className="text-right">Sub-Total</th>
              </tr>
            </thead>
            <tbody>
              {tickets.map(ticket => (
                <tr key={ticket.id}>
                  <td>Ingresso {ticket.nome_ing}</td>
                  <td>{ticket.quant}</td>
                  <td className="text-right">
                    {formatCurrencyValue(ticket.val)}
                  </td>
                  <td className="text-right">R$ 0,00</td>
                  <td className="text-right">
                    {formatCurrencyValue(ticket.valor)}
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <th>Total dos ingressos</th>
                <th>{total.qnt}</th>
                <th className="text-right">{formatCurrencyValue(total.sub)}</th>
                <th className="text-right">R$ 0,00</th>
                <th className="text-right">
                  {formatCurrencyValue(total.soma)}
                </th>
              </tr>
            </tfoot>
          </Table>
          <h5 className="font-weight-bold text-muted mt-3">
            TIPO DE PAGAMENTOx
          </h5>
          <p className="text-center">
            Seus dados serão transferidos com conexão segura SSL e nenhuma
            informação sensível do pagamento será mantida em nossos servidores.
          </p>
        </Col>
        <Col md={12} className="mb-4">
          <div className={classes.root}>
            {(event.eh_cobrado === 1 || event.eh_cobrado === '1') &&
            total.soma > 0 ? (
              <Form
                ref={formRef}
                onSubmit={handleSubmit}
                initialData={{ forma_pgto: method }}
              >
                <ExpansionPanel
                  expanded={expanded === 'panel1'}
                  onChange={handleChange('panel1')}
                  onClick={() => handleMethod(3)}
                >
                  <ExpansionPanelSummary
                    expandIcon={<MdExpandMore />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    style={{ padding: 0 }}
                  >
                    <Typography
                      className={classes.heading}
                      style={{ fontSize: 14 }}
                    >
                      <strong className="text-uppercase">
                        Cartão de crédito
                      </strong>
                      {isMobile && (
                        <>
                          <br />
                          <img
                            src={require('~/assets/img/visa.svg')}
                            alt="Visa"
                            width={35}
                            className="mr-1"
                          />
                          <img
                            src={require('~/assets/img/master.svg')}
                            alt="Master"
                            width={35}
                            className="mr-1"
                          />
                          <img
                            src={require('~/assets/img/elo.svg')}
                            alt="Elo"
                            width={35}
                            className="mr-1"
                          />
                          <img
                            src={require('~/assets/img/diners.svg')}
                            alt="Diners"
                            width={35}
                            className="mr-1"
                          />
                          <img
                            src={require('~/assets/img/amex.svg')}
                            alt="Amex"
                            width={35}
                          />
                        </>
                      )}
                    </Typography>

                    {!isMobile && (
                      <Typography className={classes.secondaryHeading}>
                        <img
                          src={require('~/assets/img/visa.svg')}
                          alt="Visa"
                          width={35}
                          className="mr-1"
                        />
                        <img
                          src={require('~/assets/img/master.svg')}
                          alt="Master"
                          width={35}
                          className="mr-1"
                        />
                        <img
                          src={require('~/assets/img/elo.svg')}
                          alt="Elo"
                          width={35}
                          className="mr-1"
                        />
                        <img
                          src={require('~/assets/img/diners.svg')}
                          alt="Diners"
                          width={35}
                          className="mr-1"
                        />
                        <img
                          src={require('~/assets/img/amex.svg')}
                          alt="Amex"
                          width={35}
                        />
                      </Typography>
                    )}
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails
                    className={`${!isMobile ? `` : `p-0`}`}
                  >
                    {card && (
                      <Col xl={8}>
                        <div className="form-group">
                          <label htmlFor="qtdParc">
                            Quantidade de Parcelas *
                          </label>
                          <Select
                            name="qtdParc"
                            placeholder="Selecione..."
                            options={installments}
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="cartao_nro">Número do cartão *</label>
                          <InputMask
                            id="cartao_nro"
                            name="cartao_nro"
                            mask="9999 9999 9999 9999"
                            placeholder="Entre com o número do cartão de crédito..."
                            style={{ fontSize: 12 }}
                          />
                        </div>
                        <Row>
                          <Col xl={5}>
                            <div className="form-group">
                              <label htmlFor="cartao_mes_exp">
                                Mês{' '}
                                <small className="text-muted">
                                  <em>(de expiração)</em>
                                </small>{' '}
                                *
                              </label>
                              <Select
                                id="cartao_mes_exp"
                                name="cartao_mes_exp"
                                options={months()}
                                placeholder="Selecione..."
                              />
                            </div>
                          </Col>
                          <Col xl={5}>
                            <div className="form-group">
                              <label htmlFor="cartao_ano_exp">
                                Ano{' '}
                                <small className="text-muted">
                                  <em>(de expiração)</em>
                                </small>{' '}
                                *
                              </label>
                              <Select
                                id="cartao_ano_exp"
                                name="cartao_ano_exp"
                                options={years()}
                                placeholder="Selecione..."
                              />
                            </div>
                          </Col>
                          <Col xl={2}>
                            <div className="form-group">
                              <label htmlFor="cartao_cod_seg">
                                <abbr
                                  title="Código de segurança"
                                  className="initialism"
                                >
                                  CVV *
                                </abbr>
                              </label>
                              <Input
                                id="cartao_cod_seg"
                                name="cartao_cod_seg"
                                type="text"
                                maxLength="4"
                                style={{ fontSize: 13, padding: 8 }}
                              />
                            </div>
                          </Col>
                        </Row>
                        <div className="form-group">
                          <label htmlFor="cartao_nome">
                            Nome do titular{' '}
                            <small className="text-muted">
                              <em>(como impresso no cartão)</em>
                            </small>
                          </label>
                          <Input
                            id="cartao_nome"
                            name="cartao_nome"
                            type="text"
                            maxLength="255"
                            placeholder="Entre com o nome impresso no cartão..."
                            style={{ fontSize: 13 }}
                          />
                        </div>
                      </Col>
                    )}
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel
                  expanded={expanded === 'panel2'}
                  onChange={handleChange('panel2')}
                  onClick={() => handleMethod(8)}
                >
                  <ExpansionPanelSummary
                    expandIcon={<MdExpandMore />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                    style={{ padding: 0 }}
                  >
                    <Typography
                      className={classes.heading}
                      style={{ fontSize: 14 }}
                    >
                      <strong className="text-uppercase">
                        Boleto Bancário
                      </strong>
                    </Typography>
                    {!isMobile && (
                      <Typography className={classes.secondaryHeading}>
                        <img
                          src={require('~/assets/img/bank-slip.svg')}
                          alt="Boleto Bancário"
                          width={35}
                        />
                      </Typography>
                    )}
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails
                    className={`${!isMobile ? `` : `p-0`}`}
                  >
                    <div className="w-100 d-flex justify-content-between">
                      <InputRadio
                        name="forma_pgto"
                        options={[
                          {
                            id: method,
                            label: 'Boleto',
                            text:
                              'O boleto será emitido em nome do sistema Psicomart',
                          },
                        ]}
                      />
                      {billet && (
                        <div>
                          <Button
                            onClick={() => {
                              window.open(billet);
                            }}
                            variant="primary"
                          >
                            Imprimir boleto
                          </Button>
                        </div>
                      )}
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <div className="d-flex pt-4 float-right">
                  {!isMobile ? (
                    <>
                      {method === 8 ? (
                        <Button
                          size="lg"
                          type="submit"
                          variant="success"
                          className="btn-block font-weight-bold btn-custom text-uppercase"
                          style={{ width: '250px' }}
                          disabled={load ? true : !!billet}
                        >
                          {load ? 'Aguarde...' : 'Gerar boleto'}
                        </Button>
                      ) : (
                        <Button
                          size="lg"
                          type="submit"
                          variant="success"
                          className="btn-block font-weight-bold btn-custom text-uppercase"
                          style={{ width: '250px' }}
                          disabled={!!load}
                        >
                          {load ? 'Aguarde...' : 'Efetuar Pagamento'}
                        </Button>
                      )}
                    </>
                  ) : (
                    <BottomButton
                      type="submit"
                      disabled={!!load}
                      text={load ? 'Aguarde...' : 'Efetuar Pagamento'}
                    />
                  )}
                </div>
              </Form>
            ) : (
              <div className="d-flex pt-4 float-right">
                {!isMobile ? (
                  <Button
                    size="lg"
                    type="button"
                    variant="success"
                    className="btn-block font-weight-bold btn-custom text-uppercase"
                    onClick={handleBillet}
                    style={{ width: '250px' }}
                    disabled={!!load}
                  >
                    {load ? 'Aguarde...' : 'Gerar Ingressos'}
                  </Button>
                ) : (
                  <BottomButton
                    type="submit"
                    disabled={!!load}
                    onClick={handleBillet}
                    text={load ? 'Aguarde...' : 'Gerar Ingressos'}
                  />
                )}
              </div>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
}

// {
//   "cod_membro": 5,
//   "event" : {
//     "cod_evento": "102",
//     "eh_cobrado": 1,
//     "tipo_eve": 1
//   },
//   "total": {
//     "soma": 159.9,
//     "qnt": 1,
//     "sub": 159.9
//   },
//   "participants": [
//     {
//       "plano": "1",
//       "nome_part": "Gustavo Freitas",
//       "email_part": "freitasgustavos@hotmail.com"
//     }
//   ],
//   "tickets":[
//     {
//       "id": "1",
//       "nome_ing": "Tipo 1",
//       "quant": 1,
//       "val": "159.90",
//       "valor": 159.9
//     }
//   ]
// }
