import styled from 'styled-components';

export const AvatarInput = styled.div`
  position: relative;
  width: 100%;
  max-width: 100px;

  img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: #eee;
  }

  label {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #eee;
    right: 0;
    bottom: 0;
    border: 0;
    color: #666;
    margin-bottom: 0;
    cursor: pointer;

    input {
      display: none;
    }
  }
`;
