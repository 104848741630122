import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';

import api from '~/services/api';
import BoxConfirm from '~/components/BoxConfirm';

export default function DeleteAffiliate({ id, load }) {
  const [showBox, setShowBox] = useState(false);

  const onClose = useCallback(() => {
    setShowBox(false);
  }, []);

  const deleteEvent = useCallback(async () => {
    await api.put(`/afiliacao/cancelar-afiliado/${id}`).then(response => {
      if (response.data.records.error) {
        toast.error(response.data.records.msg);
        onClose();
        return false;
      }

      toast.success(`Afiliado apagado com sucesso!`);
      load();
      onClose();
      return true;
    });
  }, [id, load, onClose]);

  return (
    <>
      <Button
        variant="link"
        className="dropdown-item"
        onClick={() => setShowBox(true)}
      >
        <i className="icon-bin"></i> Apagar afiliado
      </Button>
      <BoxConfirm
        title="Apagar"
        show={showBox}
        size="sm"
        onYesClick={deleteEvent}
        onNoClick={onClose}
        onClose={onClose}
        message="Tem certeza de que deseja apagar esse afiliado?"
      />
    </>
  );
}

DeleteAffiliate.propTypes = {
  id: PropTypes.string.isRequired,
  load: PropTypes.func.isRequired,
};
