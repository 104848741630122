import React, { useEffect, useState, useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';

import api from '~/services/api';

import Select from '~/components/InputSelect';

export default function Categories({ type, category }) {
  const [load, setLoad] = useState(true);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubcategories] = useState([]);

  const loadSub = useCallback(async subCategorie => {
    const response = await api.get(
      `/produto/listar-categoria-pai/?q=(categoria_pai:${subCategorie.value})`
    );

    const { records } = response.data;

    const data = records.map(category => {
      return { value: category.cod_categoria, label: category.nome_cat };
    });

    setSubcategories(data);
    setLoad(false);
  }, []);

  const loadCategories = useCallback(async () => {
    setLoad(true);
    await api
      .get(`/produto/listar-categoria-pai/?q=(tipo_cat:${type})`)
      .then(response => {
        const { records } = response.data;
        if (records) {
          const datas = records.map(data => {
            return { value: data.cod_categoria, label: data.nome_cat };
          });
          setCategories(datas);
          const sub = datas.find(data => data.value === category);
          if (sub) {
            loadSub(sub);
          }
        }
      });
    setLoad(false);
  }, [category, loadSub, type]);

  useEffect(() => {
    loadCategories();
  }, [loadCategories]);

  return (
    <Row>
      <Col>
        <div className="form-group">
          <label>Categoria</label>
          {!load && (
            <Select
              placeholder="Selecione"
              name="cod_categoria"
              options={categories}
              onChange={loadSub}
              isLoading={load}
            />
          )}
        </div>
      </Col>
      <Col>
        {!!subCategories.length && (
          <div className="form-group">
            <label>SubCategoria</label>
            {!load && (
              <Select
                placeholder="Selecione"
                name="subcategoria"
                options={subCategories}
              />
            )}
          </div>
        )}
      </Col>
    </Row>
  );
}
