import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import { groupListSuccess, groupListFailure } from './actions';

export function* groupListRequest() {
  try {
    const response = yield call(api.get, `/grupo`);

    if (response.data.records.error) {
      toast.error(response.data.records.msg);
      yield put(groupListFailure());
      return;
    }

    yield put(groupListSuccess(response.data.records));
  } catch (err) {
    toast.error('Falha no servidor, contate o administrador!');
    yield put(groupListFailure());
  }
}

export default all([takeLatest('@group/LIST_GROUP_REQUEST', groupListRequest)]);
