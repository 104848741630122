/* eslint-disable no-return-assign */
/* eslint-disable no-inner-declarations */

import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { isMobile } from 'react-device-detect';
import { Row, Col, ListGroup } from 'react-bootstrap';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import Participants from './participants';
import Payment from './payment';
import Finished from './finished';

import { adjustTopApplication, formatCurrencyValue } from '~/util';

export default function WizardPurchase({ type }) {
  const [labels, setLabels] = useState({});

  const { tickets } = useSelector(state => state.cart);
  const { total } = useSelector(state => state.cart);
  const { amount } = useSelector(state => state.cart);
  const { event } = useSelector(state => state.cart);
  const { coupon } = useSelector(state => state.cart);
  const { step } = useSelector(state => state.cart);

  useEffect(() => {
    adjustTopApplication();
  }, []);

  useEffect(() => {
    if (type === 'curso') {
      setLabels({
        lb000: 'Curso',
        lb001: 'Cursos',
        lb002: 'CURSO',
      });
    }
    if (type === 'evento') {
      setLabels({
        lb000: 'Evento',
        lb001: 'Eventos',
        lb002: 'EVENTO',
      });
    }
  }, [type]);

  const getSteps = useCallback(() => {
    return ['INGRESSO', 'PARTICIPANTES', 'PAGAMENTO'];
  }, []);

  const steps = getSteps();

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 1:
        return <Participants />;
      case 2:
        return <Payment />;
      default:
        return <Finished />;
    }
  }

  return (
    <div className="content psico-stepper">
      <Stepper
        alternativeLabel
        activeStep={step}
        orientation={isMobile ? `vertical` : `horizontal`}
      >
        {steps.map(label => (
          <Step key={label} style={{ marginBottom: 20 }}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Row className="mt-4">
        <Col xl={4}>
          <Row>
            <Col>
              <h5 className="font-weight-bold text-muted">{labels.lb002}</h5>
              <h2>{event.titulo_eve}</h2>
              <ListGroup variant="flush">
                {event.dt_eve ? (
                  <ListGroup.Item>
                    <i className="icon-calendar mr-2"></i>
                    <span>{event.dt_eve_desc}</span>
                  </ListGroup.Item>
                ) : null}

                {event.eh_online === '1' ? (
                  <ListGroup.Item>
                    <span className="text-information d-flex">
                      <i className="icon-location4 mr-2"></i>
                      <span>{labels.lb000} Online</span>
                    </span>
                  </ListGroup.Item>
                ) : (
                  <>
                    {event.local_eve_desc ? (
                      <>
                        <ListGroup.Item>
                          <span className="text-information d-flex">
                            <i className="icon-location4 mr-2"></i>
                            <span>{event.local_eve_desc}</span>
                          </span>
                        </ListGroup.Item>
                        <ListGroup.Item>
                          <span className="text-information d-flex">
                            <i className="icon-city mr-2"></i>
                            <span>
                              {event.end_eve}, {event.nro_eve},{' '}
                              {event.nome_cidade_eve}
                            </span>
                          </span>
                        </ListGroup.Item>
                      </>
                    ) : (
                      <ListGroup.Item>
                        <span className="text-information d-flex">
                          <i className="icon-location4 mr-2"></i>
                          <span>Não definido</span>
                        </span>
                      </ListGroup.Item>
                    )}
                  </>
                )}
              </ListGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5 className="font-weight-bold text-muted">INGRESSOS</h5>
              {tickets.length && (
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <span className="text-information d-flex">
                      <i className="icon-ticket mr-2"></i>
                      <span>
                        Quantidade: <strong>{amount}</strong>
                      </span>
                    </span>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <span className="text-information d-flex">
                      <i className="icon-coin-dollar mr-2"></i>
                      <span>
                        Total:
                        <strong>
                          {formatCurrencyValue(
                            coupon.total ? coupon.total : total
                          )}
                        </strong>
                      </span>
                    </span>
                  </ListGroup.Item>
                </ListGroup>
              )}
            </Col>
          </Row>
        </Col>
        <Col xl={8}>{getStepContent(step)}</Col>
      </Row>
    </div>
  );
}

WizardPurchase.propTypes = {
  type: PropTypes.string.isRequired,
};
