/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/control-has-associated-label */

import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import YouTube from 'react-youtube';
import Vimeo from '@u-wave/react-vimeo';

import { toast } from 'react-toastify';
import { Row, Card, Col, Button } from 'react-bootstrap';
import { MdFileDownload, MdDone, MdPlayCircleOutline } from 'react-icons/md';

import api from '~/services/api';
import Config from '~/config';

import { TimeLineList, UploadCurso } from './styles';
import { objIsNull, buildQueryString } from '~/util';

import * as InventoryActions from '~/store/modules/inventory/actions';

function Journey(props) {
  const dispatch = useDispatch();

  const { token } = useSelector(state => state.auth);
  const files = useSelector(state => state.inventory.files);
  const journey = useSelector(state => state.inventory.journey);

  const [videoOptions, setVideoOptions] = useState({});
  const [currentModule, setCurrentModule] = useState(0);
  const [lastModule, setLastModule] = useState(0);
  const [selectedModule, setSelectedModule] = useState(null);
  const [focusedId, setFocusedId] = useState(null);

  const { match } = props;
  const loadJourney = useCallback(
    id => {
      dispatch(InventoryActions.inventoryGetJourneyClassRequest(id));
    },
    [dispatch]
  );

  useEffect(() => {
    if (match.params.id) {
      loadJourney(match.params.id);
    }
  }, [match, loadJourney]);

  function configVideo(module) {
    const plataformVideo = parseInt(module.video_plataforma, 10);
    switch (plataformVideo) {
      case 1: {
        setVideoOptions({
          width: '100%',
          playerVars: {
            autoplay: 1,
          },
        });
        break;
      }
      case 2: {
        break;
      }
      default: {
        break;
      }
    }
  }

  useEffect(() => {
    if (objIsNull(journey)) {
      const firstModule = journey[currentModule];
      const lstModule = journey[journey.length - 1];
      setLastModule(lstModule);
      setSelectedModule(firstModule);
      configVideo(firstModule);
    }
  }, [journey, currentModule, setSelectedModule]);

  useMemo(() => {
    if (selectedModule && objIsNull(selectedModule)) {
      const query = buildQueryString({
        cod_evento: selectedModule.cod_evento,
        cod_modulo_curso: selectedModule.cod_modulo_curso,
      });
      dispatch(InventoryActions.inventoryGetFilesByModuleRequest(query));
    }
  }, [selectedModule, dispatch]);

  function getClassName(item) {
    if (
      selectedModule &&
      selectedModule.cod_modulo_curso === item.cod_modulo_curso
    ) {
      return 'current';
    }
    return item.status_membro_modulo === '1' ? 'done' : 'default';
  }

  function handleSelectedModule(event, module) {
    event.preventDefault();
    setSelectedModule(module);

    let breakForeach = false;
    Object.keys(journey).forEach(i => {
      if (
        !breakForeach &&
        journey[i].cod_modulo_curso === module.cod_modulo_curso
      ) {
        breakForeach = true;
        setCurrentModule(i);
      }
    });
    window.scrollTo(0, 0);
  }

  const handleNextModule = () => {
    // eslint-disable-next-line radix
    const nextModule = parseInt(currentModule) + 1;
    if (nextModule < journey.length) {
      setCurrentModule(nextModule);
      let breakForeach = false;
      Object.keys(journey).forEach(i => {
        if (!breakForeach && i === nextModule) {
          breakForeach = true;
          setSelectedModule(journey[i]);
        }
      });
    }
    window.scrollTo(0, 0);
  };

  const witchIconShoudRender = item => {
    if (!selectedModule)
      return (
        <MdPlayCircleOutline
          size={22}
          color="#fff"
          style={{ marginRight: 20 }}
        />
      );
    if (
      selectedModule &&
      selectedModule.cod_modulo_curso !== item.cod_modulo_curso &&
      item.status_membro_modulo === '1'
    ) {
      return <MdDone size={22} color="#059847" style={{ marginRight: 20 }} />;
    }

    if (
      selectedModule &&
      item.status_membro_modulo === '0' &&
      selectedModule.cod_modulo_curso !== item.cod_modulo_curso
    ) {
      return (
        <MdPlayCircleOutline
          size={22}
          color="#fff"
          style={{ marginRight: 20 }}
        />
      );
    }

    if (
      selectedModule &&
      selectedModule.cod_modulo_curso === item.cod_modulo_curso
    ) {
      return (
        <MdPlayCircleOutline
          size={22}
          color="#26a69a"
          style={{ marginRight: 20 }}
        />
      );
    }

    if (selectedModule && focusedId && item.cod_modulo_curso === focusedId) {
      return (
        <MdPlayCircleOutline
          size={22}
          color="#26a69a"
          style={{ marginRight: 20 }}
        />
      );
    }
  };

  async function handleMarkAsDoneVideo() {
    const data = {
      status_membro_modulo: 1,
      cod_modulo: selectedModule.cod_modulo_curso,
    };

    const response = await api.put(
      `/evento/completar-modulo-curso/${selectedModule.cod_modulo_curso}`,
      data
    );

    if (response.data.records.error) {
      toast.error(response.data.records.msg);
    } else {
      handleNextModule();

      setTimeout(() => {
        dispatch(InventoryActions.changeStatusClassCourse(selectedModule, '1'));
      }, 1000);
    }
    window.scrollTo(0, 0);
  }

  // async function handleChangeStatus(module) {
  //   let status = null;
  //   let response = null;

  //   if (module.status_membro_modulo === '0') {
  //     status = '1';
  //     response = await api.put(
  //       `/evento/completar-modulo-curso/${module.cod_modulo_curso}`,
  //       {
  //         status_membro_modulo: 1,
  //         cod_modulo: module.cod_modulo_curso,
  //       }
  //     );
  //   }

  //   if (module.status_membro_modulo === '1') {
  //     status = '0';
  //     response = await api.put(
  //       `/evento/desfazer-modulo-curso/${module.cod_modulo_curso}`,
  //       {
  //         status_membro_modulo: 0,
  //         cod_modulo: module.cod_modulo_curso,
  //       }
  //     );
  //   }

  //   if (status !== null) {
  //     if (response.data.records.error) {
  //       toast.error(response.data.records.msg);
  //     } else {
  //       setTimeout(() => {
  //         dispatch(InventoryActions.changeStatusClassCourse(module, status));
  //       }, 1000);
  //     }
  //   }
  // }

  function getNomeTurma(item) {
    if (item.length) {
      return item[0].nome_turma;
    }
    return null;
  }

  function downloadFile(event, file) {
    event.preventDefault();
    const query = buildQueryString({
      ken: token,
      arq: file.arq_eve,
      e: 1,
    });
    window.open(`${Config.URL_APP_DOWNLOAD}${query}`);
  }

  return (
    <Col xl={12} className="mt-3">
      <Row>
        <Col xl={8}>
          {selectedModule && objIsNull(selectedModule) && (
            <>
              {/* <Col xl={12}>
                <h2 className="font-weight-bold mb-2">
                  {selectedModule.nome_modulo}
                </h2>
              </Col> */}
              <Col xl={12}>
                {selectedModule.video_plataforma === '1' && (
                  <YouTube
                    opts={videoOptions}
                    videoId={selectedModule.video_id}
                    onEnd={handleMarkAsDoneVideo}
                  />
                )}
                {selectedModule.video_plataforma === '2' && (
                  <Vimeo
                    autoplay
                    video={selectedModule.video_id}
                    onEnd={handleMarkAsDoneVideo}
                    width={715}
                  />
                )}
              </Col>
              <Col xl={12} className="mt-4">
                {selectedModule.desc_modulo && (
                  <>
                    <h3 className="font-weight-bold mb-3">
                      {selectedModule.nome_modulo}
                    </h3>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: selectedModule.desc_modulo,
                      }}
                    />
                  </>
                )}
              </Col>
              <Col xl={12} className="mt-4">
                {!!files.length && (
                  <UploadCurso>
                    <h3 className="font-weight-bold mb-3">Mídias</h3>
                    <ul>
                      {files.map(item => (
                        <li>
                          <div>
                            <strong>{item.arq_eve}</strong>
                          </div>
                          <div>
                            <a
                              href="/#"
                              onClick={e => downloadFile(e, item)}
                              rel="noopener noreferrer"
                              title="Download"
                            >
                              <MdFileDownload
                                style={{ marginRight: 8 }}
                                size={24}
                                color="#666"
                              />
                            </a>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </UploadCurso>
                )}
              </Col>
              <Col xl={{ span: 2, offset: 10 }} xs={{ span: 9, offset: 6 }}>
                {selectedModule.status_membro_modulo === '1' ? (
                  selectedModule.cod_modulo_curso !==
                    lastModule.cod_modulo_curso && (
                    <Button
                      style={{ marginLeft: 54, marginBottom: 20 }}
                      variant="outline-success"
                      onClick={() => handleNextModule()}
                    >
                      Próxima aula
                    </Button>
                  )
                ) : (
                  <Button
                    variant="success"
                    onClick={() => handleMarkAsDoneVideo()}
                  >
                    Concluir aula
                  </Button>
                )}
              </Col>
            </>
          )}
        </Col>
        <Col xl={4}>
          <Card>
            <Card.Header>
              <h1 className="mb-0">{getNomeTurma(journey)}</h1>
              <span className="text-muted">
                <small>{journey.length} aulas</small>
              </span>
            </Card.Header>
            <Card.Body className="p-0">
              <TimeLineList>
                <ul>
                  {journey.map(item => (
                    <li
                      key={item.cod_modulo_curso}
                      className={getClassName(item)}
                      onMouseEnter={() => setFocusedId(item.cod_modulo_curso)}
                      onMouseLeave={() => setFocusedId(null)}
                    >
                      {focusedId === item.cod_modulo_curso ? (
                        <MdPlayCircleOutline
                          size={22}
                          color="#26a69a"
                          style={{ marginRight: 20 }}
                        />
                      ) : (
                        witchIconShoudRender(item)
                      )}

                      <a
                        href="/#"
                        title={item.nome_modulo}
                        onClick={e => handleSelectedModule(e, item)}
                      >
                        {item.nome_modulo}
                      </a>
                    </li>
                  ))}
                </ul>
              </TimeLineList>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Col>
  );
}

Journey.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

export default Journey;
