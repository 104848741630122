import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import * as EventsActions from './actions';

export function* eventsListRequest({ payload }) {
  try {
    const response = yield call(api.get, `/evento/${payload}`);

    if (response.data.records.error) {
      toast.error(response.data.records.msg);
      yield put(EventsActions.eventsListFailure());
      return;
    }

    yield put(EventsActions.eventsListSuccess(response.data.records));
  } catch (err) {
    toast.error('Falha na comunicação com servidor!');
    yield put(EventsActions.eventsListFailure());
  }
}

export function* eventsGetRequest({ payload }) {
  try {
    const response = yield call(api.get, `/evento/${payload}`);

    if (response.data.records.error) {
      toast.error(response.data.records.msg);
      yield put(EventsActions.eventsGetFailure());
      return;
    }

    yield put(EventsActions.eventsGetSuccess(response.data.records));
  } catch (err) {
    toast.error('Falha na comunicação com servidor!');
    yield put(EventsActions.eventsGetFailure());
  }
}

export function* eventsPostRequest({ payload, isNew }) {
  try {
    const response = yield call(api.post, `/evento`, payload);

    if (response.data.records.error) {
      toast.error(response.data.records.msg);
      yield put(EventsActions.eventsPostFailure());
      return;
    }

    yield put(EventsActions.eventsPostSuccess());
    if (!isNew) {
      yield put(EventsActions.eventsGetRequest(payload.cod_evento));
    }
    toast.success('Evento editado com sucesso.');
  } catch (err) {
    toast.error('Falha na comunicação com servidor!');
    yield put(EventsActions.eventsPostFailure());
  }
}

export function* eventsGetByTagRequest({ payload }) {
  try {
    const response = yield call(api.get, `/pagina/get-evento/${payload}`);

    if (response.data.records.error) {
      toast.error(response.data.records.msg);
      yield put(EventsActions.eventsGetByTagFailure());
      return;
    }

    yield put(EventsActions.eventsGetByTagSuccess(response.data.records));
  } catch (err) {
    toast.error('Falha na comunicação com servidor!');
    yield put(EventsActions.eventsGetByTagFailure());
  }
}

export default all([
  takeLatest('@events/LIST_EVENTS_REQUEST', eventsListRequest),
  takeLatest('@events/GET_EVENTS_REQUEST', eventsGetRequest),
  takeLatest('@events/POST_EVENTS_REQUEST', eventsPostRequest),
  takeLatest('@events/GET_EVENTS_BY_TAG_REQUEST', eventsGetByTagRequest),
]);
