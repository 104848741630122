import React from 'react';
import Prototype from 'prop-types';

export default function DefaultLayout({ children }) {
  return (
    <div className="page-content bg-white">
      <div className="content-wrapper">{children}</div>
    </div>
  );
}

DefaultLayout.propTypes = {
  children: Prototype.element.isRequired,
};
