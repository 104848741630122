import React, { useRef, useEffect } from 'react';
import { Form } from '@unform/web';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, Spinner } from 'react-bootstrap';
import Input from '~/components/InputText';

import { signInModalRequest } from '~/store/modules/auth/actions';

export default function LoginUser({ show, handleClose }) {
  const formRef = useRef(null);

  const modal = useSelector(state => state.auth.modal);
  const loading = useSelector(state => state.auth.loading);

  const dispatch = useDispatch();

  function handleSubmit({ email, password }) {
    const site = 1;
    dispatch(signInModalRequest(email, password, site));
  }

  useEffect(() => {
    if (!modal) {
      setTimeout(() => {
        handleClose();
      }, 1000);
    }
  }, [modal, handleClose]);

  return (
    <Modal
      centered
      className="modal-new-event"
      dialogClassName="modal-event"
      show={show}
      onHide={handleClose}
    >
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Modal.Header>
          <div className="media align-items-center align-items-md-start flex-column flex-md-row">
            <span className="text-teal mr-md-3 mb-3 mb-md-0">
              <i className="icon-reading border-2 rounded-round p-2 mt-1" />
            </span>

            <div className="media-body text-center text-md-left">
              <h3 className="media-title font-weight-semibold">
                Faça login na sua conta
              </h3>
              <span className="text-muted">Digite suas credenciais abaixo</span>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group form-group-feedback form-group-feedback-left">
            <Input
              name="email"
              type="text"
              className="form-control"
              placeholder="E-mail"
            />
            <div className="form-control-feedback">
              <i className="icon-mail5 text-muted" />
            </div>
          </div>
          <div className="form-group form-group-feedback form-group-feedback-left">
            <Input
              name="password"
              type="password"
              className="form-control"
              placeholder="Senha"
            />
            <div className="form-control-feedback">
              <i className="icon-lock2 text-muted" />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button
            size="lg"
            block
            className="text-secondary"
            variant="link"
            onClick={handleClose}
          >
            <i className="icon-cross2 mr-1 font-weight-light" />
            Cancelar
          </Button>
          <Button size="lg" block disabled={!!loading} type="submit">
            {loading ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="mr-1"
                />
                Aguarde
              </>
            ) : loading ? (
              'Carregando... '
            ) : (
              'Entrar '
            )}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
