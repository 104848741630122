import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Form } from '@unform/web';
import { Button } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';

import LoginUser from '~/components/LoginUser';
import CreateUser from '~/components/CreateUser';
import BottomButton from '~/components/BottomButton';

import { store } from '~/store';
import { resetModalRequest } from '~/store/modules/auth/actions';

function Cart({ next, product }) {
  const formRef = useRef(null);
  const dispatch = useDispatch();

  const { signed } = store.getState().auth;

  const [showLoginUser, setShowLoginUser] = useState(false);
  const [showCreateUser, setShowCreateUser] = useState(false);

  const handleShowCreateUser = () => setShowCreateUser(true);
  const handleCloseCreateUser = () => setShowCreateUser(false);

  const handleCloseLoginUser = () => setShowLoginUser(false);
  function handleShowLoginUser() {
    dispatch(resetModalRequest());
    setTimeout(() => {
      setShowLoginUser(true);
    }, 500);
  }

  async function handleSubmit() {
    if (!signed) {
      toast.warn(
        'Efetue o login na plataforma para finalizar a compra do produto!'
      );
      return false;
    }
    next();
    return true;
  }

  return (
    <>
      <LoginUser show={showLoginUser} handleClose={handleCloseLoginUser} />
      <CreateUser show={showCreateUser} handleClose={handleCloseCreateUser} />
      <div className="row mb-2 mt-3">
        <div className="col-md-12">
          <div className="card border-left-3 border-left-pink-400 rounded-0 mt-1">
            <div className="card-body">
              {signed ? (
                <>
                  <p className="quote-font mb-0">
                    Caro usuário, você já se encontra logado em nossa plataforma
                    e apto para concluir a compra do produto em questão.
                  </p>
                  {!isMobile && (
                    <button
                      type="button"
                      className="btn btn-sm btn-default border-slate-600 btn-labeled btn-labeled-left mr-2 mt-2"
                    >
                      <b>
                        <i className="icon-user" />
                      </b>
                      <span className="font-weight-bold mr-1">
                        {store.getState().user.profile.nome_membro}
                      </span>
                      <span className="font-weight-bold">
                        {store.getState().user.profile.email}
                      </span>
                    </button>
                  )}
                </>
              ) : (
                <>
                  <h6 className="mb-0 font-weight-bold">Caro usuário,</h6>
                  <p className="quote-font">
                    Conecte na sua conta e tenha acesso ao seu histórico de
                    pedidos, reimpressão de ingressos e mais!
                  </p>
                  <button
                    type="button"
                    className="btn btn-sm btn-default border-slate-600 btn-labeled btn-labeled-left mr-2"
                    onClick={handleShowLoginUser}
                  >
                    <b>
                      <i className="icon-reading" />
                    </b>
                    Login
                  </button>
                  <button
                    type="button"
                    className="btn btn-sm btn-default border-slate-600 btn-labeled btn-labeled-left mr-2"
                    onClick={handleShowCreateUser}
                  >
                    <b>
                      <i className="icon-plus3" />
                    </b>{' '}
                    Criar Conta
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <Form ref={formRef} onSubmit={handleSubmit}>
        {!isMobile ? (
          <Button
            size="lg"
            type="submit"
            variant="success"
            className="btn-block font-weight-bold btn-custom text-uppercase float-right"
            style={{ width: '200px' }}
          >
            {product.eh_cobrado === '1' ? 'Efetuar Pagamento' : 'Finalizar'}
          </Button>
        ) : (
          <BottomButton
            type="submit"
            text={
              product.eh_cobrado === '1' ? 'Efetuar Pagamento' : 'Finalizar'
            }
          />
        )}
      </Form>
    </>
  );
}

Cart.propTypes = {
  next: PropTypes.func.isRequired,
  product: PropTypes.shape({
    eh_cobrado: PropTypes.string,
  }).isRequired,
};

export default Cart;
