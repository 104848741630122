import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Card, Button } from 'react-bootstrap';
import Lottie from 'react-lottie';

import validated from '~/assets/validated.json';
import history from '~/services/history';
import { ambient } from '~/util';

import { clearCart } from '~/store/modules/cart/actions';

export default function Finished() {
  const dispatch = useDispatch();
  const [play, setPlay] = useState(false);
  const { token } = useSelector(state => state.auth);
  const { event } = useSelector(state => state.cart);
  const { paid } = useSelector(state => state.cart);
  const { billet } = useSelector(state => state.cart);
  const { ticket } = useSelector(state => state.cart);

  useEffect(() => {
    setPlay(true);
    return function clear() {
      setPlay(false);
      dispatch(clearCart());
    };
  }, [dispatch]);

  const handleTicket = useCallback(() => {
    const url = `${ambient()}/export/get-inscricao/?q=(cod_evento_cad:${
      ticket.cod_evento_cad
    },ken:${token})`;
    window.open(url);
  }, [ticket, token]);

  const handleBillet = useCallback(() => {
    window.open(billet);
  }, [billet]);

  const options = {
    loop: false,
    autoplay: play,
    animationData: validated,
  };

  return (
    <Row className="justify-content-md-center">
      <Col xl={12}>
        <Card className="card text-center mt-2">
          <Card.Body className="card-body">
            <Lottie options={options} height={250} width={250} />
            <h4 className="card-title mb-2 font-weight-black ">
              {paid ? 'Compra concluída!' : 'Inscrição Realizada!'}
            </h4>
            {!paid ? (
              <p className="mb-3">
                Clique no botão abaixo para gerar o seu boleto.
              </p>
            ) : (
              <p className="mb-3">
                Clique no botão abaixo para gerar o(s) seu(s) ingresso(s).
                Enviamos também um link para seu e-mail de confirmação.
              </p>
            )}
            <div className="d-block text-center">
              {!paid ? (
                <Button
                  size="sm"
                  variant="info"
                  onClick={handleBillet}
                  style={{ width: '250px' }}
                  className="btn-block font-weight-bold btn-custom text-uppercase m-auto"
                >
                  Imprimir Boleto
                </Button>
              ) : (
                <Button
                  size="sm"
                  variant="success"
                  onClick={handleTicket}
                  style={{ width: '250px' }}
                  className="btn-block font-weight-bold btn-custom text-uppercase m-auto"
                >
                  Baixar Ingresso(s)
                </Button>
              )}
            </div>
            <span className="d-block mt-3">
              <span className="mr-1">
                {event.eh_cobrado === '1' && 'Obrigado pela sua compra!'}
              </span>
              <span>
                Caso você não receba a confirmação da reserva, verifique sua
                caixa de spam.
              </span>
            </span>
            <p className="mt-3">
              <Button
                onClick={() => history.push(`/evento/${event.url_amig}`)}
                variant="link"
              >
                Voltar para página do evento
              </Button>
            </p>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}
