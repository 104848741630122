import produce from 'immer';

const INITIAL_STATE = {
  id: 0,
  course: {},
  courses: [],
  loading: true,
};

export default function course(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@courses/GET_COURSES_REQUEST': {
        draft.loading = true;
        draft.id = action.payload;
        break;
      }
      case '@courses/GET_COURSES_SUCCESS': {
        draft.loading = false;
        draft.course = action.payload;
        break;
      }
      case '@courses/LIST_COURSES_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@courses/LIST_COURSES_SUCCESS': {
        draft.loading = false;
        draft.courses = action.payload;
        break;
      }
      case '@courses/GET_COURSES_FAILURE':
      case '@courses/LIST_COURSES_FAILURE': {
        draft.loading = false;
        break;
      }
      case '@courses/NEW_COURSE_REQUEST': {
        draft.course = {};
        draft.loading = false;
        break;
      }
      default:
    }
  });
}
