import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Modal, Spinner } from 'react-bootstrap';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import Input from '~/components/InputText';

import api from '~/services/api';
import history from '~/services/history';

import * as LayoutActions from '~/store/modules/layout/actions';

export default function CreateEvent({ show, handleClose }) {
  const [loading, setLoading] = useState(false);
  const formRef = useRef(null);
  const dispatch = useDispatch();

  async function handleSubmit(data) {
    setLoading(true);
    try {
      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        titulo_eve: Yup.string()
          .min(5, 'Ops! Título muito pequeno')
          .required('O título é obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const response = await api.post('/evento', data);
      if (response.data.records.error) {
        toast.error(response.data.records.msg);
        setLoading(false);
        return;
      }

      toast.success(
        'Seu evento foi criado! Agora vamos para pagina do seu evento',
        { autoClose: 3000 }
      );

      setTimeout(function() {
        setLoading(false);
        handleClose();
        dispatch(LayoutActions.menusGetRequest('evento'));
        history.push(`/eventos/${response.data.records.cod_evento}/editar`);
      }, 3000);
    } catch (err) {
      setLoading(false);
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  return (
    <Modal
      centered
      className="modal-new-event"
      dialogClassName="modal-event"
      show={show}
      onHide={handleClose}
    >
      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        initialData={{ tipo_eve: '1' }}
      >
        <Modal.Header>
          <div className="media align-items-center align-items-md-start flex-column flex-md-row">
            <span className="text-teal mr-md-3 mb-3 mb-md-0">
              <i className="icon-info3 text-warning-400 border-warning-400 border-2 rounded-round p-2 mt-1"></i>
            </span>

            <div className="media-body text-center text-md-left">
              <h3 className="media-title font-weight-semibold">Criar Evento</h3>
              <span className="text-muted">
                Adicione algumas informações para o evento que você deseja criar
              </span>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label>Título do evento</label>
            <Input type="hidden" name="tipo_eve" defaultValue="1" />
            <Input
              name="titulo_eve"
              type="text"
              placeholder="Digite o título do evento"
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button
            size="lg"
            block
            className="text-secondary"
            variant="link"
            onClick={handleClose}
          >
            <i className="icon-cross2 mr-1 font-weight-light"></i>
            Cancelar
          </Button>
          <Button size="lg" block disabled={!!loading} type="submit">
            {loading ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="mr-1"
                />
                Aguarde
              </>
            ) : (
              'Criar evento'
            )}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
