/* eslint-disable import/no-cycle */

import { store } from '~/store';

export function inventoryClassesMemberRequest() {
  return {
    type: '@inventory/LIST_CLASSES_MEMBER_REQUEST',
  };
}

export function inventoryClassesMemberSuccess(data) {
  return {
    type: '@inventory/LIST_CLASSES_MEMBER_SUCCESS',
    payload: data,
  };
}

export function inventoryClassesMemberFailure() {
  return {
    type: '@inventory/LIST_CLASSES_MEMBER_FAILURE',
  };
}

export function inventoryGetJourneyClassRequest(idCourseClass) {
  return {
    type: '@inventory/GET_JOURNEY_CLASS_REQUEST',
    payload: idCourseClass,
  };
}

export function inventoryGetJourneyClassSuccess(data) {
  return {
    type: '@inventory/GET_JOURNEY_CLASS_SUCCESS',
    payload: data,
  };
}

export function inventoryGetJourneyClassFailure() {
  return {
    type: '@inventory/GET_JOURNEY_CLASS_FAILURE',
  };
}

export function setClassCourseSelected(data) {
  return {
    type: '@inventory/SET_CLASS_COURSE_TO_JOURNEY',
    payload: data,
  };
}

export function changeStatusClassCourse(item, status) {
  const { journey } = store.getState().inventory;
  const newJourney = [...journey];

  if (journey.length) {
    const elementsIndex = journey.findIndex(
      element => element.cod_modulo === item.cod_modulo
    );

    newJourney[elementsIndex] = {
      ...newJourney[elementsIndex],
      status_membro_modulo: status,
    };
  }

  return {
    type: '@inventory/CHANGE_STATUS_CLASS_COURSE',
    payload: newJourney,
  };
}

export function inventoryGetFilesByModuleRequest(query) {
  return {
    type: '@inventory/GET_FILES_BY_MODULE_REQUEST',
    payload: query,
  };
}

export function inventoryGetFilesByModuleSuccess(files) {
  return {
    type: '@inventory/GET_FILES_BY_MODULE_SUCCESS',
    payload: files,
  };
}

export function inventoryGetFilesByModuleFailure() {
  return {
    type: '@inventory/GET_FILES_BY_MODULE_FAILURE',
  };
}
