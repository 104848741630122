import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import api from '~/services/api';

import { Form, Input } from '@rocketseat/unform';

export default function EditPost({ post, loadPost, showEdit }) {
  const [disabled, setDisabled] = useState(false);
  const [title, setTitle] = useState(post.tit_post);
  const [descricao, setDescricao] = useState(post.desc_post);

  const handlePost = useCallback(
    async ({ comment, title }, { resetForm }) => {
      try {
        setDisabled(true);
        const response = await api.post('/post', {
          desc_post: comment,
          cod_post: post.cod_post,
          tit_post: title,
        });
        if (!response.data.records.error) {
          loadPost();
          resetForm();
          showEdit(false);
        }
      } catch (error) {
        setDisabled(false);
        toast.error('Falha na comunicação com servidor');
      }
    },
    [loadPost, showEdit, post]
  );

  return (
    <Form className="form-label-group mb-2" onSubmit={handlePost}>
      <Input
        name="title"
        className="form-control mb-2"
        value={title}
        onChange={e => setTitle(e.target.value)}
      />
      <Input
        multiline
        rows={7}
        name="comment"
        className="form-control"
        placeholder="Edite aqui sua descrição"
        value={descricao}
        onChange={e => {
          e.target.value.length > 0 ? setDisabled(false) : setDisabled(true);
          setDescricao(e.target.value);
        }}
      />
      <button
        disabled={disabled}
        type="submit"
        className="btn btn-sm btn-primary waves-effect waves-light mt-1 pull-right"
      >
        Salvar
      </button>
      <button
        onClick={() => showEdit(false)}
        type="button"
        className="btn btn-link waves-effect waves-light mt-1"
      >
        Cancelar
      </button>
    </Form>
  );
}
EditPost.propTypes = {
  post: PropTypes.object.isRequired,
  loadPost: PropTypes.func.isRequired,
};

EditPost.defaultProps = {
  post: {},
};
