import React from 'react';
import PropTypes from 'prop-types';

import { Route, Redirect } from 'react-router-dom';
import { store } from '~/store';

import AuthLayout from '~/pages/_layouts/auth';
import MartLayout from '~/pages/_layouts/psicomart';
import DefaultLayout from '~/pages/_layouts/default';
import ExternalLayout from '~/pages/_layouts/external';
import IframeLayout from '~/pages/_layouts/iframe';

import routes from './routes';
import Breadcrumbs from '~/components/Breadcrumbs';

function RouteWrapper({
  component: Component,
  isPrivate,
  sidebar,
  breadcrumb,
  externalAccess,
  iframe,
  ...rest
}) {
  const { signed } = store.getState().auth;
  const { psicomart } = store.getState().auth;

  if (!signed && isPrivate) {
    return <Redirect to="/" />;
  }

  if (signed && !isPrivate && !psicomart) {
    return <Redirect to="/feed" />;
  }

  if (!externalAccess) {
    if (signed && !isPrivate && psicomart) {
      return <Redirect to="/dashboard" />;
    }
  }

  // eslint-disable-next-line no-nested-ternary
  let Layout = signed ? (psicomart ? MartLayout : DefaultLayout) : AuthLayout;

  if (!isPrivate && externalAccess) {
    Layout = ExternalLayout;
  }

  if (!isPrivate && iframe) {
    Layout = IframeLayout;
  }

  document.title = rest.name
    ? `PsicoMart - ${rest.name}`
    : `PsicoMart - Ferramenta para promover eventos, cursos e produtos para psicologia`;

  return (
    <Route
      {...rest}
      render={props => {
        const { match } = props;
        const crumbs = routes
          .filter(({ path }) => match.path.includes(path))
          .map(({ path, ...rests }) => ({
            path: Object.keys(match.params).length
              ? Object.keys(match.params).reduce(
                  (paths, param) =>
                    paths.replace(`:${param}`, match.params[param]),
                  path
                )
              : path,
            ...rests,
          }));

        return (
          <Layout
            sidebar={sidebar}
            breadcrumb={breadcrumb}
            crumbs={<Breadcrumbs crumbs={crumbs} />}
          >
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  externalAccess: PropTypes.bool,
  iframe: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
  breadcrumb: PropTypes.bool,
  sidebar: PropTypes.bool,
  match: PropTypes.shape({
    path: PropTypes.string,
    params: PropTypes.shape({}),
  }),
};

RouteWrapper.defaultProps = {
  isPrivate: false,
  externalAccess: false,
  iframe: false,
  breadcrumb: false,
  sidebar: true,
  match: {},
};

export default RouteWrapper;
