/* eslint-disable no-console */
/* eslint-disable consistent-return */

import { toast } from 'react-toastify';

import Config from '~/config';

function analyzeResponse(response) {
  if (response.ok) {
    return response.json();
  }
  return null;
}

function prepare(response) {
  const address = {};

  if (response.error_message) {
    toast.error(response.error_message);
    return;
  }

  const { results } = response;
  results[0].address_components.map(addr => {
    if (addr.types.indexOf('subpremise') !== -1) {
      address.complement = addr.long_name;
    } else if (addr.types.indexOf('street_number') !== -1) {
      address.number = addr.long_name;
    } else if (addr.types.indexOf('route') !== -1) {
      address.address = addr.short_name;
    } else if (addr.types.indexOf('sublocality') !== -1) {
      address.neighborhood = addr.long_name;
    } else if (addr.types.indexOf('administrative_area_level_2') !== -1) {
      address.city = addr.long_name;
    } else if (addr.types.indexOf('administrative_area_level_1') !== -1) {
      address.state = addr.short_name;
    } else if (addr.types.indexOf('postal_code') !== -1) {
      address.code = addr.long_name;
    }
    address.place_id = results[0].place_id;
    return true;
  });

  return address;
}

export default function fetchGoogle(locale) {
  /** Não esquecer de trocar a chave no arquivo index.html */
  const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${locale}&result_type=street_address&key=${Config.KEY_GOOGLE_MAPS}`;
  const options = {
    method: 'GET',
  };

  return fetch(url, options)
    .then(analyzeResponse)
    .then(prepare)
    .catch(e => {
      console.log(e);
    });
}
