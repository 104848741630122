import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, Button, Table, Badge, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Form } from '@unform/web';

import api from '~/services/api';
import noImage from '~/assets/img/profile.png';

import Loading from '~/components/Loading';
import Input from '~/components/InputText';
import InputTextarea from '~/components/InputTextarea';
import InputSelect from '~/components/InputSelect';
import Confirm from '~/components/BoxConfirm';

import { AvatarInput } from './styles';

export default function Speakers({ data, course }) {
  const [speakers, setSpeakers] = useState([]);
  const [speaker, setSpeaker] = useState({});
  const [load, setLoad] = useState(false);
  const [modalShowSpeakers, setModalShowSpeakers] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [deleted, setDeleted] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const label = course ? 'Ministrante' : 'Palestrante';

  const loadSpeakers = useCallback(async value => {
    setLoad(true);
    await api
      .get(`/palestrante/?q=(cod_evento:${value.cod_evento})`)
      .then(response => {
        const { records } = response.data;

        if (records.error) {
          toast.error(records.msg);
          setLoad(false);
          return;
        }
        setSpeakers(records);
        setLoad(false);
      });
  }, []);

  useEffect(() => {
    loadSpeakers(data);
  }, [loadSpeakers, data]);

  const modalClose = useCallback(() => {
    setModalShowSpeakers(false);
    setAvatar(null);
    setSpeaker({});
  }, []);

  const handleSubmitSpeakers = useCallback(
    async value => {
      if (avatar && avatar.indexOf('http') < 0) value.avatar = avatar;
      if (value.cod_palestrante) {
        await api
          .put(`/palestrante/${value.cod_palestrante}`, value)
          .then(response => {
            const { records } = response.data;
            if (records.error) {
              toast.error(records.msg);
              return;
            }
            toast.success(`${label} editado com sucesso`);
          });
      } else {
        await api.post('/palestrante', value).then(response => {
          const { records } = response.data;
          if (records && records.error) {
            toast.error(records.msg);
            return;
          }
          toast.success(`${label} cadastrado com sucesso`);
        });
      }
      loadSpeakers(data);
      modalClose();
    },
    [loadSpeakers, data, modalClose, label, avatar]
  );

  const handleStatus = useCallback(
    async (id, status) => {
      await api.put(`/palestrante/${id}`, { status }).then(response => {
        const { records } = response.data;
        if (records.error) {
          toast.error(records.msg);
          return;
        }
        loadSpeakers(data);
      });
    },
    [loadSpeakers, data]
  );

  const handleConfirm = useCallback(async id => {
    setDeleted(id);
    setConfirm(true);
  }, []);

  const handleDelete = useCallback(async () => {
    if (deleted) {
      await api.delete(`/palestrante/${deleted}`).then(response => {
        const { records } = response.data;
        if (records.error) {
          toast.error(records.msg);
          return;
        }
        toast.success(`${label} apagado com sucesso`);
        setDeleted(null);
        loadSpeakers(data);
        setConfirm(false);
      });
    }
  }, [loadSpeakers, data, deleted, label]);

  const handleUpdate = useCallback(value => {
    if (value) {
      setSpeaker(value);
      setAvatar(value.img_palestrante);
    }
    setModalShowSpeakers(true);
  }, []);

  const handleAvatarSpeaker = useCallback(avatarIMG => {
    const reader = new FileReader();
    reader.readAsDataURL(avatarIMG);
    reader.onload = () => {
      setAvatar(reader.result);
    };
    reader.onerror = error => {
      toast.error(error);
    };
  }, []);

  const handleForm = useCallback(
    value => {
      return (
        <Form onSubmit={handleSubmitSpeakers} initialData={value}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {value.cod_palestrante
                ? `Editar ${label}`
                : `Cadastrar ${label.toLowerCase()}`}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md={12} className="d-flex justify-content-center">
                <AvatarInput>
                  {avatar ? (
                    <img src={avatar} alt="Avatar" />
                  ) : (
                    <img src={noImage} alt="Avatar" />
                  )}
                  <label htmlFor="avatar">
                    <i className="icon-camera"></i>
                    <input
                      type="file"
                      id="avatar"
                      onChange={e => handleAvatarSpeaker(e.target.files[0])}
                    />
                  </label>
                </AvatarInput>
              </Col>
              <Col md={12}>
                <div className="form-group">
                  <label>Nome {label.toLowerCase()}</label>
                  <Input name="nome_palestrante" placeholder="Digite o nome" />
                  <Input name="cod_palestrante" type="hidden" />
                  <Input
                    name="cod_evento"
                    type="hidden"
                    defaultValue={data.cod_evento}
                  />
                </div>
              </Col>
              <Col md={12}>
                <div className="form-group">
                  <label>Status</label>
                  <InputSelect
                    name="status"
                    placeholder="Selecione"
                    options={[
                      { value: '1', label: 'Ativo' },
                      { value: '0', label: 'Inativo' },
                    ]}
                  />
                </div>
              </Col>
              <Col md={12}>
                <div className="form-group">
                  <label>Descrição</label>
                  <InputTextarea
                    name="desc_palestrante"
                    placeholder={`Informações do ${label.toLowerCase()}`}
                    rows="4"
                  />
                </div>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-center">
            <Button
              size="lg"
              block
              className="text-secondary"
              variant="link"
              onClick={modalClose}
            >
              <i className="icon-cross2 mr-1 font-weight-light"></i>
              Cancelar
            </Button>
            <Button size="lg" block type="submit">
              {value.cod_palestrante ? 'Salvar' : 'Cadastrar'}
            </Button>
          </Modal.Footer>
        </Form>
      );
    },
    [data, handleSubmitSpeakers, modalClose, label, avatar, handleAvatarSpeaker]
  );

  return (
    <Row>
      <Col>
        {load ? (
          <Loading />
        ) : (
          <Card>
            <Card.Header>
              <Button
                variant="success"
                size="sm"
                onClick={() => handleUpdate()}
              >
                <i className="icon-plus2"></i> Cadastrar {label.toLowerCase()}
              </Button>
            </Card.Header>
            <Card.Body>
              <Table striped hover>
                <thead>
                  <tr>
                    <th colSpan="2">Nome {label.toLowerCase()}</th>
                    <th>Descrição</th>
                    <th>Status</th>
                    <th className="text-center">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(speakers).length === 0 ? (
                    <tr>
                      <td colSpan="7" className="p-0 text-center">
                        <div className="alert alert-info border-0 alert-dismissible mb-0">
                          <i className="icon-info3 border-2 rounded-round p-1 mr-2"></i>
                          <span>
                            Você ainda não cadastrou nenhum{' '}
                            {label.toLowerCase()}
                          </span>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    speakers.map(value => (
                      <tr key={value.cod_palestrante}>
                        <td className="text-center">
                          {value.img_palestrante ? (
                            <img
                              src={value.img_palestrante}
                              alt=""
                              className="rounded-circle"
                              width={40}
                            />
                          ) : (
                            <img
                              src={noImage}
                              alt=""
                              className="rounded-circle border-1 text-muted"
                              width={40}
                            />
                          )}
                        </td>
                        <td>{value.nome_palestrante}</td>
                        <td>
                          {value.desc_palestrante.substring(0, 60)}
                          {value.desc_palestrante.length > 60 && '...'}
                        </td>
                        <td>
                          {value.status === '1' ? (
                            <Badge
                              className="c-pointer text-decoration-none"
                              variant="success"
                              onClick={() =>
                                handleStatus(value.cod_palestrante, 0)
                              }
                            >
                              Ativo
                            </Badge>
                          ) : (
                            <Badge
                              className="c-pointer text-decoration-none"
                              variant="secondary"
                              onClick={() =>
                                handleStatus(value.cod_palestrante, 1)
                              }
                            >
                              Inativo
                            </Badge>
                          )}
                        </td>
                        <td className="text-center">
                          <button
                            title="Editar"
                            type="button"
                            className="btn btn-sm btn-light btn-icon"
                            onClick={() => handleUpdate(value)}
                          >
                            <i className="icon-pencil5 text-primary"></i>
                          </button>
                          <button
                            title="Apagar"
                            type="button"
                            className="btn btn-sm btn-light btn-icon mr-1"
                            onClick={() => handleConfirm(value.cod_palestrante)}
                          >
                            <i className="icon-trash text-primary"></i>
                          </button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        )}
      </Col>
      <Modal
        show={modalShowSpeakers}
        onHide={modalClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {handleForm(speaker)}
      </Modal>
      <Confirm
        title="Apagar"
        show={confirm}
        onClose={() => setConfirm(false)}
        size="sm"
        onYesClick={handleDelete}
        onNoClick={() => setConfirm(false)}
        message={`Tem certeza de que deseja apagar esse ${label.toLowerCase()}?`}
      />
    </Row>
  );
}
Speakers.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
  course: PropTypes.bool,
};

Speakers.defaultProps = {
  data: {},
  course: false,
};
