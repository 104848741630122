export function interestRequest(query) {
  return {
    type: '@interest/INTEREST_REQUEST',
    payload: query,
  };
}

export function interestSuccess(data) {
  return {
    type: '@interest/INTEREST_SUCCESS',
    payload: data,
  };
}

export function interestFailure() {
  return {
    type: '@interest/INTEREST_REQUEST',
  };
}
