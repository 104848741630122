import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Button } from 'react-bootstrap';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { parseISO } from 'date-fns';

import { toast } from 'react-toastify';
import api from '~/services/api';

import Loading from '~/components/Loading';
import InputText from '~/components/InputText';
import Input from '~/components/InputMask';
import Select from '~/components/InputSelect';
import InputDate from '~/components/DatePicker';

import { AvatarInput } from './styles';

import history from '~/services/history';

export default function Profile() {
  const formRef = useRef(null);
  const formPass = useRef(null);
  const { profile } = useSelector(state => state.user);
  const [user, setUser] = useState({});
  const [load, setLoad] = useState(true);
  const [loadPass, setLoadPass] = useState(false);

  const loadProfile = useCallback(async () => {
    await api.get(`/membro/${profile.cod_membro}`).then(response => {
      setUser(response.data.records);
      setLoad(false);
    });
  }, [profile]);

  useEffect(() => {
    loadProfile();
  }, [loadProfile]);

  const handleSubmit = useCallback(
    async data => {
      try {
        setLoad(true);
        formRef.current.setErrors({});

        const schema = Yup.object().shape({
          cpf_cnpj_membro: Yup.string().required('CPF é obrigatório'),
          dt_nascimento: Yup.string()
            .required('Data Nascimento é obrigatório')
            .nullable(),
          telefone: Yup.string().required('Telefone é obrigatório'),
          cod_servico_padrao: Yup.string().required('Profissão é obrigatório'),
          cep_membro: Yup.string().required('Cep é obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const response = await api.put(`/membro/${profile.cod_membro}`, data);

        if (response.data.records.error) {
          toast.error(response.data.records.msg);
          setLoad(false);
          return;
        }

        toast.success(response.data.records.msg);
        loadProfile();
      } catch (err) {
        const validationErrors = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            validationErrors[error.path] = error.message;
          });
          setLoad(false);
          formRef.current.setErrors(validationErrors);
        }
      }
    },
    [profile, loadProfile]
  );

  const handlePassword = useCallback(async (data, { reset }) => {
    try {
      setLoadPass(true);
      formPass.current.setErrors({});

      const schema = Yup.object().shape({
        senha_atual: Yup.string().required('Senha atual é obrigatório.'),
        nova_senha: Yup.string().required('Nova senha é obrigatório.'),
        repetir: Yup.string()
          .oneOf(
            [Yup.ref('nova_senha'), null],
            'Nova senha e seu campo de repetir são diferentes.'
          )
          .required('Repetir nova senha é obrigatório.'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      delete data.repetir;

      const response = await api.post(`/membro/alterar-senha`, data);

      if (response.data.records.error) {
        toast.error(response.data.records.msg);
        setLoadPass(false);
        return;
      }

      toast.success(response.data.records.msg);
      setLoadPass(false);
      reset();
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        setLoadPass(false);
        formPass.current.setErrors(validationErrors);
      }
    }
  }, []);

  const handleAvatar = useCallback(
    avatar => {
      const data = new FormData();
      data.append('arquivo1', avatar[0]);
      data.append('cod_membro', profile.cod_membro);

      api.post('/membro/enviar-img-perfil', data).then(response => {
        if (response.data.records.error) {
          toast.error(response.data.records.msg);
          return;
        }
        loadProfile();
        toast.success('Foto atulizada com sucesso');
      });
    },
    [profile, loadProfile]
  );

  return (
    <div className="content padding-tab-top-post">
      <Row>
        <Col xl={12}>
          <div className="d-flex justify-content-between align-items-center">
            <div className="mb-3">
              <h3 className="mb-0 font-weight-semibold">
                <i
                  role="presentation"
                  style={{ fontSize: '1.5rem' }}
                  onClick={() => history.goBack()}
                  className="icon-arrow-left52 mr-2 c-pointer"
                ></i>
                Meu perfil
              </h3>
              <span className="text-muted d-block">
                Editar informações do meu perfil
              </span>
            </div>
          </div>
        </Col>
        <Col>
          <ul className="nav nav-tabs mb-0">
            <li className="nav-item">
              <a
                href="#home"
                className="nav-link rounded-top active"
                data-toggle="tab"
              >
                Perfil
              </a>
            </li>
            <li className="nav-item">
              <a
                href="#password"
                className="nav-link rounded-top"
                data-toggle="tab"
              >
                Senha
              </a>
            </li>
          </ul>
          <div className="tab-content card card-body border-top-0 rounded-top-0 mb-0">
            <div className="tab-pane fade show active" id="home">
              {load ? (
                <Loading />
              ) : (
                <Form
                  ref={formRef}
                  onSubmit={handleSubmit}
                  initialData={{
                    cpf_cnpj_membro: user?.cpf_cnpj_membro,
                    dt_nascimento:
                      user?.dt_nascimento && parseISO(user?.dt_nascimento),
                    telefone: user?.telefone,
                    cod_servico_padrao: user?.cod_servico_padrao,
                    cep_membro: user?.cep_membro,
                  }}
                >
                  <Row className="justify-content-md-center">
                    <Col
                      md={12}
                      className="d-flex mb-4 justify-content-md-center"
                    >
                      <AvatarInput>
                        <img src={user?.img_perfil} alt="Avatar" />
                        <label htmlFor="avatar">
                          <i className="icon-camera"></i>
                          <input
                            type="file"
                            id="avatar"
                            onChange={e => handleAvatar(e.target.files)}
                          />
                        </label>
                      </AvatarInput>
                    </Col>
                    <Col xl={6}>
                      <div className="form-group">
                        <label>Nome</label>
                        <input
                          name="nome_membro"
                          className="form-control"
                          disabled
                          value={profile.nome_membro}
                        />
                      </div>
                      <div className="form-group">
                        <label>Email</label>
                        <input
                          name="email_membro"
                          className="form-control"
                          disabled
                          value={profile.email}
                        />
                      </div>
                      <Row>
                        <Col>
                          <div className="form-group">
                            <label>CPF</label>
                            <Input
                              name="cpf_cnpj_membro"
                              mask="999.999.999-99"
                            />
                          </div>
                        </Col>
                        <Col>
                          <div className="form-group">
                            <label>Data nascimento</label>
                            <InputDate
                              name="dt_nascimento"
                              showYearDropdown
                              showMonthDropdown
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="form-group">
                            <label>Telefone</label>
                            <Input name="telefone" mask="(99)999999999" />
                          </div>
                        </Col>
                        <Col>
                          <div className="form-group">
                            <label>Cep</label>
                            <Input name="cep_membro" mask="99999-999" />
                          </div>
                        </Col>

                        <Col xl="12">
                          <div className="form-group">
                            <label>Profissão</label>
                            <Select
                              name="cod_servico_padrao"
                              placeholder="Selecione"
                              options={[
                                { value: '1', label: 'Psicólogo' },
                                {
                                  value: '2',
                                  label: 'Estudante de Psicologia',
                                },
                                { value: '3', label: 'Outro' },
                              ]}
                            />
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="d-flex justify-content-md-center">
                    <Col xl={6}>
                      <Button
                        className="w-25"
                        variant="primary"
                        type="submit"
                        disabled={load}
                      >
                        {load ? 'Aguarde...' : 'Salvar'}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </div>

            <div className="tab-pane fade" id="password">
              {loadPass ? (
                <Loading />
              ) : (
                <Form ref={formPass} onSubmit={handlePassword}>
                  <Row className="justify-content-md-center">
                    <Col xl={6}>
                      <div className="form-group">
                        <label>Senha atual</label>
                        <InputText type="password" name="senha_atual" />
                      </div>
                      <div className="form-group">
                        <label>Nova senha</label>
                        <InputText type="password" name="nova_senha" />
                      </div>
                      <div className="form-group">
                        <label>Repetir nova senha</label>
                        <InputText type="password" name="repetir" />
                      </div>
                    </Col>
                  </Row>
                  <Row className="justify-content-md-center">
                    <Col xl={6}>
                      <Button className="w-25" variant="primary" type="submit">
                        Salvar
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}
