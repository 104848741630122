import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import api from '~/services/api';
import BoxConfirm from '~/components/BoxConfirm';

function DeleteEvent({ data, entity, load }) {
  const [showBox, setShowBox] = useState(false);

  function onClose() {
    setShowBox(false);
  }

  async function deleteEvent() {
    const response = await api.put(`/evento/cancelar/${data.cod_evento}`);
    if (!response.data.records.error) {
      toast.success(`${entity} apagado com sucesso!`);
      load();
      onClose();
      return;
    }
    toast.error(response.data.records.msg);
    onClose();
  }

  async function unpublishEvent() {
    if (data.status_eve !== '1') {
      onClose();
      toast.info(`Este ${entity} já se encontra despublicado!`);
      return;
    }
    await api.put(`/evento/desativar/${data.cod_evento}`).then(response => {
      if (!response.data.records.error) {
        toast.success(`${entity} despublicado com sucesso!`);
        load();
        onClose();
        return;
      }
      toast.error(response.data.records.msg);
      onClose();
    });
  }

  return (
    <>
      <span
        role="presentation"
        className="dropdown-item"
        onClick={() => setShowBox(true)}
      >
        <i className="icon-bin"></i> Apagar {entity}
      </span>
      {entity === 'Evento' && data.qtde_inscritos > 0 ? (
        <BoxConfirm
          size="sm"
          title="Apagar"
          show={showBox}
          onClose={onClose}
          onNoClick={onClose}
          onYesClick={unpublishEvent}
          aliasBtnNo="Cancelar"
          aliasBtnYes="Despublicar"
          message="Não é possível apagar um evento com inscrições confirmadas. Deseja despublicar?"
        />
      ) : (
        <BoxConfirm
          size="sm"
          title="Apagar"
          show={showBox}
          onClose={onClose}
          onNoClick={onClose}
          onYesClick={deleteEvent}
          message={`Tem certeza de que deseja apagar esse ${entity.toLowerCase()}?`}
        />
      )}
    </>
  );
}

DeleteEvent.propTypes = {
  data: PropTypes.shape({
    cod_evento: PropTypes.string,
    status_eve: PropTypes.string,
    qtde_inscritos: PropTypes.number,
  }).isRequired,
  entity: PropTypes.string.isRequired,
  load: PropTypes.func.isRequired,
};

export default DeleteEvent;
