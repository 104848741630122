export function menusGetRequest() {
  return {
    type: '@layouts/GET_MENUS_REQUEST',
  };
}

export function menusGetSuccess(data) {
  return {
    type: '@layouts/GET_MENUS_SUCCESS',
    payload: data,
  };
}

export function menusGetFailure() {
  return {
    type: '@layouts/GET_MENUS_FAILURE',
  };
}

export function activeMenuRequest(entity) {
  return {
    type: '@layouts/ACTIVE_MENU_REQUEST',
    payload: entity,
  };
}
