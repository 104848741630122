import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import * as LayoutsActions from './actions';

export function* menusGetRequest() {
  try {
    const response = yield call(api.get, `/config/listar-menu`);
    if (response.data.records.error) {
      toast.error(response.data.records.msg);
      yield put(LayoutsActions.menusGetFailure());
    }
    yield put(LayoutsActions.menusGetSuccess(response.data.records));
  } catch (err) {
    toast.error('Falha na comunicação com servidor!');
    yield put(LayoutsActions.menusGetFailure());
  }
}

export default all([takeLatest('@layouts/GET_MENUS_REQUEST', menusGetRequest)]);
