/* eslint-disable no-console */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Table, Alert } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { format, parseISO } from 'date-fns';
import ReactHtmlParser from 'react-html-parser';
import { Form } from '@unform/web';

import api from '~/services/api';
import { objIsNull, formatCurrencyValue } from '~/util';

// import InputCheck from '~/components/InputCheck';
// import Input from '~/components/InputText';
import Loading from '~/components/Loading';

export default function ModalReceipts({ modalShow, modalClose, data }) {
  const formRef = useRef(null);
  const [receipts, setReceipts] = useState([]);
  const [load, setLoad] = useState(false);
  const [anticipate, setAnticipate] = useState(false);
  const [msg, setMsg] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [simulation, setSimulation] = useState(false);

  const loadReceipts = useCallback(() => {
    if (objIsNull(data)) {
      setLoad(true);
      api
        .get(
          `/venda/listar-fatura/?q=(cod_cob_sub_conta:${data.cod_cob_sub_conta})`
        )
        .then(response => {
          if (response.data.records.error) {
            toast.error(response.data.records.msg);
            setLoad(false);
            return;
          }
          setReceipts(response.data.records);
          setLoad(false);
        });
    }
  }, [data]);

  useEffect(() => {
    loadReceipts();
  }, [loadReceipts]);

  const handleSubmit = useCallback(
    datas => {
      console.log(datas, simulation);
    },
    [simulation]
  );

  // const handleAnticipate = useCallback(cod => {
  //   setLoad(true);
  //   api.get(`/venda/ver-antecipar/${cod}`).then(response => {
  //     if (response.data.records.error) {
  //       toast.error(response.data.records.msg);
  //       setLoad(false);
  //       return;
  //     }
  //     setMsg(response.data.records.msg);
  //     setAnticipate(true);
  //     setLoad(false);
  //   });
  // }, []);

  const handleClose = useCallback(() => {
    setAnticipate(false);
    setMsg('');
    modalClose(false);
  }, [modalClose]);

  const handleCancel = useCallback(() => {
    setAnticipate(false);
    setMsg('');
  }, []);

  // const handleSimulation = useCallback(async () => {
  //   setSimulation(true);
  //   await formRef.current.submitForm();
  // }, []);

  return (
    <Modal
      show={modalShow}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size={anticipate ? 'sm' : ''}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {anticipate ? 'Atenção' : 'Recebimentos'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {load ? (
          <Loading />
        ) : (
          <>
            {anticipate ? (
              <div className="d-flex justify-content-center flex-column">
                <p className="text-center mb-4">{ReactHtmlParser(msg)}</p>
                <div className="d-flex justify-content-center">
                  <Button
                    block
                    variant="link"
                    className="text-secondary"
                    onClick={handleCancel}
                  >
                    <i className="icon-cross2 mr-1 font-weight-light"></i>
                    Cancelar
                  </Button>
                  <Button block variant="success">
                    Confirmar antecipação
                  </Button>
                </div>
              </div>
            ) : (
              <>
                <p>
                  Aqui estão os recebimentos desta venda. Datas de liberação
                  podem variar de acordo com a forma como o ingresso foi pago.
                  Caso tenha sido pago com boleto, a data de liberação será de 2
                  dias úteis após o pagamento. No caso de pagamento em cartão de
                  crédito, a data de liberação será 30 dias depois quando à
                  vista e 30, 60, 90 dias, etc quando for parcelado.
                </p>
                {receipts.length < 1 ? (
                  <Alert variant="info" className="mb-0 border-0">
                    <i className="fa fa-exclamation-triangle mr-2"></i>
                    Você ainda não possui recebimentos para esta venda
                  </Alert>
                ) : (
                  <>
                    <Form ref={formRef} onSubmit={handleSubmit}>
                      <Table striped hover>
                        <thead>
                          <tr>
                            <th>Valor</th>
                            <th>Data de liberação</th>
                            {/* <th className="text-center">Simular/Antecipar</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {receipts.map(receipt => (
                            <tr key={receipt.cod_fatura}>
                              <td>{formatCurrencyValue(receipt.valor_prof)}</td>
                              <td>
                                {receipt.dt_liberacao
                                  ? format(
                                      parseISO(receipt.dt_liberacao),
                                      'dd/MM/yyyy'
                                    )
                                  : 'Não disponivel'}
                              </td>
                              {/* <td className="text-center">
                                <Input
                                  name="cod_fatura"
                                  value={receipt.cod_fatura}
                                  type="hidden"
                                />
                                <InputCheck
                                  name="antecipar"
                                  options={[
                                    {
                                      id: '1',
                                      label: ' ',
                                    },
                                  ]}
                                />
                              </td> */}
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      {/* <div className="d-flex justify-content-end mt-3">
                        <Button
                          variant="secondary"
                          className="mr-1"
                          onClick={() => setSimulation(true)}
                          type="submit"
                        >
                          Simular
                        </Button>
                        <Button
                          onClick={() => setSimulation(false)}
                          type="submit"
                          variant="primary"
                        >
                          Antecipar
                        </Button>
                      </div> */}
                    </Form>
                  </>
                )}
              </>
            )}
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}
ModalReceipts.propTypes = {
  modalShow: PropTypes.bool.isRequired,
  modalClose: PropTypes.func.isRequired,
  data: PropTypes.objectOf(PropTypes.any),
};

ModalReceipts.defaultProps = {
  data: {},
};
