import React, { useRef, useEffect } from 'react';
import ReactSelect from 'react-select';
import { useField } from '@unform/core';

export default function Input({ name, options, isUFSelected, ...rest }) {
  const selectRef = useRef(null);

  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: 'state.value',
      getValue: ref => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }
          return ref.state.value.map(option => option.value);
        }
        if (!ref.state.value) {
          return '';
        }
        return ref.state.value.value;
      },
    });
  }, [fieldName, registerField, rest.isMulti, isUFSelected]);

  return (
    <>
      <ReactSelect
        defaultValue={
          defaultValue && options.find(option => option.value === defaultValue)
        }
        ref={selectRef}
        classNamePrefix="react-select"
        styles={{
          control: provided =>
            error
              ? {
                  ...provided,
                  borderColor: '#f44336 !important',
                }
              : provided,
        }}
        options={options}
        {...rest}
      />

      {error && <span className="form-text text-danger">{error}</span>}
    </>
  );
}
