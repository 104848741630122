import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useMemo,
} from 'react';

import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import * as Yup from 'yup';

import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import { Button, Row, Col } from 'react-bootstrap';

import * as ModulesActions from '~/store/modules/modules/actions';

import api from '~/services/api';
import history from '~/services/history';

import Loading from '~/components/Loading';
import Input from '~/components/InputText';
import TabFile from '~/components/TypeCourse/TabFile';
import TabVideo from '~/components/TypeCourse/TabVideo';

import { buildQueryString } from '~/util';
import { TextEditor } from './styles';

function ContentModule(props) {
  const dispatch = useDispatch();
  const formRef = useRef(null);

  const module = useSelector(state => state.modules.module);
  const loading = useSelector(state => state.modules.loading);

  const [htmlContent, setHtmlContent] = useState('');

  const { match } = props;
  const loadModule = useCallback(
    query => {
      dispatch(ModulesActions.modulesGetRequest(query));
    },
    [dispatch]
  );

  useEffect(() => {
    if (match.params.idCourse && match.params.idModule) {
      const query = buildQueryString({
        cod_evento: match.params.idCourse,
        cod_modulo_curso: match.params.idModule,
      });
      loadModule(query);
    }
  }, [match, loadModule]);

  useMemo(() => {
    if (module) {
      setTimeout(() => {
        setHtmlContent(module.desc_modulo);
      });
    }
  }, [module]);

  async function handleSubmit(data) {
    try {
      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        nome_modulo: Yup.string()
          .min(3, 'Ops! Nome muito pequeno!')
          .required(
            'O nome do módulo é um campo de preenchimento obrigatório!'
          ),
        tit_conteudo: Yup.string()
          .min(3, 'Ops! Título muito pequeno!')
          .required(
            'O título do conteúdo é um campo de preenchimento obrigatório!'
          ),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      data.cod_evento = match.params.idCourse;
      data.cod_modulo_curso = match.params.idModule;

      data.arr_turmas = module.arr_turmas;
      data.desc_modulo = htmlContent;

      const response = await api.post(
        `/evento/salvar-conteudo-modulo-curso`,
        data
      );

      if (response.data.records.error) {
        toast.error(response.data.records.msg);
      } else {
        toast.success('Conteúdo atualizado com sucesso!');
        setTimeout(() => {
          const query = buildQueryString({
            cod_evento: match.params.idCourse,
            cod_modulo_curso: match.params.idModule,
          });
          loadModule(query);
        }, 1000);
      }

      return false;
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
    return false;
  }

  function getInitialData() {
    return {
      nome_modulo: module.nome_modulo,
      tit_conteudo: module.tit_conteudo,
      cod_modulo_curso: module.cod_modulo_curso,
    };
  }

  return (
    <div className="content padding-tab-top-post">
      <Row>
        <Col>
          <div className="d-flex justify-content-between align-items-center">
            <div className="mb-3">
              <h3 className="mb-0 font-weight-semibold">
                <i
                  role="presentation"
                  style={{ fontSize: '1.5rem' }}
                  onClick={() => history.goBack()}
                  className="icon-arrow-left52 mr-2 c-pointer"
                ></i>
                Editor de Conteúdo
              </h3>
              <span className="text-muted d-block">
                Defina um nome e insira o conteúdo que será visualizado pelo
                seus alunos.
              </span>
            </div>
          </div>
        </Col>
        <Col xl={12}>
          {loading ? (
            <Loading />
          ) : (
            <>
              <ul className="nav nav-tabs mb-0">
                <li className="nav-item">
                  <a
                    id="ref-module"
                    href="#module"
                    data-toggle="tab"
                    className="nav-link rounded-top active"
                  >
                    Principal
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    id="ref-video"
                    href="#video"
                    data-toggle="tab"
                    className="nav-link rounded-top"
                  >
                    Vídeoaula
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    id="ref-midia"
                    href="#midia"
                    data-toggle="tab"
                    className="nav-link rounded-top"
                  >
                    Mídias
                  </a>
                </li>
              </ul>
              <div className="tab-content card card-body border-top-0 rounded-top-0 mb-0">
                <div className="tab-pane fade show active" id="module">
                  <Form
                    ref={formRef}
                    onSubmit={handleSubmit}
                    initialData={getInitialData()}
                  >
                    <Col xl={12}>
                      <div className="form-group">
                        <label htmlFor="nome_modulo">Nome *</label>
                        <Input
                          required
                          type="text"
                          id="nome_modulo"
                          name="nome_modulo"
                          className="form-control"
                          placeholder="Nome do módulo..."
                        />
                      </div>
                    </Col>
                    <Col xl={12}>
                      <div className="form-group">
                        <label htmlFor="tit_conteudo">
                          Título do Conteúdo *
                        </label>
                        <Input
                          required
                          type="text"
                          id="tit_conteudo"
                          name="tit_conteudo"
                          className="form-control"
                          placeholder="Título do conteúdo..."
                        />
                      </div>
                    </Col>
                    <Col xl={12}>
                      <div className="form-group">
                        <label>Conteúdo</label>
                        <TextEditor>
                          <ReactQuill
                            theme="snow"
                            value={htmlContent}
                            onChange={setHtmlContent}
                            modules={ContentModule.modules}
                            formats={ContentModule.formats}
                          />
                        </TextEditor>
                      </div>
                    </Col>
                    <Row className="justify-content-md-center mt-4">
                      <Col xl={2}>
                        <Button size="lg" variant="primary" block type="submit">
                          Salvar
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </div>
                <div className="tab-pane fade" id="video">
                  <TabVideo
                    module={module}
                    reload={loadModule}
                    formContentRef={formRef}
                  />
                </div>
                <div className="tab-pane fade" id="midia">
                  <TabFile module={module} />
                </div>
              </div>
            </>
          )}
        </Col>
      </Row>
    </div>
  );
}

ContentModule.modules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }, { font: [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link', 'image', 'video'],
    ['clean'],
  ],
  clipboard: {
    matchVisual: false,
  },
};

ContentModule.formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
];

ContentModule.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      idCourse: PropTypes.string,
      idModule: PropTypes.string,
    }),
  }).isRequired,
};

export default ContentModule;
