import React from 'react';

import ContentLoader from 'react-content-loader';

export default function LoaderList() {
  return (
    <ContentLoader
      height={80}
      width={215}
      speed={2}
      primaryColor="#f3f3f3"
      secondaryColor="#e7e7e7"
    >
      <rect x="15" y="5" rx="3" ry="3" width="185" height="10" />
      <rect x="15" y="30" rx="3" ry="3" width="185" height="10" />
      <rect x="15" y="55" rx="3" ry="3" width="185" height="10" />
    </ContentLoader>
  );
}
