import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { Row, Col, Button, Modal, Spinner, Card } from 'react-bootstrap';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import api from '~/services/api';
import history from '~/services/history';

import Input from '~/components/InputText';

import * as LayoutActions from '~/store/modules/layout/actions';

export default function CreateProduct({ show, handleClose }) {
  const [loading, setLoading] = useState(false);
  const [down, setDown] = useState(false);
  const [send, setSend] = useState(false);
  const [serv, setServ] = useState(false);
  const [othe, setOthe] = useState(false);
  const [type, setType] = useState(0);

  const formRef = useRef(null);
  const dispatch = useDispatch();

  async function handleSubmit(data) {
    setLoading(true);
    try {
      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        tipo_mkt_place: Yup.string().required('O título é obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const response = await api.post('/produto', data);

      if (response.data.records.error) {
        toast.error(response.data.records.msg);
        setLoading(false);
        return;
      }

      toast.success(
        'Seu produto foi criado! Agora vamos para pagina do seu produto.',
        { autoClose: 3000 }
      );

      setTimeout(function() {
        setLoading(false);
        handleClose();
        dispatch(LayoutActions.menusGetRequest('produto'));
        history.push(`/produtos/${response.data.records.cod_produto}/editar`);
      }, 3000);
    } catch (err) {
      setLoading(false);
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  return (
    <Modal
      centered
      className="modal-new-event"
      dialogClassName="modal-event"
      show={show}
      onHide={handleClose}
    >
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Modal.Header>
          <div className="media align-items-center align-items-md-start flex-column flex-md-row">
            <span className="text-teal mr-md-3 mb-3 mb-md-0">
              <i className="icon-barcode2 text-warning-400 border-warning-400 border-2 rounded-round p-2 mt-1"></i>
            </span>

            <div className="media-body text-center text-md-left">
              <h3 className="media-title font-weight-semibold">
                Criar produto
              </h3>
              <span className="text-muted">
                O produto que gostaria de cadastrar é um:
              </span>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Input type="hidden" name="tipo_mkt_place" defaultValue={type} />
          <Row className="justify-content-md-center">
            <Col xl={5} className="c-pointer">
              <Card
                onClick={() =>
                  setDown(() => {
                    setSend(false);
                    setServ(false);
                    setOthe(false);
                    setDown(true);
                    setType(1);
                  })
                }
                className={`border-light shadow-sm type-product ${down &&
                  'select-product'}`}
              >
                <Card.Body
                  style={{ minHeight: 122 }}
                  className="d-flex flex-column justify-content-center align-items-center"
                >
                  <i className="icon-cloud-download2 icon-2x"></i>
                  <p className="text-center">Material digital para download</p>
                </Card.Body>
              </Card>
            </Col>
            <Col xl={5} className="c-pointer">
              <Card
                onClick={() =>
                  setDown(() => {
                    setDown(false);
                    setServ(false);
                    setOthe(false);
                    setSend(true);
                    setType(2);
                  })
                }
                className={`border-light shadow-sm type-product ${send &&
                  'select-product'}`}
              >
                <Card.Body
                  style={{ minHeight: 122 }}
                  className="d-flex flex-column justify-content-center align-items-center"
                >
                  <i className="icon-cart icon-2x"></i>
                  <p className="text-center">Produto para envio</p>
                </Card.Body>
              </Card>
            </Col>
            <Col xl={5} className="c-pointer">
              <Card
                onClick={() =>
                  setDown(() => {
                    setDown(false);
                    setSend(false);
                    setOthe(false);
                    setServ(true);
                    setType(3);
                  })
                }
                className={`border-light shadow-sm type-product ${serv &&
                  'select-product'}`}
              >
                <Card.Body
                  style={{ minHeight: 122 }}
                  className="d-flex flex-column justify-content-center align-items-center"
                >
                  <i className="icon-rotate-cw2 icon-2x"></i>
                  <p className="text-center">Serviço de assinatura</p>
                </Card.Body>
              </Card>
            </Col>
            <Col xl={5} className="c-pointer">
              <Card
                onClick={() =>
                  setDown(() => {
                    setDown(false);
                    setSend(false);
                    setServ(false);
                    setOthe(true);
                    setType(4);
                  })
                }
                className={`border-light shadow-sm type-product ${othe &&
                  'select-product'}`}
              >
                <Card.Body
                  style={{ minHeight: 122 }}
                  className="d-flex flex-column justify-content-center align-items-center"
                >
                  <i className="icon-box icon-2x"></i>
                  <p className="text-center">Outros</p>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button
            size="lg"
            block
            className="text-secondary"
            variant="link"
            onClick={handleClose}
          >
            <i className="icon-cross2 mr-1 font-weight-light"></i>
            Cancelar
          </Button>
          <Button size="lg" block disabled={!!loading} type="submit">
            {loading ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="mr-1"
                />
                Aguarde
              </>
            ) : (
              'Criar Produto'
            )}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
