import styled from 'styled-components';

export const Wrapper = styled.div`
  .footer {
    color: #999;
    margin: 0 auto;
    text-align: center;
    width: 100%;
  }

  .footer-padding {
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;
