import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ContentLoader from 'react-content-loader';
import { Link } from 'react-router-dom';

import * as ModsActions from '~/store/modules/mods/actions';
import * as GroupActions from '~/store/modules/group/actions';

import history from '~/services/history';
import MyGroups from '~/components/MyGroups';
import ButtonTakePart from '~/components/ButtonTakePart';

export default function Groups() {
  const dispatch = useDispatch();
  const loading = useSelector(state => state.group.loading);
  const { groups } = useSelector(state => state.group);

  useEffect(() => {
    dispatch(GroupActions.groupListRequest());
    dispatch(ModsActions.modsRequest(2));
  }, [dispatch]);

  function takePart() {
    dispatch(ModsActions.modsRequest(2));
  }

  return (
    <div className="content">
      <div className="row padding-tab-top-post">
        <div className="col-xl-3">
          <MyGroups fixed />
        </div>
        <div className="col-xl-9">
          <div className="d-flex justify-content-between align-items-center">
            <div className="mb-3">
              <h3 className="mb-0 font-weight-semibold">
                <i
                  role="presentation"
                  style={{ fontSize: '1.5rem' }}
                  onClick={() => history.goBack()}
                  className="icon-arrow-left52 mr-2 c-pointer"
                ></i>
                Sugestões para você
              </h3>
              <span className="text-muted d-block">
                Grupos nos quais você pode ter interesse
              </span>
            </div>
            <div>
              <button type="button" className="btn btn-sm btn-info">
                <i className="icon-checkmark2 mr-1"></i>Criar Grupo
              </button>
            </div>
          </div>

          <div className="row">
            {loading ? (
              <div className="col">
                <ContentLoader height={590} width={1360} speed={2}>
                  <rect x="0" y="0" rx="8" ry="8" width="310" height="470" />
                  <rect x="350" y="0" rx="8" ry="8" width="310" height="470" />
                  <rect x="700" y="0" rx="8" ry="8" width="310" height="470" />
                  <rect x="1050" y="0" rx="8" ry="8" width="310" height="470" />
                </ContentLoader>
              </div>
            ) : (
              <>
                {groups.map(group => (
                  <div className="col-sm-6 col-xl-3" key={group.cod_grupo}>
                    <div className="card">
                      <Link to={`/groups/${group.cod_grupo}`}>
                        <div className="card-img-actions mx-1 mt-1">
                          <img
                            className="card-img img-fluid"
                            src="global_assets/images/demo/flat/1.png"
                            alt={group.nome_grupo}
                          />
                        </div>
                      </Link>

                      <div className="card-body d-flex flex-column aling-items-center">
                        <div className="d-flex mb-2">
                          <div>
                            <h6 className="font-weight-semibold text-center">
                              {group.nome_grupo}
                            </h6>
                            <span className="text-justify">
                              {`${group.desc_grupo.substring(0, 100)}...`}
                            </span>
                          </div>
                        </div>

                        <ButtonTakePart group={group} takePart={takePart} />
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
