import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { formatDistance } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';

import EditComment from '~/components/EditComment';
import ReplayComment from '~/components/ReplayComment';
import VoteComment from '~/components/VoteComment';
import DeleteComment from '~/components/DeleteComment';
import PostCommentSub from '~/components/PostCommentSub';

export default function PostComment({ comments, loadPost }) {
  const { profile } = useSelector(state => state.user);
  const [showEdit, setShowEdit] = useState(false);

  return (
    <>
      <li className="media flex-column flex-md-row">
        {comments.midia_post ? (
          <div className="mr-md-3 mb-2 mb-md-0">
            <a href="#1">
              <img
                src="global_assets/images/demo/users/face2.jpg"
                className="rounded-circle"
                width="38"
                height="38"
                alt=""
              />
            </a>
          </div>
        ) : (
          <div className="mr-2">
            <a href="#1" className="btn bg-teal-400 rounded-round btn-icon">
              <span className="letter-icon">
                {comments.nome_membro.substring(0, 1)}
              </span>
            </a>
          </div>
        )}
        <div className="media-body">
          <div className="media-title">
            <ul className="list-inline list-inline-dotted font-size-sm mb-0">
              <li className="list-inline-item">
                <span className="font-weight-semibold text-primary c-pointer">
                  {comments.nome_membro}
                </span>
              </li>
              <li className="list-inline-item">
                <span className="text-muted">
                  {formatDistance(new Date(comments.dt_cadastro), new Date(), {
                    locale: pt,
                  })}
                </span>
              </li>
            </ul>
          </div>

          {showEdit ? (
            <EditComment
              post={comments}
              loadPost={loadPost}
              showEdit={setShowEdit}
            />
          ) : (
            <p>{comments.desc_post}</p>
          )}

          <ul className="list-inline list-inline-dotted font-size-sm mb-2">
            <VoteComment comments={comments} loadPost={loadPost} />
            {comments.cod_membro !== profile.cod_membro ? (
              <ReplayComment comments={comments} loadPost={loadPost} />
            ) : (
              ''
            )}
            {comments.cod_membro === profile.cod_membro ? (
              <>
                <li className="list-inline-item">
                  <span
                    className="text-muted c-pointer"
                    onClick={() => setShowEdit(true)}
                  >
                    Editar
                  </span>
                </li>

                <DeleteComment id={comments.cod_post} loadPost={loadPost} />
              </>
            ) : (
              ''
            )}
          </ul>
        </div>
      </li>
      {comments.filhos.length > 0 ? (
        <div className="card-body ml-3">
          <ul className="media-list">
            {comments.filhos.map(sub => (
              <PostCommentSub
                comments={sub}
                loadPost={loadPost}
                key={sub.cod_post}
              />
            ))}
          </ul>
        </div>
      ) : (
        ''
      )}
    </>
  );
}

PostComment.propTypes = {
  comments: PropTypes.object,
  loadPost: PropTypes.func,
};

PostComment.defaultProps = {
  comments: {},
};
