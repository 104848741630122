import React, { Component } from 'react';
import { uniqueId } from 'lodash';

import api from '~/services/api';

import { Content } from './styles';
import { getUrlImg } from '~/util';

import Upload from '~/components/Upload';
import FileList from '~/components/FileList';

class App extends Component {
  state = {
    uploadedFiles: [],
  };

  async componentDidMount() {
    const { id } = this.props;
    const response = await api.get(
      `/produto/listar-produto-img/?q=(cod_produto:${id})`
    );

    this.setState({
      uploadedFiles: response.data.records.map(file => {
        const url = getUrlImg(file.img_prod);
        return {
          id: file.cod_produto_img,
          preview: url,
          uploaded: true,
          url,
        };
      }),
    });
  }

  handleUpload = files => {
    const uploadedFiles = files.map(file => ({
      file,
      id: uniqueId(),
      preview: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
      url: null,
    }));

    this.setState({
      uploadedFiles: this.state.uploadedFiles.concat(uploadedFiles),
    });

    uploadedFiles.forEach(this.processUpload);
  };

  updateFile = (id, data) => {
    this.setState({
      uploadedFiles: this.state.uploadedFiles.map(uploadedFile => {
        return id === uploadedFile.id
          ? { ...uploadedFile, ...data }
          : uploadedFile;
      }),
    });
  };

  processUpload = uploadedFile => {
    const data = new FormData();
    const { url, id } = this.props;

    data.append('cod_produto', id);
    data.append('arquivo1', uploadedFile.file, uploadedFile.name);

    api
      .post(url, data, {
        onUploadProgress: e => {
          const progress = parseInt(Math.round((e.loaded * 100) / e.total));

          this.updateFile(uploadedFile.id, {
            progress,
          });
        },
      })
      .then(response => {
        if (response.data.records.error) {
          this.updateFile(uploadedFile.id, {
            error: true,
          });
          return;
        }

        this.updateFile(uploadedFile.id, {
          uploaded: true,
          id: response.data.records.cod_produto_img,
          url: getUrlImg(response.data.records.url_img),
        });
      })
      .catch(() => {
        this.updateFile(uploadedFile.id, {
          error: true,
        });
      });
  };

  handleDelete = async id => {
    await api.put(`/produto/cancelar-produto-img/${id}`);

    this.setState({
      uploadedFiles: this.state.uploadedFiles.filter(file => file.id !== id),
    });
  };

  componentWillUnmount() {
    this.state.uploadedFiles.forEach(file => URL.revokeObjectURL(file.preview));
  }

  render() {
    const { uploadedFiles } = this.state;

    return (
      <Content>
        <Upload
          accept="image/*"
          upMessage="Arraste uma imagem ou clique aqui para enviar"
          onUpload={this.handleUpload}
        />
        {!!uploadedFiles.length && (
          <FileList files={uploadedFiles} onDelete={this.handleDelete} />
        )}
      </Content>
    );
  }
}

export default App;
