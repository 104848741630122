import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { format } from 'date-fns';
import { toast } from 'react-toastify';
import { Button, Badge, Card, Table } from 'react-bootstrap';

import pt from 'date-fns/locale/pt-BR';
import * as ClassActions from '~/store/modules/classes/actions';

import Loading from '~/components/Loading';
import BoxConfirm from '~/components/BoxConfirm';
import CreateClass from '~/components/CreateClass';

import api from '~/services/api';

import { objIsNull, buildQueryString } from '~/util';

function TabClass(course) {
  const dispatch = useDispatch();
  const classes = useSelector(state => state.classes.classes);
  const loading = useSelector(state => state.classes.loading);

  const [stoped, setStoped] = useState(false);
  const [showBox, setShowBox] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [showModalClass, setShowModalClass] = useState(false);
  const [selectedClass, setSelectedClass] = useState({});

  const loadClasses = useCallback(
    id => {
      if (!stoped) {
        dispatch(ClassActions.classesGetByCourseRequest(id));
        setStoped(true);
      }
    },
    [stoped, setStoped, dispatch]
  );

  useMemo(() => {
    const boolean = !(course && objIsNull(course.course));
    if (!boolean) {
      loadClasses(course.course.cod_evento);
    }
    setShowMessage(boolean);
  }, [course, loadClasses, setShowMessage]);

  const handleShowModalClass = () => setShowModalClass(true);
  function handleCloseModalClass() {
    setShowModalClass(false);
    dispatch(ClassActions.clearClassesRequest());
  }

  function onClose() {
    setShowBox(false);
  }

  async function removeClass() {
    if (objIsNull(selectedClass)) {
      const data = {
        cod_evento: selectedClass.cod_evento,
        cod_turma_curso: selectedClass.cod_turma_curso,
      };
      const response = await api.put(
        `/evento/cancelar-turma-curso/${data.cod_turma_curso}`,
        data
      );
      if (response.data.records.error) {
        toast.error(response.data.records.msg);
      } else {
        toast.success('Turma removida com sucesso!');
        dispatch(ClassActions.classesGetByCourseRequest(data.cod_evento));
      }

      onClose();
      setSelectedClass({});
    }
  }

  function handleEdit(idTurmaCurso, idEvento) {
    const data = {
      cod_evento: idEvento,
      cod_turma_curso: idTurmaCurso,
    };
    const query = buildQueryString(data);
    dispatch(ClassActions.classesGetRequest(query));
    setTimeout(() => {
      setShowModalClass(true);
    }, 500);
  }

  function handleRemove(item) {
    setShowBox(true);
    setSelectedClass(item);
  }

  function renderClassesTable() {
    return (
      <>
        <CreateClass
          show={showModalClass}
          handleClose={() => handleCloseModalClass()}
        />
        <BoxConfirm
          title="Remover Turma"
          show={showBox}
          size="sm"
          onYesClick={removeClass}
          onNoClick={onClose}
          onClose={onClose}
          message="Deseja realmente remover a turma escolhida?"
        />
        <div className="page-header-content header-elements-md-inline p-0">
          <div className="page-title d-flex p-0">
            <h4 className="mb-0 font-weight-semibold">
              <i className="icon-graduation2 mr-2 c-pointer"></i> Listagem das
              Turmas
              <span className="text-muted d-block" style={{ fontSize: 12 }}>
                Segue abaixo todas as turmas do curso {course.course.titulo_eve}
                . Poderá existir somente uma turma marcado como{' '}
                <strong>padrão</strong> que poderá receber inscrições.
              </span>
            </h4>
          </div>
          <div className="header-elements d-none">
            <div className="justify-content-center">
              <Button
                className="ml-3"
                variant="success"
                onClick={handleShowModalClass}
              >
                <i className="icon-plus2 mr-1"></i> Criar Turma
              </Button>
            </div>
          </div>
        </div>
        <Table striped hover className="mt-3 mb-3">
          <thead>
            <tr>
              <th>Nome da Turma</th>
              <th>Turma Padrão</th>
              <th className="text-center">Data de Cadastro</th>
              <th className="text-center" style={{ width: 200 }}>
                Ações
              </th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(classes).length === 0 ? (
              <tr className="text-center">
                <td colSpan="4">
                  <strong>Nenhuma turma cadastrada até o momento.</strong>
                </td>
              </tr>
            ) : (
              classes.map(item => (
                <tr key={item.cod_turma_curso}>
                  <td>{item.nome_turma}</td>
                  <td>
                    {item.eh_padrao === '1' ? (
                      <Badge variant="primary">Sim</Badge>
                    ) : (
                      <Badge variant="secondary">Não</Badge>
                    )}
                  </td>
                  <td className="text-center">
                    {format(new Date(item.dt_cadastro), 'PP', {
                      locale: pt,
                    })}
                  </td>
                  <td className="text-center">
                    <button
                      type="button"
                      title="Editar"
                      className="btn btn-light"
                      onClick={() =>
                        handleEdit(item.cod_turma_curso, item.cod_evento)
                      }
                    >
                      <i className="icon-pencil5 text-primary"></i>
                    </button>
                    <button
                      type="button"
                      title="Remover"
                      className="btn btn-light ml-2"
                      onClick={() => handleRemove(item)}
                    >
                      <i className="icon-trash text-primary"></i>
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </>
    );
  }

  return (
    <>
      {showMessage ? (
        <div className="alert alert-info border-0 alert-dismissible mb-0">
          Faça o cadastro de um novo curso primeiramente.
        </div>
      ) : (
        <Card.Body className="p-0 mb-3">
          {loading ? (
            <div className="mt-5">
              <Loading />
            </div>
          ) : (
            renderClassesTable()
          )}
        </Card.Body>
      )}
    </>
  );
}

export default TabClass;
