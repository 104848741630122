/* eslint-disable react/no-danger */

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { Container, Table } from 'react-bootstrap';

import * as ProductActions from '~/store/modules/product/actions';
import { objIsNull, adjustTopApplication, formatCurrencyValue } from '~/util';

import Loading from '~/components/Loading';
import BottomButton from '~/components/BottomButton';

export default function ExternalProduct(props) {
  const { match } = props;

  const dispatch = useDispatch();
  const loading = useSelector(state => state.product.loading);
  const product = useSelector(state => state.product.product);

  useEffect(() => {
    adjustTopApplication();
    dispatch(ProductActions.productsGetByTagRequest(match.params.tag));
  }, [dispatch, match]);

  function getAliasButton() {
    return parseInt(product.por_valor, 10) === 0 ? 'Eu Quero!' : 'Comprar';
  }

  function getAffiliate() {
    if (match.params.afiliado) {
      return match.params.afiliado;
    }
    return '';
  }

  function getUrlProductButton(item) {
    if (item.tipo_mkt_place === '1') {
      const data = {
        ...item,
        pro_qtde: 1,
        pro_valor_total: parseInt(item.por_valor, 10) * 1,
      };
      localStorage.setItem('@psicomart:products', JSON.stringify(data));
      return `/purchase/produto/${product.url_amig}/${getAffiliate()}`;
    }
    return `/quantity/produto/${product.url_amig}/${getAffiliate()}`;
  }

  return (
    <div className="box-event">
      {loading ? (
        <div className="mt-5">
          <Loading />
        </div>
      ) : (
        <>
          <Container className="pt-5">
            <div className="row">
              {objIsNull(product) ? (
                <>
                  <div className="col-xl-8">
                    <div className="card no-border no-shadow">
                      <div className="card-body">
                        <h1 className="mb-3 mt-2 font-weight-black event-name">
                          {product.nome_produto}
                        </h1>

                        {product.por_valor && (
                          <span className="text-information d-flex">
                            <i className="icon-coin-dollar"></i>
                            <span>
                              {formatCurrencyValue(product.por_valor)}
                            </span>
                          </span>
                        )}

                        {parseInt(product.prod_estoque, 10) > 0 && (
                          <span className="text-information d-flex">
                            <i className="icon-database-menu"></i>
                            <span>Produto em Estoque</span>
                          </span>
                        )}

                        <h3 className="heading mt-4 mb-3">
                          Descrição do Produto
                        </h3>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: product.desc_produto,
                          }}
                        />

                        <h3 className="heading mt-4 mb-3">
                          Sobre o Fornecedor
                        </h3>

                        <p>
                          <span className="mb-0 font-weight-semibold">
                            {product.nome_organiz || 'Sem informações'}
                          </span>
                          {product.nome_organiz && (
                            <>
                              <span
                                className="pt-2 d-block"
                                dangerouslySetInnerHTML={{
                                  __html: product.desc_organiz,
                                }}
                              />
                              <span
                                className="pt-2"
                                style={{ display: 'block' }}
                              >
                                <a
                                  href="/#"
                                  className="btn btn-outline bg-slate-600 text-slate-600 border-slate-600 btn-custom text-uppercase mr-2 mt-1"
                                >
                                  <i className="icon-envelop3 mr-1"></i> Fale
                                  com ele
                                </a>
                                <a
                                  href="/#"
                                  className="btn btn-outline bg-slate-600 text-slate-600 border-slate-600 btn-custom text-uppercase mr-2 mt-1"
                                >
                                  <i className="icon-stack3 mr-1"></i> Mais
                                  Produtos
                                </a>
                              </span>
                            </>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-4  mb-5">
                    <div className="card border-grey-custom mt-3">
                      {product.img_prod && product.img_prod.length && (
                        <div className="card-img-actions">
                          <img
                            alt={product.nome_produto}
                            className="card-img-top img-fluid"
                            src={product.img_prod[0].img_prod}
                          />
                        </div>
                      )}

                      <div className="card-header bg-grey-custom header-elements-inline">
                        <h4 className="card-title text-dark">
                          <i className="icon-cart-add mr-1 icon-1x"></i> Item
                        </h4>
                      </div>
                      <div className="card-body p-0">
                        <Table striped hover className="table-custom">
                          <tbody>
                            <tr>
                              <td>Valor do Produto</td>
                              <td className="text-right">
                                <span>
                                  {formatCurrencyValue(product.por_valor)}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </Table>

                        {!isMobile ? (
                          <span className="nav-link text-dark">
                            <Link
                              to={getUrlProductButton(product)}
                              className="btn btn-lg btn-success btn-block font-weight-bold btn-custom text-uppercase mb-2"
                            >
                              {getAliasButton()}
                            </Link>
                          </span>
                        ) : (
                          <BottomButton
                            type="link"
                            text={getAliasButton()}
                            url={getUrlProductButton(product)}
                          />
                        )}
                      </div>
                      {/* <div className="card-footer bg-transparent d-flex justify-content-between">
                        <span className="text-muted">Última atualização há 3 minutos</span>
                        <span>
                          <i className="icon-star-full2 font-size-base text-warning-300"></i>
                          <i className="icon-star-full2 font-size-base text-warning-300"></i>
                          <i className="icon-star-full2 font-size-base text-warning-300"></i>
                          <i className="icon-star-full2 font-size-base text-warning-300"></i>
                          <i className="icon-star-half font-size-base text-warning-300"></i>
                          <span className="text-muted ml-2">(12)</span>
                        </span>
                      </div> */}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="col-xl-12">
                    <div className="card no-border">
                      <div className="card-body">
                        <h1 className="mb-3 mt-3 font-weight-black event-name">
                          {product.nome_produto}
                        </h1>
                        <div className="alert alert-info alert-styled-left alert-dismissible">
                          <span className="font-weight-semibold">
                            Caro usuário:
                          </span>{' '}
                          <span>Produto inexistente em nossa plataforma!</span>
                          <br />
                          <span>
                            Entre em contato conosco através do seguinte e-mail{' '}
                            <a
                              href="mailto:joseguilhermeha@gmail.com"
                              className="alert-link"
                            >
                              joseguilhermeha@gmail.com
                            </a>{' '}
                            para maiores informações.
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </Container>
        </>
      )}
    </div>
  );
}

ExternalProduct.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      tag: PropTypes.string,
      afiliado: PropTypes.string,
    }),
  }).isRequired,
};
