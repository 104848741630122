import SignIn from '~/pages/SignIn';
import Login from '~/components/Login';
import Recover from '~/pages/Recover';
import Affiliate from '~/pages/Affiliate';
import Dashboard from '~/pages/Dashboard';
import Main from '~/pages/Main';
import Profile from '~/pages/Profile';
import Groups from '~/pages/Groups';
import ShowGroups from '~/pages/Groups/show';
import Cases from '~/pages/Cases';
import ExternalEvents from '~/pages/Events/external';
import Events from '~/pages/Events';
import MyEvents from '~/pages/Events/my';
import EditEvents from '~/pages/Events/edit';
import Participants from '~/pages/Events/participants';
import ShearchEvents from '~/pages/Events/search';
import MyCourses from '~/pages/Courses';
import ContentModule from '~/pages/Courses/content';
import Course from '~/pages/Courses/form';
import MyModules from '~/pages/Courses/modules';
import MyStudents from '~/pages/Courses/students';
import Inventory from '~/pages/Inventory';
import Journey from '~/pages/Inventory/journey';
import MyProducts from '~/pages/Produtos/my';
import EditProduct from '~/pages/Produtos/edit';
import Sales from '~/pages/Events/sales';
import Shopping from '~/pages/Shopping';
import MyAffiliations from '~/pages/Affiliate/my-affiliations';
import CurrentAccount from '~/pages/Config/currentaccount';
import Documents from '~/pages/Documents';
import TicketEvents from '~/pages/Events/ticket';
import PurchaseEvents from '~/pages/Events/purchase';
import ExternalProduct from '~/pages/Produtos/external';
import QuantityProduct from '~/pages/Produtos/quantity';
import DownloadProduct from '~/pages/Produtos/download';
import Customers from '~/pages/Produtos/customers';
import NotPublished from '~/pages/Error/notpublished';
import NotFound from '~/pages/Error/404';
import Payment from '~/pages/Payment';

export default [
  {
    exact: true,
    path: '/',
    name: 'Home',
    component: SignIn,
  },
  {
    exact: true,
    path: '/login/:email/:password',
    name: 'Login',
    component: Login,
  },
  {
    path: '/redefinir-senha/:token',
    name: 'Recuperar Senha',
    component: Recover,
  },
  {
    path: '/afiliado/:codigo',
    name: 'Seja um Afiliado',
    component: Affiliate,
  },
  {
    isPrivate: true,
    breadcrumb: true,
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
  },
  {
    isPrivate: true,
    path: '/feed',
    name: 'Feed',
    component: Main,
  },
  {
    isPrivate: true,
    path: '/profile',
    name: 'Meu Perfil',
    component: Profile,
  },
  {
    exact: true,
    isPrivate: true,
    path: '/config/conta',
    name: 'Configuração de Conta Corrente',
    component: CurrentAccount,
  },
  {
    exact: true,
    isPrivate: true,
    path: '/groups',
    name: 'Grupos',
    component: Groups,
  },
  {
    isPrivate: true,
    path: '/groups/:id',
    name: 'Editar Grupo',
    component: ShowGroups,
  },
  {
    isPrivate: true,
    path: '/casos',
    name: 'Casos Clínicos',
    component: Cases,
  },
  {
    exact: true,
    isPrivate: true,
    breadcrumb: true,
    sidebar: false,
    path: '/eventos/pagina',
    name: 'Eventos',
    component: Events,
  },
  {
    exact: true,
    isPrivate: true,
    path: '/eventos',
    name: 'Eventos',
    component: MyEvents,
  },
  {
    exact: true,
    isPrivate: true,
    path: '/eventos/:id/editar',
    name: 'Editar Evento',
    component: EditEvents,
  },
  {
    exact: true,
    isPrivate: true,
    path: '/participantes',
    name: 'Participantes',
    component: Participants,
  },
  {
    exact: true,
    isPrivate: true,
    path: '/eventos/pesquisar/:city',
    name: 'Pesquisar Eventos',
    component: ShearchEvents,
  },
  {
    exact: true,
    isPrivate: true,
    path: '/cursos',
    name: 'Cursos',
    component: MyCourses,
  },
  {
    exact: true,
    isPrivate: true,
    path: '/alunos',
    name: 'Meus Alunos',
    component: MyStudents,
  },
  {
    exact: true,
    isPrivate: true,
    path: '/modulos',
    name: 'Meus Módulos',
    component: MyModules,
  },
  {
    exact: true,
    isPrivate: true,
    path: '/modulos/:idCourse/item/:idModule/conteudo',
    name: 'Criar Conteúdo',
    component: ContentModule,
  },
  {
    exact: true,
    isPrivate: true,
    path: '/cursos/:id/:tag?',
    name: 'Editar Curso',
    component: Course,
  },
  {
    exact: true,
    isPrivate: true,
    path: '/inventario',
    name: 'Meus Cursos',
    component: Inventory,
  },
  {
    isPrivate: true,
    path: '/inventario/turma/:id',
    name: 'Jornada do Curso',
    component: Journey,
  },
  {
    exact: true,
    isPrivate: true,
    path: '/produtos',
    name: 'Produtos',
    component: MyProducts,
  },
  {
    exact: true,
    isPrivate: true,
    path: '/clientes',
    name: 'Meus Clientes',
    component: Customers,
  },
  {
    exact: true,
    isPrivate: true,
    path: '/produtos/:id/editar',
    name: 'Editar Produto',
    component: EditProduct,
  },
  {
    exact: true,
    isPrivate: true,
    path: '/produtos/:id/download',
    name: 'Downloads',
    component: DownloadProduct,
  },
  {
    exact: true,
    isPrivate: true,
    path: '/vendas',
    name: 'Minhas Vendas',
    component: Sales,
  },
  {
    exact: true,
    isPrivate: true,
    path: '/compras',
    name: 'Minhas Compras',
    component: Shopping,
  },
  {
    exact: true,
    isPrivate: true,
    path: '/afiliacoes',
    name: 'Minhas Afiliações',
    component: MyAffiliations,
  },
  {
    isPrivate: true,
    path: '/documentos',
    name: 'Documentos',
    component: Documents,
  },
  {
    sidebar: false,
    externalAccess: true,
    path: '/ticket/:type/:tag/:afiliado?',
    name: 'Selecionar Ingresso',
    component: TicketEvents,
  },
  {
    sidebar: false,
    externalAccess: true,
    path: '/purchase/:type/:tag/:afiliado?',
    name: 'Comprar Ingresso',
    component: PurchaseEvents,
  },
  {
    exact: true,
    sidebar: false,
    externalAccess: true,
    path: '/detail/produto/:tag/:afiliado?',
    name: 'Detalhe do Produto',
    component: ExternalProduct,
  },
  {
    exact: true,
    sidebar: false,
    externalAccess: true,
    path: '/quantity/produto/:tag/:afiliado?',
    title: 'Quantidade do Produto',
    component: QuantityProduct,
  },
  {
    exact: true,
    sidebar: false,
    isPrivate: false,
    breadcrumb: false,
    iframe: true,
    path: '/payments/:json',
    name: 'Forma de pagamento',
    component: Payment,
  },
  {
    exact: true,
    sidebar: false,
    externalAccess: true,
    iframe: false,
    path: '/:type/:tag/:afiliado?',
    name: 'Evento',
    component: ExternalEvents,
  },
  {
    sidebar: false,
    isPrivate: true,
    breadcrumb: true,
    path: '/notpublished',
    name: 'Não Publicado',
    component: NotPublished,
  },
  {
    sidebar: false,
    isPrivate: true,
    breadcrumb: true,
    path: '*',
    name: 'Pagina não Encontrada',
    component: NotFound,
  },
];
