import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { signOut } from '~/store/modules/auth/actions';

export default function Header() {
  const dispatch = useDispatch();
  const { profile } = useSelector(state => state.user);

  function handleSignOut() {
    dispatch(signOut());
  }

  return (
    <div className="navbar fixed-top navbar-expand-md navbar-dark">
      <div className="navbar-brand wmin-100">
        <Link
          to="/feed"
          className="d-inline-block"
          style={{ fontSize: 18, color: '#fff' }}
        >
          Psico.in
        </Link>
      </div>
      <div className="d-md-none">
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbar-mobile"
        >
          <i className="icon-paragraph-justify3"></i>
        </button>
        {/* <button
          className="navbar-toggler sidebar-mobile-main-toggle"
          type="button"
        >
          <i className="icon-paragraph-justify3"></i>
        </button> */}
      </div>
      <div className="collapse navbar-collapse" id="navbar-mobile">
        <div className="header-elements bg-transparent py-0 border-0 mb-3 mb-md-0">
          <form action="#">
            <div className="form-group form-group-feedback form-group-feedback-right">
              <input
                type="search"
                className="form-control bg-light-alpha border-transparent wmin-md-300"
                placeholder="Pesquisar"
              />
              <div className="form-control-feedback">
                <i className="icon-search4 font-size-sm"></i>
              </div>
            </div>
          </form>
        </div>

        <span className="ml-md-auto mr-md-3">&nbsp;</span>

        <div className="d-flex justify-content-center">
          <Link to="/psicologos" className="btn btn-link btn-float text-white">
            <i className="icon-users2 text-white"></i>
            <span>Psicólogos</span>
          </Link>
          <Link to="/casos" className="btn btn-link btn-float text-white">
            <i className="icon-address-book2 text-white"></i>
            <span>Casos</span>
          </Link>
          <Link to="/documentos" className="btn btn-link btn-float text-white">
            <i className="icon-stack3 text-white"></i>
            <span>Documentos</span>
          </Link>
          <Link to="/shopping" className="btn btn-link btn-float text-white">
            <i className="icon-cart5 text-white"></i>
            <span>Shopping</span>
          </Link>
        </div>

        <span className="ml-md-auto mr-md-3">&nbsp;</span>

        <ul className="navbar-nav">
          <li className="nav-item dropdown">
            <a
              href="#notifications"
              className="navbar-nav-link dropdown-toggle caret-0"
              data-toggle="dropdown"
            >
              <i className="icon-bell2"></i>
              <span className="d-md-none ml-2">Activity</span>
              <span className="badge badge-pill badge-mark bg-orange-400 border-orange-400 ml-auto ml-md-0"></span>
            </a>

            <div className="dropdown-menu dropdown-menu-right dropdown-content wmin-md-350">
              <div className="dropdown-content-header">
                <span className="font-weight-semibold">Latest activity</span>
                <a href="#1" className="text-default">
                  <i className="icon-search4 font-size-base"></i>
                </a>
              </div>

              <div className="dropdown-content-body dropdown-scrollable">
                <ul className="media-list">
                  <li className="media">
                    <div className="mr-3">
                      <a
                        href="#1"
                        className="btn bg-success-400 rounded-round btn-icon"
                      >
                        <i className="icon-mention"></i>
                      </a>
                    </div>

                    <div className="media-body">
                      <a href="#1">Taylor Swift</a> mentioned you in a post
                      <div className="font-size-sm text-muted mt-1">
                        4 minutes ago
                      </div>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="dropdown-content-footer bg-light">
                <a href="#1" className="text-grey mr-auto">
                  All activity
                </a>
                <div>
                  <a
                    href="#1"
                    className="text-grey"
                    data-popup="tooltip"
                    title="Clear list"
                  >
                    <i className="icon-checkmark3"></i>
                  </a>
                  <a
                    href="#1"
                    className="text-grey ml-2"
                    data-popup="tooltip"
                    title="Settings"
                  >
                    <i className="icon-gear"></i>
                  </a>
                </div>
              </div>
            </div>
          </li>
          <li className="nav-item dropdown">
            <a
              href="#messages"
              className="navbar-nav-link dropdown-toggle caret-0"
              data-toggle="dropdown"
            >
              <i className="icon-bubbles5"></i>
              <span className="d-md-none ml-2">Messages</span>
              <span className="badge badge-pill badge-mark bg-orange-400 border-orange-400 ml-auto ml-md-0"></span>
            </a>

            <div className="dropdown-menu dropdown-menu-right dropdown-content wmin-md-350">
              <div className="dropdown-content-header">
                <span className="font-weight-semibold">Messages</span>
                <a href="#1" className="text-default">
                  <i className="icon-compose"></i>
                </a>
              </div>

              <div className="dropdown-content-body dropdown-scrollable">
                <ul className="media-list">
                  <li className="media">
                    <div className="mr-3 position-relative">
                      <img
                        src="global_assets/images/demo/users/face10.jpg"
                        width="36"
                        height="36"
                        className="rounded-circle"
                        alt=""
                      />
                    </div>

                    <div className="media-body">
                      <div className="media-title">
                        <a href="#1">
                          <span className="font-weight-semibold">
                            James Alexander
                          </span>
                          <span className="text-muted float-right font-size-sm">
                            04:58
                          </span>
                        </a>
                      </div>

                      <span className="text-muted">
                        who knows, maybe that would be the best thing for me...
                      </span>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="dropdown-content-footer justify-content-center p-0">
                <a
                  href="#more"
                  className="bg-light text-grey w-100 py-2"
                  data-popup="tooltip"
                  title="Load more"
                >
                  <i className="icon-menu7 d-block top-0"></i>
                </a>
              </div>
            </div>
          </li>
          <li className="nav-item dropdown dropdown-user">
            <a
              href="#user"
              className="navbar-nav-link d-flex align-items-center dropdown-toggle"
              data-toggle="dropdown"
            >
              {profile.midia ? (
                <img
                  src="global_assets/images/demo/users/face11.jpg"
                  className="rounded-circle mr-2"
                  height="34"
                  alt=""
                />
              ) : (
                <div className="mr-1">
                  <div className="btn bg-teal-400 rounded-round btn-icon">
                    <span className="letter-icon">
                      {profile.nome_membro.substring(0, 1)}
                    </span>
                  </div>
                </div>
              )}
              <span>{profile.nome_membro}</span>
            </a>

            <div className="dropdown-menu dropdown-menu-right">
              <a href="#profile" className="dropdown-item">
                <i className="icon-user-plus"></i> Meu Perfil
              </a>
              <a href="#messages" className="dropdown-item">
                <i className="icon-comment-discussion"></i> Mensagens{' '}
                <span className="badge badge-pill bg-blue ml-auto">58</span>
              </a>
              <div className="dropdown-divider"></div>

              <span className="dropdown-item" onClick={handleSignOut}>
                <i className="icon-switch2"></i> Sair
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}
