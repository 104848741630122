/* eslint-disable no-nested-ternary */

import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Card, Table, Alert, Button } from 'react-bootstrap';
import { Form } from '@unform/web';
import { format, parseISO } from 'date-fns';
import { toast } from 'react-toastify';

import { objIsNull, ambient, formatCurrencyValue } from '~/util';
import history from '~/services/history';
import api from '~/services/api';

import Select from '~/components/InputSelect';
import Input from '~/components/InputText';
import DatePicker from '~/components/DatePicker';
import InputCheck from '~/components/InputCheck';
import ModalReceipts from '~/components/ModalReceipts';

import Loading from '~/components/Loading';

const options = items => {
  const data = items.map(event => {
    return {
      value: event.cod,
      label: event.desc,
      type: event.tipo,
    };
  });
  return data;
};

export default function Sales() {
  const formRef = useRef(null);
  const [sales, setSales] = useState([]);
  const [sale, setSale] = useState({});
  const [events, setEvents] = useState([]);
  const [load, setLoad] = useState(true);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState('');
  const [modalShow, setModalShow] = useState(false);

  const [startDateSales, setStartDateSales] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1)
  );

  const [endDateSales, setEndDateSales] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
  );

  const { token } = useSelector(state => state.auth);

  const handleSubmit = useCallback(async data => {
    const dt_inicio = format(data.dt_inicio, 'dd/MM/yyyy');
    const dt_fim = format(data.dt_fim, 'dd/MM/yyyy');

    setLoad(true);

    const response = await api.get(
      `/venda/listar-pagamento/?q=(dt_inicio:${dt_inicio},dt_fim:${dt_fim},tipo_data:${
      data.status
      },${data.tipo === '3' ? 'cod_produto' : 'cod_evento'}:${data.cod_evento})`
    );

    setSales(response.data.records);
    setLoad(false);
  }, []);

  const loadEvents = useCallback(async () => {
    setLoading(true);
    await api.get('/evento/listar-eve-cur-pro').then(response => {
      if (response.data.records.error) {
        toast.error(response.data.records.msg);
        setLoading(false);
        return false;
      }
      const { records } = response.data;
      setEvents(records);
      setLoading(false);
      return false;
    });
  }, []);

  useEffect(() => {
    loadEvents();
  }, [loadEvents]);

  useMemo(() => {
    if (objIsNull(events)) {
      const eve = events[events.length - 1];
      const dt_inicio = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        1
      );
      const dt_fim = new Date(
        new Date().getFullYear(),
        new Date().getMonth() + 1,
        0
      );
      handleSubmit({
        cod_evento: eve.cod,
        tipo: eve.tipo,
        dt_inicio,
        dt_fim,
        status: '1',
      });
    }
  }, [events, handleSubmit]);

  const handleTeste = useCallback(data => {
    setType(data.type);
  }, []);

  const handleReceipts = useCallback(data => {
    setSale(data);
    setModalShow(true);
  }, []);

  const handleTicket = useCallback(
    id => {
      const url = `${ambient()}/export/get-inscricao-admin/?q=(cod_evento_cad:${id},ken:${token})`;
      window.open(url);
    },
    [token]
  );

  return (
    <div className="content padding-tab-top-post">
      <Row>
        <Col xl={12}>
          <div className="d-flex justify-content-between align-items-center">
            <div className="mb-3">
              <h3 className="mb-0 font-weight-semibold">
                <i
                  role="presentation"
                  style={{ fontSize: '1.5rem' }}
                  onClick={() => history.goBack()}
                  className="icon-arrow-left52 mr-2 c-pointer"
                ></i>
                Minhas vendas
              </h3>
              <span className="text-muted d-block">
                Gerenciar minhas vendas
              </span>
            </div>
          </div>
        </Col>
        <Col xl={12}>
          <Card>
            <Card.Body>
              {loading ? (
                <Loading />
              ) : (
                  <Form
                    ref={formRef}
                    onSubmit={handleSubmit}
                    initialData={{
                      status: '1',
                      cod_evento: events.length && events[events.length - 1].cod,
                      dt_inicio: startDateSales,
                      dt_fim: endDateSales,
                    }}
                  >
                    <Row>
                      <Col>
                        <div className="form-group ">
                          <label>Filtro</label>
                          <Input name="tipo" value={type} type="hidden" />
                          <Select
                            name="cod_evento"
                            options={options(events)}
                            placeholder="Selecione o evento"
                            onChange={e => handleTeste(e)}
                          />
                        </div>
                      </Col>
                      <Col>
                        <div className="form-group ">
                          <label>Data início do pagamento</label>
                          <DatePicker
                            placeholderText="Data início"
                            name="dt_inicio"
                            selected={startDateSales}
                            onChange={date => setStartDateSales(date)}
                          />
                        </div>
                      </Col>
                      <Col>
                        <div className="form-group ">
                          <label>Data fim do pagamento</label>
                          <DatePicker
                            placeholderText="Data fim"
                            name="dt_fim"
                            selected={endDateSales}
                            onChange={date => setEndDateSales(date)}
                          />
                        </div>
                      </Col>

                      <Col
                        xl={2}
                        className="d-flex justify-content-center align-items-center"
                      >
                        <InputCheck
                          name="status"
                          options={[
                            {
                              id: '1',
                              label: 'Somente Pagos',
                              text: '',
                            },
                          ]}
                        />
                      </Col>

                      <Col xl={12}>
                        <div className="form-group mb-1">
                          <button
                            id="filter"
                            type="submit"
                            className="btn btn-primary "
                          >
                            Filtrar
                        </button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                )}
            </Card.Body>
          </Card>
        </Col>
        <Col xl={12}>
          {load ? (
            <Loading />
          ) : (
              <Card>
                {sales.length > 0 && (
                  <Card.Header className="text-left">
                    <span>Total de vendas: {sales.length}</span>
                  </Card.Header>
                )}
                <Card.Body>
                  {sales.length < 1 ? (
                    <Alert variant="info" className="mb-0 border-0">
                      <i className="fa fa-exclamation-triangle mr-2"></i>
                    Não encontramos nenhuma venda no período selecionado.
                    </Alert>
                  ) : (
                      <Table striped hover>
                        <tbody>
                          {sales.map(value => (
                            <tr key={value.invoice_id}>
                              <td>
                                <div className="d-flex flex-column">
                                  <small className="text-muted">Comprador</small>
                                  {value.nome_membro_pagador}
                                </div>
                              </td>
                              <td>
                                <div className="d-flex flex-column">
                                  <small className="text-muted">N° do pedido</small>
                                  {value.cod_cob_sub_conta}
                                </div>
                              </td>
                              <td>
                                <div className="d-flex flex-column">
                                  <small className="text-muted">
                                    Data da compra
                              </small>
                                  {format(
                                    parseISO(value.dt_cadastro),
                                    'dd/MM/yyyy'
                                  )}
                                </div>
                              </td>
                              <td>
                                <div className="d-flex flex-column">
                                  <small className="text-muted">
                                    Participantes
                              </small>
                                  {value.nome_participantes}
                                </div>
                              </td>
                              <td>
                                {value.forma_pgto === '3' && (
                                  <i
                                    className="icon-credit-card2"
                                    title="Cartão de credito"
                                  ></i>
                                )}
                                {value.forma_pgto === '8' && (
                                  <i className="icon-barcode2" title="Boleto"></i>
                                )}
                              </td>
                              <td style={{ width: 140 }}>
                                <div className="d-flex flex-column">
                                  <small className="text-muted">
                                    {value.flg_pago === '1' && (
                                      <span className="badge badge-mark border-success mr-1"></span>
                                    )}
                                    {value.flg_pago !== '1' && (
                                      <span className="badge badge-mark border-warning mr-1"></span>
                                    )}
                                    {value.status_pgto}
                                  </small>
                                  {value.valor_cob !== '0.00'
                                    ? formatCurrencyValue(value.valor_cob)
                                    : 'Grátis'}
                                </div>
                              </td>
                              <td>
                                <div className="d-flex flex-column">
                                  <small className="text-muted">Líquido</small>
                                  {value.vlr_liq_prof
                                    ? formatCurrencyValue(value.vlr_liq_prof)
                                    : '-'}
                                </div>
                              </td>

                              {value.cod_evento && (
                                <td style={{ width: 50 }}>
                                  <Button
                                    onClick={() =>
                                      handleTicket(value.cod_evento_cad)
                                    }
                                    title="Imprimir ingresso"
                                    variant="light"
                                    size="sm"
                                  >
                                    <i className="icon-ticket icon-1x text-primary"></i>
                                  </Button>
                                </td>
                              )}
                              <td style={{ width: 50 }}>

                                <Button
                                  onClick={() =>
                                    handleReceipts(value)
                                  }
                                  title="Recebimentos"
                                  variant="light"
                                  size="sm"
                                >
                                  <i
                                    role="presentation"
                                    className="icon-cash3 text-primary c-pointer"
                                  ></i>
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    )}
                </Card.Body>
              </Card>
            )}
        </Col>
      </Row>
      <ModalReceipts
        modalShow={modalShow}
        modalClose={setModalShow}
        data={sale}
      />
    </div>
  );
}
