import React, { useEffect, useState, useRef } from 'react';
import ReactDatepicker, { registerLocale } from 'react-datepicker';
import ReactInputMask from 'react-input-mask';

import { useField } from '@unform/core';

import { isToday, setHours, setMinutes } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';
import { ptBR } from 'date-fns/locale';

export default function DatepickerTime({ name, startDate, endDate, ...rest }) {
  const ref = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);
  const [selectedDate, setSelectedDate] = useState(defaultValue);

  useEffect(() => {
    registerLocale('ptBR', ptBR);
    registerField({
      name: fieldName,
      ref: ref.current,
      path: 'props.selected',
      clearValue: pickerRef => {
        pickerRef.clear();
      },
    });
  }, [fieldName, registerField, startDate, endDate]);

  return (
    <>
      <ReactDatepicker
        className={error ? 'form-control border-danger' : 'form-control'}
        locale={ptBR}
        name={fieldName}
        minTime={
          (startDate && isToday(startDate)) || isToday(endDate)
            ? setHours(
                setMinutes(new Date(), new Date().getMinutes()),
                new Date().getHours()
              )
            : setHours(setMinutes(new Date(), 0), 0)
        }
        maxTime={setHours(setMinutes(new Date(), 45), 23)}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        timeCaption="Hora"
        dateFormat="HH:mm"
        selected={selectedDate}
        onChange={date => setSelectedDate(date)}
        customInput={<ReactInputMask mask="99:99" />}
        ref={ref}
        {...rest}
      />
      {error && <span className="form-text text-danger">{error}</span>}
    </>
  );
}
