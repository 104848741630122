import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Row, Col, Card, Badge, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { format, parseISO } from 'date-fns';

import history from '~/services/history';
import api from '~/services/api';
import Loading from '~/components/Loading';
import { ambient } from '~/util';

export default function Shopping() {
  const [shoppings, setShoppings] = useState([]);
  const [load, setLoad] = useState(true);
  const [wait, setWait] = useState(false);
  const { token } = useSelector(state => state.auth);

  useEffect(() => {
    api.get(`/inscricao/listar-minhas-compras`).then(response => {
      if (response.data.records.error) {
        toast.error(response.data.records.msg);
        setLoad(false);
        return;
      }
      setShoppings(response.data.records);
      setLoad(false);
    });
  }, []);

  const handleTicket = useCallback(
    id => {
      const url = `${ambient()}/export/get-inscricao/?q=(cod_evento_cad:${id},ken:${token})`;
      window.open(url);
    },
    [token]
  );

  const handleBillet = useCallback(data => {
    setWait(true);
    api
      .get(
        `/inscricao/emitir-boleto/?q=(cod_cob_sub_conta:${data.cod_cob_sub_conta})`
      )
      .then(response => {
        if (response.data.records.error) {
          toast.error(response.data.records.msg);
          setWait(false);
          return;
        }
        if (response.data.records.url) {
          window.open(response.data.records.url);
        } else {
          toast.error('Boleto não disponível');
        }
        setWait(false);
      });
  }, []);

  return (
    <div className="content padding-tab-top-post">
      <Row>
        <Col>
          <div className="d-flex justify-content-between align-items-center">
            <div className="mb-3">
              <h3 className="mb-0 font-weight-semibold">
                <i
                  role="presentation"
                  style={{ fontSize: '1.5rem' }}
                  onClick={() => history.goBack()}
                  className="icon-arrow-left52 mr-2 c-pointer"
                ></i>
                Minhas Compras
              </h3>
              <span className="text-muted d-block">
                Aqui você pode gerenciar suas compras
              </span>
            </div>
            <div></div>
          </div>
        </Col>
        <Col xl={12}>
          {load ? (
            <Loading />
          ) : (
            <Card>
              <Card.Body>
                <div id="loading" style={{ display: wait ? 'block' : 'none' }}>
                  <div id="loading-image">
                    <Loading />
                  </div>
                </div>
                <table className="table table-hover table-striped">
                  <thead className="bg-light">
                    <tr>
                      <th>Tipo</th>
                      <th>Nome</th>
                      <th>Valor</th>
                      <th>Data da compra</th>
                      <th className="text-center">Status</th>
                      <th className="text-center">Download</th>
                      <th className="text-center">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(shoppings).length === 0 ? (
                      <tr>
                        <td colSpan="6" className="p-0 text-center">
                          <div className="alert alert-info border-0 alert-dismissible mb-0">
                            <i className="icon-info3 border-2 rounded-round p-1 mr-2"></i>
                            <span>
                              Caro usuário, você não efetuou nenhuma compra
                            </span>
                          </div>
                        </td>
                      </tr>
                    ) : (
                      shoppings.map(shopping => (
                        <tr key={shopping.cod_cob_sub_conta}>
                          <td>{shopping.nome_tip_eve}</td>
                          <td>{shopping.nome_item}</td>
                          <td>{shopping.valor_pago}</td>
                          <td>
                            {format(
                              parseISO(shopping.dt_cadastro),
                              'dd/MM/yyyy'
                            )}
                          </td>
                          <td className="text-center">
                            {shopping.flg_pago === '1' && (
                              <Badge variant="success">Pago</Badge>
                            )}
                            {shopping.flg_pago === '2' && (
                              <Badge variant="info">
                                Pago. Aguardando despache
                              </Badge>
                            )}
                            {shopping.flg_pago === '3' && (
                              <Badge variant="success">
                                Pago. Produto enviado
                              </Badge>
                            )}
                            {shopping.flg_pago === '0' ||
                              (!shopping.flg_pago && (
                                <Badge variant="secondary">
                                  Pendente Pagamento
                                </Badge>
                              ))}
                          </td>
                          <td className="text-center">
                            {shopping.cod_evento && shopping.mostrar_ingresso && (
                              <Button
                                onClick={() =>
                                  handleTicket(shopping.cod_evento_cad)
                                }
                                title="Baixar ingresso"
                                variant="light"
                                size="sm"
                              >
                                <i className="icon-ticket icon-1x text-primary"></i>
                              </Button>
                            )}
                            {shopping.tipo_mkt_place === '1' && (
                              <Link
                                to={`/produtos/${shopping.cod_produto}/download`}
                              >
                                <Button
                                  title="Arquivos para download"
                                  variant="light"
                                  size="sm"
                                >
                                  <i className="icon-download"></i>
                                </Button>
                              </Link>
                            )}
                          </td>
                          <td>
                            <div className="d-flex justify-content-end align-items-center">
                              <div className="list-icons text-primary">
                                <div className="dropdown">
                                  <button
                                    type="button"
                                    className="btn btn-link dropdown-toggle"
                                    data-toggle="dropdown"
                                  >
                                    <i className="icon-cog6"></i>
                                  </button>

                                  <div className="dropdown-menu dropdown-menu-right">
                                    <button
                                      disabled={
                                        shopping.forma_pgto !== '8' ||
                                        shopping.flg_pago
                                      }
                                      onClick={() => {
                                        handleBillet(shopping);
                                      }}
                                      type="button"
                                      className="btn btn-link dropdown-item"
                                    >
                                      <i className="icon-barcode2"></i>
                                      2º via boleto
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </Card.Body>
            </Card>
          )}
        </Col>
      </Row>
    </div>
  );
}
