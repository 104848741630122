import produce from 'immer';

const INITIAL_STATE = {
  cases: [],
  case: null,
  selected: null,
  loading: true,
};

export default function cases(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@cases/GET_CASES_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@cases/GET_CASES_SUCCESS': {
        draft.loading = false;
        draft.case = action.payload;
        break;
      }
      case '@cases/LIST_CASES_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@cases/LIST_CASES_SUCCESS': {
        draft.loading = true;
        draft.selected = action.payload.shift();
        draft.cases = [draft.selected, ...action.payload];
        break;
      }
      case '@cases/LOAD_LIST_SUCCESS': {
        draft.cases = action.payload;
        break;
      }
      case '@cases/LIST_CASES_FAILURE':
      case '@cases/GET_CASES_FAILURE':
      case '@cases/LOAD_LIST_REQUEST':
      case '@cases/LOAD_CASES_REQUEST': {
        draft.loading = false;
        break;
      }
      default:
    }
  });
}
