/* eslint-disable no-shadow */
/* eslint-disable no-plusplus */
/* eslint-disable no-prototype-builtins */

import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Form } from '@unform/web';
import { Button } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';

import Input from '~/components/InputText';
import LoginUser from '~/components/LoginUser';
import CreateUser from '~/components/CreateUser';
import BottomButton from '~/components/BottomButton';

import history from '~/services/history';

import { store } from '~/store';

import { ehCobrado } from '~/util';
import { resetModalRequest } from '~/store/modules/auth/actions';
import { addParticipant, addStep } from '~/store/modules/cart/actions';

export default function Participants() {
  const formRef = useRef(null);
  const dispatch = useDispatch();
  let initial = -1;

  const { signed } = store.getState().auth;
  const { profile } = useSelector(state => state.user);
  const { tickets } = useSelector(state => state.cart);
  const { event } = useSelector(state => state.cart);

  const [showLoginUser, setShowLoginUser] = useState(false);
  const [showCreateUser, setShowCreateUser] = useState(false);

  const handleShowCreateUser = () => setShowCreateUser(true);
  const handleCloseCreateUser = () => setShowCreateUser(false);
  const handleCloseLoginUser = () => setShowLoginUser(false);

  const handleShowLoginUser = useCallback(() => {
    dispatch(resetModalRequest());
    setTimeout(() => {
      setShowLoginUser(true);
    }, 1000);
  }, [dispatch]);

  useEffect(() => {
    console.log(tickets);
    if (!tickets.length) {
      history.goBack();
    }
  }, [tickets]);

  const parts = useCallback(quant => {
    const rows = [];
    for (let i = 1; i <= quant; i++) {
      rows.push(i);
    }
    return rows;
  }, []);

  const handleSubmit = useCallback(
    async data => {
      dispatch(addParticipant(data.participants));
      dispatch(addStep());
    },
    [dispatch]
  );

  return (
    <>
      <h5 className="font-weight-bold text-muted">PARTICIPANTES</h5>

      <LoginUser show={showLoginUser} handleClose={handleCloseLoginUser} />
      <CreateUser show={showCreateUser} handleClose={handleCloseCreateUser} />

      <div className="row mb-2 ">
        <div className="col-md-12">
          <div className="card border-left-3 border-left-pink-400 rounded-0 mt-1">
            <div className="card-body">
              {signed ? (
                <>
                  <p className="quote-font mb-0">
                    Você já se encontra logado em nossa plataforma, basta
                    preencher o formulário de participantes e clicar no botão
                    continuar para prosseguir a inscrição.
                  </p>
                  {!isMobile && (
                    <button
                      type="button"
                      className="btn btn-sm btn-default border-slate-600 btn-labeled btn-labeled-left mr-2 mt-2"
                    >
                      <b>
                        <i className="icon-user"></i>
                      </b>
                      <span className="font-weight-bold mr-1">
                        {store.getState().user.profile.nome_membro}
                      </span>
                      <span className="font-weight-bold">
                        ({store.getState().user.profile.email})
                      </span>
                    </button>
                  )}
                </>
              ) : (
                <>
                  <h6 className="mb-0 font-weight-bold">Caro usuário,</h6>
                  <p className="quote-font">
                    Conecte na sua conta e tenha acesso ao seu histórico de
                    pedidos, reimpressão de ingressos e mais!
                  </p>
                  <button
                    type="button"
                    className="btn btn-sm btn-default border-slate-600 btn-labeled btn-labeled-left mr-2"
                    onClick={handleShowLoginUser}
                  >
                    <b>
                      <i className="icon-reading"></i>
                    </b>{' '}
                    Login
                  </button>
                  <button
                    type="button"
                    className="btn btn-sm bbtn-default border-slate-600 btn-labeled btn-labeled-left mr-2"
                    onClick={handleShowCreateUser}
                  >
                    <b>
                      <i className="icon-plus3"></i>
                    </b>{' '}
                    Criar Conta
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <Form ref={formRef} onSubmit={handleSubmit}>
        <div className="row">
          {tickets.map((ticket, i) => {
            return (
              <div key={ticket.id} className="col-xl-12 mb-2">
                {parts(ticket.ticketAmount.amount).map((part, index) => {
                  initial++;
                  return (
                    <div className="row" data={initial} key={Math.random()}>
                      <div className="col-xl-12">
                        <h6
                          className="font-weight-bold d-block w-100"
                          style={{ borderBottom: '1px dotted #c5c5c5' }}
                        >
                          Inscrição nº {index + 1}{' '}
                          <small className="text-muted">
                            - {ticket.nome_ing}
                          </small>
                        </h6>
                      </div>
                      <div className="col-xl-6">
                        <div className="form-group">
                          <label>Nome</label>
                          <Input
                            type="hidden"
                            name={`participants[${initial}][plano]`}
                            defaultValue={ticket.id}
                          />
                          <Input
                            required
                            type="text"
                            name={`participants[${initial}][nome_part]`}
                            placeholder="Digite seu nome..."
                            style={{ fontSize: 13 }}
                            defaultValue={
                              i === 0 && index === 0 && profile
                                ? profile.nome_membro
                                : ''
                            }
                          />
                        </div>
                      </div>
                      <div className="col-xl-6">
                        <div className="form-group">
                          <label>E-mail</label>
                          <Input
                            required
                            type="email"
                            name={`participants[${initial}][email_part]`}
                            placeholder="Digite seu e-mail..."
                            style={{ fontSize: 13 }}
                            defaultValue={
                              i === 0 && index === 0 && profile
                                ? profile.email
                                : ''
                            }
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>

        {!isMobile ? (
          <Button
            disabled={!profile}
            size="lg"
            type="submit"
            variant="success"
            className="btn-block font-weight-bold btn-custom text-uppercase float-right"
            style={{ width: '150px' }}
          >
            {ehCobrado(event) ? 'Efetuar Inscrição' : 'Próximo'}
          </Button>
        ) : (
          <BottomButton
            disabled={!profile}
            type="submit"
            text={ehCobrado(event) ? 'Efetuar Inscrição' : 'Próximo'}
          />
        )}
      </Form>
    </>
  );
}
