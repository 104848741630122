/* eslint-disable react/prop-types */
import React, { useState, useMemo, useCallback } from 'react';

import { Button, Row, Col, Card } from 'react-bootstrap';

import ReactQuill from 'react-quill';
import { parseISO } from 'date-fns';
import Input from '~/components/InputText';
import InputMask from '~/components/InputMask';
import Dropzone from '~/components/Dropzone';
import Textarea from '~/components/InputTextarea';
import DatePiker from '~/components/DatePicker';
import DatePikerTime from '~/components/DatePickerTime';
import LocationEvent from '~/components/LocationEvent';
import CreateTicket from '~/components/CreateTicket';

import { objIsNull } from '~/util';
import { UploadCurso, TextEditor, AvatarInput } from './styles';

import 'react-quill/dist/quill.snow.css';

import noImage from '~/assets/img/profile.png';

function TabMain({
  course,
  banner,
  loadImg,
  avatarOrganizer,
  htmlContent,
  setHtmlContent,
  setNewCourseTicket,
  handleAvatarOrganizer,
  isNewCourse,
}) {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [typeTicket, setTypeTicket] = useState(null);
  const [paid, setPaid] = useState(null);
  const [showCreateTicket, setShowCreateTicket] = useState(false);

  useMemo(() => {
    if (course && objIsNull(course)) {
      setTimeout(() => {
        setStartDate(course.dt_eve ? parseISO(course.dt_eve) : null);
        setEndDate(course.dt_eve_fim ? parseISO(course.dt_eve_fim) : null);
      });
    }
  }, [course]);

  const ticketTypesArray = {
    null: 'Crie seu ingresso',
    true: 'Ingresso pago',
    false: 'Ingresso grátis',
  };

  const handleTypeTicket = typed => {
    if (typed === 1) {
      setTypeTicket(1);
    } else {
      setTypeTicket(2);
    }
    setShowCreateTicket(true);
  };

  const handleCreateTicketClose = useCallback(
    data => {
      setNewCourseTicket(data);
    },
    [setNewCourseTicket]
  );

  return (
    <>
      <Card>
        <Card.Body>
          <Row>
            <Col xl={8}>
              <Row>
                <Col xl={12}>
                  <Row>
                    <Col xl={12}>
                      <div className="form-group">
                        <label htmlFor="titulo_eve">Nome do Curso *</label>
                        <Input type="hidden" id="tipo_eve" name="tipo_eve" />
                        <Input
                          type="hidden"
                          id="status_eve"
                          name="status_eve"
                        />
                        <Input
                          required
                          type="text"
                          id="titulo_eve"
                          name="titulo_eve"
                          className="form-control"
                          placeholder="Nome do curso..."
                        />
                      </div>
                    </Col>
                    <Col xl={12}>
                      <div className="form-group">
                        <label>Descrição</label>
                        <TextEditor>
                          <ReactQuill
                            theme="snow"
                            value={htmlContent}
                            onChange={setHtmlContent}
                            modules={TabMain.modules}
                            formats={TabMain.formats}
                            preserveWhitespace
                          />
                        </TextEditor>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            {objIsNull(course) ? (
              <Col xl={4}>
                <div className="form-group mb-0">
                  <label>Banner</label>
                  <UploadCurso>
                    <div className="input-group">
                      <Dropzone
                        id={course.cod_evento}
                        loadEvent={loadImg}
                        label="Arraste uma imagem ou clique aqui para enviar."
                      >
                        <Col className="text-center">
                          <img
                            src={banner || course.img_eve}
                            alt={course.titulo_eve}
                            className="img-fluid w-100"
                            style={{ maxHeight: 250 }}
                          />
                        </Col>
                      </Dropzone>
                    </div>
                  </UploadCurso>
                </div>
                <div className="form-group text-center mt-2">
                  <label className="text-muted">
                    <small>
                      Tamanho: 1905px por 1020px (padrão da página de eventos do
                      Facebook)
                    </small>
                  </label>
                </div>
              </Col>
            ) : null}
          </Row>
          <Row></Row>
        </Card.Body>
      </Card>

      <LocationEvent event={course} module="curso" />

      <Card>
        <Card.Body>
          <Card.Title>Quando o curso vai acontecer?</Card.Title>
          <Row xl={12}>
            <Col xl={6}>
              <Col xl={12}>
                <Row>
                  <Col xl={7}>
                    <div className="form-group">
                      <label htmlFor="dt_eve">Data Início*</label>
                      <DatePiker
                        id="dt_eve"
                        name="dt_eve"
                        required
                        onChange={date => {
                          setStartDate(date);
                        }}
                        selected={startDate}
                        minDate={new Date()}
                      />
                    </div>
                  </Col>
                  <Col xl={3}>
                    <div className="form-group">
                      <label htmlFor="hora_eve">Hora início*</label>
                      <DatePikerTime
                        id="hora_eve"
                        startDate={startDate}
                        endDate={endDate}
                        required
                        name="hora_eve"
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col xl={12}>
                <Row>
                  <Col xl={7}>
                    <div className="form-group">
                      <label htmlFor="dt_eve_fim">Data fim</label>
                      <DatePiker
                        id="dt_eve_fim"
                        name="dt_eve_fim"
                        selected={endDate}
                        onChange={date => setEndDate(date)}
                        minDate={new Date()}
                      />
                    </div>
                  </Col>
                  <Col xl={3}>
                    <div className="form-group">
                      <label htmlFor="hora_eve_fim">Hora fim</label>
                      <DatePikerTime
                        id="hora_eve_fim"
                        endDate={endDate}
                        name="hora_eve_fim"
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Col>

            {isNewCourse && (
              <Col
                xl={5}
                className="d-flex flex-column justify-content-center align-items-center "
              >
                <span className="mb-3 font-weight-semibold">
                  Crie um ingresso para seu curso*
                </span>
                <Row>
                  <Col className="ml-3" style={{ width: 300 }}>
                    <Card
                      onClick={() => handleTypeTicket(1)}
                      type="button"
                      className={`border-light shadow-sm type-ticket ${
                        typeTicket === 1 ? 'select-ticket' : ''
                      }`}
                    >
                      <Card.Body className="d-flex flex-column justify-content-center align-items-center">
                        <i className="icon-ticket icon-2x"></i>
                        <p className="mt-2">{ticketTypesArray[paid]}</p>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        </Card.Body>
      </Card>

      <Card>
        <Card.Body>
          <Card.Title>Sobre o Organizador</Card.Title>

          <Row>
            <Col
              md={2}
              className="d-flex justify-content-center align-items-center"
            >
              <AvatarInput>
                {avatarOrganizer !== '0' ? (
                  <img src={avatarOrganizer} alt="Avatar" />
                ) : (
                  <img src={noImage} alt="Avatar" />
                )}
                <label htmlFor="avatarOrganizer">
                  <i className="icon-camera"></i>
                  <input
                    type="file"
                    id="avatarOrganizer"
                    onChange={e => handleAvatarOrganizer(e.target.files[0])}
                  />
                </label>
              </AvatarInput>
            </Col>
            <Col>
              <Row>
                <Col>
                  <div className="form-group">
                    <label htmlFor="nome_organiz">Nome</label>
                    <Input
                      className="form-control"
                      type="hidden"
                      id="img_organiz"
                      name="img_organiz"
                      value="0"
                    />
                    <Input
                      className="form-control"
                      type="text"
                      id="nome_organiz"
                      name="nome_organiz"
                      placeholder="Nome do Organizador..."
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="form-group">
                    <label htmlFor="tel_organiz">Telefone (Whatsapp)</label>
                    <InputMask
                      mask="(99) 999999999"
                      className="form-control"
                      type="text"
                      id="tel_organiz"
                      name="tel_organiz"
                      placeholder="Telefone do Organizador..."
                    />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col md={12}>
              <div className="form-group">
                <label htmlFor="desc_organiz">Descrição</label>
                <Textarea id="desc_organiz" name="desc_organiz" rows="5" />
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Row className="justify-content-md-center mt-4">
        <Col xl={2}>
          <Button size="lg" variant="primary" block type="submit">
            Salvar
          </Button>
        </Col>
      </Row>

      <CreateTicket
        tickets={[]}
        event={course}
        show={showCreateTicket}
        setShow={setShowCreateTicket}
        setExternalPaid={setPaid}
        handleExternalClose={handleCreateTicketClose}
        endDate={endDate}
        isNewCourse
      />
    </>
  );
}

TabMain.modules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }, { font: [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link', 'image', 'video'],
    ['clean'],
  ],
  clipboard: {
    matchVisual: false,
  },
};

TabMain.formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
];

TabMain.propTypes = {};

export default TabMain;
