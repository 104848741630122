import React, { useState, useCallback, useRef } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import { parcels } from '~/util';
import api from '~/services/api';

import Select from '~/components/InputSelect';
import Input from '~/components/InputText';
import InputCurrency from '~/components/InputCurrency';

export default function Send({ data }) {
  const formRef = useRef(null);

  const [load, setLoad] = useState(false);
  const [value, setValue] = useState(data.eh_cobrado);

  const handleSubmit = useCallback(async data => {
    setLoad(true);
    try {
      formRef.current.setErrors({});
      const schema = Yup.object().shape({
        eh_cobrado: Yup.string().required('Tipo de pagamento é obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const response = await api.post(`/produto/salvar-preco-produto`, data);

      if (response.data.records.error) {
        toast.error(response.data.records.msg);
        setLoad(false);
        return;
      }

      toast.success('Preço atualizado com sucesso');
      setLoad(false);
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
        setLoad(false);
      }
    }
  }, []);

  return (
    <Form
      ref={formRef}
      onSubmit={handleSubmit}
      initialData={{
        cod_produto: data.cod_produto,
        por_valor: data.por_valor,
        eh_cobrado: data.eh_cobrado,
        max_parcela: data.max_parcela,
      }}
    >
      <Row>
        <Col xl={12}>
          <Row>
            <Col xl={4}>
              <div className="form-group">
                <label>Tipo de pagamento</label>
                <Input type="hidden" name="cod_produto" />
                <Select
                  placeholder="Selecione"
                  name="eh_cobrado"
                  onChange={e =>
                    e.value === '0' ? setValue('0') : setValue('1')
                  }
                  options={[
                    { value: '1', label: 'Pago' },
                    { value: '0', label: 'Grátis' },
                  ]}
                />
              </div>
            </Col>
          </Row>
        </Col>
        <Col xl={4}>
          <div className="form-group">
            <label>Valor</label>
            {value === '0' ? (
              <InputCurrency
                onFocus={e => {
                  e.target.select();
                }}
                name="por_valor"
                disabled
                value={0}
              />
            ) : (
              <InputCurrency
                onFocus={e => {
                  e.target.select();
                }}
                name="por_valor"
              />
            )}
          </div>
        </Col>
        {value !== '0' && (
          <Col xl={4}>
            <div className="form-group">
              <label>Máximo de parcelas na venda</label>
              <Select
                placeholder="Selecione"
                name="max_parcela"
                options={parcels()}
              />
            </div>
          </Col>
        )}
        {/* <Col xl={6}>
          <div className="form-group">
            <label>Valor promocional</label>
            {value === '0' ? (
              <InputCurrency name="por_valor" disabled={true} value={0} />
            ) : (
              <InputCurrency
                onFocus={e => {
                  e.target.select();
                }}
                name="por_valor"
              />
            )}
          </div>
        </Col> */}
        <Col xl={12}>
          <Row className="justify-content-md-center">
            <Col xl={2}>
              <Button
                disabled={load}
                size="lg"
                block
                type="submit"
                variant="primary"
              >
                {load ? 'Aguarde...' : 'Salvar'}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
}
