import React from 'react';

function Footer() {
  return (
    <div className="navbar navbar-expand-lg navbar-light">
      <div className="text-center d-lg-none w-100">
        <button
          type="button"
          className="navbar-toggler dropdown-toggle"
          data-toggle="collapse"
          data-target="#navbar-footer"
        >
          <i className="icon-unfold mr-2"></i>
          Footer
        </button>
      </div>

      <div className="navbar-collapse collapse" id="navbar-footer">
        <span className="navbar-text">
          © 2020 <a href="#1">Psico.in</a> by{' '}
          <a href="https://psicomanager.com.br">PsicoManager</a>
        </span>

        <ul className="navbar-nav ml-lg-auto">
          <li className="nav-item">
            <a href="/#" className="navbar-nav-link">
              <i className="icon-lifebuoy mr-2"></i> Suporte
            </a>
          </li>
          <li className="nav-item">
            <a href="/#" className="navbar-nav-link">
              <i className="icon-file-text2 mr-2"></i> Documentação
            </a>
          </li>
          <li className="nav-item">
            <a href="/#" className="navbar-nav-link font-weight-semibold">
              <span className="text-pink-400">
                <i className="icon-cart2 mr-2"></i> Loja
              </span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Footer;
