import React, { useRef, useEffect } from 'react';
import { useField } from '@unform/core';

export default function Input({ name, label, ...rest }) {
  const inputRef = useRef(null);

  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <>
      <label htmlFor={fieldName}>{label}</label>

      <textarea
        id={fieldName}
        ref={inputRef}
        defaultValue={defaultValue}
        className={
          error
            ? 'form-control bg-light border-danger'
            : 'form-control bg-light'
        }
        {...rest}
      />

      {error && <span className="form-text text-danger">{error}</span>}
    </>
  );
}
