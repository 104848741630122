import React from 'react';
import Dropzone from 'react-dropzone';

import { toast } from 'react-toastify';
import api from '~/services/api';

import { Upload } from './styles';

export default function DropzoneFile({ id, label, loadEvent, children }) {
  function handleUpload(files) {
    files.forEach(async file => {
      const data = new FormData();
      data.append('arquivo1', file);
      data.append('id', id);
      await api.post('/evento/enviar-arquivo-evento', data).then(response => {
        if (response.data.records.error) {
          toast.error(response.data.records.msg);
          return;
        }
        toast.success('Upload efetuado com sucesso');
        loadEvent(response.data.records);
      });
    });
  }
  return (
    <Dropzone onDropAccepted={handleUpload}>
      {({ getRootProps, getInputProps }) => (
        <Upload
          className="h-100 d-flex justify-content-center align-items-center"
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <p>{label}</p>
          {children}
        </Upload>
      )}
    </Dropzone>
  );
}
