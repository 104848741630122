import React, { useEffect } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import {
  MdCheckCircle,
  MdError,
  MdFileDownload,
  MdDeleteForever,
} from 'react-icons/md';

import { Container, FileInfo } from './styles';

const FileList = ({ files, onDelete }) => {
  return (
    <Container>
      {files.map(uploadedFile => (
        <li key={uploadedFile.id}>
          <FileInfo>
            <div>
              <strong>{uploadedFile.name}</strong>
              {/* <span>
              {uploadedFile.readableSize}
              {!!uploadedFile.url && (
                <button onClick={() => onDelete(uploadedFile.id)}>
                  Excluir
                </button>
              )}
            </span> */}
            </div>
          </FileInfo>

          <div>
            {!uploadedFile.uploaded && !uploadedFile.error && (
              <CircularProgressbar
                styles={{
                  root: { width: 24 },
                  path: { stroke: '#7159c1' },
                }}
                strokeWidth={10}
                percentage={uploadedFile.progress}
              />
            )}

            {uploadedFile.url && (
              <>
                {uploadedFile.downloadStatus ? (
                  <a
                    href="/#"
                    title="asdasdasdasd"
                    onClick={uploadedFile.downloadFunction}
                    rel="noopener noreferrer"
                  >
                    <MdFileDownload
                      style={{ marginRight: 8 }}
                      size={24}
                      color="#666"
                    />
                  </a>
                ) : (
                  <a
                    href={uploadedFile.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <MdFileDownload
                      style={{ marginRight: 8 }}
                      size={24}
                      color="#666"
                    />
                  </a>
                )}

                {onDelete && (
                  <a
                    href="/#"
                    onClick={e => {
                      e.preventDefault();
                      onDelete(uploadedFile.id);
                    }}
                    title="Remover"
                    rel="noopener noreferrer"
                  >
                    <MdDeleteForever
                      style={{ marginRight: 8 }}
                      size={24}
                      color="#666"
                    />
                  </a>
                )}
              </>
            )}

            {uploadedFile.uploaded && (
              <MdCheckCircle size={24} color="#32CD32" />
            )}
            {uploadedFile.error && (
              <MdError
                size={24}
                color="#e57878"
                title="Erro ao enviar a imagem"
              />
            )}
          </div>
        </li>
      ))}
    </Container>
  );
};

export default FileList;
