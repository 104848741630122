import styled from 'styled-components';

export const BoxBottomButton = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10;
  border-top: 1px solid rgba(0, 21, 39, 0.1);
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 10px 0;
  flex-shrink: 2;
  display: flex;
  align-items: center;
  background-color: #fff;
  border: 1px solid #c7c5c5;
  padding-bottom: 8px;

  a {
    width: 100%;
    max-width: 400px;
    margin: 0 0 10px;
    min-width: 200px !important;
  }

  span {
    display: flex;
    flex-direction: column-reverse;
    text-align: center;
    width: 100%;
    max-width: 400px;
  }

  span span {
    padding-bottom: 8px;
  }
`;
