export function coursesGetRequest(id) {
  return {
    type: '@courses/GET_COURSES_REQUEST',
    payload: id,
  };
}

export function coursesGetSuccess(data) {
  return {
    type: '@courses/GET_COURSES_SUCCESS',
    payload: data,
  };
}

export function coursesGetFailure() {
  return {
    type: '@courses/GET_COURSES_FAILURE',
  };
}

export function coursesNewCourse() {
  return {
    type: '@courses/NEW_COURSE_REQUEST',
  };
}

export function coursesListRequest() {
  return {
    type: '@courses/LIST_COURSES_REQUEST',
  };
}

export function coursesListSuccess(data) {
  return {
    type: '@courses/LIST_COURSES_SUCCESS',
    payload: data,
  };
}

export function coursesListFailure() {
  return {
    type: '@courses/LIST_COURSES_FAILURE',
  };
}
