import produce from 'immer';

const INITIAL_STATE = {
  groups: [],
  followers: [],
  loading: false,
};

export default function mods(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@mods/MODS_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@mods/MODS_GROUP_SUCCESS': {
        draft.loading = false;
        draft.groups = action.payload;
        break;
      }

      case '@mods/FOLLOWERS_GET_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@mods/FOLLOWERS_GET_SUCCESS': {
        draft.loading = false;
        draft.followers = action.payload;
        break;
      }

      case '@mods/MODS_GROUP_FAILURE':
      case '@mods/FOLLOWERS_GET_FAILURE': {
        draft.loading = false;
        break;
      }
      default:
    }
  });
}
