import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Carousel from 'react-bootstrap/Carousel';
import CarouselMini from 'react-multi-carousel';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Form, Input } from '@rocketseat/unform';
import { Row, Col, Card, Badge } from 'react-bootstrap';
import { format } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';
import 'react-multi-carousel/lib/styles.css';

import noImage from '~/assets/img/sem.png';
import history from '~/services/history';

import { eventsListRequest } from '~/store/modules/event/actions';

import CreateEvent from '~/components/CreateEvent';
import LoaderEvent from '~/components/LoaderEvent';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
    slidesToSlide: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    slidesToSlide: 5,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

function Events(props) {
  const dispatch = useDispatch();

  const { events } = useSelector(state => state.event);
  const loading = useSelector(state => state.event.loading);
  const [show, setShow] = useState(false);

  useEffect(() => {
    dispatch(eventsListRequest(''));
  }, [dispatch]);

  const loadEvents = useCallback(() => {
    dispatch(eventsListRequest(''));
  }, [dispatch]);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  function handleSubmit(itens) {
    history.push(`/eventos/pesquisar/${itens.city}?${itens.search}`);
  }

  const { deviceType } = props;
  return (
    <div className="content">
      <div className="profile-cover mt-1">
        <Row>
          <Col>
            <Carousel controls={false}>
              <Carousel.Item>
                <img
                  style={{ height: 395, maxHeight: 395 }}
                  className="d-block w-100"
                  src="http://epp.crppr.org.br/wp-content/uploads/2018/03/Banner-2.png"
                  alt="BAnner"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  style={{ height: 395, maxHeight: 395 }}
                  className="d-block w-100"
                  src="https://fgg.edu.br/wp-content/uploads/2019/08/banner_semanapsico-1.jpg"
                  alt="BAnner"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  style={{ height: 395, maxHeight: 395 }}
                  className="d-block w-100 "
                  src="https://credencial.papodeuniversitario.com.br/media/papodeuniversitario/resized/980x373_evento-847-banner.jpeg"
                  alt="BAnner"
                />
              </Carousel.Item>
            </Carousel>
          </Col>
        </Row>
      </div>
      <div className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="text-center d-lg-none w-100">
          <button
            type="button"
            className="navbar-toggler dropdown-toggle"
            data-toggle="collapse"
            data-target="#navbar-second"
          >
            <i className="icon-menu7 mr-2"></i>
          </button>
        </div>

        <div className="navbar-collapse collapse" id="navbar-second">
          <ul className="list-inline list-inline-condensed mb-0">
            <li className="list-inline-item">
              <span
                role="presentation"
                onClick={handleShow}
                style={{ cursor: 'pointer' }}
                className="btn btn-link text-default"
              >
                <i className="icon-plus2 mr-1"></i> Criar evento
              </span>
            </li>
          </ul>

          <ul className="list-inline mb-0 ml-md-auto">
            <li className="list-inline-item">
              <Link
                to="/eventos/meus-eventos"
                className="btn btn-link text-default"
              >
                <i className="icon-presentation mr-1"></i> Meus eventos
              </Link>
            </li>
            <li className="list-inline-item">
              <Link to="/eventos" className="btn btn-link text-default">
                <i className="icon-ticket mr-1"></i> Meus ingressos
              </Link>
            </li>
          </ul>
        </div>
      </div>

      <Card className="mt-3">
        <Card.Body>
          <h5 className="mb-3">Pesquisar evento</h5>

          <Form onSubmit={handleSubmit}>
            <div className="form-row justify-content-md-center">
              <div className="col-md-4">
                <div className="form-group form-group-feedback form-group-feedback-right">
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Pesquisa por evento"
                    name="search"
                  />
                  <div className="form-control-feedback form-control-feedback-sm">
                    <i className="icon-search4 text-muted"></i>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group form-group-feedback form-group-feedback-right">
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Pesquisa por cidade"
                    name="city"
                  />
                  <div className="form-control-feedback form-control-feedback-sm">
                    <i className="icon-pin-alt text-muted"></i>
                  </div>
                </div>
              </div>
              <div className="col-md-1">
                <button className="btn btn-dark text-monospace" type="submit">
                  Procurar
                </button>
              </div>
            </div>
          </Form>
        </Card.Body>
      </Card>

      <Row className="mt-2">
        <Col>
          <div className="d-flex justify-content-between align-items-center">
            <div className="mb-2">
              <h3 className="mb-0 font-weight-semibold ">Perto de você</h3>
              <span className="d-block ">
                Eventos proximos da sua localidade
              </span>
            </div>
          </div>
          {loading ? (
            <LoaderEvent />
          ) : (
            <CarouselMini
              swipeable
              draggable={false}
              showDots={false}
              responsive={responsive}
              ssr // means to render carousel on server-side.
              infinite={false}
              autoPlay={false}
              keyBoardControl
              customTransition="all .5"
              transitionDuration={500}
              containerClassNclassName="carousel-container"
              removeArrowOnDeviceType={['tablet', 'mobile']}
              deviceType={deviceType}
              dotListClassNclassName="custom-dot-list-style"
              itemClassNclassName="carousel-item-padding-40-px"
              partialVisible
            >
              {events.map(event => (
                <div className="pr-4" key={event.cod_evento}>
                  <Link
                    to={`/eventos/${event.cod_evento}`}
                    className="text-dark"
                  >
                    <Card className="events">
                      {event.img_eve ? (
                        <Card.Img
                          style={{ maxHeight: 180, height: 180 }}
                          variant="top"
                          src={event.img_eve}
                        />
                      ) : (
                        <Card.Img
                          style={{ maxHeight: 180, height: 180 }}
                          variant="top"
                          src={noImage}
                        />
                      )}
                      <Card.Body className="d-flex align-items-center p-0 mt-n1">
                        <Badge variant="dark">
                          <h3 className="font-weight-bold text-uppercase mb-0">
                            {format(new Date(event.dt_eve), 'dd', {
                              locale: pt,
                            })}{' '}
                            {format(new Date(event.dt_eve), 'MMM', {
                              locale: pt,
                            })}
                          </h3>
                        </Badge>
                        <div
                          className="text-center text-uppercase w-100 mt-1"
                          style={{ color: '#707070' }}
                        >
                          <i className="icon-location3"></i>
                          Uberlândia-MG
                        </div>
                      </Card.Body>
                    </Card>
                  </Link>
                </div>
              ))}
            </CarouselMini>
          )}
        </Col>
      </Row>

      <CreateEvent
        show={show}
        handleClose={handleClose}
        loadEvents={loadEvents}
      />
    </div>
  );
}

Events.propTypes = {
  deviceType: PropTypes.string.isRequired,
};

export default Events;
