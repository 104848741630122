/* eslint-disable react/no-danger */
/* eslint-disable no-restricted-globals */

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import { isMobile } from 'react-device-detect';
import { Row, Col, Card, Container, Button } from 'react-bootstrap';

import history from '~/services/history';

import * as CartAction from '~/store/modules/cart/actions';
import * as FreightAction from '~/store/modules/freight/actions';
import * as ProductAction from '~/store/modules/product/actions';

import Loading from '~/components/Loading';
import Input from '~/components/InputText';
import Select from '~/components/InputSelect';
import InputMask from '~/components/InputMask';
import BottomButton from '~/components/BottomButton';

import {
  adjustTopApplication,
  buildQueryString,
  formatCurrencyValue,
} from '~/util';

export default function Quantity(props) {
  const { match } = props;

  const [cep, setCep] = useState('');
  const [total, setTotal] = useState(0);
  const [frete, setFrete] = useState(0);
  const [opFrete, setOpFrete] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [descFrete, setDescFrete] = useState('');
  const [showSelect, setShowSelect] = useState(false);

  const dispatch = useDispatch();

  const loading = useSelector(state => state.product.loading);
  const product = useSelector(state => state.product.product);
  const freight = useSelector(state => state.freight.freightValue);

  useEffect(() => {
    adjustTopApplication();
    dispatch(ProductAction.productsGetByTagRequest(match.params.tag));
    localStorage.removeItem('@psicomart:address');
    localStorage.removeItem('@psicomart:products');
  }, [dispatch, match]);

  useEffect(() => {
    const count = Object.keys(freight).length;
    if (count !== 0) {
      if (count > 1) {
        /** Mais de um FRETE então pode-se montar o SELECT para escolha. */
        setShowSelect(true);
      } else {
        /** Único FRETE já deixar selecionado e configurado no STORAGE. */
        const recipient = freight[0].frt_cep_destino;
        const freteValue = parseFloat(freight[0].frt_vlr_frete_fixo);
        localStorage.setItem(
          '@psicomart:address',
          JSON.stringify({
            cep: recipient,
            value: freteValue,
          })
        );

        setFrete(freteValue);
        setTotal(subTotal + freteValue);
        setDescFrete(freight[0].frt_descricao);
      }
    }
  }, [subTotal, freight]);

  function getAffiliate() {
    if (match.params.afiliado) {
      return match.params.afiliado;
    }
    return '';
  }

  function handleTotal(e, item) {
    let productQuantity = e.target.value;
    productQuantity = isNaN(productQuantity) ? 0 : productQuantity;

    let productValueTotal = 0;
    if (productQuantity) {
      productValueTotal = item.por_valor * productQuantity;
      const productCart = {
        ...item,
        pro_qtde: productQuantity,
        pro_valor_total: productValueTotal,
        pro_url_afiliado: getAffiliate(),
      };
      dispatch(CartAction.addProduct(productCart));
      localStorage.setItem('@psicomart:products', JSON.stringify(productCart));
    }

    setTotal(productValueTotal + frete);
    setSubTotal(productValueTotal);
    setQuantity(productQuantity);
  }

  function handleCep(e) {
    const freteValue = 0;
    let totalValue = 0;

    setCep(e.target.value);
    if (e.target.value) {
      totalValue = total + freteValue;
      const configCep = {
        cep: e.target.value,
        value: freteValue,
      };
      dispatch(CartAction.setConfigCep(configCep));
    }

    setTotal(totalValue);
    setFrete(freteValue);
  }

  async function handleCalculateCep(e) {
    setDescFrete('');
    setShowSelect(false);
    localStorage.removeItem('@psicomart:address');
    if (e.target.value) {
      let cp = e.target.value;
      if (cp.length) {
        cp = cp.replace(/_|-/g, '');
        const strLength = cp.length;
        if (strLength === 8) {
          cp = `${cp.substr(0, 5)}-${cp.substr(5, 3)}`;
          const query = buildQueryString({
            pro_qtde: quantity,
            cod_produto: product.cod_produto,
            frt_cep_mascara: cp,
          });
          dispatch(FreightAction.getFreightValueRequest(query));
        }
      }
    }
  }

  function cepValidate() {
    if (!cep) {
      toast.error('CEP é um campo de preenchimento obrigatório!');
      return false;
    }
    const value = cep.replace(/_|-/g, '');
    if (value.length !== 8) {
      toast.error('CEP inválido!');
      return false;
    }
    /** Caso exista o SELECT para escolha de uma opção: */
    if (showSelect) {
      if (!opFrete) {
        toast.error('Escolha uma opção de frete!');
        return false;
      }
    }

    return true;
  }

  function handleSubmit() {
    if (quantity > 0) {
      if (product.tipo_mkt_place === '2') {
        const cepValidator = cepValidate();
        if (!cepValidator) {
          return false;
        }
      }
      history.push(`/purchase/produto/${product.url_amig}/${getAffiliate()}`);
    } else {
      toast.error('O campo quantidade é de preenchimento obrigatório!');
      return false;
    }
    return true;
  }

  function getOptionsSelect() {
    const count = Object.keys(freight).length;
    if (count > 0) {
      const options = [];
      Object.keys(freight).forEach(i => {
        const item = freight[i];

        let strDays = '';
        if (item.frt_dias_prazo_entrega) {
          strDays = ` - ${item.frt_dias_prazo_entrega} dias úteis`;
        }

        const label = `${item.frt_descricao} (${formatCurrencyValue(
          item.frt_vlr_frete_fixo
        )}) ${strDays}`;
        const option = {
          value: parseInt(item.frt_vlr_frete_fixo, 10),
          label,
        };
        options.push(option);
      });
      return options;
    }
    return [];
  }

  function handleChangeFreight(e) {
    const regex = /\(([^)]+)\)/;
    const matches = regex.exec(e.label);
    if (matches[1]) {
      const freteValue = parseFloat(matches[1].split('R$')[1]);
      const freteOpcao = freteValue || 0.1;

      setOpFrete(freteOpcao);
      setFrete(freteValue);
      setTotal(subTotal + freteValue);

      localStorage.setItem(
        '@psicomart:address',
        JSON.stringify({
          cep,
          value: freteValue,
        })
      );
    }
  }

  function getAliasButton(ehCobrado) {
    return ehCobrado === '1' ? 'Concluir Compra' : 'Próximo Etapa';
  }

  return (
    <div className="box-ticket content padding-tab-top-post mt-5">
      {loading ? (
        <Loading />
      ) : (
        <Container>
          <Row>
            <Col xl={12}>
              <div className="d-flex justify-content-between align-items-center">
                <div className="mb-4">
                  <h1 className="mb-0 font-weight-semibold">
                    <i
                      role="presentation"
                      style={{ fontSize: '1.5rem' }}
                      onClick={() => history.goBack()}
                      className="icon-arrow-left52 mr-2 c-pointer"
                    ></i>
                    {product.nome_produto}
                  </h1>
                  <span
                    className="text-muted d-block"
                    style={{ marginLeft: 35 }}
                  >
                    Definir a quantidade de produtos
                  </span>
                </div>
              </div>
            </Col>
            <Col xl={12}>
              <Card>
                <Form>
                  <Card.Body>
                    <Col xl={12}>
                      <Row>
                        <Col xl={4}>
                          <h5 className="font-weight-bold text-muted">
                            DADOS DO PRODUTO
                          </h5>
                          <h2>{product.nome_produto}</h2>
                          <p
                            style={{ fontSize: 12 }}
                            dangerouslySetInnerHTML={{
                              __html: product.desc_produto,
                            }}
                          />
                        </Col>
                        <Col xl={8}>
                          <Col xl={12}>
                            <Row>
                              <h5 className="text-uppercase text-muted d-block w-100 mb-2 font-weight-bold">
                                Quantidades
                              </h5>
                            </Row>
                          </Col>
                          <Card className="box-card-ticket border-left-3 border-left-slate rounded-left-0">
                            <Card.Body>
                              <Row>
                                <Col xl={6}>
                                  <h6 className="font-weight-semibold mb-0">
                                    {product.nome_produto}
                                  </h6>
                                  {product.eh_cobrado === '1' ? (
                                    <span className="pt-1 d-block">
                                      {formatCurrencyValue(product.por_valor)}
                                    </span>
                                  ) : (
                                    <span>
                                      <small>
                                        O valor de{' '}
                                        {formatCurrencyValue(product.por_valor)}{' '}
                                        não será cobrado!
                                      </small>
                                    </span>
                                  )}
                                </Col>
                                <Col xl={6}>
                                  <div className="text-sm-right mb-0 mt-3 mt-sm-0 w-100">
                                    <Input
                                      type="number"
                                      id="pro_qtde"
                                      name="pro_qtde"
                                      className="form-control"
                                      placeholder="Quantidade"
                                      style={{ width: '100%' }}
                                      onChange={e => handleTotal(e, product)}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>

                          {product.tipo_mkt_place === '2' && (
                            <Card className="box-card-ticket border-left-3 border-left-slate rounded-left-0">
                              <Card.Body>
                                <Row>
                                  <Col xs={6}>
                                    {!isMobile ? (
                                      <div>
                                        <h6 className="font-weight-semibold mb-0">
                                          Cálculo do Frete
                                        </h6>
                                        <span>
                                          <small>
                                            Entre com seu CEP para verificar o
                                            valor do frete a ser pago.
                                          </small>
                                        </span>
                                      </div>
                                    ) : (
                                      <h6 className="font-weight-semibold mb-0">
                                        Frete
                                      </h6>
                                    )}
                                  </Col>
                                  <Col xs={6}>
                                    <div className="text-sm-right mb-0 mt-sm-0 ml-auto w-100">
                                      <InputMask
                                        id="cep"
                                        name="cep"
                                        type="text"
                                        className="form-control"
                                        mask="99999-999"
                                        placeholder="CEP"
                                        disabled={!(parseInt(quantity, 10) > 0)}
                                        onChange={e => handleCep(e)}
                                        onBlur={e => handleCalculateCep(e)}
                                      />
                                    </div>
                                  </Col>
                                  <Col xs={12}>
                                    {showSelect && (
                                      <div
                                        className="d-sm-flex align-item-sm-center flex-sm-nowrap"
                                        style={{ fontSize: 10 }}
                                      >
                                        <Select
                                          id="frt_cod_tipo"
                                          name="frt_cod_tipo"
                                          placeholder="-- SELECIONE UM TIPO DE FRETE --"
                                          className="w-100 mt-2"
                                          options={getOptionsSelect()}
                                          onChange={e => handleChangeFreight(e)}
                                        />
                                      </div>
                                    )}
                                  </Col>
                                </Row>
                              </Card.Body>
                            </Card>
                          )}

                          <div className="alert alert-info alert-styled-left alert-arrow-left">
                            <div className="d-flex justify-content-between">
                              <div className="d-block w-100">
                                {product.tipo_mkt_place === '2' && (
                                  <>
                                    <h6 className="mb-0">Subtotal</h6>
                                    <h6 className="mb-0">
                                      <span>Frete</span>{' '}
                                      {descFrete !== '' && (
                                        <small
                                          className="text-uppercase"
                                          style={{ fontSize: 9 }}
                                        >
                                          {descFrete}
                                        </small>
                                      )}
                                    </h6>
                                    <hr className="mt-1 mb-1" />
                                  </>
                                )}
                                <h6 className="mb-0">Totalização</h6>
                              </div>
                              <div className="d-block w-100">
                                {product.tipo_mkt_place === '2' && (
                                  <>
                                    <h6 className="alert-link mb-0 text-right">
                                      {formatCurrencyValue(subTotal)}
                                    </h6>
                                    <h6 className="alert-link mb-0 text-right">
                                      {formatCurrencyValue(frete)}
                                    </h6>
                                    <hr className="mt-1 mb-1" />
                                  </>
                                )}
                                <h6 className="alert-link mb-0 text-right">
                                  {formatCurrencyValue(total)}
                                </h6>
                              </div>
                            </div>
                          </div>

                          <div className="d-flex justify-content-center mb-2">
                            {!isMobile ? (
                              <Button
                                variant="success"
                                size="lg"
                                className="btn btn-lg btn-success btn-block font-weight-bold btn-custom text-uppercase mb-2"
                                disabled={!(quantity > 0)}
                                onClick={handleSubmit}
                              >
                                {getAliasButton(product.eh_cobrado)}
                              </Button>
                            ) : (
                              <BottomButton
                                type="button"
                                onClick={handleSubmit}
                                disabled={!(quantity > 0)}
                                text={getAliasButton(product.eh_cobrado)}
                              />
                            )}
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Card.Body>
                </Form>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
}

Quantity.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      tag: PropTypes.string,
      afiliado: PropTypes.string,
    }),
  }).isRequired,
};
