import history from '~/services/history';
import Config from '~/config';

export function objIsNull(obj) {
  return Object.keys(obj).length;
}

export function toUpperCase(data) {
  const r = data.replace('-', ' ');
  return r.toLowerCase().replace(/(?:^|\s)\S/g, a => {
    return a.toUpperCase();
  });
}

export function adjustTopApplication() {
  document.querySelector('body').classList.add('p-0');
}

export function replaceSpecialChars(str) {
  return str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/([^\w]+|\s+)/g, '-')
    .replace(/\-\-+/g, '-') //eslint-disable-line
    .replace(/(^-+|-+$)/, '')
    .toLowerCase();
}

export function getAvatar(avatar) {
  if (avatar === '1' || avatar === '0') {
    return null;
  }
  const file = avatar.split('/');
  return file[file.length - 1];
}

export function ehCobrado(evento) {
  if (
    evento.ing_grat1 === '2' ||
    evento.ing_grat2 === '2' ||
    evento.ing_grat3 === '2'
  ) {
    return true;
  }
  return false;
}

export function formatCurrencyValue(value) {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
    maximumFractionDigits: 3,
    // maximumSignificantDigits: 2,
  }).format(value);
}

export function formatPercentValue(value) {
  return new Intl.NumberFormat('pt-BR', {
    style: 'percent',
  }).format(value);
}

export function buildQueryString(objeto, strComplemento) {
  const arrFiltro = [];
  let s = '';

  // eslint-disable-next-line no-restricted-syntax
  for (const k in objeto) {
    if (objeto[k] || objeto[k] === 0) {
      if (typeof objeto[k] === 'object') {
        if (objeto[k].length) {
          arrFiltro.push(`${k}:${objeto[k].join('')}`);
        }
      } else {
        arrFiltro.push(`${k}:${objeto[k]}`);
      }
    }
  }

  if (arrFiltro.length) {
    s = `?q=(${arrFiltro.join(',')})`;
  }

  if (strComplemento !== '' && strComplemento !== undefined) {
    s = s === '' ? strComplemento : `${s}&${strComplemento}`;
  }

  return s;
}

export function redirectLogin(user) {
  switch (user.tipo_eve) {
    case '1':
      history.push(`/eventos/${user.cod_evento}/editar`);
      break;
    case '2':
      history.push(`/cursos/${user.cod_evento}/editar`);
      break;
    case '3':
      history.push(`/produtos/${user.cod_produto}/editar`);
      break;
    default:
      if (user.eh_vendedor) {
        history.push('/dashboard');
      } else if (user.eh_comprador) {
        history.push('/inventario');
      } else if (user.eh_afiliado) {
        history.push('/afiliacoes');
      }
      break;
  }

  return user;
}

export function youTubeGetID(url) {
  let id = '';
  url = url
    .replace(/(>|<)/gi, '')
    .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  if (url[2] !== undefined) {
    // eslint-disable-next-line
    id = url[2].split(/[^0-9a-z_\-]/i);
    // eslint-disable-next-line prefer-destructuring
    id = id[0];
  } else {
    id = url;
  }
  return id;
}

export function vimeoGetID(url) {
  url.match(
    // eslint-disable-next-line
    /(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/
  );

  let type = '';
  if (RegExp.$3.indexOf('vimeo') > -1) {
    type = 'vimeo';
  }

  const obj = {
    type,
    id: RegExp.$6,
  };

  return obj.id;
}

export function getUrlImg(img) {
  const url = `http://api.psicomart.com.br/img.php?e=1&c=${img}`;
  return url;
}

export function ambient(local) {
  if (local) {
    const url =
      process.env.NODE_ENV === 'development'
        ? Config.URL_APP_DEVELOPMENT
        : Config.URL_APP_PRODUCTION;
    return url;
  }

  const url =
    process.env.NODE_ENV === 'development'
      ? Config.URL_DEVELOPMENT
      : Config.URL_PRODUCTION;

  return url;
}

export function parcels() {
  const parcel = [
    { value: '1', label: '1 parcela' },
    {
      value: '2',
      label: '2 parcelas',
    },
    {
      value: '3',
      label: '3 parcelas',
    },
    {
      value: '4',
      label: '4 parcelas',
    },
    {
      value: '5',
      label: '5 parcelas',
    },
    {
      value: '6',
      label: '6 parcelas',
    },
    // {
    //   value: '7',
    //   label: '7 parcelas',
    // },
    // {
    //   value: '8',
    //   label: '8 parcelas',
    // },
    // {
    //   value: '9',
    //   label: '9 parcelas',
    // },
    // {
    //   value: '10',
    //   label: '10 parcelas',
    // },
    // {
    //   value: '11',
    //   label: '11 parcelas',
    // },
    // {
    //   value: '12',
    //   label: '12 parcelas',
    // },
  ];
  return parcel;
}

export function months() {
  return [
    { value: '01', label: '01 - Janeiro' },
    { value: '02', label: '02 - Fevereiro' },
    { value: '03', label: '03 - Março' },
    { value: '04', label: '04 - Abril' },
    { value: '05', label: '05 - Maio' },
    { value: '06', label: '06 - Junho' },
    { value: '07', label: '07 - Julho' },
    { value: '08', label: '08 - Agosto' },
    { value: '09', label: '09 - Setembro' },
    { value: '10', label: '10 - Outubro' },
    { value: '11', label: '11 - Novembro' },
    { value: '12', label: '12 - Dezembro' },
  ];
}

export function years() {
  return [
    { value: '2020', label: '2020' },
    { value: '2021', label: '2021' },
    { value: '2022', label: '2022' },
    { value: '2023', label: '2023' },
    { value: '2024', label: '2024' },
    { value: '2025', label: '2025' },
    { value: '2026', label: '2026' },
    { value: '2027', label: '2027' },
    { value: '2028', label: '2028' },
    { value: '2029', label: '2029' },
    { value: '2030', label: '2030' },
  ];
}

export function bank() {
  return [
    { value: 'Banco do Brasil', label: 'Banco do Brasil' },
    { value: 'Bradesco', label: 'Bradesco' },
    { value: 'Caixa Econômica', label: 'Caixa Econômica' },
    { value: 'Itaú', label: 'Itaú' },
    { value: 'Inter', label: 'Inter' },
    { value: 'Santander', label: 'Santander' },
    { value: 'Banrisul', label: 'Banrisul' },
    { value: 'Sicredi', label: 'Sicredi' },
    { value: 'Sicoob', label: 'Sicoob' },
    { value: 'Inter', label: 'Banco Inter' },
    { value: 'BRB', label: 'BRB' },
    { value: 'PagSeguro', label: 'PagSeguro' },
    { value: 'Neon', label: 'Neon' },
    { value: 'Safra', label: 'Safra' },
    { value: 'Banco Original', label: 'Banco Original' },
    { value: 'Nubank', label: 'Nubank' },
  ];
}

export function shortStateSearch(longstate) {
  switch (longstate) {
    case 'AC':
      return 'Acre';
    case 'AL':
      return 'Alagoas';
    case 'AP':
      return 'Amapá';
    case 'AM':
      return 'Amazonas';
    case 'BA':
      return 'Bahia';
    case 'CE':
      return 'Ceará';
    case 'DF':
      return 'Distrito Federal';
    case 'ES':
      return 'Espírito Santo';
    case 'GO':
      return 'Goiás';
    case 'MA':
      return 'Maranhão';
    case 'MT':
      return 'Mato Grosso';
    case 'MS':
      return 'Mato Grosso do Sul';
    case 'MG':
      return 'Minas Gerais';
    case 'PA':
      return 'Pará';
    case 'PB':
      return 'Paraíba';
    case 'PR':
      return 'Paraná';
    case 'PE':
      return 'Pernambuco';
    case 'PI':
      return 'Piauí';
    case 'RJ':
      return 'Rio de Janeiro';
    case 'RN':
      return 'Rio Grande do Norte';
    case 'RS':
      return 'Rio Grande do Sul';
    case 'RO':
      return 'Rondônia';
    case 'RR':
      return 'Roraima';
    case 'SC':
      return 'Santa Catarina';
    case 'SP':
      return 'São Paulo';
    case 'SE':
      return 'Sergipe';
    case 'TO':
      return 'Tocantins';

    default:
      return '';
  }
}

export function state() {
  return [
    { value: 'Acre', label: 'Acre' },
    { value: 'Alagoas', label: 'Alagoas' },
    { value: 'Amapá', label: 'Amapá' },
    { value: 'Amazonas', label: 'Amazonas' },
    { value: 'Bahia', label: 'Bahia' },
    { value: 'Ceará', label: 'Ceará' },
    { value: 'Distrito Federal', label: 'Distrito Federal' },
    { value: 'Espírito Santo', label: 'Espírito Santo' },
    { value: 'Goiás', label: 'Goiás' },
    { value: 'Maranhão', label: 'Maranhão' },
    { value: 'Mato Grosso', label: 'Mato Grosso' },
    { value: 'Mato Grosso do Sul', label: 'Mato Grosso do Sul' },
    { value: 'Minas Gerais', label: 'Minas Gerais' },
    { value: 'Pará', label: 'Pará' },
    { value: 'Paraíba', label: 'Paraíba' },
    { value: 'Paraná', label: 'Paraná' },
    { value: 'Pernambuco', label: 'Pernambuco' },
    { value: 'Piauí', label: 'Piauí' },
    { value: 'Rio de Janeiro', label: 'Rio de Janeiro' },
    { value: 'Rio Grande do Norte', label: 'Rio Grande do Norte' },
    { value: 'Rio Grande do Sul', label: 'Rio Grande do Sul' },
    { value: 'Rondônia', label: 'Rondônia' },
    { value: 'Roraima', label: 'Roraima' },
    { value: 'Santa Catarina', label: 'Santa Catarina' },
    { value: 'São Paulo', label: 'São Paulo' },
    { value: 'Sergipe', label: 'Sergipe' },
    { value: 'Tocantins', label: 'Tocantins' },
  ];
}

export function join(array) {
  const data = array.map(value => {
    return value.id;
  });

  return data.join('|');
}
