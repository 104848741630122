/* eslint-disable react/no-danger */

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import { Row, Col } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';

import Step from '@material-ui/core/Step';
import Stepper from '@material-ui/core/Stepper';
import StepLabel from '@material-ui/core/StepLabel';

import * as ProductAction from '~/store/modules/product/actions';

import Cart from './cart';
import Payment from './payment';
import Finished from './finished';
import Loading from '~/components/Loading';

import { adjustTopApplication } from '~/util';

function CartPurchase({ tag }) {
  const dispatch = useDispatch();

  const [boleto, setBoleto] = useState(null);
  const [activeStep, setActiveStep] = useState(1);

  const product = useSelector(state => state.product.product);
  const loading = useSelector(state => state.product.loading);

  useEffect(() => {
    adjustTopApplication();
    dispatch(ProductAction.productsGetByTagRequest(tag));
  }, [dispatch, tag]);

  function getSteps() {
    return ['PRODUTO', 'AUTENTICAÇÃO', 'PAGAMENTO'];
  }

  const steps = getSteps();
  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 1:
        return <Cart next={handleNext} product={product} />;
      case 2:
        return (
          <Payment next={handleNext} product={product} setBoleto={setBoleto} />
        );
      default:
        return <Finished product={product} boleto={boleto} />;
    }
  }

  return (
    <div className="content psico-stepper">
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        orientation={isMobile ? `vertical` : `horizontal`}
      >
        {steps.map(label => (
          <Step key={label} style={{ marginBottom: 10 }}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {loading ? (
        <Loading />
      ) : (
        <Row>
          <Col xl={4}>
            <h5 className="font-weight-bold text-muted">DADOS DO PRODUTO</h5>
            <h2>{product.nome_produto}</h2>
            <p
              style={{ fontSize: 12 }}
              dangerouslySetInnerHTML={{ __html: product.desc_produto }}
            />
          </Col>
          <Col xl={8}>{getStepContent(activeStep)}</Col>
        </Row>
      )}
    </div>
  );
}

CartPurchase.propTypes = {
  tag: PropTypes.string.isRequired,
};

export default CartPurchase;
