import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import * as ShoppingActions from './actions';

export function* shoppingGetRequest() {
  try {
    const response = yield call(api.get, `/inscricao/listar-minhas-compras`);
    if (response.data.records.error) {
      toast.error(response.data.records.msg);
      yield put(ShoppingActions.shoppingGetFailure());
      return;
    }
    yield put(ShoppingActions.shoppingGetSuccess(response.data.records));
  } catch (err) {
    toast.error('Falha na comunicação com servidor!');
    yield put(ShoppingActions.shoppingGetFailure());
  }
}

export default all([
  takeLatest('@shopping/GET_SHOPPING_REQUEST', shoppingGetRequest),
]);
