import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import history from '~/services/history';
import api from '~/services/api';
import { redirectLogin } from '~/util';

import { signInSuccess, signFailure } from './actions';
import * as LayoutActions from '~/store/modules/layout/actions';

export function* signIn({ payload }) {
  try {
    const { userField, passwdField, site } = payload;

    const response = yield call(api.post, 'auth', {
      userField,
      passwdField,
      site,
    });

    if (response.data.records.error) {
      toast.error(response.data.records.msg);
      yield put(signFailure());
      return;
    }

    const { menu, token, ...user } = response.data.records;

    api.defaults.headers.Authorization = `Bearer ${token}`;

    yield put(signInSuccess(token, user));
    yield put(LayoutActions.menusGetSuccess(menu));

    if (!user.eh_vendedor) {
      history.push(`/inventario`);
    }

    redirectLogin(user);
  } catch (err) {
    toast.error('Falha na comunicação com servidor, contate o administrador!');
    yield put(signFailure());
  }
}

export function* signInModal({ payload }) {
  try {
    const { userField, passwdField, site } = payload;

    const response = yield call(api.post, 'auth', {
      userField,
      passwdField,
      site,
    });

    if (response.data.records.error) {
      toast.error(response.data.records.msg);
      yield put(signFailure());
      return;
    }
    toast.success('Login efetuado com sucesso!');

    const { token, ...user } = response.data.records;

    api.defaults.headers.Authorization = `Bearer ${token}`;

    yield put(signInSuccess(token, user));
  } catch (err) {
    toast.error('Falha na comunicação com servidor, contate o administrador!');
    yield put(signFailure());
  }
}

export function* signUp({ payload }) {
  try {
    const { name, email, password } = payload;

    yield call(api.post, 'users', {
      name,
      email,
      password,
    });

    history.push('/');
  } catch (err) {
    toast.error('Falha no cadastro, verifique seus dados!');
    yield put(signFailure());
  }
}

export function setToken({ payload }) {
  if (!payload) return;
  const { token } = payload.auth;
  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
  }
}

export function signOut() {
  history.push('/');
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
  takeLatest('@auth/SIGN_IN_MODAL_REQUEST', signInModal),
  takeLatest('@auth/SIGN_UP_REQUEST', signUp),
  takeLatest('@auth/SIGN_OUT', signOut),
]);
