import produce from 'immer';

const INITIAL_STATE = {
  id: 0,
  query: '',
  module: {},
  modules: [],
  loading: true,
};

export default function modules(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@modules/GET_MODULES_BY_COURSE_REQUEST': {
        draft.id = action.payload;
        draft.loading = true;
        break;
      }
      case '@modules/GET_MODULE_REQUEST': {
        draft.query = action.payload;
        draft.loading = true;
        break;
      }
      case '@modules/GET_MODULES_BY_COURSE_SUCCESS': {
        draft.loading = false;
        draft.modules = action.payload;
        break;
      }
      case '@modules/GET_MODULE_SUCCESS': {
        draft.module = action.payload;
        draft.loading = false;
        break;
      }
      case '@modules/CLEAR_MODULE_REQUEST': {
        draft.id = 0;
        draft.module = {};
        draft.loading = false;
        break;
      }
      case '@modules/GET_MODULE_FAILURE':
      case '@modules/GET_MODULES_BY_COURSE_FAILURE': {
        draft.loading = false;
        break;
      }
      default:
    }
  });
}
