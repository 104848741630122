/* eslint-disable no-console */

import React from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';
import PropTypes from 'prop-types';
import { Form, Input } from '@rocketseat/unform';
import { Row, Col, Card, Badge } from 'react-bootstrap';

import history from '~/services/history';

import noImage from '~/assets/img/sem.png';

export default function Search(props) {
  const { match } = props;
  const { location } = props;

  navigator.geolocation.getCurrentPosition(local => {
    console.log(local);
  });

  function handleSubmit(query) {
    history.push(`/eventos/pesquisar/${query.city}?${query.search}`);
  }

  return (
    <div className="content padding-tab-top-post">
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <h5 className="mb-3">Pesquisar evento</h5>
              <Form
                onSubmit={handleSubmit}
                initialData={{
                  search: location.search.substr(1),
                  city: match.params.city,
                }}
                className="mt-3"
              >
                <div className="form-row justify-content-md-center">
                  <div className="col-md-4">
                    <div className="form-group form-group-feedback form-group-feedback-right">
                      <Input
                        type="text"
                        className="form-control"
                        placeholder="Pesquisa por evento"
                        name="search"
                      />
                      <div className="form-control-feedback form-control-feedback-sm">
                        <i className="icon-search4 text-muted"></i>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group form-group-feedback form-group-feedback-right">
                      <Input
                        type="text"
                        className="form-control"
                        placeholder="Pesquisa por cidade"
                        name="city"
                      />
                      <div className="form-control-feedback form-control-feedback-sm">
                        <i className="icon-pin-alt text-muted"></i>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-1">
                    <button
                      className="btn btn-dark text-monospace"
                      type="submit"
                    >
                      Procurar
                    </button>
                  </div>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <h5>4 eventos encontrados</h5>
        </Col>
        <Col md={3}>
          <Link to="/eventos" className="text-dark">
            <Card className="events">
              <Card.Img
                style={{ maxHeight: 180, height: 180 }}
                variant="top"
                src={noImage}
              />
              <Card.Body className="d-flex align-items-center p-0 mt-n1">
                <Badge variant="dark">
                  <h3 className="font-weight-bold text-uppercase mb-0">
                    {format(new Date('2020-02-20'), 'dd', {
                      locale: pt,
                    })}{' '}
                    {format(new Date('2020-02-20'), 'MMM', {
                      locale: pt,
                    })}
                  </h3>
                </Badge>

                <div
                  className="text-center text-uppercase w-100 mt-1"
                  style={{ color: '#707070' }}
                >
                  <i className="icon-location3"></i>
                  Uberlândia-MG
                </div>
                {/* <div>
                        <i className="icon-alarm"></i>
                        {format(new Date(event.dt_eve), 'HH:mm')}
                      </div> */}
              </Card.Body>
            </Card>
          </Link>
        </Col>
        <Col md={3}>
          <Link to="/eventos" className="text-dark">
            <Card className="events">
              <Card.Img
                style={{ maxHeight: 180, height: 180 }}
                variant="top"
                src={noImage}
              />
              <Card.Body className="d-flex align-items-center p-0 mt-n1">
                <Badge variant="dark">
                  <h3 className="font-weight-bold text-uppercase mb-0">
                    {format(new Date('2020-02-20'), 'dd', {
                      locale: pt,
                    })}{' '}
                    {format(new Date('2020-02-20'), 'MMM', {
                      locale: pt,
                    })}
                  </h3>
                </Badge>

                <div
                  className="text-center text-uppercase w-100 mt-1"
                  style={{ color: '#707070' }}
                >
                  <i className="icon-location3"></i>
                  Uberlândia-MG
                </div>
                {/* <div>
                        <i className="icon-alarm"></i>
                        {format(new Date(event.dt_eve), 'HH:mm')}
                      </div> */}
              </Card.Body>
            </Card>
          </Link>
        </Col>
        <Col md={3}>
          <Link to="/eventos" className="text-dark">
            <Card className="events">
              <Card.Img
                style={{ maxHeight: 180, height: 180 }}
                variant="top"
                src={noImage}
              />
              <Card.Body className="d-flex align-items-center p-0 mt-n1">
                <Badge variant="dark">
                  <h3 className="font-weight-bold text-uppercase mb-0">
                    {format(new Date('2020-02-20'), 'dd', {
                      locale: pt,
                    })}{' '}
                    {format(new Date('2020-02-20'), 'MMM', {
                      locale: pt,
                    })}
                  </h3>
                </Badge>

                <div
                  className="text-center text-uppercase w-100 mt-1"
                  style={{ color: '#707070' }}
                >
                  <i className="icon-location3"></i>
                  Uberlândia-MG
                </div>
                {/* <div>
                        <i className="icon-alarm"></i>
                        {format(new Date(event.dt_eve), 'HH:mm')}
                      </div> */}
              </Card.Body>
            </Card>
          </Link>
        </Col>
        <Col md={3}>
          <Link to="/eventos" className="text-dark">
            <Card className="events">
              <Card.Img
                style={{ maxHeight: 180, height: 180 }}
                variant="top"
                src={noImage}
              />
              <Card.Body className="d-flex align-items-center p-0 mt-n1">
                <Badge variant="dark">
                  <h3 className="font-weight-bold text-uppercase mb-0">
                    {format(new Date('2020-02-20'), 'dd', {
                      locale: pt,
                    })}{' '}
                    {format(new Date('2020-02-20'), 'MMM', {
                      locale: pt,
                    })}
                  </h3>
                </Badge>

                <div
                  className="text-center text-uppercase w-100 mt-1"
                  style={{ color: '#707070' }}
                >
                  <i className="icon-location3"></i>
                  Uberlândia-MG
                </div>
                {/* <div>
                        <i className="icon-alarm"></i>
                        {format(new Date(event.dt_eve), 'HH:mm')}
                      </div> */}
              </Card.Body>
            </Card>
          </Link>
        </Col>
      </Row>
    </div>
  );
}

Search.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      city: PropTypes.string,
    }).isRequired,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};
