import React from 'react';
import PropTypes from 'prop-types';

function WhatsAppButton({ phone }) {
  const number = phone.replace(/[^\w\s]/gi, '').replace(/ /g, '');
  return (
    <a
      rel="noopener noreferrer"
      target="_blank"
      href={`https://api.whatsapp.com/send?phone=55${number}`}
      className="btn btn-outline bg-slate-600 text-slate-600 border-slate-600 btn-custom text-uppercase mr-2 mt-1"
    >
      <i className="icon-phone mr-1" /> Fale com ele
    </a>
  );
}

WhatsAppButton.propTypes = {
  phone: PropTypes.string.isRequired,
};

export default WhatsAppButton;
