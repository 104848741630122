import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { MdCheck } from 'react-icons/md';

import api from '~/services/api';

export default function ButtonCheckin({ data, check }) {
  const [checkin, setCheckin] = useState(check);

  async function handleCheckin() {
    api.post('/participante/salvar-checkin', {
      cod_evento: data.cod_evento,
      cod_evento_cad_part: data.cod_evento_cad_part,
    });
    if (checkin > 0) {
      setCheckin(0);
    } else {
      setCheckin(1);
    }
  }

  return (
    <>
      {checkin > 0 ? (
        <Button
          variant="light"
          size="sm"
          title="Checkin realizado"
          onClick={handleCheckin}
        >
          <MdCheck size={20} />
        </Button>
      ) : (
        <Button
          variant="primary"
          size="sm"
          title="Realizar Checkin"
          onClick={handleCheckin}
        >
          Checkin
        </Button>
      )}
    </>
  );
}
