import React from 'react';
import Prototype from 'prop-types';

import Header from '~components/Layout/Header';
import Footer from '~components/Layout/Footer';

import { Wrapper } from './styles';

export default function DefaultLayout({ children }) {
  return (
    <Wrapper>
      <Header />
      <div className="page-content mt-3">
        <div className="content-wrapper mt-3">{children}</div>
      </div>
      <Footer />
    </Wrapper>
  );
}

DefaultLayout.propTypes = {
  children: Prototype.element.isRequired,
};
