import { combineReducers } from 'redux';

import auth from './auth/reducer';
import user from './user/reducer';
import mods from './mods/reducer';
import group from './group/reducer';
import interest from './interest/reducer';
import cases from './case/reducer';
import event from './event/reducer';
import product from './product/reducer';
import cart from './cart/reducer';
import shopping from './shopping/reducer';
import freight from './freight/reducer';
import course from './courses/reducer';
import classes from './classes/reducer';
import students from './students/reducer';
import modules from './modules/reducer';
import layouts from './layout/reducer';
import inventory from './inventory/reducer';
import affiliations from './affiliations/reducer';

export default combineReducers({
  auth,
  user,
  mods,
  group,
  interest,
  cases,
  event,
  product,
  cart,
  shopping,
  freight,
  course,
  classes,
  students,
  modules,
  layouts,
  inventory,
  affiliations,
});
