import React, { Component } from 'react';
import { uniqueId } from 'lodash';
import filesize from 'filesize';

import api from '~/services/api';

import { Content } from './styles';

import Upload from '~/components/Upload';
import FileArq from '~/components/FileArq';

const { auth } = JSON.parse(localStorage.getItem('persist:psico.in'));
const { token } = JSON.parse(auth);

class App extends Component {
  state = {
    uploadedFiles: [],
  };

  async componentDidMount() {
    const { id } = this.props;
    const response = await api.get(
      `/produto/listar-produto-arq/?q=(cod_produto:${id})`
    );

    this.setState({
      uploadedFiles: response.data.records.map(file => {
        const url = `http://api.psicomart.com.br/v1/1090/rs/export/get-arq/?q=(ken:${token},arq:${file.arq_prod},e:1)`;
        return {
          id: file.cod_produto_arq,
          name: file.arq_prod,
          preview: file.arq_prod,
          uploaded: true,
          url,
        };
      }),
    });
  }

  componentWillUnmount() {
    this.state.uploadedFiles.forEach(file => URL.revokeObjectURL(file.preview));
  }

  handleUpload = files => {
    const uploadedFiles = files.map(file => ({
      file,
      id: uniqueId(),
      name: file.name,
      readableSize: filesize(file.size),
      preview: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
      url: null,
    }));

    this.setState({
      uploadedFiles: this.state.uploadedFiles.concat(uploadedFiles),
    });

    uploadedFiles.forEach(this.processUpload);
  };

  updateFile = (id, data) => {
    this.setState({
      uploadedFiles: this.state.uploadedFiles.map(uploadedFile => {
        return id === uploadedFile.id
          ? { ...uploadedFile, ...data }
          : uploadedFile;
      }),
    });
  };

  processUpload = uploadedFile => {
    const data = new FormData();
    const { id } = this.props;

    data.append('arquivo1', uploadedFile.file, uploadedFile.name);
    data.append('cod_produto', id);

    api
      .post('/produto/enviar-arq-produto', data, {
        onUploadProgress: e => {
          const progress = parseInt(Math.round((e.loaded * 100) / e.total));

          this.updateFile(uploadedFile.id, {
            progress,
          });
        },
      })
      .then(response => {
        if (response.data.records.error) {
          this.updateFile(uploadedFile.id, {
            error: true,
          });
          return;
        }

        this.updateFile(uploadedFile.id, {
          uploaded: true,
          id: response.data.records.cod_produto_arq,
          url: `http://api.psicomart.com.br/v1/1090/rs/export/get-arq/?q=(ken:${token},arq:${response.data.records.arq_name},e:1)`,
          name: response.data.records.arq_name,
        });
      })
      .catch(() => {
        this.updateFile(uploadedFile.id, {
          error: true,
        });
      });
  };

  handleDelete = async id => {
    await api.put(`/produto/cancelar-produto-arq/${id}`);

    this.setState({
      uploadedFiles: this.state.uploadedFiles.filter(file => file.id !== id),
    });
  };

  render() {
    const { uploadedFiles } = this.state;

    return (
      <Content>
        <Upload
          upMessage="Arraste um arquivo ou imagem, ou clique aqui para enviar"
          accept="application/*"
          onUpload={this.handleUpload}
        />
        {!!uploadedFiles.length && (
          <FileArq files={uploadedFiles} onDelete={this.handleDelete} />
        )}
      </Content>
    );
  }
}

export default App;
