import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { Row, Col, Card, Button, Table, Badge, Alert } from 'react-bootstrap';
import { Form } from '@unform/web';
import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';

import { toast } from 'react-toastify';
import api from '~/services/api';
import history from '~/services/history';
import { objIsNull } from '~/util';

import Loading from '~/components/Loading';
import Select from '~/components/InputSelect';
import ModalProfile from '~/components/ModalProfile';

export default function Customers() {
  const formRef = useRef(null);
  const [products, setProducts] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [product, setProduct] = useState({});
  const [status, setStatus] = useState(true);
  const [load, setLoad] = useState(false);
  const [loadP, setLoadP] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const loadProducts = useCallback(() => {
    setLoad(true);
    api.get('/produto/meus-produtos').then(response => {
      if (response.data.records.error) {
        toast.error(response.data.records.msg);
        setLoad(false);
        return;
      }
      setProducts(response.data.records);
      setLoad(false);
    });
  }, []);

  useEffect(() => {
    loadProducts();
  }, [loadProducts]);

  const options = itens => {
    const data = itens.map(value => {
      return {
        value: value.cod_produto,
        label: value.nome_produto,
        type: value.tipo_mkt_place,
      };
    });
    return data;
  };

  const handleSubmit = useCallback(async data => {
    if (data.product) {
      setLoadP(true);
      api
        .get(
          `/inscricao/listar-cliente-produto/?q=(cod_produto:${data.product})`
        )
        .then(response => {
          if (response.data.records.error) {
            toast.error(response.data.records.msg);
            setLoadP(false);
            return;
          }
          setCustomers(response.data.records);
          setLoadP(false);
        });
    }
  }, []);

  const handleChange = useCallback(data => {
    if (data) {
      const { type } = data;
      setStatus(true);
      if (type === '3') {
        setStatus(false);
      }
    }
  }, []);

  const handleShow = useCallback(data => {
    setProduct(data);
    setModalShow(true);
  }, []);

  useMemo(() => {
    if (objIsNull(products)) {
      const prod = products[products.length - 1];
      if (prod.tipo_mkt_place === '3') {
        setStatus(false);
      }
      handleSubmit({ product: prod.cod_produto });
    }
  }, [products, handleSubmit]);

  return (
    <div className="content padding-tab-top-post">
      <Row>
        <Col>
          <div className="d-flex justify-content-between align-items-center">
            <div className="mb-3">
              <h3 className="mb-0 font-weight-semibold">
                <i
                  role="presentation"
                  style={{ fontSize: '1.5rem' }}
                  onClick={() => history.goBack()}
                  className="icon-arrow-left52 mr-2 c-pointer"
                ></i>
                Meus Clientes
              </h3>
              <span className="text-muted d-block">
                Gerenciar meus clientes
              </span>
            </div>
          </div>
        </Col>
        <Col xl={12}>
          <Card>
            <Card.Body>
              <Form
                ref={formRef}
                onSubmit={handleSubmit}
                initialData={{
                  product:
                    products.length &&
                    products[products.length - 1].cod_produto,
                }}
              >
                <Row>
                  <Col>
                    <div className="form-group">
                      <label>Produto</label>
                      {load ? (
                        <Loading />
                      ) : (
                        <Select
                          name="product"
                          placeholder="Selecione um produto"
                          options={options(products)}
                          onChange={handleChange}
                        />
                      )}
                    </div>
                  </Col>
                  <Col>
                    <div className="form-group">
                      <label>Status assinatura</label>
                      {load ? (
                        <Loading />
                      ) : (
                        <Select
                          isClearable
                          isDisabled={status}
                          name="status"
                          placeholder="Selecione"
                          options={[
                            { value: 1, label: 'Ativo' },
                            { value: 2, label: 'Cancelado' },
                          ]}
                        />
                      )}
                    </div>
                  </Col>
                  <Col xl={12}>
                    <Button type="submit" variant="primary">
                      Filtrar
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          {loadP ? (
            <Loading />
          ) : (
            <Card>
              <Card.Body>
                {customers.length < 1 ? (
                  <Alert variant="info" className="mb-0 border-0">
                    <i className="fa fa-exclamation-triangle mr-2"></i>
                    Você ainda não possui clientes para este produto. Publique-o
                    e divulgue o link para suas redes.
                  </Alert>
                ) : (
                  <Table striped hover>
                    <tbody>
                      {customers.map(customer => (
                        <tr key={customer.cod_cob_sub_conta}>
                          <td>
                            <div className="d-flex flex-column">
                              <a href="#/">{customer.nome_membro}</a>
                              <small className="text-muted">
                                {customer.email_membro}
                              </small>
                            </div>
                          </td>
                          <td>
                            <div className="d-flex flex-column">
                              <small className="text-muted">Telefone</small>
                              {customer.telefone}
                            </div>
                          </td>
                          <td>
                            <div className="d-flex flex-column">
                              <small className="text-muted">Data compra</small>
                              {format(parseISO(customer.dt_cadastro), 'PPpp', {
                                locale: pt,
                              })}
                            </div>
                          </td>
                          <td>
                            <div className="d-flex flex-column">
                              <small className="text-muted">Assinatura</small>
                              <div>
                                {customer.tipo_mkt_place === '3' ? (
                                  <Badge variant="success">Ativo</Badge>
                                ) : (
                                  '-'
                                )}
                              </div>
                            </div>
                          </td>
                          <td className="text-right">
                            <Button
                              variant="primary"
                              size="sm"
                              onClick={() => handleShow(customer)}
                            >
                              Visualizar
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
              </Card.Body>
            </Card>
          )}
        </Col>
      </Row>
      <ModalProfile
        modalShow={modalShow}
        modalClose={setModalShow}
        data={product}
      />
    </div>
  );
}
