/* eslint-disable react/prop-types */

import React from 'react';
import { Container, Card } from 'react-bootstrap';

import Cart from '~/components/CartPurchase';
import Wizard from '~/components/WizardPurchase';

export default function Purchase({ match }) {
  const { type, tag } = match.params;
  return (
    <div className="content mt-5">
      <Container>
        <Card className="mt-2">
          <Card.Body className="box-wizard">
            {type === 'produto' ? (
              <Cart tag={tag} />
            ) : (
              <Wizard type={type} tag={tag} />
            )}
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
}
