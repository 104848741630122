import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import * as AffiliationsActions from './actions';

export function* affiliationsGet() {
  try {
    const response = yield call(api.get, `/afiliacao/listar-afiliado-usu`);
    if (response.data.records.error) {
      toast.error(response.data.records.msg);
      yield put(AffiliationsActions.affiliationsGetFailure());
    }
    yield put(
      AffiliationsActions.affiliationsGetSuccess(response.data.records)
    );
  } catch (err) {
    toast.error('Falha na comunicação com servidor!');
    yield put(AffiliationsActions.affiliationsGetFailure());
  }
}

export function* affiliationGetById({ payload }) {
  try {
    const response = yield call(
      api.get,
      `/afiliacao/listar-info-afiliado-usu/?q=(cod_afiliacao:${payload})`
    );
    if (response.data.records.error) {
      toast.error(response.data.records.msg);
      yield put(AffiliationsActions.affiliationGetByIdFailure());
    }
    yield put(
      AffiliationsActions.affiliationGetByIdSuccess(response.data.records)
    );
  } catch (err) {
    toast.error('Falha na comunicação com servidor!');
    yield put(AffiliationsActions.affiliationGetByIdFailure());
  }
}

export default all([
  takeLatest('@affiliations/GET_AFFILIATION_REQUEST', affiliationsGet),
  takeLatest('@affiliations/GET_AFFILIATION_BY_ID_REQUEST', affiliationGetById),
]);
