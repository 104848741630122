import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from '@rocketseat/unform';

import api from '~/services/api';

export default function ReplayComment({ comments, loadPost }) {
  const [disabled, setDisabled] = useState(true);
  const [showResponse, setShowResponse] = useState(false);

  async function handleComment({ comment }, { resetForm }) {
    setDisabled(true);
    const response = await api.post('/post', {
      resp: '1',
      cod_post_parente: comments.cod_post_parente,
      cod_post_pai: comments.filhos ? comments.cod_post : comments.cod_post_pai,
      desc_post: comment,
      onde_post: '6',
    });
    if (!response.data.records.error) {
      loadPost();
      resetForm();
      setShowResponse(false);
    }
  }

  return (
    <>
      <li className="list-inline-item mb-1">
        <span
          className="text-muted c-pointer"
          onClick={() => setShowResponse(showResponse ? false : true)}
        >
          Responder
        </span>
      </li>

      {showResponse ? (
        <Form className="form-label-group" onSubmit={handleComment}>
          <Input
            style={{ backgroundColor: '#f5f5f5' }}
            multiline
            rows={1}
            name="comment"
            className="form-control"
            placeholder="Escreva aqui sua resposta"
            onChange={e =>
              e.target.value.length > 0 ? setDisabled(false) : setDisabled(true)
            }
          />
          <button
            disabled={disabled}
            type="submit"
            className="btn btn-sm btn-primary waves-effect waves-light mt-1"
          >
            Responder
          </button>
          <button
            onClick={() => setShowResponse(false)}
            type="button"
            className="btn btn-link waves-effect waves-light mt-1"
          >
            Cancelar
          </button>
        </Form>
      ) : (
        ''
      )}
    </>
  );
}
ReplayComment.propTypes = {
  comments: PropTypes.object,
};

ReplayComment.defaultProps = {
  comments: {},
};
