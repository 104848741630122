import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card, Badge, Table, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import history from '~/services/history';
import api from '~/services/api';
import Loading from '~/components/Loading';
import { getUrlImg, ambient } from '~/util';

import DeleteProduct from '~/components/DeleteProduct';
import CreateProduct from '~/components/CreateProduct';

import noImage from '~/assets/img/sem.png';

export default function MyProducts() {
  const [products, setProducts] = useState([]);
  const [load, setLoad] = useState(false);
  const [show, setShow] = useState(false);

  const loadProdutcs = useCallback(async () => {
    setLoad(true);
    await api.get('/produto/meus-produtos').then(response => {
      if (response.data.records.error) {
        toast.error(response.data.records.msg);
        setLoad(false);
        return;
      }
      setProducts(response.data.records);
      setLoad(false);
    });
  }, []);

  useEffect(() => {
    loadProdutcs();
  }, [loadProdutcs]);

  const statusProdutc = useCallback(
    async (data, action) => {
      if (!data.nome_produto) {
        toast.error('Produto não pode ser publicado sem um nome!');
        return;
      }
      await api.put(`/produto/${action}/${data.cod_produto}`).then(response => {
        if (response.data.records.error) {
          toast.error(response.data.records.msg);
          return;
        }
        loadProdutcs();
      });
    },
    [loadProdutcs]
  );

  const copy = useCallback(() => {
    toast.info('Link copiado com sucesso!');
  }, []);

  const urlCopy = useCallback(product => {
    const url = `${ambient('front')}/detail/produto/${product.url_amig}`;

    return url;
  }, []);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="content padding-tab-top-post">
      <Row>
        <Col>
          <div className="d-flex justify-content-between align-items-center">
            <div className="mb-3">
              <h3 className="mb-0 font-weight-semibold">
                <i
                  role="presentation"
                  style={{ fontSize: '1.5rem' }}
                  onClick={() => history.goBack()}
                  className="icon-arrow-left52 mr-2 c-pointer"
                ></i>
                Meus Produtos
              </h3>
              <span className="text-muted d-block">
                Aqui você pode gerenciar seus produtos
              </span>
            </div>
            <div>
              <button
                type="button"
                onClick={handleShow}
                className="btn btn-sm btn-info"
              >
                <i className="icon-plus3 mr-1"></i>Criar Produto
              </button>
            </div>
          </div>
        </Col>
        <Col xl={12}>
          <Card>
            <Card.Body>
              {load ? (
                <Loading />
              ) : (
                <Table striped hover>
                  <thead>
                    <tr>
                      <th colSpan={2}>Produto</th>
                      <th>Valor</th>
                      <th className="text-center">Página do Produto</th>
                      <th>Publicado</th>
                      <th className="text-center">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(products).length === 0 ? (
                      <tr>
                        <td colSpan="6" className="p-0 text-center">
                          <div className="alert alert-info border-0 alert-dismissible mb-0">
                            <i className="icon-info3 border-2 rounded-round p-1 mr-2"></i>
                            <span>
                              Caro usuário, você não cadastrou nenhum produto
                            </span>
                          </div>
                        </td>
                      </tr>
                    ) : (
                      products.map(product => (
                        <tr key={product.cod_produto}>
                          <td>
                            {product.img.length > 0 ? (
                              <img
                                width={80}
                                height={50}
                                src={getUrlImg(product.img[0].img_prod)}
                                alt={product.nome_produto}
                                className="img-preview rounded"
                              />
                            ) : (
                              <img
                                width={80}
                                height={50}
                                src={noImage}
                                alt={product.nome_produto}
                                className="img-preview rounded"
                              />
                            )}
                          </td>
                          <td>
                            <Link
                              to={`/produtos/${product.cod_produto}/editar`}
                              title="Editar produto"
                            >
                              {product.nome_produto}
                            </Link>
                          </td>
                          <td>
                            {new Intl.NumberFormat('pt-BR', {
                              style: 'currency',
                              currency: 'BRL',
                            }).format(product.por_valor)}
                          </td>
                          <td className="text-center">
                            <Link
                              to={`/detail/produto/${product.url_amig}`}
                              target="_blank"
                            >
                              <Button variant="light" size="sm">
                                <i className="icon-link text-primary"></i>
                              </Button>
                            </Link>
                          </td>
                          <td>
                            {product.status_prod === '1' && (
                              <Badge
                                className="c-pointer"
                                variant="success"
                                onClick={() =>
                                  statusProdutc(product, 'desativar')
                                }
                              >
                                Produto publicado (Despublicar)
                              </Badge>
                            )}
                            {product.status_prod !== '1' && (
                              <Badge
                                className="c-pointer"
                                variant="secondary"
                                onClick={() => statusProdutc(product, 'ativar')}
                              >
                                Produto não puclicado (Publicar)
                              </Badge>
                            )}
                          </td>
                          <td style={{ width: 170 }}>
                            <div className="d-flex justify-content-end align-items-center">
                              <Link
                                to={`/produtos/${product.cod_produto}/editar`}
                                title="Editar produto"
                              >
                                <Button variant="light" size="sm">
                                  <i className="icon-pencil5 text-primary"></i>
                                </Button>
                              </Link>

                              <div className="list-icons text-primary ml-2">
                                <div className="dropdown">
                                  <button
                                    type="button"
                                    className="btn btn-light dropdown-toggle"
                                    data-toggle="dropdown"
                                  >
                                    <i className="icon-cog6 text-primary"></i>
                                  </button>

                                  <div className="dropdown-menu dropdown-menu-right">
                                    <CopyToClipboard
                                      text={urlCopy(product)}
                                      onCopy={() => copy()}
                                    >
                                      <button
                                        type="button"
                                        className="btn btn-link dropdown-item"
                                      >
                                        <i className="icon-link"></i>
                                        Copiar link da venda
                                      </button>
                                    </CopyToClipboard>
                                    <div className="dropdown-divider"></div>
                                    {product.status_prod === '1' && (
                                      <button
                                        type="button"
                                        className="btn btn-link dropdown-item"
                                        onClick={() =>
                                          statusProdutc(product, 'desativar')
                                        }
                                      >
                                        <i className="icon-eye-blocked"></i>
                                        Despublicar
                                      </button>
                                    )}

                                    {product.status_prod !== '1' && (
                                      <button
                                        type="button"
                                        className="btn btn-link dropdown-item"
                                        onClick={() =>
                                          statusProdutc(product, 'ativar')
                                        }
                                      >
                                        <i className="icon-eye"></i>
                                        Publicar
                                      </button>
                                    )}
                                    <div className="dropdown-divider"></div>
                                    <DeleteProduct
                                      id={product.cod_produto}
                                      load={loadProdutcs}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </Table>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <CreateProduct show={show} handleClose={handleClose} />
    </div>
  );
}
