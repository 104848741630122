import React from 'react';
import PropTypes from 'prop-types';

import ButtonUpVote from '~/components/ButtonUpVote';
import ButtonDownVote from '~/components/ButtonDownVote';

export default function VoteComment({ comments, loadPost }) {
  return (
    <li className="list-inline-item">
      <span className="mr-1">{comments.nota || 0}</span>
      <ButtonUpVote post={comments} vote={loadPost} />
      <ButtonDownVote post={comments} vote={loadPost} />
    </li>
  );
}
VoteComment.propTypes = {
  comments: PropTypes.object.isRequired,
  loadPost: PropTypes.func.isRequired,
};

VoteComment.defaultProps = {
  comments: {},
};
