import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
  useRef,
} from 'react';

import PropTypes from 'prop-types';

import { Form } from '@unform/web';
import { parseISO } from 'date-fns';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Card, Button } from 'react-bootstrap';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import ReactQuill from 'react-quill';
import api from '~/services/api';

import history from '~/services/history';

import {
  eventsGetRequest,
  eventsPostRequest,
} from '~/store/modules/event/actions';

import Loading from '~/components/Loading';
import LocationEvent from '~/components/LocationEvent';
import DatePiker from '~/components/DatePicker';
import DatePikerTime from '~/components/DatePickerTime';
import Input from '~/components/InputText';
import Textarea from '~/components/InputTextarea';
import Dropzone from '~/components/Dropzone';
import ButtonPublish from '~/components/ButtonPublishEvent';
import TableTicket from '~/components/TableTicket';
import InputMask from '~/components/InputMask';
import Affiliates from '~/components/Affiliates';
import DiscountCoupons from '~/components/DiscountCoupons';
import Speakers from '~/components/Speakers';
import BoxConfirm from '~/components/BoxConfirm';
import CreateTicket from '~/components/CreateTicket';

import ModalCreateAccount from '~/components/ModalCreateAccount';

import { formatDateHour } from '~/util/format';
import { replaceSpecialChars, objIsNull } from '~/util';

import { TextEditor, AvatarInput } from './styles';

import 'react-quill/dist/quill.snow.css';

import noImage from '~/assets/img/profile.png';

function Edit(props) {
  const dispatch = useDispatch();

  const { match } = props;
  const formEditRef = useRef(null);

  const { event } = useSelector(state => state.event);
  const { loading } = useSelector(state => state.event);
  const { profile } = useSelector(state => state.user);

  const [banner, setBanner] = useState(null);
  const [htmlContent, setHtmlContent] = useState('');
  const [affiliate, setAffiliate] = useState(false);
  const [speaker, setSpeaker] = useState(false);
  const [coupons, setCoupons] = useState(false);
  const [tickets, setTickets] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [openBoxConfirm, setOpenBoxConfirm] = useState(false);
  const [showCreateAccount, setShowCreateAccount] = useState(false);
  const [isNewEvent, setIsNewEvent] = useState(null);
  const [typeTicket, setTypeTicket] = useState(null);
  const [paid, setPaid] = useState(null);
  const [showCreateTicket, setShowCreateTicket] = useState(false);
  const [newEventTicket, setNewEventTicket] = useState(null);
  const [showConfirmSaveBox, setShowConfirmSaveBox] = useState(false);
  const [avatarOrganizer, setAvatarOrganizer] = useState(
    event.img_organiz ? event.img_organiz : profile.img_perfil
  );
  const ticketTypesArray = {
    null: 'Crie seu ingresso',
    true: 'Ingresso pago',
    false: 'Ingresso grátis',
  };

  const handleCreateTicketClose = useCallback(
    data => {
      setNewEventTicket(data);
    },
    [setNewEventTicket]
  );

  const loadEvent = useCallback(
    id => {
      dispatch(eventsGetRequest(id));
      setBanner(null);
      setIsNewEvent(event.is_new);
    },
    [dispatch, event.is_new]
  );

  async function publicEvent(data, action) {
    if (!data.titulo_eve) {
      toast.error('Evento não pode ser publicado sem um título!');
      return;
    }

    if (!data.dt_eve) {
      toast.error('Evento não pode ser publicado sem a data!');
      return;
    }

    const response = await api.put(`/evento/${action}/${data.cod_evento}`);
    if (response.data.records.error) {
      if (response.data.records.error_cc) {
        setOpenBoxConfirm(true);
        return;
      }
      toast.error(response.data.records.msg);
      return;
    }

    loadEvent(match.params.id);
    toast.success('Evento publicado com sucesso!');

    // eslint-disable-next-line no-unused-expressions
    isNewEvent && history.push('/eventos');
  }

  const handleTypeTicket = typed => {
    if (typed === 1) {
      setTypeTicket(1);
    } else {
      setTypeTicket(2);
    }
    setShowCreateTicket(true);
  };

  const handleAvatarOrganizer = useCallback(avatarIMG => {
    const reader = new FileReader();
    reader.readAsDataURL(avatarIMG);
    reader.onload = () => {
      setAvatarOrganizer(reader.result);
    };
    reader.onerror = error => {
      toast.error(error);
    };
  }, []);

  useEffect(() => {
    loadEvent(match.params.id);
  }, [loadEvent, match, showCreateAccount]);

  async function handleSubmit(data) {
    try {
      formEditRef.current.setErrors({});

      const schema = Yup.object().shape({
        titulo_eve: Yup.string()
          .min(6, 'O nome do evento é muito pequeno!')
          .required('Nome do evento é obrigatório!'),
        dt_eve: Yup.date('Data inválida')
          .required('Data início é obrigatória!')
          .nullable(),
        hora_eve: Yup.date('Data inválida!')
          .required('Hora início é obrigatória!')
          .nullable(),
        dt_eve_fim: Yup.date('Data inválida!')
          .required('Data fim é obrigatória!')
          .nullable(),
        hora_eve_fim: Yup.date('Data inválida!')
          .required('Hora fim é obrigatória!')
          .nullable(),
        eh_online: Yup.string().min(
          1,
          'Onde o evento vai acontecer é obrigatório!'
        ),
      });
      if (isNewEvent) {
        if (newEventTicket === null) {
          toast.error('Deve ser cadastrado um ingresso antes de prosseguir.');
          return;
        }
      }

      if (!data.titulo_eve) {
        formEditRef.current.getFieldRef('titulo_eve').focus();
      }

      await schema.validate(data, {
        abortEarly: false,
      });

      data.url_amig = replaceSpecialChars(data.titulo_eve);
      data.dt_eve = formatDateHour(data.dt_eve, data.hora_eve);
      data.dt_eve_fim = formatDateHour(data.dt_eve_fim, data.hora_eve_fim);

      const value = [data.val1, data.val2, data.val3];
      data.eh_cobrado = '0';
      value.map(val => {
        if (val && val !== '0,00') {
          data.eh_cobrado = '1';
        }
        return true;
      });

      if (data.google_eve === '1') {
        data.local_eve_desc = 'https://app.psicomart.com.br';
      }

      data.desc_eve = htmlContent;

      if (avatarOrganizer && avatarOrganizer.indexOf('http') < 0) {
        data.avatar = avatarOrganizer;
        data.img_organiz = avatarOrganizer;
      } else {
        delete data.img_organiz;
      }

      delete data.hora_eve;
      delete data.hora_eve_fim;

      /* if (newEventTicket === null) {
        toast.error('Deve ser cadastrado um ingresso primeiro');
        return;
      } */

      dispatch(eventsPostRequest(data, isNewEvent));

      if (isNewEvent) {
        setShowConfirmSaveBox(true);
      }
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formEditRef.current.setErrors(validationErrors);
      }
    }
  }

  useMemo(() => {
    if (event && objIsNull(event)) {
      setTimeout(() => {
        setHtmlContent(event.desc_eve);
        setStartDate(event.dt_eve ? parseISO(event.dt_eve) : null);
        setEndDate(event.dt_eve_fim ? parseISO(event.dt_eve_fim) : null);
        setAvatarOrganizer(event.img_organiz);
      });
    }
  }, [event, setHtmlContent]);

  const loadImg = useCallback(data => {
    setBanner(data.img_eve);
  }, []);

  const handleExternalClose = () => {
    setShowCreateAccount(false);
    publicEvent(event, 'ativar');
    history.push('/eventos');
  };

  return (
    <div className="content padding-tab-top-post">
      <Row>
        <Col xl={12}>
          <div className="d-flex justify-content-between align-items-center">
            <div className="mb-3">
              <h3 className="mb-0 font-weight-semibold">
                <i
                  role="presentation"
                  style={{ fontSize: '1.5rem' }}
                  onClick={() => history.goBack()}
                  className="icon-arrow-left52 mr-2 c-pointer"
                ></i>
                {isNewEvent ? 'Criar' : 'Editar'} evento
              </h3>
              <span className="text-muted d-block">
                Editar informações do evento
              </span>
            </div>
            <div>
              <ModalCreateAccount
                showModal={showCreateAccount}
                handleExternalClose={handleExternalClose}
              />
              <BoxConfirm
                show={openBoxConfirm}
                onClose={() => setOpenBoxConfirm(false)}
                size="sm"
                title="Aviso"
                message="Para poder publicar é preciso cadastrar uma conta corrente."
                messageBottomBold="Deseja cadastrar agora?"
                aliasBtnYes="Cadastrar conta"
                onNoClick={() => setOpenBoxConfirm(false)}
                onYesClick={() => {
                  setShowCreateAccount(true);
                  setOpenBoxConfirm(false);
                }}
              />
              <CreateTicket
                tickets={[]}
                event={event}
                show={showCreateTicket}
                setShow={setShowCreateTicket}
                externalPaid={paid}
                setExternalPaid={setPaid}
                handleExternalClose={handleCreateTicketClose}
                endDate={endDate}
                isNewCourse
              />
              <BoxConfirm
                title="Sucesso"
                show={showConfirmSaveBox}
                size="sm"
                aliasBtnYes="Publicar"
                onYesClick={() => {
                  publicEvent(formEditRef.current.getData(), 'ativar');
                  setShowConfirmSaveBox(false);
                }}
                aliasBtnNo="Complementar"
                onNoClick={() => {
                  setShowConfirmSaveBox(false);
                  history.push(`/eventos/${event.cod_evento}/editar`);
                }}
                onClose={() => {
                  setShowConfirmSaveBox(false);
                  history.push('/eventos');
                }}
                messageTopBold="Evento criado com sucesso!"
                message="Seu evento foi criado.Agora você poderá complementá-lo ou publicá-lo para começar a divulgar."
                iconSuccess
              />
              {!loading && (
                <>
                  <ButtonPublish
                    event={event}
                    openBoxConfirm={setOpenBoxConfirm}
                    formRef={formEditRef.current}
                    disabled={isNewEvent}
                  />{' '}
                  <Link
                    to="route"
                    target="_blank"
                    onClick={e => {
                      e.preventDefault();
                      window.open(`/evento/${event.url_amig}`);
                    }}
                    className={`ml-1 btn ${
                      isNewEvent ? 'disabled btn-secondary' : 'btn-primary'
                    } `}
                    variant="primary"
                  >
                    <i className="icon-earth mr-1"></i>
                    Link
                  </Link>
                </>
              )}
            </div>
          </div>
        </Col>
        <Col>
          {loading ? (
            <Loading />
          ) : (
            <>
              <ul className="nav nav-tabs mb-0">
                <li className="nav-item">
                  <a
                    href="#event"
                    className="nav-link rounded-top active"
                    data-toggle="tab"
                  >
                    Principal
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="#ticket"
                    className={`nav-link rounded-top ${isNewEvent &&
                      'disabled'}`}
                    data-toggle="tab"
                    onClick={() => setTickets(true)}
                  >
                    Preço
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="#coupons"
                    className={`nav-link rounded-top ${isNewEvent &&
                      'disabled'}`}
                    data-toggle="tab"
                    onClick={() => setCoupons(true)}
                  >
                    Cupons de desconto
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="#speaker"
                    className={`nav-link rounded-top ${isNewEvent &&
                      'disabled'}`}
                    data-toggle="tab"
                    onClick={() => setSpeaker(true)}
                  >
                    Palestrante
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="#affiliates"
                    className={`nav-link rounded-top ${isNewEvent &&
                      'disabled'}`}
                    data-toggle="tab"
                    onClick={() => setAffiliate(true)}
                  >
                    Afiliados
                  </a>
                </li>
              </ul>

              <div className="tab-content card card-body border-top-0 rounded-top-0 mb-0">
                <div className="tab-pane fade show active" id="event">
                  <Form
                    ref={formEditRef}
                    onSubmit={handleSubmit}
                    initialData={{
                      titulo_eve: event.titulo_eve,
                      desc_eve: event.desc_eve,
                      img_eve: event.img_eve,
                      dt_eve: event.dt_eve ? parseISO(event.dt_eve) : null,
                      hora_eve: event.dt_eve ? parseISO(event.dt_eve) : null,
                      dt_eve_fim: event.dt_eve_fim
                        ? parseISO(event.dt_eve_fim)
                        : null,
                      hora_eve_fim: event.dt_eve_fim
                        ? parseISO(event.dt_eve_fim)
                        : null,
                      nome_organiz: event.nome_organiz
                        ? event.nome_organiz
                        : profile.nome_membro,
                      tel_organiz: event.tel_organiz
                        ? event.tel_organiz
                        : profile.telefone,
                      desc_organiz: event.desc_organiz,
                      eh_online: event.eh_online,
                      cep_eve: event.cep_eve,
                      end_eve: event.end_eve,
                      local_eve_desc: event.local_eve_desc,
                      google_eve: event.google_eve,
                    }}
                  >
                    <Card>
                      <Card.Body>
                        <Row>
                          <Col xl={8}>
                            <Row>
                              <Col xl={12}>
                                <Row>
                                  <Col xl={12}>
                                    <div className="form-group">
                                      <label>Nome do evento*</label>
                                      <Input
                                        name="titulo_eve"
                                        type="text"
                                        placeholder="Nome do evento"
                                      />
                                      <Input
                                        type="hidden"
                                        name="cod_evento"
                                        defaultValue={event.cod_evento}
                                      />
                                    </div>
                                  </Col>
                                  <Col xl={12}>
                                    <div className="form-group">
                                      <label htmlFor="">
                                        Descrição do Evento
                                      </label>
                                      <TextEditor>
                                        <ReactQuill
                                          theme="snow"
                                          value={htmlContent}
                                          onChange={setHtmlContent}
                                          modules={Edit.modules}
                                          formats={Edit.formats}
                                        />
                                      </TextEditor>
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Col>
                          <Col xl={4}>
                            <div className="form-group mb-0">
                              <label>Banner</label>
                              <div className="input-group">
                                <Dropzone
                                  label="Arraste uma imagem ou clique aqui para enviar"
                                  id={event.cod_evento}
                                  loadEvent={loadImg}
                                >
                                  <Col className="text-center">
                                    <img
                                      src={banner || event.img_eve}
                                      alt=""
                                      className="img-fluid w-100"
                                      style={{ maxHeight: 250 }}
                                    />
                                  </Col>
                                </Dropzone>
                              </div>
                            </div>
                            <div className="form-group text-center mb-1">
                              <label className="text-muted">
                                <small>
                                  Tamanho: 1905px por 1020px (padrão da página
                                  de eventos do Facebook)
                                </small>
                              </label>
                            </div>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>

                    <LocationEvent event={event} module="evento" />

                    <Card>
                      <Card.Body>
                        <Card.Title>Quando o evento vai acontecer?</Card.Title>
                        <Row xl={12}>
                          <Col xl={6}>
                            <Col xl={12}>
                              <Row>
                                <Col xl={7}>
                                  <div className="form-group">
                                    <label>Data início*</label>
                                    <DatePiker
                                      required
                                      name="dt_eve"
                                      id="data_evento"
                                      minDate={new Date()}
                                      onChange={date => {
                                        setStartDate(date);
                                      }}
                                      selected={startDate}
                                    />
                                  </div>
                                </Col>
                                <Col xl={3}>
                                  <div className="form-group">
                                    <label>Hora início*</label>
                                    <DatePikerTime
                                      required
                                      startDate={startDate}
                                      endDate={endDate}
                                      name="hora_eve"
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                            <Col xl={12}>
                              <Row>
                                <Col xl={7}>
                                  <div className="form-group">
                                    <label>Data fim*</label>
                                    <DatePiker
                                      name="dt_eve_fim"
                                      minDate={
                                        startDate !== null
                                          ? startDate
                                          : new Date()
                                      }
                                      selected={endDate}
                                      onChange={date => setEndDate(date)}
                                      required
                                    />
                                  </div>
                                </Col>
                                <Col xl={3}>
                                  <div className="form-group">
                                    <label>Hora fim*</label>
                                    <DatePikerTime
                                      required
                                      endDate={endDate}
                                      name="hora_eve_fim"
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Col>

                          {isNewEvent && (
                            <Col
                              xl={5}
                              className="d-flex flex-column justify-content-center align-items-center "
                            >
                              <span className="mb-3 font-weight-semibold">
                                Crie um ingresso para seu evento*
                              </span>
                              <Row>
                                <Col className="ml-3" style={{ width: 300 }}>
                                  <Card
                                    onClick={() => handleTypeTicket(1)}
                                    type="button"
                                    className={`border-light shadow-sm type-ticket ${
                                      typeTicket === 1 ? 'select-ticket' : ''
                                    }`}
                                  >
                                    <Card.Body className="d-flex flex-column justify-content-center align-items-center">
                                      <i className="icon-ticket icon-2x"></i>
                                      <p className="mt-2">
                                        {ticketTypesArray[paid]}
                                      </p>
                                    </Card.Body>
                                  </Card>
                                </Col>
                              </Row>
                            </Col>
                          )}
                        </Row>
                      </Card.Body>
                    </Card>

                    <Card>
                      <Card.Body>
                        <Card.Title>Sobre o organizador</Card.Title>
                        <Row>
                          <Col
                            md={2}
                            className="d-flex justify-content-center align-items-center"
                          >
                            <AvatarInput>
                              {avatarOrganizer !== '0' ? (
                                <img src={avatarOrganizer} alt="Avatar" />
                              ) : (
                                <img src={noImage} alt="Avatar" />
                              )}
                              <label htmlFor="avatarOrganizer">
                                <i className="icon-camera"></i>
                                <input
                                  type="file"
                                  id="avatarOrganizer"
                                  onChange={e =>
                                    handleAvatarOrganizer(e.target.files[0])
                                  }
                                />
                              </label>
                            </AvatarInput>
                          </Col>
                          <Col>
                            <Row>
                              <Col>
                                <div className="form-group">
                                  <label>Nome</label>
                                  <Input
                                    className="form-control"
                                    type="hidden"
                                    name="img_organiz"
                                    value="0"
                                  />
                                  <Input
                                    className="form-control"
                                    type="text"
                                    name="nome_organiz"
                                    placeholder="Nome do organizador"
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <div className="form-group">
                                  <label>Telefone (Whatsapp)</label>
                                  <InputMask
                                    mask="(99) 999999999"
                                    className="form-control"
                                    type="text"
                                    name="tel_organiz"
                                    placeholder="Telefone do organizador"
                                  />
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col md={12} className="mt-1">
                            <div className="form-group">
                              <label>Descrição</label>
                              <Textarea name="desc_organiz" rows="5" />
                            </div>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                    <Row className="justify-content-md-center mt-4">
                      <Col xl={2}>
                        <Button size="lg" variant="primary" block type="submit">
                          Salvar
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </div>
                <div className="tab-pane fade" id="ticket">
                  {tickets && <TableTicket event={event} />}
                </div>
                <div className="tab-pane fade" id="coupons">
                  {coupons && <DiscountCoupons data={event} />}
                </div>
                <div className="tab-pane fade" id="speaker">
                  {speaker && <Speakers data={event} />}
                </div>
                <div className="tab-pane fade" id="affiliates">
                  {affiliate && <Affiliates data={event} />}
                </div>
              </div>
            </>
          )}
        </Col>
      </Row>
    </div>
  );
}

Edit.modules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }, { font: [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link', 'image', 'video'],
    ['clean'],
  ],
  clipboard: {
    matchVisual: false,
  },
};

Edit.formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
];

Edit.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

export default Edit;
