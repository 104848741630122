import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Card } from 'react-bootstrap';

import history from '~/services/history';
import api from '~/services/api';

import FileArq from '~/components/FileArq';
import Loading from '~/components/Loading';

export default function Download() {
  const { id } = useParams();
  const [product, setProduct] = useState({});
  const [files, setFiles] = useState([]);
  const [load, setLoad] = useState(false);

  const loadFiles = useCallback(() => {
    const { auth } = JSON.parse(localStorage.getItem('persist:psico.in'));
    const { token } = JSON.parse(auth);
    api
      .get(`/produto/listar-produto-arq/?q=(cod_produto:${id})`)
      .then(response => {
        const data = response.data.records.map(file => {
          const url = `http://api.psicomart.com.br/v1/1090/rs/export/get-arq/?q=(ken:${token},arq:${file.arq_prod},e:1)`;

          return {
            id: file.cod_produto_arq,
            name: file.arq_prod,
            preview: file.arq_prod,
            uploaded: true,
            url,
          };
        });
        setFiles(data);
      });
  }, [id]);

  const loadProduct = useCallback(() => {
    setLoad(true);
    api.get(`/produto/${id}`).then(response => {
      setProduct(response.data.records);
      setLoad(false);
    });
  }, [id]);

  useEffect(() => {
    loadProduct();
    loadFiles();
  }, [loadProduct, loadFiles]);

  return (
    <div className="content padding-tab-top-post">
      {load ? (
        <Loading />
      ) : (
        <>
          <Row>
            <Col>
              <div className="d-flex justify-content-between align-items-center">
                <div className="mb-3">
                  <h3 className="mb-0 font-weight-semibold">
                    <i
                      role="presentation"
                      style={{ fontSize: '1.5rem' }}
                      onClick={() => history.goBack()}
                      className="icon-arrow-left52 mr-2 c-pointer"
                    ></i>
                    {product.nome_produto || `Cod do Produto: ${id}`}
                  </h3>
                  <span className="text-muted d-block">
                    Arquivos para download deste produto
                  </span>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <Card.Body>
                  <Card.Title>Arquivos para download</Card.Title>
                  <FileArq files={files} />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}
