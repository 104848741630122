export function modsRequest(query) {
  return {
    type: '@mods/MODS_REQUEST',
    payload: query,
  };
}

export function modsGroupSuccess(data) {
  return {
    type: '@mods/MODS_GROUP_SUCCESS',
    payload: data,
  };
}

export function modsGroupFailure() {
  return {
    type: '@mods/MODS_GROUP_FAILURE',
  };
}

export function followersGetRequest(query) {
  return {
    type: '@mods/FOLLOWERS_GET_REQUEST',
    payload: query,
  };
}

export function followersGetSuccess(data) {
  return {
    type: '@mods/FOLLOWERS_GET_SUCCESS',
    payload: data,
  };
}

export function followersGetFailure() {
  return {
    type: '@mods/FOLLOWERS_GET_FAILURE',
  };
}
