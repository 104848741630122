import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as Yup from 'yup';

import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import { Button, Modal, Spinner, Table } from 'react-bootstrap';

import Input from '~/components/InputText';
import InputCheck from '~/components/InputCheck';

import api from '~/services/api';

import * as ModulesActions from '~/store/modules/modules/actions';

import { objIsNull } from '~/util';

function CreateModule({ course, show, handleClose }) {
  const dispatch = useDispatch();

  const module = useSelector(state => state.modules.module);
  const classes = useSelector(state => state.classes.classes);

  const formRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [arrClasses, setArrClasses] = useState([]);

  useEffect(() => {
    if (objIsNull(module)) {
      setArrClasses(module.arr_turma);
    } else {
      const arrTempClasses = classes.map(item => {
        return item.cod_turma_curso;
      });
      setArrClasses(arrTempClasses);
    }
  }, [module, classes, setArrClasses]);

  async function handleSubmitModule(data) {
    try {
      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        nome_modulo: Yup.string()
          .min(3, 'Ops! Nome muito pequeno!')
          .required(
            'O nome do módulo é um campo de preenchimento obrigatório!'
          ),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      setLoading(true);
      let response;
      let message;

      /** Recolhendo as turmas escolhidas pelo usuário. */
      data.arr_turma = arrClasses;

      if (objIsNull(module)) {
        data.cod_modulo_curso = module.cod_modulo_curso;
        response = await api.put(`/evento/salvar-modulo-curso/${course}`, data);
        message = 'Módulo atualizado com sucesso!';
      } else {
        response = await api.post('/evento/salvar-modulo-curso', data);
        message = 'Módulo cadastrado com sucesso!';
      }

      if (response.data.records.error) {
        setLoading(false);
        toast.error(response.data.records.msg);
      } else {
        setLoading(false);
        toast.success(message);
        setTimeout(() => {
          handleClose();
          dispatch(ModulesActions.modulesGetByCourseRequest(course));
        }, 1000);
      }

      return false;
    } catch (err) {
      setLoading(false);
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  function populateForm() {
    if (objIsNull(module)) {
      return module;
    }
    return { cod_evento: course };
  }

  function deleteClass(idClass) {
    const arrOut = arrClasses.filter(item => item !== idClass);
    return arrOut;
  }

  function handleChangeClass(data, event) {
    let arrOut = [];

    const { checked } = event.target;
    if (checked) {
      /** Caso o módulo não tenha turmas vinculadas. */
      arrOut = arrClasses
        ? [...arrClasses, data.cod_turma_curso]
        : [data.cod_turma_curso];
    } else {
      /** Removendo a turma selecionada. */
      let breakForEach = false;
      arrClasses.forEach(item => {
        if (!breakForEach) {
          if (item === data.cod_turma_curso) {
            arrOut = deleteClass(item);
            breakForEach = true;
          }
        }
      });
    }

    setArrClasses(arrOut);
  }

  function checkClassChecked(idClass) {
    if (arrClasses) {
      const filtered = arrClasses.filter(item => item === idClass);
      if (filtered.length) {
        return filtered[0].toString();
      }
    }
    return null;
  }

  return (
    <Modal
      centered
      className="modal-new-event"
      dialogClassName="modal-event"
      show={show}
      onHide={handleClose}
    >
      <Form
        ref={formRef}
        onSubmit={handleSubmitModule}
        initialData={populateForm()}
      >
        <Modal.Header>
          <div className="media align-items-center align-items-md-start flex-column flex-md-row">
            <span className="text-teal mr-md-3 mb-3 mb-md-0">
              <i className="icon-drawer3 text-warning-400 border-warning-400 border-2 rounded-round p-2 mt-1"></i>
            </span>
            <div className="media-body text-center text-md-left">
              <h3 className="media-title font-weight-semibold">Criar Módulo</h3>
              <span className="text-muted">
                Preencha o formulário abaixo para adicionar um novo módulo.
              </span>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label htmlFor="nome_modulo">Nome *</label>
            <Input id="cod_evento" name="cod_evento" type="hidden" />
            <Input
              required
              type="text"
              id="nome_modulo"
              name="nome_modulo"
              placeholder="Entre com o nome do módulo..."
            />
          </div>
          <div className="form-group mb-0">
            {objIsNull(classes) > 0 && (
              <Table striped hover className="table-bordered mt-1">
                <thead>
                  <tr>
                    <th>Selecione as turmas:</th>
                  </tr>
                </thead>
                <tbody>
                  {classes.map(item => (
                    <tr key={item.cod_turma_curso}>
                      <td>
                        <InputCheck
                          name="status"
                          onChange={event => {
                            handleChangeClass(item, event);
                          }}
                          checkedValue={
                            objIsNull(module)
                              ? checkClassChecked(item.cod_turma_curso)
                              : item.cod_turma_curso.toString()
                          }
                          options={[
                            {
                              id: item.cod_turma_curso,
                              label: item.nome_turma,
                            },
                          ]}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button
            block
            size="lg"
            variant="link"
            className="text-secondary"
            onClick={handleClose}
          >
            <i className="icon-cross2 mr-1 font-weight-light"></i>
            Cancelar
          </Button>
          <Button size="lg" block disabled={!!loading} type="submit">
            {loading ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="mr-1"
                />
                Aguarde
              </>
            ) : (
              'Salvar Módulo'
            )}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default CreateModule;
