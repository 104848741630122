/* eslint-disable no-prototype-builtins */

import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from 'react-bootstrap';
import { Form } from '@unform/web';

import MethodPayment from './methodPayment';
import InputGroup from '~/components/InputGroup';

import { formatCurrencyValue } from '~/util';

import { getCouponRequest } from '~/store/modules/cart/actions';

export default function Payment() {
  const dispatch = useDispatch();
  const { event } = useSelector(state => state.cart);
  const { tickets } = useSelector(state => state.cart);
  const { total } = useSelector(state => state.cart);
  const { amount } = useSelector(state => state.cart);
  const { coupon } = useSelector(state => state.cart);
  const { loading } = useSelector(state => state.cart);

  const handleSubmit = useCallback(
    data => {
      const { num_cupom } = data;
      const { cod_evento } = event;
      if (coupon.num_cupom !== num_cupom.toUpperCase()) {
        dispatch(getCouponRequest({ num_cupom, cod_evento }));
      }
    },
    [dispatch, event, coupon]
  );

  return (
    <>
      <h5 className="font-weight-bold text-muted">DETALHES DO PAGAMENTO</h5>
      <Table striped hover responsive className="table-bordered">
        <thead>
          <tr>
            <th>Ingresso</th>
            <th>Quantidade</th>
            <th className="text-right">Valor</th>
            <th className="text-right">Taxa</th>
            <th className="text-right">Sub-Total</th>
          </tr>
        </thead>
        <tbody>
          {tickets.map(ticket => (
            <tr key={ticket.id}>
              <td>Ingresso {ticket.nome_ing}</td>
              <td>{ticket.ticketAmount.amount}</td>
              <td className="text-right">{formatCurrencyValue(ticket.val)}</td>
              <td className="text-right">R$ 0,00</td>
              <td className="text-right">
                {formatCurrencyValue(ticket.val * ticket.ticketAmount.amount)}
              </td>
            </tr>
          ))}
          <tr>
            <td className="text-right" colSpan="4">
              Desconto
            </td>
            <td className="text-right">
              - {formatCurrencyValue(coupon.discount || 0)}
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th>Total dos ingressos</th>
            <th>{amount}</th>
            <th className="text-right">
              {formatCurrencyValue(
                tickets.reduce((sum, ticket) => {
                  return sum + ticket.val;
                }, 0)
              )}
            </th>
            <th className="text-right">R$ 0,00</th>
            <th className="text-right">
              {formatCurrencyValue(coupon.total >= 0 ? coupon.total : total)}
            </th>
          </tr>
        </tfoot>
      </Table>
      {event.eh_cobrado === '1' && (
        <>
          <h5 className="font-weight-bold text-muted mt-2">
            CUPOM DE DESCONTO
          </h5>
          <Form onSubmit={handleSubmit} initialData={coupon} className="row">
            <div className="form-group col-6">
              <InputGroup
                name="num_cupom"
                button
                label="Aplicar"
                load={loading}
                style={{ textTransform: 'uppercase' }}
              />
            </div>
          </Form>
          <h5 className="font-weight-bold text-muted">TIPO DE PAGAMENTO</h5>
          <span>
            Seus dados serão transferidos com conexão segura SSL e nenhuma
            informação sensível do pagamento será mantida em nossos servidores.
          </span>
        </>
      )}

      <MethodPayment />
    </>
  );
}
