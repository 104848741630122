import React, { useState, useRef, useEffect } from 'react';

import * as Yup from 'yup';
import PropTypes from 'prop-types';

import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import { Button, Col, Row } from 'react-bootstrap';

import { objIsNull, buildQueryString, vimeoGetID, youTubeGetID } from '~/util';

import api from '~/services/api';

import Input from '~/components/InputText';
import InputRadio from '~/components/InputRadio';

function TabVideo({ module, reload, formContentRef }) {
  const formRef = useRef(null);

  const [plataformVideo, setPlataformVideo] = useState(0);

  useEffect(() => {
    if (module && objIsNull(module)) {
      setPlataformVideo(module.video_plataforma);
    }
  }, [module, setPlataformVideo]);

  function checkOption(type) {
    if (parseInt(module.video_plataforma, 10) === type) {
      return true;
    }
    return false;
  }

  function handleChangePlataform(event) {
    const { value } = event.target;
    setPlataformVideo(value);
    formRef.current.setData({
      ...module,
      video_url: '',
      video_plataforma: value,
    });
  }

  async function handleSubmit(data) {
    if (!parseInt(plataformVideo, 10)) {
      toast.error(
        'Escolha a plataforma na qual seu vídeo se encontra hospedado!'
      );
      return false;
    }

    const firstTabData = formContentRef.current.getData();
    if (firstTabData.nome_modulo === '') {
      toast.error('O nome do módulo é um campo de preenchimento obrigatório!');
      document.querySelector('#ref-module').click();
      return false;
    }

    if (firstTabData.tit_conteudo === '') {
      toast.error(
        'O título do conteúdo é um campo de preenchimento obrigatório!'
      );
      document.querySelector('#ref-module').click();
      return false;
    }

    try {
      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        video_url: Yup.string()
          .min(3, 'Ops! URL do vídeo muito pequena!')
          .required('A URL do vídeo é um campo de preenchimento obrigatório!'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      let idVideo = '';
      if (parseInt(plataformVideo, 10) === 1) {
        const idVideoYouTube = youTubeGetID(data.video_url);
        if (idVideoYouTube === '') {
          toast.error('O link enviado não corresponde a um vídeo do YouTube!');
          return false;
        }
        idVideo = idVideoYouTube;
      }

      if (parseInt(plataformVideo, 10) === 2) {
        const idVideoVimeo = vimeoGetID(data.video_url);
        if (idVideoVimeo === '') {
          toast.error('O link enviado não corresponde a um vídeo do Vimeo!');
          return false;
        }
        idVideo = idVideoVimeo;
      }

      data.video_id = idVideo;
      data.video_plataforma = plataformVideo;

      const response = await api.put(
        `/evento/salvar-video-modulo-curso/${data.cod_modulo_curso}`,
        data
      );

      if (response.data.records.error) {
        toast.error(response.data.records.msg);
      } else {
        formContentRef.current.submitForm();
        toast.success('Vídeo salvo com sucesso!');
        setTimeout(() => {
          const query = buildQueryString({
            cod_evento: data.cod_evento,
            cod_modulo_curso: data.cod_modulo_curso,
          });
          reload(query);
        }, 1000);
      }
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }

    return false;
  }

  function getInitialData() {
    if (module && objIsNull(module)) {
      return {
        cod_evento: module.cod_evento,
        cod_modulo_curso: module.cod_modulo_curso,
        video_plataforma: parseInt(module.video_plataforma, 10),
        video_url: module.video_url,
      };
    }
    return null;
  }

  return (
    objIsNull(module) && (
      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        initialData={getInitialData()}
      >
        <Col xl={12}>
          <div className="page-header-content header-elements-md-inline p-0">
            <div className="page-title d-flex p-0">
              <h4 className="mb-0 font-weight-semibold">
                <i className="icon-video-camera3 mr-2 c-pointer"></i>{' '}
                Configurando Vídeoaula
                <span className="text-muted d-block" style={{ fontSize: 12 }}>
                  Envie o link de seu vídeo. Plataformas aceitas em nossa
                  aplicação: YouTube ou Vimeo.
                </span>
              </h4>
            </div>
          </div>
        </Col>
        <Col xl={12} className="mt-4 mb-1">
          <div className="form-group mb-3 mb-md-2">
            <label
              style={{ fontSize: 12 }}
              className="d-block font-weight-semibold mb-3"
            >
              Escolha a plataforma na qual sua vídeoaula está hospedada:
            </label>
            <div className="form-check form-check-inline form-check-right">
              <InputRadio
                name="video_plataforma"
                defaultValue={checkOption(1)}
                onChange={event => {
                  handleChangePlataform(event);
                }}
                options={[
                  {
                    id: 1,
                    label: 'YouTube',
                  },
                ]}
              />
            </div>
            <div className="form-check form-check-inline form-check-right">
              <InputRadio
                name="video_plataforma"
                defaultValue={checkOption(2)}
                onChange={event => {
                  handleChangePlataform(event);
                }}
                options={[
                  {
                    id: 2,
                    label: 'Vimeo',
                  },
                ]}
              />
            </div>
          </div>
        </Col>

        <Col xl={6}>
          <Input type="hidden" id="cod_evento" name="cod_evento" />
          <Input type="hidden" id="cod_modulo_curso" name="cod_modulo_curso" />
        </Col>

        {plataformVideo > 0 && (
          <Col xl={6}>
            <div className="form-group">
              <label htmlFor="video_url">URL do Vídeo *</label>
              <Input
                required
                type="url"
                id="video_url"
                name="video_url"
                className="form-control"
                placeholder="URL do vídeo..."
              />
            </div>
          </Col>
        )}

        <Row className="justify-content-md-center mt-4">
          <Col xl={2}>
            <Button size="lg" variant="primary" block type="submit">
              Salvar
            </Button>
          </Col>
        </Row>
      </Form>
    )
  );
}

TabVideo.propTypes = {
  module: PropTypes.shape({
    video_url: PropTypes.string,
    video_plataforma: PropTypes.string,
    cod_evento: PropTypes.string,
    cod_modulo_curso: PropTypes.string,
  }).isRequired,
  reload: PropTypes.func.isRequired,
  formContentRef: PropTypes.shape({
    current: PropTypes.shape({
      getData: PropTypes.func,
      submitForm: PropTypes.func,
    }),
  }).isRequired,
};

export default TabVideo;
