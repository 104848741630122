import React from 'react';
import Prototype from 'prop-types';

import HeaderExternal from '~components/Layout/HeaderExternal';

import { Wrapper } from './styles';

export default function DefaultLayout({ children }) {
  const year = new Date().getUTCFullYear();
  return (
    <Wrapper>
      <HeaderExternal />

      <div className="content-wrapper">{children}</div>

      <div className="footer footer-padding">
        <span>&copy; COPYRIGHT {year}</span>
        {' - '}
        <span>Powered by PsicoMart</span>
      </div>
    </Wrapper>
  );
}

DefaultLayout.propTypes = {
  children: Prototype.element.isRequired,
};
