import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Row, Col, Badge, Table, Button } from 'react-bootstrap';
import { format } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { toast } from 'react-toastify';
import history from '~/services/history';

import api from '~/services/api';
import { getUrlImg, ambient } from '~/util';
import { eventsListRequest } from '~/store/modules/event/actions';

import CreateEvent from '~/components/CreateEvent';
import DeleteEvent from '~/components/DeleteEvent';
import Loading from '~/components/Loading';
import noImage from '~/assets/img/sem.png';
import BoxConfirm from '~/components/BoxConfirm';

import ModalCreateAccount from '~/components/ModalCreateAccount';

export default function My() {
  const dispatch = useDispatch();

  const { events } = useSelector(state => state.event);
  const loading = useSelector(state => state.event.loading);
  const [show, setShow] = useState(false);
  const [eventRef, setEventRef] = useState({});
  const [showCreateAccount, setShowCreateAccount] = useState(false);
  const [openBoxConfirm, setOpenBoxConfirm] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  useEffect(() => {
    dispatch(eventsListRequest('meus-eventos/?q=(tipo_eve:1)'));
  }, [dispatch, showCreateAccount]);

  const loadEvents = useCallback(() => {
    dispatch(eventsListRequest('meus-eventos/?q=(tipo_eve:1)'));
  }, [dispatch]);

  async function publicEvent(event, action, shouldOpenBoxConfirm) {
    if (!event.titulo_eve) {
      toast.error('Evento não pode ser publicado sem um título!');
      return;
    }

    if (!event.dt_eve) {
      toast.error('Evento não pode ser publicado sem a data!');
      return;
    }

    await api.put(`/evento/${action}/${event.cod_evento}`).then(response => {
      if (response.data.records.error) {
        if (response.data.records.error_cc) {
          setEventRef(event);

          // eslint-disable-next-line no-unused-expressions
          shouldOpenBoxConfirm && setOpenBoxConfirm(true);

          return;
        }
        toast.error(response.data.records.msg);
        return;
      }
      loadEvents();
    });
  }

  const handleExternalClose = () => {
    setShowCreateAccount(false);
    publicEvent(eventRef, 'ativar', false);
    loadEvents();
  };

  const copy = useCallback(() => {
    toast.info('Link copiado com sucesso!');
  }, []);

  const urlCopy = useCallback(event => {
    const url = `${ambient('front')}/evento/${event.url_amig}`;

    return url;
  }, []);

  const getBadgePresencial = useCallback(event => {
    if (event) {
      switch (event.eh_online) {
        case '0':
        case null:
          return <Badge variant="secondary">Presencial</Badge>;
        case '1':
          return <Badge variant="primary">On-line</Badge>;
        default:
          return null;
      }
    }
    return null;
  }, []);

  return (
    <div className="content padding-tab-top-post">
      <ModalCreateAccount
        showModal={showCreateAccount}
        handleExternalClose={handleExternalClose}
      />
      <BoxConfirm
        show={openBoxConfirm}
        onClose={() => setOpenBoxConfirm(false)}
        size="sm"
        title="Aviso"
        message="Para poder publicar é preciso cadastrar uma conta corrente."
        messageBottomBold="Deseja cadastrar agora?"
        aliasBtnYes="Cadastrar conta"
        onNoClick={() => setOpenBoxConfirm(false)}
        onYesClick={() => {
          setShowCreateAccount(true);
          setOpenBoxConfirm(false);
        }}
      />
      <Row>
        <Col>
          <div className="d-flex justify-content-between align-items-center">
            <div className="mb-3">
              <h3 className="mb-0 font-weight-semibold">
                <i
                  role="presentation"
                  style={{ fontSize: '1.5rem' }}
                  onClick={() => history.goBack()}
                  className="icon-arrow-left52 mr-2 c-pointer"
                ></i>
                Meus Eventos
              </h3>
              <span className="text-muted d-block">
                Aqui você pode gerenciar seus eventos criados
              </span>
            </div>
            <div>
              <button
                type="button"
                className="btn btn-sm btn-info"
                onClick={handleShow}
              >
                <i className="icon-plus3 mr-1"></i>Criar Evento
              </button>
            </div>
          </div>
        </Col>
        <Col md={12}>
          {loading ? (
            <Loading />
          ) : (
            <Card>
              <Card.Body>
                <Table striped hover>
                  <thead>
                    <tr>
                      <th colSpan="2">Evento</th>
                      <th>Presencial</th>
                      <th>Publicado</th>
                      <th className="text-center">Página do Evento</th>
                      <th>Data do evento</th>
                      <th className="text-center">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(events).length === 0 ? (
                      <tr>
                        <td colSpan="6" className="p-0 text-center">
                          <div className="alert alert-info border-0 alert-dismissible mb-0">
                            <i className="icon-info3 border-2 rounded-round p-1 mr-2"></i>
                            <span>
                              Caro usuário, você não cadastrou nenhum evento
                            </span>
                          </div>
                        </td>
                      </tr>
                    ) : (
                      events.map(event => (
                        <tr key={event.cod_evento}>
                          <td>
                            {event.img_eve ? (
                              <img
                                width={80}
                                height={50}
                                src={getUrlImg(event.img_eve)}
                                alt={event.titulo_eve}
                                className="img-preview rounded"
                              />
                            ) : (
                              <img
                                width={80}
                                height={50}
                                src={noImage}
                                alt={event.titulo_eve}
                                className="img-preview rounded"
                              />
                            )}
                          </td>
                          <td>
                            <Link
                              to={`/eventos/${event.cod_evento}/editar`}
                              title="Editar evento"
                            >
                              {event.titulo_eve
                                ? event.titulo_eve
                                : 'TITULO NÃO DEFINIDO'}
                            </Link>
                          </td>
                          <td>{getBadgePresencial(event)}</td>
                          <td>
                            {event.status_eve === '1' ? (
                              <Badge
                                className="c-pointer"
                                variant="success"
                                onClick={() =>
                                  publicEvent(event, 'desativar', true)
                                }
                              >
                                Evento publicado (Despublicar)
                              </Badge>
                            ) : (
                              <Badge
                                className="c-pointer"
                                variant="secondary"
                                onClick={() =>
                                  publicEvent(event, 'ativar', true)
                                }
                              >
                                Evento não publicado (Publicar)
                              </Badge>
                            )}
                          </td>
                          <td className="d-flex justify-content-center align-items-center">
                            <Link
                              target="_blank"
                              to={`/evento/${event.url_amig}`}
                            >
                              <Button variant="light" size="sm">
                                <i className="icon-link text-primary"></i>
                              </Button>
                            </Link>
                          </td>
                          <td>
                            {event.dt_eve
                              ? format(new Date(event.dt_eve), 'PP', {
                                  locale: pt,
                                })
                              : 'A definir'}
                          </td>
                          <td>
                            <div className="d-flex justify-content-center align-items-center">
                              <Link
                                to={`/eventos/${event.cod_evento}/editar`}
                                title="Editar evento"
                              >
                                <Button variant="light" size="sm">
                                  <i className="icon-pencil5 text-primary"></i>
                                </Button>
                              </Link>

                              <div className="list-icons text-primary ml-2">
                                <div className="dropdown">
                                  <button
                                    type="button"
                                    className="btn btn-light dropdown-toggle"
                                    data-toggle="dropdown"
                                  >
                                    <i className="icon-cog6 text-primary"></i>
                                  </button>

                                  <div className="dropdown-menu dropdown-menu-right">
                                    <CopyToClipboard
                                      text={urlCopy(event)}
                                      onCopy={() => copy()}
                                    >
                                      <button
                                        className="btn btn-link dropdown-item"
                                        type="button"
                                      >
                                        <i className="icon-link"></i>
                                        Copiar link da inscrição
                                      </button>
                                    </CopyToClipboard>
                                    <div className="dropdown-divider"></div>
                                    {event.status_eve === '1' ? (
                                      <button
                                        type="button"
                                        className="btn btn-link dropdown-item"
                                        onClick={() =>
                                          publicEvent(event, 'desativar')
                                        }
                                      >
                                        <i className="icon-eye-blocked"></i>
                                        Despublicar evento
                                      </button>
                                    ) : (
                                      <button
                                        type="button"
                                        className="btn btn-link dropdown-item"
                                        onClick={() =>
                                          publicEvent(event, 'ativar')
                                        }
                                      >
                                        <i className="icon-eye"></i>
                                        Publicar evento
                                      </button>
                                    )}
                                    <div className="dropdown-divider"></div>
                                    <DeleteEvent
                                      data={event}
                                      entity="Evento"
                                      load={loadEvents}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          )}
        </Col>
      </Row>
      <CreateEvent
        show={show}
        handleClose={handleClose}
        loadEvents={loadEvents}
      />
      <ModalCreateAccount
        showModal={showCreateAccount}
        handleExternalClose={handleExternalClose}
      />
    </div>
  );
}
