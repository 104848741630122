import React, { useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Modal, Button } from 'react-bootstrap';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import api from '~/services/api';

import Input from '~/components/InputText';
import InputDecimal from '~/components/InputDecimal';

export default function EditAffiliate({ data, loading }) {
  const formRef = useRef();
  const [show, setShow] = useState(false);
  const [load, setLoad] = useState(false);

  const handleClose = useCallback(() => {
    setShow(false);
  }, []);

  const handleSubmit = useCallback(
    async value => {
      try {
        setLoad(true);
        formRef.current.setErrors({});
        const schema = Yup.object().shape({
          valor_comissao: Yup.string().required('Comissão é obrigatória'),
          url_afiliacao: Yup.string().required('URL é obrigatoria'),
        });
        await schema.validate(value, {
          abortEarly: false,
        });

        api
          .put(`/afiliacao/salvar-afiliado/${data.cod_afiliacao}`, {
            cod_afiliacao: data.cod_afiliacao,
            status: 1,
            valor_comissao: value.valor_comissao,
            url_afiliacao: value.url_afiliacao,
          })
          .then(response => {
            if (response.data.records.error) {
              toast.error(response.data.records.msg);
              setLoad(false);
              return false;
            }
            toast.success('Afiliado editado com sucesso');
            setLoad(false);
            handleClose();
            loading();
            return false;
          });
      } catch (err) {
        const validationErrors = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            validationErrors[error.path] = error.message;
          });
          formRef.current.setErrors(validationErrors);
        }
        setLoad(false);
      }
    },
    [data, handleClose, loading]
  );

  return (
    <>
      <Button variant="light" onClick={() => setShow(true)}>
        <i className="icon-pencil5 text-primary"></i>
      </Button>
      <Modal size="sm" centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Editar afiliado</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            initialData={{
              url_afiliacao: data.url_afiliacao,
              valor_comissao: parseInt(data.valor_comissao),
            }}
          >
            <Row>
              <Col xl={12}>
                <div className="form-group">
                  <label>Comissão</label>
                  <InputDecimal name="valor_comissao" group />
                </div>
              </Col>
              <Col>
                <div className="form-group">
                  <label>URL</label>
                  <Input name="url_afiliacao" />
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="text-center">
                <Button
                  className="w-25"
                  size="lg"
                  type="submit"
                  disabled={load}
                >
                  {load ? 'Aguarde...' : 'Salvar'}
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

EditAffiliate.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
  loading: PropTypes.func.isRequired,
};

EditAffiliate.defaultProps = {
  data: {},
};
