export function shoppingGetRequest() {
  return {
    type: '@shopping/GET_SHOPPING_REQUEST',
  };
}

export function shoppingGetSuccess(data) {
  return {
    type: '@shopping/GET_SHOPPING_SUCCESS',
    payload: data,
  };
}

export function shoppingGetFailure() {
  return {
    type: '@shopping/GET_SHOPPING_FAILURE',
  };
}
