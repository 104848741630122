import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

import { BoxBottomButton } from './styles';

function BottomButton(props) {
  const { type, disabled, onClick, text, url } = props;
  return (
    <BoxBottomButton>
      <span>
        {(type === 'button' || type === 'submit') && (
          <Button
            type={type}
            variant="success"
            size="lg"
            className="btn btn-lg btn-success btn-block font-weight-bold btn-custom text-uppercase mb-0"
            disabled={disabled}
            onClick={onClick}
          >
            {text}
          </Button>
        )}
        {type === 'link' && (
          <Link
            role="button"
            to={url}
            className="btn btn-lg btn-success btn-block font-weight-bold btn-custom text-uppercase mb-0"
          >
            {text}
          </Link>
        )}
      </span>
    </BoxBottomButton>
  );
}

BottomButton.propTypes = {
  url: PropTypes.string,
  type: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
};

export default BottomButton;
