import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';

import * as Yup from 'yup';

import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import { Button, Modal, Spinner } from 'react-bootstrap';

import Input from '~/components/InputText';

import api from '~/services/api';
import history from '~/services/history';

import * as LayoutActions from '~/store/modules/layout/actions';

export default function CreateCourse({ show, handleClose }) {
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  async function handleSubmit(data) {
    setLoading(true);
    try {
      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        titulo_eve: Yup.string()
          .min(5, 'Ops! Nome muito pequeno!')
          .required('O nome do curso é um campo de preenchimento obrigatório!'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const response = await api.post('/evento', data);
      if (response.data.records.error) {
        setLoading(false);
        toast.error(response.data.records.msg);
        return false;
      }
      toast.success(
        'Seu curso foi criado com sucesso, agora vamos completar as informações do mesmo!'
      );
      setTimeout(() => {
        setLoading(false);
        handleClose();
        dispatch(LayoutActions.menusGetRequest('curso'));
        history.push(`/cursos/${response.data.records.cod_evento}/editar`);
      }, 2000);
    } catch (err) {
      setLoading(false);
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  return (
    <Modal
      centered
      className="modal-new-event"
      dialogClassName="modal-event"
      show={show}
      onHide={handleClose}
    >
      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        initialData={{
          tipo_eve: '2',
          local_eve: '1',
        }}
      >
        <Modal.Header>
          <div className="media align-items-center align-items-md-start flex-column flex-md-row">
            <span className="text-teal mr-md-3 mb-3 mb-md-0">
              <i className="icon-graduation2 text-warning-400 border-warning-400 border-2 rounded-round p-2 mt-1"></i>
            </span>
            <div className="media-body text-center text-md-left">
              <h3 className="media-title font-weight-semibold">Criar Curso</h3>
              <span className="text-muted">
                Crie um curso de maneira rápida!
              </span>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label htmlFor="titulo_eve">Nome *</label>
            <Input
              type="hidden"
              id="tipo_eve"
              name="tipo_eve"
              defaultValue="2"
            />
            <Input
              type="hidden"
              id="local_eve"
              name="local_eve"
              defaultValue="1"
            />
            <Input
              required
              type="text"
              id="titulo_eve"
              name="titulo_eve"
              placeholder="Entre com o nome de curso..."
            />
          </div>
          {/* <div className="form-group">
            <label htmlFor="desc_eve">Descrição</label>
            <Textarea
              type="text"
              rows="5"
              id="desc_eve"
              name="desc_eve"
              placeholder="Entre com a descrição do seu curso..."
            />
          </div> */}
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button
            block
            size="lg"
            variant="link"
            className="text-secondary"
            onClick={handleClose}
          >
            <i className="icon-cross2 mr-1 font-weight-light"></i>
            Cancelar
          </Button>
          <Button size="lg" block disabled={!!loading} type="submit">
            {loading ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="mr-1"
                />
                Aguarde
              </>
            ) : (
              'Criar Curso'
            )}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
