import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';

import { resetModalRequest, signOut } from '~/store/modules/auth/actions';

import LoginUser from '~/components/LoginUser';
import Loading from '~/components/Loading';

export default function HeaderExternal() {
  const dispatch = useDispatch();
  const [showLoginUser, setShowLoginUser] = useState(false);
  const [load, setLoad] = useState(false);

  const handleCloseLoginUser = () => setShowLoginUser(false);

  const { signed } = useSelector(state => state.auth);
  const { profile } = useSelector(state => state.user);

  function handleShowLoginUser() {
    setLoad(true);
    dispatch(resetModalRequest());
    setTimeout(() => {
      setShowLoginUser(true);
      setLoad(false);
    }, 1000);
  }

  function handleSignOut() {
    dispatch(signOut());
  }

  return (
    <div
      className="navbar navbar-expand-md navbar-dark fixed-top justify-content-between align-items-center p-0"
      style={{ zIndex: 1, position: 'absolute' }}
    >
      <Link to="/" className="pl-5 pb-1">
        <img
          width={150}
          src={require('../../../assets/img/psicoMart11.png')}
          alt="Logo"
        />
      </Link>
      <div className="nav-item dropdown dropdown-user">
        {signed ? (
          <>
            <a
              href="#user"
              className="navbar-nav-link d-flex align-items-center dropdown-toggle"
              data-toggle="dropdown"
            >
              {profile.img_perfil ? (
                <img
                  src={profile.img_perfil}
                  className="rounded-circle mr-2"
                  height="34"
                  alt=""
                />
              ) : (
                <div className="mr-1">
                  <div className="btn bg-teal-400 rounded-round btn-icon">
                    <span className="letter-icon">
                      {profile.nome_membro.substring(0, 1)}
                    </span>
                  </div>
                </div>
              )}
              <span>{profile.nome_membro}</span>
            </a>
            <div className="dropdown-menu dropdown-menu-right">
              <Link to="/profile" className="dropdown-item">
                <i className="icon-user-plus"></i> Meu Perfil
              </Link>
              <div className="dropdown-divider"></div>

              <Link
                to="route"
                onClick={handleSignOut}
                className="dropdown-item"
              >
                <i className="icon-switch2"></i> Sair
              </Link>
            </div>
          </>
        ) : (
          <>
            <Button
              style={{ width: 90, height: 40 }}
              variant="link"
              className="navbar-nav-link mr-4"
              onClick={handleShowLoginUser}
            >
              {load ? (
                <Loading size="sm" variant="light" />
              ) : (
                <h6 className="mb-0">Login</h6>
              )}
            </Button>
            <LoginUser
              show={showLoginUser}
              handleClose={handleCloseLoginUser}
            />
          </>
        )}
      </div>
    </div>
  );
}
