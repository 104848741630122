import React from 'react';

export default function Notfound() {
  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center mb-3"
      style={{ height: 'calc(100vh - 150px)' }}
    >
      <h1>Ops!</h1>
      <h5>
        Caro usuário o evento que você está tentando acessar ainda não foi
        publicado.
      </h5>
    </div>
  );
}
