import produce from 'immer';

const INITIAL_STATE = {
  events: [],
  event: {},
  loading: true,
};

export default function event(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@events/GET_EVENTS_BY_TAG_REQUEST':
      case '@events/GET_EVENTS_REQUEST': {
        draft.loading = true;
        break;
      }

      case '@events/GET_EVENTS_BY_TAG_SUCCESS':
      case '@events/GET_EVENTS_SUCCESS': {
        draft.loading = false;
        draft.event = action.payload;
        break;
      }

      case '@events/LIST_EVENTS_REQUEST': {
        draft.loading = true;
        break;
      }

      case '@events/LIST_EVENTS_SUCCESS': {
        draft.loading = false;
        draft.events = action.payload;
        break;
      }

      case '@events/POST_EVENTS_REQUEST': {
        draft.loading = true;
        break;
      }

      case '@events/POST_EVENTS_SUCCESS': {
        draft.loading = false;
        break;
      }

      case '@events/GET_EVENTS_BY_TAG_FAILURE':
      case '@events/LIST_EVENTS_FAILURE':
      case '@events/POST_EVENTS_FAILURE':
      case '@events/GET_EVENTS_FAILURE': {
        draft.loading = false;
        break;
      }

      default:
    }
  });
}
