import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import * as CourseActions from './actions';

export function* coursesGetRequest({ payload }) {
  try {
    const response = yield call(api.get, `/evento/${payload}`);
    if (response.data.records.error) {
      toast.error(response.data.records.msg);
      yield put(CourseActions.coursesGetFailure());
      return;
    }
    yield put(CourseActions.coursesGetSuccess(response.data.records));
  } catch (err) {
    toast.error('Falha na comunicação com servidor!');
    yield put(CourseActions.coursesGetFailure());
  }
}

export function* listCoursesRequest() {
  try {
    const response = yield call(
      api.get,
      `/evento/meus-eventos/?q=(tipo_eve:2)`
    );
    if (response.data.records.error) {
      toast.error(response.data.records.msg);
      yield put(CourseActions.coursesListFailure());
    }
    yield put(CourseActions.coursesListSuccess(response.data.records));
  } catch (err) {
    toast.error('Falha na comunicação com servidor!');
    yield put(CourseActions.coursesListFailure());
  }
}

export default all([
  takeLatest('@courses/GET_COURSES_REQUEST', coursesGetRequest),
  takeLatest('@courses/LIST_COURSES_REQUEST', listCoursesRequest),
]);
