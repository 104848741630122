import React, { useRef, useEffect } from 'react';
import { useField } from '@unform/core';
import NumberFormat from 'react-number-format';

export default function InputDecimal({ name, group, ...rest }) {
  const inputRef = useRef(null);

  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <>
      {group ? (
        <>
          <div className="input-group">
            <NumberFormat
              getInputRef={inputRef}
              className={error ? 'form-control border-danger' : 'form-control'}
              defaultValue={defaultValue}
              displayType="input"
              fixedDecimalScale
              decimalScale={2}
              thousandSeparator="."
              decimalSeparator=","
              {...rest}
            />
            <div className="input-group-append">
              <span className="input-group-text text-secondary">%</span>
            </div>
          </div>
          {error && <span className="form-text text-danger">{error}</span>}
        </>
      ) : (
        <>
          <NumberFormat
            getInputRef={inputRef}
            className={error ? 'form-control border-danger' : 'form-control'}
            defaultValue={defaultValue}
            displayType="input"
            fixedDecimalScale
            decimalScale={2}
            thousandSeparator=","
            decimalSeparator="."
            {...rest}
          />
          {error && <span className="form-text text-danger">{error}</span>}
        </>
      )}
    </>
  );
}
