import React from 'react';

import { Switch } from 'react-router-dom';

import Route from './Route';
import routes from './routes';

import SignIn from '~/pages/SignIn';

function Routes() {
  return (
    <Switch>
      <Route exact name="Home" component={SignIn} path="/" />
      {routes.map(item => (
        <Route {...item} key={Math.random()} />
      ))}
    </Switch>
  );
}

export default Routes;
