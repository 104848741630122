import React, { useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Modal, Button } from 'react-bootstrap';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import ReactHtmlParser from 'react-html-parser';

import api from '~/services/api';

import InputGroup from '~/components/InputGroup';
import Input from '~/components/InputText';
import InputDecimal from '~/components/InputDecimal';
import Loading from '~/components/Loading';

export default function CreateAffiliate({ data, loading }) {
  const formRef = useRef();
  const saveRef = useRef();
  const { cod_evento, cod_produto, tipo_eve } = data;
  const [show, setShow] = useState(false);
  const [affiliate, setAffiliate] = useState({});
  const [load, setLoad] = useState(false);

  const handleClose = useCallback(() => {
    setShow(false);
  }, []);

  const handleSubmit = useCallback(
    async value => {
      try {
        setLoad(true);
        formRef.current.setErrors({});
        const schema = Yup.object().shape({
          email_membro: Yup.string()
            .email('Email digitado é invalido')
            .required('Email do afiliado é obrigatorio'),
        });
        await schema.validate(value, {
          abortEarly: false,
        });

        api
          .post('/afiliacao/verificar-afiliado', {
            email_membro: value.email_membro,
            cod_produto,
            cod_evento,
            tipo_eve,
          })
          .then(response => {
            if (response.data.records.error) {
              toast.error(response.data.records.msg);
              setLoad(false);
              return false;
            }
            setAffiliate(response.data.records);
            setLoad(false);
            return false;
          });
      } catch (err) {
        const validationErrors = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            validationErrors[error.path] = error.message;
          });
          formRef.current.setErrors(validationErrors);
        }
        setLoad(false);
      }
    },
    [cod_evento, cod_produto, tipo_eve]
  );

  const handleSave = useCallback(
    async value => {
      try {
        setLoad(true);
        saveRef.current.setErrors({});
        const schema = Yup.object().shape({
          valor_comissao: Yup.string().required('Comissão é obrigatória'),
          url_afiliacao: Yup.string().required('URL é obrigatoria'),
        });
        await schema.validate(value, {
          abortEarly: false,
        });

        api
          .put(`/afiliacao/salvar-afiliado/${affiliate.cod_afiliacao}`, {
            cod_afiliacao: affiliate.cod_afiliacao,
            status: 1,
            valor_comissao: value.valor_comissao,
            url_afiliacao: value.url_afiliacao,
          })
          .then(response => {
            if (response.data.records.error) {
              toast.error(response.data.records.msg);
              setLoad(false);
              return false;
            }
            toast.success('Afiliado cadastrado com sucesso');
            setLoad(false);
            handleClose();
            loading();
            setAffiliate({});
            return false;
          });
      } catch (err) {
        const validationErrors = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            validationErrors[error.path] = error.message;
          });
          setLoad(false);
          saveRef.current.setErrors(validationErrors);
        }
      }
    },
    [affiliate.cod_afiliacao, handleClose, loading]
  );

  return (
    <>
      <Button variant="success" size="sm" onClick={() => setShow(true)}>
        <i className="icon-plus2"></i> Criar afiliado
      </Button>
      <Modal
        size="sm"
        centered
        show={show}
        onHide={handleClose}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Cadastrar afiliado</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Row>
              <Col>
                <label>Email do afiliado</label>
                <div className="input-group mb-3">
                  <InputGroup
                    button
                    id="email_membro"
                    name="email_membro"
                    label="Vincular"
                    placeholder="Digite o email do afiliado"
                    load={load}
                  />
                </div>
              </Col>
            </Row>
          </Form>
          {load ? (
            <Loading />
          ) : (
            Object.keys(affiliate).length !== 0 && (
              <Form ref={saveRef} onSubmit={handleSave}>
                <Row>
                  <Col>
                    <div className="alert alert-info border-0 alert-dismissible mb-0 text-center">
                      {ReactHtmlParser(affiliate.msg)}
                    </div>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col xl={12}>
                    <div className="form-group">
                      <label>Comissão</label>
                      <InputDecimal name="valor_comissao" group />
                    </div>
                  </Col>
                  <Col>
                    <div className="form-group">
                      <label>URL</label>
                      <Input name="url_afiliacao" />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className="text-center">
                    <Button
                      className="w-25"
                      size="lg"
                      type="submit"
                      disabled={load}
                    >
                      {load ? 'Aguarde...' : 'Salvar'}
                    </Button>
                  </Col>
                </Row>
              </Form>
            )
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

CreateAffiliate.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
  loading: PropTypes.func.isRequired,
};

CreateAffiliate.defaultProps = {
  data: {},
};
