import React from 'react';
import ContentLoader from 'react-content-loader';

export default function LoaderPost() {
  return (
    <div className="card-body">
      <ContentLoader
        height={150}
        width={1000}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#e7e7e7"
      >
        <rect x="70" y="15" rx="4" ry="4" width="150" height="8" />
        <rect x="70" y="35" rx="3" ry="3" width="85" height="8" />
        <rect x="0" y="80" rx="3" ry="3" width="580" height="8" />
        <rect x="0" y="100" rx="3" ry="3" width="550" height="8" />
        <rect x="0" y="120" rx="3" ry="3" width="500" height="8" />
        <circle cx="31" cy="31" r="30" />
      </ContentLoader>
      <ContentLoader height={150} width={1000} speed={2}>
        <circle cx="50" cy="60" r="30" />
        <rect x="90" y="35" width="125.5" height="8" />
        <rect x="90" y="55" width="296" height="8" />
        <rect x="90" y="75" width="253.5" height="8" />
      </ContentLoader>
    </div>
  );
}
