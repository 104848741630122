import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import api from '~/services/api';

export default function ButtonTakePart({ group, takePart }) {
  const [take, setTake] = useState(group.entrou);

  const handleAssociate = useCallback(
    async (action, id) => {
      try {
        const response = await api.post('/seguidor-mod', {
          acao: action,
          cod_item: id,
          cod_onde_seguir: 2,
        });

        if (response.data.records.error) {
          toast.error(response.data.records.msg);
        }
        takePart();

        if (action === 1) {
          setTake(true);
        } else {
          setTake(false);
        }
      } catch (error) {
        toast.error('Falha na comunicação com servidor');
      }
    },
    [setTake, takePart]
  );

  return (
    <>
      {take ? (
        <button
          onClick={() => handleAssociate(2, group.cod_grupo)}
          type="button"
          className="btn btn-sm btn-light mt-auto text-success"
        >
          <i className="icon-checkmark2 mr-1"></i> Entrou
        </button>
      ) : (
        <button
          onClick={() => handleAssociate(1, group.cod_grupo)}
          type="button"
          className="btn btn-sm btn-light mt-auto"
        >
          <i className="icon-plus2 mr-1"></i>Participar
        </button>
      )}

      {/* <button
          onClick={() => handleAssociate(2, group.cod_grupo)}
          type="button"
          className="btn btn-sm btn-light mt-auto"
        >
          Solicitado
        </button> */}
    </>
  );
}

ButtonTakePart.prototype = {
  group: PropTypes.object,
  takePart: PropTypes.func,
};

ButtonTakePart.defaultProps = {
  group: [],
};
