import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as LayoutsActions from '~/store/modules/layout/actions';

function AuthRoles() {
  const dispatch = useDispatch();
  const { profile } = useSelector(state => state.user);

  const loadMenus = useCallback(() => {
    if (profile) {
      dispatch(LayoutsActions.menusGetRequest());
    }
  }, [dispatch, profile]);

  useEffect(() => {
    loadMenus();
  }, [loadMenus]);

  return true;
}

export default AuthRoles;
