import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Loader from '~/components/LoaderList';

function MyGroups() {
  const loading = useSelector(state => state.mods.loading);
  const { groups } = useSelector(state => state.mods);

  return (
    <div className="card">
      <ul className="list-group noborder">
        <li className="list-group-item font-weight-semibold">
          MEUS GRUPOS
          <div className="ml-auto user-like">
            <Link to="#">
              <i className="icon-plus3  text-dark"></i>
            </Link>
          </div>
        </li>
        {loading ? (
          <Loader />
        ) : (
          <>
            {groups.map(group => (
              <Link to={'/groups/' + group.cod_item} key={group.cod_item}>
                <li
                  className="list-group-item list-interesse"
                  style={{ cursor: 'pointer' }}
                >
                  <i className="icon-grid5 font-size-xs  mr-1"></i>{' '}
                  {group.nome_grupo}
                  <span className="badge badge-pill bg-pink-400 ml-auto">
                    1
                  </span>
                </li>
              </Link>
            ))}
          </>
        )}
      </ul>
      <span
        className={
          groups.length >= 5
            ? 'list-group-item font-weight-semibold nopadding-top'
            : 'd-none'
        }
      >
        <Link to="/groups" className="text-dark">
          Ver Mais &gt;&gt;
        </Link>
      </span>
    </div>
  );
}

export default MyGroups;
