import React from 'react';
import PropTypes from 'prop-types';
import Spinner from 'react-bootstrap/Spinner';

export default function Loading({ animation, variant, size }) {
  return (
    <div className="d-flex justify-content-center">
      <Spinner animation={animation} variant={variant} size={size} />
    </div>
  );
}
Loading.propTypes = {
  animation: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
};

Loading.defaultProps = {
  animation: 'border',
  variant: 'dark',
  size: null,
};
