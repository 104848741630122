import React, { useRef, useState, useCallback } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import api from '~/services/api';

import Input from '~/components/InputText';

export default function Stock({ data }) {
  const formRef = useRef(null);
  const [load, setLoad] = useState(false);

  const handleSubmit = useCallback(async data => {
    setLoad(true);
    try {
      formRef.current.setErrors({});
      const schema = Yup.object().shape({
        prod_estoque: Yup.string().required('Estoque atual é obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const response = await api.post(`/produto/atualizar-estoque`, data);

      if (response.data.records.error) {
        toast.error(response.data.records.msg);
        setLoad(false);
        return;
      }

      toast.success('Estoque atulizado com sucesso');
      setLoad(false);
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
        setLoad(false);
      }
    }
  }, []);

  return (
    <Form
      ref={formRef}
      onSubmit={handleSubmit}
      initialData={{
        cod_produto: data.cod_produto,
        prod_estoque: data.prod_estoque,
        prod_estoque_min: data.prod_estoque_min,
      }}
    >
      <Row className="mt-4">
        <Col>
          <div className="form-group">
            <label>Estoque atual</label>
            <Input type="hidden" name="cod_produto" />
            <Input
              name="prod_estoque"
              placeholder="Digite aqui o estoque atual"
            />
          </div>
        </Col>
        <Col>
          <div className="form-group">
            <label>Estoque mínimo</label>
            <Input
              name="prod_estoque_min"
              placeholder="Digite aqui o estoque mínimo"
            />
          </div>
        </Col>
        <Col xl={12}>
          <Row className="justify-content-md-center">
            <Col xl={2}>
              <Button
                disabled={load}
                size="lg"
                block
                type="submit"
                variant="primary"
              >
                {load ? 'Aguarde...' : 'Salvar'}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
}
