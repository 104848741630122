import React from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

import UploadFiles from '~/components/UploadFiles';

export default function Material() {
  const { id } = useParams();
  return (
    <Row>
      <Col xl={12}>
        <div className="form-group">
          <label htmlFor="">Anexar arquivos para download</label>
          <UploadFiles id={id} />
        </div>
      </Col>
    </Row>
  );
}
