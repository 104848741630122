/* eslint-disable react/no-danger */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import { toast } from 'react-toastify';
import { isMobile } from 'react-device-detect';
import { Button, Container, Table, Row, Col } from 'react-bootstrap';

import * as EventActions from '~/store/modules/event/actions';
import { ehCobrado, formatCurrencyValue, adjustTopApplication } from '~/util';

import history from '~/services/history';
import bannerHolder from '~/assets/img/banner-holder.png';

import Loading from '~/components/Loading';
import BottomButton from '~/components/BottomButton';
import WhatsAppButton from '~/components/WhatsAppButton/WhatsAppButton';

import noImage from '~/assets/img/profile.png';

export default function Show(props) {
  const { match } = props;
  const dispatch = useDispatch();

  const event = useSelector(state => state.event.event);
  const loading = useSelector(state => state.event.loading);

  const [labels, setLabels] = useState({});
  useEffect(() => {
    const { params } = match;
    adjustTopApplication();
    dispatch(EventActions.eventsGetByTagRequest(params.tag));
    if (params.type === 'curso') {
      setLabels({
        lb000: 'Curso',
        lb001: 'Cursos',
        lb002: 'Ministrante',
        lb003: 'Ministrantes',
      });
    }
    if (params.type === 'evento') {
      setLabels({
        lb000: 'Evento',
        lb001: 'Eventos',
        lb002: 'Palestrante',
        lb003: 'Palestrantes',
      });
    }
  }, [dispatch, match]);

  function getTicketsEvent(e, idTicket) {
    const aliasName = `nome_ing${idTicket}`;
    const aliasPrice = `val${idTicket}`;
    if (e[aliasPrice]) {
      let ticketName = e[aliasName];
      if (ticketName === null || ticketName === '') {
        ticketName = `Ingresso ${idTicket}`;
      }
      return (
        <tr>
          <td>{ticketName}</td>
          <td className="text-right">
            <span>{formatCurrencyValue(e[aliasPrice])}</span>
          </td>
        </tr>
      );
    }
    return null;
  }

  function getAffiliate() {
    const { params } = match;
    if (params.afiliado) {
      return params.afiliado;
    }
    return '';
  }

  function handleSignUp() {
    const { params } = match;
    if (event.dt_expirada) {
      toast.error(
        'Não se pode efetuar a inscrição neste evento pois o mesmo se encontra expirado!'
      );
    } else {
      const url = `/ticket/${params.type}/${event.url_amig}/${getAffiliate()}`;
      history.push(url);
    }
  }

  return (
    <div className="box-event">
      {loading ? (
        <div className="mt-5">
          <Loading />
        </div>
      ) : (
        <>
          {event.img_eve && (
            <div className="profile-cover bg-wrapper mt-3">
              <div className="event-banner">
                <img
                  alt={event.titulo_eve}
                  src={bannerHolder}
                  srcSet={`${bannerHolder} 500w, ${bannerHolder} 1600w`}
                  sizes="(max-width: 991px) 100vw, 95vw"
                  style={{
                    backgroundImage: `url('${event.img_eve}')`,
                    zIndex: 1,
                  }}
                />
              </div>
              <div
                className="d-flex bg-content justify-content-center align-items-center"
                style={{
                  background: `url('${event.img_eve}')`,
                }}
              ></div>
            </div>
          )}

          <Container className={isMobile ? `pt-0` : `pt-5`}>
            <div className="row">
              {event.status_eve === '1' ? (
                <>
                  <div className="col-xl-8">
                    <div className="card no-border no-shadow mb-1">
                      <div className="card-body">
                        <h1 className="mb-3 mt-2 font-weight-black event-name">
                          {event.titulo_eve}
                        </h1>

                        {event.dt_eve ? (
                          <span className="text-information d-flex">
                            <i className="icon-calendar2"></i>
                            <span>{event.dt_eve_desc}</span>
                          </span>
                        ) : null}

                        {event.eh_online === '1' ? (
                          <span className="text-information d-flex">
                            <i className="icon-location4"></i>
                            <span>{labels.lb000} Online</span>
                          </span>
                        ) : (
                          <>
                            {event.local_eve_desc && (
                              <>
                                <span className="text-information d-flex">
                                  <i className="icon-location4"></i>
                                  <span>{event.local_eve_desc}</span>
                                </span>
                                <span className="text-information d-flex">
                                  <i className="icon-city"></i>
                                  <span>
                                    {event.end_eve}, {event.nro_eve},{' '}
                                    {event.nome_cidade_eve}
                                  </span>
                                </span>
                              </>
                            )}
                          </>
                        )}

                        <h3 className="heading mt-4 mb-3 font-weight-light">
                          Descrição do {labels.lb000}
                        </h3>
                        <p
                          dangerouslySetInnerHTML={{ __html: event.desc_eve }}
                        />
                        {event.palestrantes.length > 0 && (
                          <>
                            <h3 className="heading mt-4 mb-3 font-weight-light">
                              {event.palestrantes.length > 1
                                ? labels.lb003
                                : labels.lb002}
                            </h3>

                            <ul className="media-list">
                              {event.palestrantes.map(palestrante => (
                                <li className="media" style={{ minHeight: 85 }}>
                                  {palestrante.img_palestrante && (
                                    <div className="mr-2">
                                      <img
                                        src={palestrante.img_palestrante}
                                        alt=""
                                        className="rounded-circle"
                                        width="50"
                                      />
                                    </div>
                                  )}
                                  <div className="media-body">
                                    <div className="media-title font-weight-semibold">
                                      {palestrante.nome_palestrante}
                                    </div>
                                    <span className="text-muted">
                                      {palestrante.desc_palestrante}
                                    </span>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </>
                        )}

                        <h3 className="heading mt-4 mb-3 font-weight-light">
                          Sobre o Organizador
                        </h3>
                        <Row>
                          <Col md={2} className="text-center">
                            {event.img_organiz === '0' ? (
                              <img
                                src={noImage}
                                alt=""
                                width={70}
                                className="rounded-circle border-1 text-muted"
                              />
                            ) : (
                              <img
                                src={event.img_organiz}
                                alt=""
                                width={100}
                                className="rounded-circle"
                              />
                            )}
                          </Col>
                          <Col>
                            <p>
                              <span className="mb-0 font-weight-semibold">
                                {event.nome_organiz || 'Sem informações'}
                              </span>
                              {event.nome_organiz && (
                                <>
                                  <span
                                    className="pt-2 d-block"
                                    dangerouslySetInnerHTML={{
                                      __html: event.desc_organiz,
                                    }}
                                  />
                                </>
                              )}
                            </p>
                          </Col>
                        </Row>
                        <p>
                          {event.tel_organiz !== null &&
                            event.tel_organiz !== '' && (
                              <span
                                className="pt-2"
                                style={{ display: 'block' }}
                              >
                                <WhatsAppButton phone={event.tel_organiz} />
                              </span>
                            )}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-4 mb-5">
                    <div className="card border-grey-custom mt-3">
                      <div className="card-header bg-grey-custom header-elements-inline">
                        <h4 className="card-title text-dark">
                          <i className="icon-cart2 mr-1 icon-1x"></i> Inscrição
                        </h4>
                      </div>
                      <div className="card-body p-0">
                        <Table striped hover className="table-custom">
                          <tbody>
                            {ehCobrado(event) ? (
                              <>
                                {getTicketsEvent(event, '1')}
                                {getTicketsEvent(event, '2')}
                                {getTicketsEvent(event, '3')}
                              </>
                            ) : (
                              <tr>
                                <td colSpan="2" className="text-left">
                                  <span>{`${labels.lb000} Gratuito`}</span>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>

                        {!isMobile ? (
                          <span className="nav-link text-dark">
                            <Button
                              size="lg"
                              type="button"
                              variant="success"
                              onClick={() => handleSignUp()}
                              className="btn btn-lg btn-success btn-block font-weight-bold btn-custom text-uppercase mb-2"
                            >
                              Inscrever
                            </Button>
                          </span>
                        ) : (
                          <BottomButton
                            type="button"
                            text="Inscrever"
                            onClick={() => handleSignUp()}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="col-xl-12">
                    <div className="card no-border">
                      <div className="card-body">
                        <h1 className="mb-3 mt-3 font-weight-black event-name">
                          {event.titulo_eve}
                        </h1>
                        <div className="alert alert-info alert-styled-left alert-dismissible">
                          <span className="font-weight-semibold">
                            Caro usuário:
                          </span>{' '}
                          <span>
                            O {labels.lb000} {event.titulo_eve} ainda não foi
                            publicado!
                          </span>
                          <br />
                          <span>
                            Aguarde por mais informações ou entre em{' '}
                            <a
                              href="mailto:joseguilhermeha@gmail.com"
                              className="alert-link"
                            >
                              contato
                            </a>{' '}
                            com o organizador!
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </Container>
        </>
      )}
    </div>
  );
}

Show.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      tag: PropTypes.string,
      type: PropTypes.string,
      afiliado: PropTypes.string,
    }),
  }).isRequired,
};
