import produce from 'immer';

const INITIAL_STATE = {
  files: [],
  classes: [],
  journey: [],
  query: '',
  classCourse: null,
  loading: false,
  idCourseClass: 0,
};

export default function inventory(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@inventory/LIST_CLASSES_MEMBER_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@inventory/LIST_CLASSES_MEMBER_SUCCESS': {
        draft.loading = false;
        draft.classes = action.payload;
        break;
      }
      case '@inventory/GET_JOURNEY_CLASS_REQUEST': {
        draft.loading = true;
        draft.idCourseClass = action.payload;
        break;
      }
      case '@inventory/GET_JOURNEY_CLASS_SUCCESS': {
        draft.loading = false;
        draft.journey = action.payload;
        break;
      }
      case '@inventory/GET_JOURNEY_CLASS_FAILURE':
      case '@inventory/LIST_CLASSES_MEMBER_FAILURE':
      case '@inventory/GET_FILES_BY_MODULE_FAILURE': {
        draft.loading = false;
        break;
      }
      case '@inventory/SET_CLASS_COURSE_TO_JOURNEY': {
        draft.loading = false;
        draft.classCourse = action.payload;
        break;
      }
      case '@inventory/CHANGE_STATUS_CLASS_COURSE': {
        draft.loading = false;
        draft.journey = action.payload;
        break;
      }
      case '@inventory/GET_FILES_BY_MODULE_REQUEST': {
        draft.loading = false;
        draft.query = action.payload;
        break;
      }
      case '@inventory/GET_FILES_BY_MODULE_SUCCESS': {
        draft.loading = false;
        draft.files = action.payload;
        break;
      }
      default: {
        break;
      }
    }
  });
}
