/* eslint-disable import/no-cycle */

import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import * as InventoryActions from './actions';

export function* inventoryClassesMember() {
  try {
    const response = yield call(api.get, `/evento/listar-membro-turma`);
    if (response.data.records.error) {
      toast.error(response.data.records.msg);
      yield put(InventoryActions.inventoryClassesMemberFailure());
    }
    yield put(
      InventoryActions.inventoryClassesMemberSuccess(response.data.records)
    );
  } catch (err) {
    toast.error('Falha na comunicação com servidor!');
    yield put(InventoryActions.inventoryClassesMemberFailure());
  }
}

export function* inventoryGetJourneyClass({ payload }) {
  try {
    const response = yield call(
      api.get,
      `/evento/listar-membro-modulo/?q=(cod_turma_curso:${payload})`
    );
    if (response.data.records.error) {
      toast.error(response.data.records.msg);
      yield put(InventoryActions.inventoryGetJourneyClassFailure());
    }
    yield put(
      InventoryActions.inventoryGetJourneyClassSuccess(response.data.records)
    );
  } catch (err) {
    toast.error('Falha na comunicação com servidor!');
    yield put(InventoryActions.inventoryGetJourneyClassFailure());
  }
}

export function* inventoryGetFilesByModule({ payload }) {
  try {
    const response = yield call(
      api.get,
      `/evento/listar-evento-arq/${payload}`
    );
    if (response.data.records.error) {
      toast.error(response.data.records.msg);
      yield put(InventoryActions.inventoryGetFilesByModuleFailure());
    }
    yield put(
      InventoryActions.inventoryGetFilesByModuleSuccess(response.data.records)
    );
  } catch (err) {
    toast.error('Falha na comunicação com servidor!');
    yield put(InventoryActions.inventoryGetFilesByModuleFailure());
  }
}

export default all([
  takeLatest('@inventory/LIST_CLASSES_MEMBER_REQUEST', inventoryClassesMember),
  takeLatest('@inventory/GET_JOURNEY_CLASS_REQUEST', inventoryGetJourneyClass),
  takeLatest(
    '@inventory/GET_FILES_BY_MODULE_REQUEST',
    inventoryGetFilesByModule
  ),
]);
