export function modulesGetByCourseRequest(id) {
  return {
    type: '@modules/GET_MODULES_BY_COURSE_REQUEST',
    payload: id,
  };
}

export function modulesGetByCourseSuccess(data) {
  return {
    type: '@modules/GET_MODULES_BY_COURSE_SUCCESS',
    payload: data,
  };
}

export function modulesGetByCourseFailure() {
  return {
    type: '@modules/GET_MODULES_BY_COURSE_FAILURE',
  };
}

export function modulesGetRequest(query) {
  return {
    type: '@modules/GET_MODULE_REQUEST',
    payload: query,
  };
}

export function modulesGetSuccess(data) {
  return {
    type: '@modules/GET_MODULE_SUCCESS',
    payload: data,
  };
}

export function modulesGetFailure() {
  return {
    type: '@modules/GET_MODULE_FAILURE',
  };
}

export function moduleClearRequest() {
  return {
    type: '@modules/CLEAR_MODULE_REQUEST',
  };
}
