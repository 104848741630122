import styled from 'styled-components';

export const TextEditor = styled.div`
  .ql-editor {
    height: 16em;
    max-width: 100em;
  }
`;

export const AvatarInput = styled.div`
  position: relative;
  width: 100%;
  max-width: 120px;

  img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
  }

  label {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #eee;
    right: 0;
    bottom: 0;
    border: 0;
    color: #666;
    margin-bottom: 0;
    cursor: pointer;

    input {
      display: none;
    }
  }
`;

export const New = styled.small`
  font-size: 11px;
  margin-left: 10px;
  cursor: pointer;
`;
