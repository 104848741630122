import React, { useState, useRef, useCallback } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Form as FormEmail } from '@unform/web';
import { Form, Input } from '@rocketseat/unform';
import * as Yup from 'yup';

import { toast } from 'react-toastify';
import { signInRequest } from '~/store/modules/auth/actions';

import api from '~/services/api';
import InputText from '~/components/InputText';

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Insira um e-mail válido')
    .required('O e-mail é obrigatório'),
  password: Yup.string().required('A senha é obrigatória'),
});

export default function SignIn() {
  const formRef = useRef(null);
  const [show, setShow] = useState(false);
  const [load, setLoad] = useState(false);
  const dispatch = useDispatch();
  const loading = useSelector(state => state.auth.loading);

  const handleSubmit = useCallback(
    ({ email, password }) => {
      const site = 1;
      dispatch(signInRequest(email, password, site));
    },
    [dispatch]
  );

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleRecover = useCallback(async data => {
    setLoad(true);
    try {
      formRef.current.setErrors({});
      const schemaIntern = Yup.object().shape({
        email_membro: Yup.string()
          .email('Email invalido.')
          .required('Email é obrigatório.'),
      });

      await schemaIntern.validate(data, {
        abortEarly: false,
      });

      const response = await api.post(
        '/auth/enviar-recuperar-senha-membro',
        data
      );

      if (response.data.records.error) {
        toast.error(response.data.records.msg);
        setLoad(false);
        return;
      }
      toast.success(response.data.records.msg);
      setLoad(false);
      handleClose();
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
        setLoad(false);
      }
    }
  }, []);

  return (
    <>
      <Form schema={schema} onSubmit={handleSubmit} className="login-form">
        <div className="card mb-0">
          <div className="card-body">
            <div className="text-center mb-3">
              <img
                width={180}
                src={require('../../assets/img/psicoMart22.png')}
                alt="Logo PsicoMart"
              />
              {/* <i className="icon-reading icon-2x text-slate-300 border-slate-300 border-3 rounded-round p-3 mb-3 mt-1"></i> */}
              <h5 className="mb-0 mt-3">Faça login na sua conta</h5>
              {/* <span className="d-block text-muted">
                Digite suas credenciais abaixo
              </span> */}
            </div>

            <div className="form-group form-group-feedback form-group-feedback-left">
              <Input
                name="email"
                type="text"
                className="form-control"
                placeholder="E-mail"
              />
              <div className="form-control-feedback">
                <i className="icon-mail5 text-muted"></i>
              </div>
            </div>

            <div className="form-group form-group-feedback form-group-feedback-left">
              <Input
                name="password"
                type="password"
                className="form-control"
                placeholder="Senha"
              />
              <div className="form-control-feedback">
                <i className="icon-lock2 text-muted"></i>
              </div>
            </div>

            <div className="form-group">
              <Button
                type="submit"
                variant="primary"
                block
                size="lg"
                disabled={loading}
              >
                {loading ? 'Aguarde... ' : 'Entrar '}
                <i className="icon-circle-right2 ml-1"></i>
              </Button>
            </div>

            <p className="text-center">
              <Button variant="link" type="button" onClick={handleShow}>
                Esqueceu a senha?
              </Button>
            </p>
          </div>
        </div>
      </Form>
      <Modal size="sm" centered show={show} onHide={handleClose}>
        <FormEmail ref={formRef} onSubmit={handleRecover}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="text-center mb-3">
              <i className="icon-spinner11 icon-2x text-warning border-warning border-3 rounded-round p-3 mb-3 mt-1"></i>
              <h5 className="mb-0">Recuperar Senha</h5>
              <span className="d-block text-muted">
                Enviaremos as instruções por e-mail
              </span>
            </div>

            <div className="form-group form-group-feedback form-group-feedback-right">
              <InputText type="hidden" name="site" defaultValue="1" />
              <InputText name="email_membro" placeholder="Digite seu email" />
              <div className="form-control-feedback">
                <i className="icon-mail5 text-muted"></i>
              </div>
            </div>

            <Button
              type="submit"
              variant="primary"
              block
              size="lg"
              disabled={!!load}
            >
              {load ? 'Aguarde...' : 'Redefinir senha'}
            </Button>
          </Modal.Body>
        </FormEmail>
      </Modal>
    </>
  );
}
