import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import MyInterest from '~components/MyInterest';
import MyGroups from '~components/MyGroups';
import Explorar from '~components/Explorar';
import Post from '~/components/Post';

import * as ModsActions from '~/store/modules/mods/actions';
import * as InterestActions from '~/store/modules/interest/actions';
import RecentEvents from '~/components/RecentEvents';
import Followers from '~/components/Followers';

export default function Main() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(ModsActions.modsRequest(2));
    dispatch(InterestActions.interestRequest(1));
  }, [dispatch]);

  return (
    <>
      <div className="content">
        <div className="row padding-tab-top-post">
          <div className="col">
            <div className="card">
              <ul className="list-group noborder">
                <Link to="/feed">
                  <li className="list-group-item list-interesse c-pointer">
                    <i className="icon-home2 font-size-xs  mr-1"></i>Home
                  </li>
                </Link>
                <a href="/">
                  <li className="list-group-item list-interesse c-pointer">
                    <i className="icon-earth font-size-xs  mr-1"></i>Todos Feeds
                  </li>
                </a>
              </ul>
            </div>

            <MyInterest />

            <MyGroups />

            <Explorar />
          </div>

          <div className="col-xl-7">
            <div className="card">
              <div className="card-body">
                <ul className="nav nav-tabs nav-tabs-bottom nav-justified">
                  <li className="nav-item">
                    <a
                      href="#bottom-justified-tab1"
                      className="nav-link active show"
                      data-toggle="tab"
                    >
                      Geral
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="#bottom-justified-tab2"
                      className="nav-link"
                      data-toggle="tab"
                    >
                      Partilhar um caso
                    </a>
                  </li>

                  <li className="nav-item dropdown">
                    <a
                      href="#1"
                      className="nav-link dropdown-toggle"
                      data-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Outros
                    </a>
                    <div
                      className="dropdown-menu dropdown-menu-right"
                      x-placement="bottom-end"
                      style={{
                        position: 'absolute',
                        willChange: 'transform',
                        top: 0,
                        left: 0,
                        transform: 'translate3d(201px, 40px, 0px)',
                      }}
                    >
                      <a
                        href="#bottom-justified-tab3"
                        className="dropdown-item show"
                        data-toggle="tab"
                        aria-expanded="true"
                      >
                        Artigo
                      </a>
                      <a
                        href="#bottom-justified-tab4"
                        className="dropdown-item show"
                        data-toggle="tab"
                        aria-expanded="true"
                      >
                        Evento
                      </a>
                    </div>
                  </li>
                </ul>

                <div className="tab-content">
                  <div
                    className="tab-pane fade active show"
                    id="bottom-justified-tab1"
                  >
                    <textarea
                      rows="3"
                      cols="3"
                      className="form-control"
                      placeholder="Publique novidades ou dúvidas sobre psicologia"
                    ></textarea>
                  </div>

                  <div className="tab-pane fade" id="bottom-justified-tab2">
                    <textarea
                      rows="3"
                      cols="3"
                      className="form-control"
                      placeholder="Publique novidades ou dúvidas sobre psicologia"
                    ></textarea>
                  </div>

                  <div className="tab-pane fade" id="bottom-justified-tab3">
                    <textarea
                      rows="3"
                      cols="3"
                      className="form-control"
                      placeholder="Publique novidades ou dúvidas sobre psicologia"
                    ></textarea>
                  </div>

                  <div className="tab-pane fade" id="bottom-justified-tab4">
                    Aliquip jean shorts ullamco ad vinyl cillum PBR. Homo
                    nostrud organic, assumenda labore aesthet.
                  </div>
                </div>
              </div>
            </div>

            <Post />
          </div>

          <div className="col">
            <div className="sortable ui-sortable">
              <ul className="nav nav-tabs nav-justified">
                <li className="nav-item">
                  <a
                    href="#components-tab"
                    className="nav-link active"
                    data-toggle="tab"
                  >
                    <i className="icon-grid-alt"></i>
                  </a>
                </li>

                <li className="nav-item">
                  <a href="#forms-tab" className="nav-link" data-toggle="tab">
                    <i className="icon-menu6"></i>
                  </a>
                </li>

                <li className="nav-item dropdown">
                  <a href="#other-tab" className="nav-link" data-toggle="tab">
                    <i className="icon-grid5"></i>
                  </a>
                </li>
              </ul>

              <div className="tab-content">
                <div className="tab-pane fade active show" id="components-tab">
                  <div className="card card-body">
                    <div className="media mb-3">
                      <div className="mr-3 align-self-center">
                        <i className="icon-trophy3 icon-2x text-blue-400 opacity-75"></i>
                      </div>

                      <div className="media-body">
                        <h6 className="font-weight-semibold mb-0">67.983</h6>
                        <span className="text-muted">Pontuação Total</span>
                      </div>
                    </div>

                    <div
                      className="progress mb-2"
                      style={{ height: '0.125rem' }}
                    >
                      <div
                        className="progress-bar bg-blue"
                        style={{ width: '67%' }}
                      >
                        <span className="sr-only">67% Complete</span>
                      </div>
                    </div>

                    <div>
                      <span className="float-right">67%</span>
                      Completar cadastro
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-header bg-transparent header-elements-inline">
                      <span className="text-uppercase font-size-sm font-weight-semibold">
                        Tendências
                      </span>
                      <div className="header-elements">
                        <div className="list-icons">
                          {/* <a
                            href="#1"
                            className="list-icons-item"
                            data-action="collapse"
                          ></a> */}
                        </div>
                      </div>
                    </div>

                    <div className="card-body">
                      <div className="row">
                        <ul className="media-list">
                          <li className="media">
                            <div className="mr-3 position-relative"></div>

                            <div className="media-body">
                              <a href="#1">
                                <span className="font-weight-bold font-size-med1 text-dark">
                                  Estou com uma paciente que diz ter sido
                                  assediada pelo padastro
                                </span>
                              </a>
                              <br />
                              <span className="font-size-sm text-muted">
                                Caso Clínico - 3 comentários
                              </span>
                            </div>
                          </li>

                          <li className="media">
                            <div className="mr-3 position-relative"></div>

                            <div className="media-body">
                              <a href="#1">
                                <span className="font-weight-bold font-size-med1 text-dark">
                                  Criança que bate nos colegas
                                </span>
                              </a>
                              <br />
                              <span className="font-size-sm text-muted">
                                Caso Clínico - 2 comentários
                              </span>
                            </div>
                          </li>

                          <li className="media">
                            <div className="mr-3 position-relative"></div>

                            <div className="media-body">
                              <a href="#1">
                                <span className="font-weight-bold font-size-med1 text-dark">
                                  (Enquete da Semana) Seus pacientes estão
                                  esquecendo das sessões?
                                </span>
                              </a>
                              <br />
                              <span className="font-size-sm text-muted">
                                Enquete - 22 respostas
                              </span>
                            </div>
                          </li>

                          <li className="media">
                            <div className="mr-3 position-relative"></div>

                            <div className="media-body">
                              <a href="#1">
                                <span className="font-weight-bold font-size-med1 text-dark">
                                  (E-book) Baixe e-book sobre as abordagens
                                  psicológicas
                                </span>
                              </a>
                              <br />
                              <span className="font-size-sm text-muted">
                                E-book - 32 comentários
                              </span>
                            </div>
                          </li>

                          <li className="media">
                            <div className="mr-3 position-relative"></div>

                            <div className="media-body">
                              <a href="#1">
                                <span className="font-weight-bold font-size-med1 text-dark">
                                  20% de desconto no curso sobre Hipnose
                                </span>
                              </a>
                              <br />
                              <span className="font-size-sm text-muted">
                                Cursos - 7 comentários
                              </span>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-header bg-transparent header-elements-inline">
                      <span className="card-title font-weight-semibold">
                        Top Download da Semana
                      </span>
                      <div className="header-elements">
                        <div className="list-icons">
                          {/* <a
                            className="list-icons-item"
                            data-action="collapse"
                          ></a> */}
                        </div>
                      </div>
                    </div>

                    <div className="card-body">
                      <ul className="media-list">
                        <li className="media">
                          <a href="#1" className="mr-3">
                            <img
                              src="global_assets/images/demo/users/127525331_1GG.jpg"
                              width="36"
                              height="46"
                              className=""
                              alt=""
                            />
                          </a>

                          <div className="media-body">
                            <div className="font-weight-semibold">
                              O livro da Psicologia - Editora Globo
                            </div>
                            <span className="font-size-sm text-muted">
                              Baixe Grátis
                            </span>
                          </div>

                          <div className="ml-3 align-self-center">
                            <a href="#1" className="text-default">
                              <i className="icon-download"></i>
                            </a>
                          </div>
                        </li>

                        <li className="media">
                          <a href="#1" className="mr-3">
                            <img
                              src="global_assets/images/demo/users/identificarmentiroso.jpg"
                              width="36"
                              height="46"
                              className=""
                              alt=""
                            />
                          </a>

                          <div className="media-body">
                            <div className="font-weight-semibold">
                              Walter Sommers
                            </div>
                            <span className="font-size-sm text-muted">
                              Lead developer
                            </span>
                          </div>

                          <div className="ml-3 align-self-center">
                            <a href="#1" className="text-default">
                              <i className="icon-download"></i>
                            </a>
                          </div>
                        </li>

                        <li className="media">
                          <a href="#1" className="mr-3">
                            <img
                              src="global_assets/images/demo/users/cores.jpg"
                              width="36"
                              height="46"
                              className=""
                              alt=""
                            />
                          </a>

                          <div className="media-body">
                            <div className="font-weight-semibold">
                              A Psicologia das Cores
                            </div>
                            <span className="font-size-sm text-muted">
                              Front end developer
                            </span>
                          </div>

                          <div className="ml-3 align-self-center">
                            <a href="#1" className="text-default">
                              <i className="icon-download"></i>
                            </a>
                          </div>
                        </li>

                        <li className="media">
                          <a href="#1" className="mr-3">
                            <img
                              src="global_assets/images/demo/users/face14.jpg"
                              width="36"
                              height="46"
                              className=""
                              alt=""
                            />
                          </a>

                          <div className="media-body">
                            <div className="font-weight-semibold">
                              (E-book) 5 Dicas de como engajar seus pacientes no
                              tratamento{' '}
                            </div>
                            <span className="font-size-sm text-muted">
                              72 curtidas
                            </span>
                          </div>

                          <div className="ml-3 align-self-center">
                            <a href="#1" className="text-default">
                              <i className="icon-download"></i>
                            </a>
                          </div>
                        </li>

                        <li className="media">
                          <a href="#1" className="mr-3">
                            <i className="icon-file-word icon-2x text-primary-300 top-0"></i>
                          </a>

                          <div className="media-body">
                            <div className="font-weight-semibold">
                              Modelo de Atestado nova Resolução do CFP
                            </div>
                            <span className="font-size-sm text-muted">
                              15 curtidas
                            </span>
                          </div>

                          <div className="ml-3 align-self-center">
                            <a href="#1" className="text-default">
                              <i className="icon-download"></i>
                            </a>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="tab-pane fade" id="forms-tab">
                  <Followers add />

                  <RecentEvents />
                </div>

                <div className="tab-pane fade" id="other-tab">
                  <div className="card">
                    <div className="card-header bg-transparent header-elements-inline">
                      <span className="text-uppercase font-size-sm font-weight-semibold">
                        Produtos em Promoção
                      </span>
                      <div className="header-elements"></div>
                    </div>

                    <div className="card-body">
                      <ul className="media-list">
                        <li className="media">
                          <a href="#1" className="mr-3">
                            <img
                              src="global_assets/images/demo/users/6d.png"
                              width="50"
                              height="46"
                              className=""
                              alt=""
                            />
                          </a>

                          <div className="media-body">
                            <div className="font-weight-semibold">
                              Imersão Hipnose Clínica 7 dias - SP
                            </div>
                            <span className="font-size-sm text-muted">
                              São Paulo - SP
                            </span>
                          </div>

                          <div className="">
                            <h5 className="text-center text-success mb-0">
                              20
                            </h5>
                            <div className="font-size-sm text-muted line-height-1">
                              Dez
                            </div>
                          </div>
                        </li>

                        <li className="media">
                          <a href="#1" className="mr-3">
                            <img
                              src="global_assets/images/demo/users/identificarmentiroso.jpg"
                              width="50"
                              height="46"
                              className=""
                              alt=""
                            />
                          </a>

                          <div className="media-body">
                            <div className="font-weight-semibold">
                              1 - Seminário Online em Análise do Comportamento
                            </div>
                            <span className="font-size-sm text-muted">
                              Online
                            </span>
                          </div>

                          <div className="">
                            <h5 className="text-center text-success mb-0">
                              18
                            </h5>
                            <div className="font-size-sm text-muted line-height-1">
                              Dez
                            </div>
                          </div>
                        </li>

                        <li className="media">
                          <a href="#1" className="mr-3">
                            <img
                              src="global_assets/images/demo/users/2d.png"
                              width="50"
                              height="46"
                              className=""
                              alt=""
                            />
                          </a>

                          <div className="media-body">
                            <div className="font-weight-semibold">
                              Formação em Análise das Microimpressões
                            </div>
                            <span className="font-size-sm text-muted">
                              São José do Rio Preto - SP
                            </span>
                          </div>

                          <div className="">
                            <h5 className="text-center text-success mb-0">
                              16
                            </h5>
                            <div className="font-size-sm text-muted line-height-1">
                              Dez
                            </div>
                          </div>
                        </li>

                        <li className="media">
                          <a href="#1" className="mr-3">
                            <img
                              src="global_assets/images/demo/users/Professor.png"
                              width="50"
                              height="46"
                              className=""
                              alt=""
                            />
                          </a>

                          <div className="media-body">
                            <div className="font-weight-semibold">
                              Formação em professor EAD{' '}
                            </div>
                            <span className="font-size-sm text-muted">
                              72 curtidas
                            </span>
                          </div>

                          <div className="">
                            <h5 className="text-center text-success mb-0">
                              15
                            </h5>
                            <div className="font-size-sm text-muted line-height-1">
                              Dez
                            </div>
                          </div>
                        </li>

                        <li className="media">
                          <a href="#1" className="mr-3">
                            <img
                              src="global_assets/images/demo/users/banner.png"
                              width="50"
                              height="46"
                              className=""
                              alt=""
                            />
                          </a>

                          <div className="media-body">
                            <div className="font-weight-semibold">
                              Evento sobre liderança para psicólogos
                            </div>
                            <span className="font-size-sm text-muted">
                              15 curtidas
                            </span>
                          </div>

                          <div className="">
                            <h5 className="text-center text-success mb-0">
                              02
                            </h5>
                            <div className="font-size-sm text-muted line-height-1">
                              Dez
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-header bg-transparent header-elements-inline">
                      <span className="text-uppercase font-size-sm font-weight-semibold">
                        Filmes e Séries
                      </span>
                      <div className="header-elements">
                        <div className="list-icons">
                          {/* <a
                            className="list-icons-item ui-sortable-handle"
                            data-action="move"
                          ></a> */}
                        </div>
                      </div>
                    </div>

                    <div className="card-body">
                      <ul className="media-list">
                        <li className="media">
                          <a href="#1" className="mr-3">
                            <img
                              src="global_assets/images/demo/users/6d.png"
                              width="50"
                              height="46"
                              className=""
                              alt=""
                            />
                          </a>

                          <div className="media-body">
                            <div className="font-weight-semibold">
                              Imersão Hipnose Clínica 7 dias - SP
                            </div>
                            <span className="font-size-sm text-muted">
                              São Paulo - SP
                            </span>
                          </div>
                        </li>

                        <li className="media">
                          <a href="#1" className="mr-3">
                            <img
                              src="global_assets/images/demo/users/identificarmentiroso.jpg"
                              width="50"
                              height="46"
                              className=""
                              alt=""
                            />
                          </a>

                          <div className="media-body">
                            <div className="font-weight-semibold">
                              1 - Seminário Online em Análise do Comportamento
                            </div>
                            <span className="font-size-sm text-muted">
                              Online
                            </span>
                          </div>
                        </li>

                        <li className="media">
                          <a href="#1" className="mr-3">
                            <img
                              src="global_assets/images/demo/users/2d.png"
                              width="50"
                              height="46"
                              className=""
                              alt=""
                            />
                          </a>

                          <div className="media-body">
                            <div className="font-weight-semibold">
                              Formação em Análise das Microimpressões
                            </div>
                            <span className="font-size-sm text-muted">
                              São José do Rio Preto - SP
                            </span>
                          </div>
                        </li>

                        <li className="media">
                          <a href="#1" className="mr-3">
                            <img
                              src="global_assets/images/demo/users/Professor.png"
                              width="50"
                              height="46"
                              className=""
                              alt=""
                            />
                          </a>

                          <div className="media-body">
                            <div className="font-weight-semibold">
                              Formação em professor EAD{' '}
                            </div>
                            <span className="font-size-sm text-muted">
                              72 curtidas
                            </span>
                          </div>
                        </li>

                        <li className="media">
                          <a href="#1" className="mr-3">
                            <img
                              src="global_assets/images/demo/users/banner.png"
                              width="50"
                              height="46"
                              className=""
                              alt=""
                            />
                          </a>

                          <div className="media-body">
                            <div className="font-weight-semibold">
                              Evento sobre liderança para psicólogos
                            </div>
                            <span className="font-size-sm text-muted">
                              15 curtidas
                            </span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
