/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import api from '~/services/api';

export default function ButtonPublishEvent({
  event,
  openBoxConfirm,
  formRef,
  loadEvent,
  ...rest
}) {
  const [status, setStatus] = useState(event.status_eve);

  useEffect(() => {
    setStatus(event.status_eve);
  }, [event.status_eve]);

  async function publicEvent(data, action) {
    if (!data.titulo_eve) {
      toast.error('Evento não pode ser publicado sem um título!');
      return;
    }

    if (!data.dt_eve) {
      toast.error('Evento não pode ser publicado sem a data!');
      return;
    }

    const response = await api.put(`/evento/${action}/${data.cod_evento}`);
    if (response.data.records.error) {
      if (response.data.records.error_cc) {
        openBoxConfirm(true);
        return;
      }
      toast.error(response.data.records.msg);
      return;
    }

    if (action === 'ativar') {
      setStatus('1');
      toast.success('Evento publicado com sucesso!');
    } else {
      setStatus('2');
      toast.success('Evento despublicado com sucesso!');
    }

    formRef && formRef.submitForm();
    setTimeout(() => {
      loadEvent(data.cod_evento);
    }, 2000);
  }

  const { disabled } = { ...rest };

  return (
    <>
      {status === '1' ? (
        <Button
          variant="danger"
          type="button"
          onClick={() => publicEvent(event, 'desativar')}
        >
          <i className="icon-undo2 mr-2"></i> Despublicar
        </Button>
      ) : (
        <Button
          variant={disabled ? 'secondary' : 'success'}
          type="button"
          onClick={() => publicEvent(event, 'ativar')}
          {...rest}
        >
          <i className="icon-redo2 mr-2"></i> Publicar
        </Button>
      )}
    </>
  );
}

ButtonPublishEvent.defaultProps = {
  loadEvent: () => {},
};

ButtonPublishEvent.propTypes = {
  event: PropTypes.shape().isRequired,
  openBoxConfirm: PropTypes.func.isRequired,
  formRef: PropTypes.element.isRequired,
  loadEvent: PropTypes.func,
};
