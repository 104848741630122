export function studentsGetByCourseAndClassRequest(query) {
  return {
    type: '@students/GET_STUDENTS_BY_COURSE_AND_CLASS_REQUEST',
    payload: query,
  };
}

export function studentsGetByCourseAndClassSuccess(data) {
  return {
    type: '@students/GET_STUDENTS_BY_COURSE_AND_CLASS_SUCCESS',
    payload: data,
  };
}

export function studentsGetByCourseAndClassFailure() {
  return {
    type: '@students/GET_STUDENTS_BY_COURSE_AND_CLASS_FAILURE',
  };
}
