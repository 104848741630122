import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { useField } from '@unform/core';

export default function Input({ name, load, label, button, ...rest }) {
  const inputRef = useRef(null);

  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <>
      <div className="input-group">
        <input
          id={fieldName}
          ref={inputRef}
          defaultValue={defaultValue}
          className={error ? 'form-control border-danger' : 'form-control'}
          {...rest}
        />
        <div className="input-group-append">
          {button ? (
            <Button type="submit" variant="success" disabled={load}>
              {load ? 'Aguarde...' : label}
            </Button>
          ) : (
            <span className="input-group-text text-dark" id="basic-addon2">
              {label}
            </span>
          )}
        </div>
      </div>
      {error && <span className="form-text text-danger">{error}</span>}
    </>
  );
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
  load: PropTypes.bool,
  label: PropTypes.string.isRequired,
  button: PropTypes.bool,
};

Input.defaultProps = {
  load: false,
  button: false,
};
