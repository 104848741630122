import React from 'react';

import { useParams } from 'react-router-dom';
import { Col } from 'react-bootstrap';

import UploadFiles from '~/components/UploadContentModuleFiles';

import { objIsNull } from '~/util';

function TabFile({ module }) {
  const { idCourse, idModule } = useParams();
  return (
    <>
      {(objIsNull(module)) && (
        <Col xl={12}>
          <div className="page-header-content header-elements-md-inline p-0">
            <div className="page-title d-flex p-0">
              <h4 className="mb-0 font-weight-semibold">
                <i className="icon-file-upload mr-2 c-pointer"></i> Gerenciamento de Mídias
                <span className="text-muted d-block" style={{ fontSize: 12 }}>
                  Gerencie as mídias do módulo {module.nome_modulo}.
                </span>
              </h4>
            </div>
          </div>
          <div className="form-group mt-3 mb-1">
            <UploadFiles
              download
              idCourse={idCourse}
              idModule={idModule}
            />
          </div>
        </Col>
      )}
    </>
  );
}

export default TabFile;
