import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Badge, Row, Col, Card, Button } from 'react-bootstrap';

import pt from 'date-fns/locale/pt-BR';
import api from '~/services/api';
import history from '~/services/history';
import noImage from '~/assets/img/sem.png';
import { ambient, getUrlImg } from '~/util';

import * as CoursesActions from '~/store/modules/courses/actions';

import DeleteEvent from '~/components/DeleteEvent';
import CreateCourse from '~/components/CreateCourse';
import BoxConfirm from '~/components/BoxConfirm';

import ModalCreateAccount from '~/components/ModalCreateAccount';

function MyCourses() {
  const dispatch = useDispatch();
  const courses = useSelector(state => state.course.courses);

  const [showCourse, setShowCourse] = useState(false);

  const [courseRef, setCourseRef] = useState({});
  const [showCreateAccount, setShowCreateAccount] = useState(false);
  const [openBoxConfirm, setOpenBoxConfirm] = useState(false);

  const handleCloseCourse = () => setShowCourse(false);
  const handleShowCourse = () => setShowCourse(true);

  const loadCourses = useCallback(() => {
    dispatch(CoursesActions.coursesListRequest());
  }, [dispatch]);

  useEffect(() => {
    loadCourses();
  }, [loadCourses, showCreateAccount]);

  function getBadgePresencial(course) {
    if (course) {
      switch (course.eh_online) {
        case '0':
        case null:
          return <Badge variant="secondary">Presencial</Badge>;
        case '1':
          return <Badge variant="primary">On-line</Badge>;
        default:
          return null;
      }
    }
    return null;
  }

  async function publicEvent(id, action, shouldOpenBoxConfirm) {
    const response = await api.put(`/evento/${action}/${id}`);
    if (response.data.records.error) {
      if (response.data.records.error_cc) {
        setCourseRef(id);
        // eslint-disable-next-line no-unused-expressions
        shouldOpenBoxConfirm && setOpenBoxConfirm(true);
        return;
      }
      toast.error(response.data.records.msg);
    } else {
      loadCourses();
    }
  }

  const handleExternalClose = () => {
    setShowCreateAccount(false);
    publicEvent(courseRef, 'ativar', false);
    loadCourses();
  };

  function getBadgePublicado(course) {
    if (course) {
      switch (course.status_eve) {
        case '2':
        case null:
          return (
            <Badge
              className="c-pointer"
              variant="secondary"
              onClick={() => publicEvent(course.cod_evento, 'ativar', true)}
            >
              <u>Não publicado (Publicar)</u>
            </Badge>
          );
        case '1':
          return (
            <Badge
              className="c-pointer"
              variant="success"
              onClick={() => publicEvent(course.cod_evento, 'desativar', false)}
            >
              <u>Publicado (Despublicar)</u>
            </Badge>
          );
        default:
          return null;
      }
    }
    return null;
  }

  const copy = useCallback(() => {
    toast.info('Link copiado com sucesso!');
  }, []);

  const urlCopy = useCallback(course => {
    const url = `${ambient('front')}/curso/${course.url_amig}`;

    return url;
  }, []);

  return (
    <div className="content padding-tab-top-post">
      <ModalCreateAccount
        showModal={showCreateAccount}
        handleExternalClose={handleExternalClose}
      />
      <BoxConfirm
        show={openBoxConfirm}
        onClose={() => setOpenBoxConfirm(false)}
        size="sm"
        title="Aviso"
        message="Para poder publicar é preciso cadastrar uma conta corrente."
        messageBottomBold="Deseja cadastrar agora?"
        aliasBtnYes="Cadastrar conta"
        onNoClick={() => setOpenBoxConfirm(false)}
        onYesClick={() => {
          setShowCreateAccount(true);
          setOpenBoxConfirm(false);
        }}
      />
      <CreateCourse show={showCourse} handleClose={handleCloseCourse} />
      <Row>
        <Col>
          <div className="d-flex justify-content-between align-items-center">
            <div className="mb-3">
              <h3 className="mb-0 font-weight-semibold">
                <i
                  role="presentation"
                  style={{ fontSize: '1.5rem' }}
                  onClick={() => history.goBack()}
                  className="icon-arrow-left52 mr-2 c-pointer"
                ></i>
                Meus Cursos
              </h3>
              <span className="text-muted d-block">
                Aqui você pode gerenciar seus cursos
              </span>
            </div>
            <div>
              <button
                type="button"
                onClick={handleShowCourse}
                className="btn btn-sm btn-info"
              >
                <i className="icon-plus3 mr-1"></i> Criar Curso
              </button>
            </div>
          </div>
        </Col>
        <Col xl={12}>
          <Card>
            <Card.Body>
              <table className="table table-striped table-hover">
                <thead>
                  <tr>
                    <th colSpan="2">Curso</th>
                    <th>Presencial</th>
                    <th>Publicado</th>
                    <th className="text-center">Página do Curso</th>
                    <th className="text-center">Data de Cadastro</th>
                    <th className="text-center">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(courses).length === 0 ? (
                    <tr>
                      <td colSpan="7" className="p-0 text-center">
                        <div className="alert alert-info border-0 alert-dismissible mb-0">
                          <i className="icon-info3 border-2 rounded-round p-1 mr-2"></i>
                          <span>
                            Caro usuário, você não cadastrou nenhum curso
                          </span>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    courses.map(course => (
                      <tr key={course.cod_evento}>
                        <td>
                          {course.img_eve ? (
                            <img
                              width={80}
                              height={50}
                              src={getUrlImg(course.img_eve)}
                              alt={course.titulo_eve}
                              className="img-preview rounded"
                            />
                          ) : (
                            <img
                              width={80}
                              height={50}
                              src={noImage}
                              alt={course.titulo_eve}
                              className="img-preview rounded"
                            />
                          )}
                        </td>
                        <td>
                          <Link
                            title="Editar Curso"
                            to={`/cursos/${course.cod_evento}/editar`}
                          >
                            {course.titulo_eve}
                          </Link>
                        </td>
                        <td>{getBadgePresencial(course)}</td>
                        <td>{getBadgePublicado(course)}</td>
                        <td className="text-center">
                          <Link
                            target="_blank"
                            title={course.titulo_eve}
                            to={`/curso/${course.url_amig}`}
                          >
                            <Button variant="light" size="sm">
                              <i className="icon-link text-primary"></i>
                            </Button>
                          </Link>
                        </td>
                        <td className="text-center">
                          {format(new Date(course.dt_cadastro), 'PP', {
                            locale: pt,
                          })}
                        </td>
                        <td style={{ width: 200 }}>
                          <div className="d-flex justify-content-center align-items-center">
                            <Link
                              title="Editar Curso"
                              to={`/cursos/${course.cod_evento}/editar`}
                            >
                              <Button variant="light" size="sm">
                                <i className="icon-pencil5 text-primary"></i>
                              </Button>
                            </Link>
                            <div className="list-icons text-primary ml-2">
                              <div className="dropdown">
                                <button
                                  type="button"
                                  className="btn btn-light dropdown-toggle"
                                  data-toggle="dropdown"
                                >
                                  <i className="icon-cog6 text-primary"></i>
                                </button>
                                <div className="dropdown-menu dropdown-menu-right">
                                  <CopyToClipboard
                                    text={urlCopy(course)}
                                    onCopy={() => copy()}
                                  >
                                    <button
                                      type="button"
                                      className="btn btn-link dropdown-item"
                                    >
                                      <i className="icon-link"></i>
                                      Copiar link da inscrição
                                    </button>
                                  </CopyToClipboard>
                                  <div className="dropdown-divider"></div>
                                  {course.status_eve === '1' ? (
                                    <button
                                      type="button"
                                      className="btn btn-link dropdown-item"
                                      onClick={() =>
                                        publicEvent(
                                          course.cod_evento,
                                          'desativar',
                                          false
                                        )
                                      }
                                    >
                                      <i className="icon-eye-blocked"></i>
                                      Despublicar curso
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      className="btn btn-link dropdown-item"
                                      onClick={() =>
                                        publicEvent(
                                          course.cod_evento,
                                          'ativar',
                                          true
                                        )
                                      }
                                    >
                                      <i className="icon-eye"></i>
                                      Publicar curso
                                    </button>
                                  )}
                                  <div className="dropdown-divider"></div>
                                  <DeleteEvent
                                    data={course}
                                    entity="Curso"
                                    load={loadCourses}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default MyCourses;
