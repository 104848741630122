import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Whats = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 3;
  background-color: #70bd62;
  color: white;
  padding: 7px 11px;
  cursor: pointer;
  border-radius: 10px;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;
