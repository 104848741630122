import React, { useRef, useState, useCallback, useEffect } from 'react';

import { Row, Col, Button, Modal, Spinner } from 'react-bootstrap';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import * as cep from 'cep-promise';
import Input from '~/components/InputText';
import InputMask from '~/components/InputMask';
import Select from '~/components/InputSelect';
import { bank, state, shortStateSearch } from '~/util';
import api from '~/services/api';

// eslint-disable-next-line react/prop-types
const ModalCreateAccount = ({ showModal, handleExternalClose }) => {
  // Variáveis do modal
  const formRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(showModal);
  const [showOperationField, setShowOperationField] = useState(false);
  const [showAgDigitField, setShowAgDigitField] = useState(false);
  const [showAcDigitField, setShowAcDigitField] = useState(true);
  const [agency, setAgency] = useState('');
  const [account, setAccount] = useState('');
  const [agText, setAgText] = useState('');
  const [ccText, setCcText] = useState('');
  const [kindPerson, setKindPerson] = useState(false);
  const [isUFSelected, setIsUFSelected] = useState(false);

  useEffect(() => {
    setShow(showModal);
  }, [showModal]);

  // Funções de fluxo
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleClose = useCallback(() => {
    handleExternalClose();
    setShow(false);
    setShowOperationField(false);
    setShowAgDigitField(false);
  });

  const handleSubmit = useCallback(
    async dataSend => {
      try {
        setIsLoading(true);
        formRef.current.setErrors({});
        const schema = Yup.object().shape({
          cod_banco: Yup.string().required('O Banco é obrigatório'),
          tipo_conta: Yup.string().required('O Tipo de conta é obrigatório'),
          agencia: Yup.string().required('A Agência é obrigatória'),
          agencia_digito:
            showAgDigitField &&
            Yup.string().required('O dígito da agência é obrigatório'),
          num_conta: Yup.string().required('A Conta é obrigatória'),
          conta_digito: Yup.string().required(
            'O dígito da conta é obrigatório'
          ),
          operacao:
            showOperationField &&
            Yup.string().required('A operação é obrigatória'),
          tipo_pessoa_conta: Yup.string().required(
            'O Tipo de pessoa é obrigatório'
          ),
          nome: Yup.string().required('O Nome completo é obrigatório'),
          cpf_cnpj_conta: Yup.string().when(
            'tipo_pessoa_conta',
            (tipo_pessoa_conta, schema) => {
              return tipo_pessoa_conta === '2'
                ? schema.required('O CNPJ é obrigatório')
                : schema.required('O CPF é obrigatório');
            }
          ),
          telefone: Yup.string().required('O Telefone é obrigatório'),
          cep: Yup.string().required('O Cep é obrigatório'),
          endereco: Yup.string().required('O Endereço é obrigatório'),
          cidade: Yup.string().required('A Cidade é obrigatória'),
          uf: Yup.string().required('O Estado é obrigatório'),
          resp_name: Yup.string().when(
            'tipo_pessoa_conta',
            (tipo_pessoa_conta, schema) => {
              return tipo_pessoa_conta === '2'
                ? schema.required('O Nome do responsável é obrigatório')
                : schema;
            }
          ),
          resp_cpf: Yup.string().when(
            'tipo_pessoa_conta',
            (tipo_pessoa_conta, schema) => {
              return tipo_pessoa_conta === '2'
                ? schema.required('O CPF do responsável é obrigatório')
                : schema;
            }
          ),
        });
        await schema.validate(dataSend, {
          abortEarly: false,
        });

        const response = await api.post('/config/salvar-conta', dataSend);
        if (response.data.records.error) {
          toast.error(response.data.records.msg);
          setIsLoading(false);
          return;
        }

        handleClose();
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        const validationErrors = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            validationErrors[error.path] = error.message;
          });
          formRef.current.setErrors(validationErrors);
        }
      }
    },
    [showAgDigitField, showOperationField, handleClose]
  );

  // Funções de variáveis
  const handleKindPerson = person => {
    if (person.value === 1) {
      setKindPerson(false);
    } else {
      setKindPerson(true);
    }
  };

  const searchCEP = useCallback(() => {
    if (formRef.current.getFieldValue('cep')) {
      let strCEP = formRef.current.getFieldValue('cep');
      strCEP = strCEP.replace('-', '');
      cep(strCEP)
        .then(response => {
          formRef.current.setFieldValue('endereco', response.street);
          formRef.current.setFieldValue('cidade', response.city);
          const ufLong = shortStateSearch(response.state);
          setIsUFSelected(true);
          formRef.current.clearField('uf');
          formRef.current.setFieldValue('uf', { value: ufLong, label: ufLong });
        })
        .catch(e => toast.error(e.message));
    }
  }, []);

  const mask = bk => {
    switch (bk.value) {
      case 'Banco do Brasil':
        setAgency('9999');
        setAccount('99999999');
        setAgText(
          'O campo agência deve conter 4 dígitos, preencha com zero a esquerda para completar se necessário.'
        );
        setCcText('');
        setShowAgDigitField(true);
        setShowAcDigitField(true);
        setShowOperationField(false);
        break;
      case 'Bradesco':
        setAgency('9999');
        setAccount('9999999');
        setAgText(
          'O campo agência deve conter 4 dígitos, preencha com zero a esquerda para completar se necessário.'
        );
        setCcText('');
        setShowAgDigitField(true);
        setShowAcDigitField(true);
        setShowOperationField(false);
        break;
      case 'Caixa Econômica':
        setAgency('9999');
        setAccount('99999999');
        setAgText(
          'O campo agência deve conter 4 dígitos, preencha com zero a esquerda para completar.'
        );
        setCcText('');
        setShowAgDigitField(false);
        setShowAcDigitField(true);
        setShowOperationField(true);
        break;
      case 'Itaú':
        setAgency('9999');
        setAccount('99999');
        setAgText(
          'O campo agência deve conter 4 dígitos, preencha com zero a esquerda para completar.'
        );
        setCcText('');
        setShowAgDigitField(false);
        setShowAcDigitField(true);
        setShowOperationField(false);
        break;
      case 'Inter':
        setAgency('9999');
        setAccount('999999999');
        setAgText(
          'O campo agência deve conter 4 dígitos, preencha com zero a esquerda para completar.'
        );
        setCcText('');
        setShowAgDigitField(false);
        setShowAcDigitField(true);
        setShowOperationField(false);
        break;
      case 'Santander':
        setAgency('9999');
        setAccount('99999999');
        setAgText(
          'O campo agência deve conter 4 dígitos, preencha com zero a esquerda para completar.'
        );
        setCcText('');
        setShowAgDigitField(false);
        setShowAcDigitField(true);
        setShowOperationField(false);
        break;
      case 'Banrisul':
        setAgency('9999');
        setAccount('999999999');
        setAgText(
          'O campo agência deve conter 4 dígitos, preencha com zero a esquerda para completar.'
        );
        setCcText('');
        setShowAgDigitField(false);
        setShowAcDigitField(true);
        setShowOperationField(false);
        break;
      case 'Sicredi':
        setAgency('9999');
        setAccount('9999999');
        setAgText(
          'O campo agência deve conter 4 dígitos, preencha com zero a esquerda para completar.'
        );
        setCcText(
          // 'O campo conta deve seguir o seguinte padrão 999999D, onde D é o digito verificador.'
          'O ultimo dígito deve ir no campo "Díg.Conta"'
        );
        setShowAgDigitField(false);
        setShowAcDigitField(true);
        setShowOperationField(false);
        break;
      case 'Sicoob':
        setAgency('9999');
        setAccount('999999999');
        setAgText(
          'O campo agência deve conter 4 dígitos, preencha com zero a esquerda para completar.'
        );
        setCcText('');
        setShowAgDigitField(false);
        setShowAcDigitField(true);
        setShowOperationField(false);
        break;
      case 'BRB':
        setAgency('9999');
        setAccount('999999999');
        setAgText(
          'O campo agência deve conter 4 dígitos, preencha com zero a esquerda para completar.'
        );
        setCcText('');
        setShowAgDigitField(false);
        setShowAcDigitField(true);
        setShowOperationField(false);
        break;
      case 'PagSeguro':
        setAgency('9999');
        setAccount('99999999');
        setAgText(
          'O campo agência deve conter 4 dígitos, preencha com zero a esquerda para completar.'
        );
        setCcText('');
        setShowAgDigitField(false);
        setShowAcDigitField(true);
        setShowOperationField(false);
        break;
      case 'Neon':
        setAgency('9999');
        setAccount('9999999');
        setAgText(
          'O campo agência deve conter 4 dígitos, preencha com zero a esquerda para completar.'
        );
        setCcText('');
        setShowAgDigitField(false);
        setShowAcDigitField(true);
        setShowOperationField(false);
        break;
      case 'Safra':
        setAgency('9999');
        setAccount('99999999');
        setAgText(
          'O campo agência deve conter 4 dígitos, preencha com zero a esquerda para completar.'
        );
        setCcText('');
        setShowAgDigitField(false);
        setShowAcDigitField(true);
        setShowOperationField(false);
        break;
      case 'Banco Original':
        setAgency('9999');
        setAccount('9999999');
        setAgText(
          'O campo agência deve conter 4 dígitos, preencha com zero a esquerda para completar.'
        );
        setCcText('');
        setShowAgDigitField(false);
        setShowAcDigitField(true);
        setShowOperationField(false);
        break;
      case 'Nubank':
        setAgency('9999');
        setAccount('9999999999');
        setAgText(
          'O campo agência deve conter 4 dígitos, preencha com zero a esquerda para completar.'
        );
        setCcText('');
        setShowAgDigitField(false);
        setShowAcDigitField(true);
        setShowOperationField(false);
        break;

      default:
        break;
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Cadastrar conta corrente</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Row>
              <Col>
                <div className="form-group">
                  <label>Banco</label>
                  <Select
                    name="cod_banco"
                    options={bank()}
                    placeholder="Selecione o banco"
                    onChange={mask}
                  />
                </div>
              </Col>
              <Col>
                <div className="form-group">
                  <label>Tipo de conta</label>
                  <Select
                    name="tipo_conta"
                    options={[
                      { value: 'Corrente', label: 'Corrente' },
                      { value: 'Poupança', label: 'Poupança' },
                    ]}
                    placeholder="Selecione o tipo da conta"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={showAgDigitField || showOperationField ? 4 : 6}>
                <div className="form-group">
                  <label>Agência</label>
                  <InputMask
                    name="agencia"
                    mask={agency}
                    maskChar=""
                    alwaysShowMask={false}
                    placeholder="Digite a agência"
                  />
                  <small className="form-text text-muted">{agText}</small>
                </div>
              </Col>

              {showAgDigitField && (
                <Col xs={2}>
                  <div className="form-group">
                    <label>Díg.Agência</label>
                    <InputMask
                      name="agencia_digito"
                      mask="9"
                      placeholder="Dígito"
                    />
                    <small className="form-text text-muted">
                      Apenas 01(um) caractere
                    </small>
                  </div>
                </Col>
              )}
              {showOperationField && (
                <Col xs={2}>
                  <div className="form-group">
                    <label>Operação</label>
                    <InputMask name="operacao" mask="999" placeholder="Op." />
                    <small className="form-text text-muted">
                      03(Três) dígitos
                    </small>
                  </div>
                </Col>
              )}

              <Col xs={4}>
                <div className="form-group">
                  <label>Conta</label>
                  <InputMask
                    name="num_conta"
                    mask={account}
                    maskChar=""
                    alwaysShowMask={false}
                    placeholder="Digite a conta"
                  />
                  <small className="form-text text-muted">{ccText}</small>
                </div>
              </Col>
              {showAcDigitField && (
                <Col xs={2}>
                  <div className="form-group">
                    <label>Díg.Conta</label>
                    <InputMask
                      name="conta_digito"
                      mask="9"
                      placeholder="Dígito"
                    />
                    <small className="form-text text-muted">
                      Apenas 01(um) caractere
                    </small>
                  </div>
                </Col>
              )}
            </Row>

            <div className="form-group">
              <label>Tipo de pessoa</label>
              <Select
                name="tipo_pessoa_conta"
                options={[
                  { value: 1, label: 'Pessoa Física' },
                  { value: 2, label: 'Pessoa Juridica' },
                ]}
                onChange={handleKindPerson}
                placeholder="Selecione o tipo de pessoa"
              />
            </div>
            {kindPerson ? (
              <div className="form-group">
                <label>Razão Social</label>
                <Input name="nome" placeholder="Digite a razão social" />
              </div>
            ) : (
              <div className="form-group">
                <label>Nome Completo</label>
                <Input name="nome" placeholder="Digite seu nome completo" />
              </div>
            )}
            <Row>
              <Col>
                {kindPerson ? (
                  <div className="form-group">
                    <label>CNPJ</label>
                    <InputMask
                      name="cpf_cnpj_conta"
                      mask="99.999.999/9999-99"
                      placeholder="Digite o cnpj"
                    />
                  </div>
                ) : (
                  <div className="form-group">
                    <label>CPF</label>
                    <InputMask
                      name="cpf_cnpj_conta"
                      mask="999.999.999-99"
                      placeholder="Digite seu cpf"
                    />
                  </div>
                )}
              </Col>
              <Col>
                <div className="form-group">
                  <label>Telefone</label>
                  <InputMask
                    name="telefone"
                    mask="(99) 999999999"
                    placeholder="Digite seu telefone"
                  />
                </div>
              </Col>
            </Row>
            {kindPerson ? (
              <Row>
                <Col>
                  <div className="form-group">
                    <label>Nome do responsável pela conta</label>
                    <Input
                      name="resp_name"
                      placeholder="Digite o nome do responsável"
                    />
                  </div>
                </Col>
                <Col>
                  <div className="form-group">
                    <label>CPF do responsável pela conta</label>
                    <InputMask
                      name="resp_cpf"
                      mask="999.999.999-99"
                      placeholder="Digite o cpf do responsável"
                    />
                  </div>
                </Col>
              </Row>
            ) : (
              ''
            )}
            <Row>
              <Col xl={4}>
                <div className="form-group">
                  <label>CEP</label>
                  <InputMask
                    name="cep"
                    mask="99999-999"
                    placeholder="Digite o cep"
                    onBlur={() => searchCEP()}
                  />
                </div>
              </Col>
              <Col>
                <div className="form-group">
                  <label>Endereço</label>
                  <Input
                    name="endereco"
                    placeholder="Ex: Rua Floriano Peixoto, 60"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="form-group">
                  <label>Cidade</label>
                  <Input name="cidade" placeholder="Digite a cidade" />
                </div>
              </Col>
              <Col>
                <div className="form-group">
                  <label>Estado</label>
                  <Select
                    name="uf"
                    options={state()}
                    placeholder="Selecione o estado"
                    isUFSelected={isUFSelected}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" type="submit">
            {isLoading ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="mr-1"
                />
                Aguarde
              </>
            ) : (
              'Salvar'
            )}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ModalCreateAccount;
