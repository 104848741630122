import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Row, Col, Card } from 'react-bootstrap';

// import { toast } from 'react-toastify';
import { BoxCardHover } from './styles';

import history from '~/services/history';

import * as InventoryActions from '~/store/modules/inventory/actions';

function Inventory() {
  const dispatch = useDispatch();
  const classes = useSelector(state => state.inventory.classes);

  const loadClasses = useCallback(() => {
    dispatch(InventoryActions.inventoryClassesMemberRequest());
  }, [dispatch]);

  useEffect(() => {
    loadClasses();
  }, [loadClasses]);

  function redirectToJourney(item) {
    // if (item.flg_pago) {
    dispatch(InventoryActions.setClassCourseSelected(item));
    history.push(`/inventario/turma/${item.cod_turma_curso}`);
    // } else {
    //   toast.info('O curso será liberado após o pagamento');
    // }
  }

  return (
    <Row className="mt-4">
      <Col xl={10} className="m-auto">
        <Row>
          {/* <Col xl={3}>
            <CardInventoryUser />
          </Col> */}
          <Col xl={12}>
            {Object.keys(classes).length === 0 ? (
              <div className="alert alert-info border-0 alert-dismissible mb-0">
                Você ainda não está matriculado em nenhum curso!
              </div>
            ) : (
              <>
                {classes.map(item => (
                  <BoxCardHover key={item.cod_turma_curso}>
                    <Card onClick={() => redirectToJourney(item)}>
                      <Card.Body>
                        <div className="media mb-3">
                          <div className="mr-4 mt-1">
                            <button
                              type="button"
                              className="btn btn-primary btn-float"
                            >
                              <i className="icon-book-play icon-2x"></i>
                            </button>
                          </div>
                          <div className="media-body">
                            <h3 className="mb-0 font-weight-semibold">
                              <span>{item.titulo_eve}</span>
                              <span
                                className="float-right"
                                style={{ lineHeight: 1.2 }}
                              >
                                <small style={{ fontSize: 10 }}>
                                  <strong className="text-muted">
                                    {item.nome_turma}
                                  </strong>
                                </small>
                              </span>
                            </h3>
                            {item.nome_prof !== '' && item.nome_prof !== null && (
                              <h6
                                className="mb-0 font-weight-semibold"
                                style={{ fontSize: 12 }}
                              >
                                <span className="text-muted">
                                  Organizador:{' '}
                                </span>
                                {item.nome_prof}
                              </h6>
                            )}
                          </div>
                        </div>
                        <div
                          className="progress mb-2"
                          style={{ height: '0.225rem' }}
                        >
                          <div
                            className="progress-bar bg-success"
                            style={{ width: `${item.porcentagem}%` }}
                          >
                            <span className="sr-only">
                              {item.porcentagem}% Completo
                            </span>
                          </div>
                        </div>
                        <div>
                          <span className="float-left">
                            <small>{item.qtd_modulos} aula(s)</small>
                          </span>
                          <span className="float-right">
                            <small>
                              {Math.round(item.porcentagem)}% da jornada
                              completa
                            </small>
                          </span>
                        </div>
                      </Card.Body>
                    </Card>
                  </BoxCardHover>
                ))}
              </>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Inventory;
