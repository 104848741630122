import React from 'react';

export default function Show() {
  return (
    <>
      <div className="content">
        <div className="profile-cover">
          <div
            className="profile-cover-img"
            style={{
              backgroundImage: 'url(../global_assets/images/demo/cover2.jpg)',
            }}
          ></div>
          <div className="d-flex justify-content-end media">
            <ul className="list-inline list-inline-condensed mb-0">
              <li className="list-inline-item">
                <a href="#1" className="btn btn-light border-transparent">
                  <i className="icon-file-picture mr-2"></i> Cover image
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="text-center d-lg-none w-100">
            <button
              type="button"
              className="navbar-toggler dropdown-toggle"
              data-toggle="collapse"
              data-target="#navbar-second"
            >
              <i className="icon-menu7 mr-2"></i>
            </button>
          </div>

          <div className="navbar-collapse collapse" id="navbar-second">
            <ul className="navbar-nav">
              <li className="nav-item">
                <a href="#1" className="navbar-nav-link text-success">
                  <i className="icon-checkmark2 mr-1"></i>
                  Entrou
                </a>
              </li>
              <li className="nav-item">
                <a href="#1" className="navbar-nav-link">
                  <i className="icon-plus2 mr-1"></i>
                  Participar
                </a>
              </li>
              <li className="nav-item">
                <a href="#1" className="navbar-nav-link text-info">
                  <i className="icon-checkmark2 mr-1"></i>
                  Solicitado
                </a>
              </li>
            </ul>

            <ul className="navbar-nav ml-lg-auto">
              <li className="nav-item">
                <a href="#1" className="navbar-nav-link">
                  <i className="icon-pencil mr-2"></i>
                  Postar
                </a>
              </li>
              <li className="nav-item">
                <a href="#1" className="navbar-nav-link">
                  <i className="icon-comment mr-2"></i>
                  Enviar Mensagem
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="row padding-tab-top-post">
          <div className="col-xl-3">
            <div className="card">
              <ul className="list-group noborder">
                <li className="list-group-item list-interesse">
                  <i className="icon-users4 font-size-xs  mr-1"></i>
                  <a href="#1">Convide seus colegas </a>&nbsp;para entrar no
                  grupo
                </li>

                <li className="list-group-item list-interesse">
                  <i className="icon-heart5 font-size-xs  mr-2"></i>82.188
                  pessoas curtiram isso
                </li>
              </ul>
            </div>

            <div className="card">
              <div className="card-body p-0">
                <div className="nav nav-sidebar mb-2">
                  <li className="nav-item">
                    <a href="#1" className="nav-link text-dark">
                      <i className="icon-googleplus5"></i>
                      Início
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#1" className="nav-link text-dark">
                      <i className="icon-googleplus5"></i>
                      Sobre
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#1" className="nav-link text-dark">
                      <i className="icon-portfolio"></i>
                      Fotos e Vídeos
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#1" className="nav-link text-dark">
                      <i className="icon-compose"></i>
                      Eventos
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#1" className="nav-link text-dark">
                      <i className="icon-user-plus"></i>
                      Discussões
                      <span className="badge bg-success ml-auto">
                        8 criadas
                      </span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#1" className="nav-link text-dark">
                      <i className="icon-collaboration"></i>
                      Enquetes
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#1" className="nav-link text-dark">
                      <i className="icon-cart"></i>
                      Loja
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#1" className="nav-link text-dark">
                      <i className="icon-download"></i>
                      Arquivos
                    </a>
                  </li>
                </div>
              </div>
            </div>

            <div className="card">
              <a href="#1">
                <img
                  src="../global_assets/images/demo/flat/18.png"
                  className="img-fluid"
                  alt=""
                />
              </a>

              <div className="card-body">
                <div className="list-feed">
                  <div className="list-feed-item border-warning-400">
                    <div className="text-muted font-size-sm mb-1">
                      12 minutos atrás
                    </div>
                    <a href="#1">Essa é o tópico de uma discussão!!!</a>
                  </div>

                  <div className="list-feed-item border-warning-400">
                    <div className="text-muted font-size-sm mb-1">
                      12 de novembro de 2019
                    </div>
                    <a href="#1">
                      Essa é o tópico de uma discussão. Responda sobre alguma
                      coisa.
                    </a>
                  </div>

                  <div className="list-feed-item border-warning-400">
                    <div className="text-muted font-size-sm mb-1">
                      11 de novembro de 2019
                    </div>
                    <a href="#1">Essa é o tópico de uma discussão!!!</a>
                  </div>

                  <div className="list-feed-item border-warning-400">
                    <div className="text-muted font-size-sm mb-1">
                      12 minutes ago
                    </div>
                    <a href="#1">Essa é o tópico de uma discussão!!!</a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-9">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-start align-items-center mb-1">
                  <div className="mr-1">
                    <img
                      src="../global_assets/images/demo/users/face1.jpg"
                      className="rounded-round"
                      alt="avtar img holder"
                      height="45"
                      width="45"
                    />
                  </div>
                  <div className="user-page-info">
                    <p className="mb-0">
                      <a href="#1" className="">
                        Mariana Gomes -{' '}
                        <span className="text-grey-300">
                          Psicanalista em Porto Alegre(RS)
                        </span>
                      </a>
                    </p>
                    <span className="font-small-2 text-grey-300">
                      Há 2 horas
                    </span>
                  </div>
                  <div className="ml-auto user-like text-danger">
                    <i className="icon-heart6 font-size-icon3"></i>
                  </div>
                </div>
                <p>
                  I love jujubes wafer pie ice cream tiramisu. Chocolate I love
                  pastry pastry sesame snaps wafer. Pastry topping biscuit
                  lollipop topping I love lemon drops sweet roll bonbon. Brownie
                  donut icing.
                </p>
                <div className="d-flex justify-content-start align-items-center mb-1">
                  <div className="d-flex align-items-center">
                    <i className="icon-heart6 font-medium-2 mr-1"></i>
                    <span>145 Curtidas</span>
                  </div>

                  <div className="ml-4">
                    <i className="icon-file-text font-medium-2 "></i>
                    <span>14 Comentários</span>
                  </div>
                  <p className="ml-auto d-flex align-items-center">
                    <i className="feather icon-message-square font-medium-2 "></i>
                    &nbsp;
                  </p>
                </div>

                <div className="card-body">
                  <ul className="media-list">
                    <li className="media flex-column flex-md-row">
                      <div className="mr-md-3 mb-2 mb-md-0">
                        <a href="#1">
                          <img
                            src="../global_assets/images/demo/users/face2.jpg"
                            className="rounded-circle"
                            width="38"
                            height="38"
                            alt=""
                          />
                        </a>
                      </div>

                      <div className="media-body">
                        <div className="media-title">
                          <a href="#1" className="font-weight-semibold">
                            William Jennings
                          </a>
                          <span className="text-muted ml-3">Just now</span>
                        </div>

                        <p>
                          He moonlight difficult engrossed an it sportsmen.
                          Interested has all devonshire difficulty gay
                          assistance joy. Unaffected at ye of compliment
                          alteration to.
                        </p>

                        <ul className="list-inline list-inline-dotted font-size-sm">
                          <li className="list-inline-item">
                            114{' '}
                            <a href="#1">
                              <i className="icon-arrow-up22 text-success"></i>
                            </a>
                            <a href="#1">
                              <i className="icon-arrow-down22 text-danger"></i>
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a href="#1">Reply</a>
                          </li>
                          <li className="list-inline-item">
                            <a href="#1">Edit</a>
                          </li>
                        </ul>
                      </div>
                    </li>

                    <li className="media flex-column flex-md-row">
                      <div className="mr-md-3 mb-2 mb-md-0">
                        <a href="#1">
                          <img
                            src="../global_assets/images/demo/users/face3.jpg"
                            className="rounded-circle"
                            width="38"
                            height="38"
                            alt=""
                          />
                        </a>
                      </div>

                      <div className="media-body">
                        <div className="media-title">
                          <a href="#1" className="font-weight-semibold">
                            Margo Baker
                          </a>
                          <span className="text-muted ml-3">5 minutes ago</span>
                        </div>

                        <p>
                          Place voice no arise along to. Parlors waiting so
                          against me no. Wishing calling are warrant settled was
                          luckily. Express besides it present if at an opinion
                          visitor.
                        </p>

                        <ul className="list-inline list-inline-dotted font-size-sm">
                          <li className="list-inline-item">
                            90{' '}
                            <a href="#1">
                              <i className="icon-arrow-up22 text-success"></i>
                            </a>
                            <a href="#1">
                              <i className="icon-arrow-down22 text-danger"></i>
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a href="#1">Reply</a>
                          </li>
                          <li className="list-inline-item">
                            <a href="#1">Edit</a>
                          </li>
                        </ul>
                      </div>
                    </li>

                    <li className="media flex-column flex-md-row">
                      <div className="mr-md-3 mb-2 mb-md-0">
                        <a href="#1">
                          <img
                            src="../global_assets/images/demo/users/face4.jpg"
                            className="rounded-circle"
                            width="38"
                            height="38"
                            alt=""
                          />
                        </a>
                      </div>

                      <div className="media-body">
                        <div className="media-title">
                          <a href="#1" className="font-weight-semibold">
                            Victoria Johnson
                          </a>
                          <span className="text-muted ml-3">3 hours ago</span>
                        </div>

                        <p>
                          Finished why bringing but sir bachelor unpacked any
                          thoughts. Unpleasing unsatiable particular inquietude
                          did nor sir.
                        </p>

                        <ul className="list-inline list-inline-dotted font-size-sm">
                          <li className="list-inline-item">
                            32{' '}
                            <a href="#1">
                              <i className="icon-arrow-up22 text-success"></i>
                            </a>
                            <a href="#1">
                              <i className="icon-arrow-down22 text-danger"></i>
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a href="#1">Reply</a>
                          </li>
                          <li className="list-inline-item">
                            <a href="#1">Edit</a>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>

                <fieldset className="form-label-group mb-50">
                  <textarea
                    className="form-control"
                    id="label-textarea"
                    rows="3"
                    placeholder="Add Comment"
                  ></textarea>
                  <label>&nbsp;</label>
                </fieldset>
                <button
                  type="button"
                  className="btn btn-sm btn-primary waves-effect waves-light"
                >
                  Post Comment
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
