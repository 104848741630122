export function affiliationsGetRequest() {
  return {
    type: '@affiliations/GET_AFFILIATION_REQUEST',
  };
}

export function affiliationsGetSuccess(data) {
  return {
    type: '@affiliations/GET_AFFILIATION_SUCCESS',
    payload: data,
  };
}

export function affiliationsGetFailure() {
  return {
    type: '@affiliations/GET_AFFILIATION_FAILURE',
  };
}

export function affiliationGetByIdRequest(id) {
  return {
    type: '@affiliations/GET_AFFILIATION_BY_ID_REQUEST',
    payload: id,
  };
}

export function affiliationGetByIdSuccess(data) {
  return {
    type: '@affiliations/GET_AFFILIATION_BY_ID_SUCCESS',
    payload: data,
  };
}

export function affiliationGetByIdFailure() {
  return {
    type: '@affiliations/GET_AFFILIATION_BY_ID_FAILURE',
  };
}
