export function freightsGetRequest() {
  return {
    type: '@freights/GET_FREIGHTS_REQUEST',
  };
}

export function freightsGetSuccess(data) {
  return {
    type: '@freights/GET_FREIGHTS_SUCCESS',
    payload: data,
  };
}

export function freightsGetFailure() {
  return {
    type: '@freights/GET_FREIGHTS_FAILURE',
  };
}

export function freightGetRequest(id) {
  return {
    type: '@freights/GET_FREIGHT_REQUEST',
    payload: id,
  };
}

export function freightGetSuccess(freight) {
  return {
    type: '@freights/GET_FREIGHT_SUCCESS',
    payload: freight,
  };
}

export function freightGetFailure() {
  return {
    type: '@freights/GET_FREIGHT_FAILURE',
  };
}

export function freightClearRequest() {
  return {
    type: '@freights/CLEAR_FREIGHT_REQUEST',
  };
}

export function getFreightValueRequest(query) {
  return {
    type: '@freights/GET_FREIGHT_VALUE_REQUEST',
    payload: query,
  };
}

export function getFreightValueSuccess(freightValue) {
  return {
    type: '@freights/GET_FREIGHT_VALUE_SUCCESS',
    payload: freightValue,
  };
}

export function getFreightValueFailure() {
  return {
    type: '@freights/GET_FREIGHT_VALUE_FAILURE',
  };
}
