import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Prototype from 'prop-types';
import { NavLink } from 'react-router-dom';

import Header from '~components/Layout/HeaderMart';
import CreateCourse from '~/components/CreateCourse';
import CreateProduct from '~/components/CreateProduct';

import { Whats } from './styles';

import { objIsNull } from '~/util';

function PsicoMart(props) {
  const [showCourse, setShowCourse] = useState(false);
  const [showProduct, setShowProduct] = useState(false);

  const { profile } = useSelector(state => state.user);
  const { menus } = useSelector(state => state.layouts);

  const handleCloseCourse = () => setShowCourse(false);
  const handleCloseProduct = () => setShowProduct(false);

  const { sidebar, breadcrumb, children, crumbs } = props;
  function renderCourseMenus() {
    if (objIsNull(menus)) {
      const { curso } = menus;
      if (curso) {
        return (
          <>
            <li className="nav-item-header">
              <div className="text-uppercase font-size-xs line-height-xs">
                CURSOS
              </div>
              <i className="icon-menu" title="Forms"></i>
            </li>
            <li className="nav-item">
              <NavLink
                to="/cursos"
                activeClassName="active"
                className="nav-link"
              >
                <i className="icon-list"></i>
                <span>Meus Cursos</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/alunos"
                activeClassName="active"
                className="nav-link"
              >
                <i className="icon-users"></i>
                <span>Meus Alunos</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/modulos"
                activeClassName="active"
                className="nav-link"
              >
                <i className="icon-drawer3"></i>
                <span>Conteúdo</span>
              </NavLink>
            </li>
          </>
        );
      }
    }
    return null;
  }

  function renderEventMenus() {
    if (objIsNull(menus)) {
      const { evento } = menus;
      if (evento) {
        return (
          <>
            <li className="nav-item-header">
              <div className="text-uppercase font-size-xs line-height-xs">
                EVENTOS
              </div>
              <i className="icon-menu" title="Main"></i>
            </li>
            <li className="nav-item">
              <NavLink
                to="/eventos"
                activeClassName="active"
                className="nav-link"
              >
                <i className="icon-list"></i> <span>Meus Eventos</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/participantes" className="nav-link">
                <i className="icon-users4"></i>
                <span>Participantes</span>
              </NavLink>
            </li>
          </>
        );
      }
    }
    return null;
  }

  function renderProductMenus() {
    if (objIsNull(menus)) {
      const { produto } = menus;
      if (produto) {
        return (
          <>
            <li className="nav-item-header">
              <div className="text-uppercase font-size-xs line-height-xs">
                PRODUTO
              </div>
              <i className="icon-menu" title="Forms"></i>
            </li>
            <li className="nav-item">
              <NavLink
                to="/produtos"
                activeClassName="active"
                className="nav-link"
              >
                <i className="icon-list"></i>
                <span>Meus Produtos</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/clientes"
                activeClassName="active"
                className="nav-link"
              >
                <i className="icon-users"></i>
                <span>Meus Clientes</span>
              </NavLink>
            </li>
          </>
        );
      }
    }
    return null;
  }

  function renderProductionMenus() {
    if (objIsNull(menus)) {
      const { curso, evento, produto } = menus;
      if (curso || evento || produto) {
        return (
          <>
            <li className="nav-item-header">
              <div className="text-uppercase font-size-xs line-height-xs">
                VENDAS E COMPRAS
              </div>
              <i className="icon-menu" title="Forms"></i>
            </li>
            <li className="nav-item">
              <NavLink
                to="/vendas"
                activeClassName="active"
                className="nav-link"
              >
                <i className="icon-cart5"></i> <span>Minhas Vendas</span>
              </NavLink>
            </li>

            {profile.eh_comprador && (
              <>
                <li className="nav-item">
                  <NavLink
                    to="/compras"
                    activeClassName="active"
                    className="nav-link"
                  >
                    <i className="icon-bag"></i> <span>Minhas Compras</span>
                  </NavLink>
                </li>
              </>
            )}
          </>
        );
      }
    }
    return null;
  }

  function renderConsumerMenu() {
    if (objIsNull(menus)) {
      if (menus['curso-consumidor']) {
        return (
          <>
            <li className="nav-item">
              <NavLink
                to="/inventario"
                activeClassName="active"
                className="nav-link"
              >
                <i className="icon-video-camera3"></i> <span>Cursos</span>
              </NavLink>
            </li>
          </>
        );
      }
    }
    return null;
  }

  return (
    <>
      <Header />
      <div className="page-content navbar-top">
        {sidebar ? (
          <div
            className="sidebar sidebar-dark sidebar-main sidebar-expand-md"
            style={{ minHeight: '100vh', background: '#324148' }}
          >
            <div className="sidebar-mobile-toggler text-center">
              <a href="/#" className="sidebar-mobile-main-toggle">
                <i className="icon-arrow-left8"></i>
              </a>
              Navigation
              <a href="/#" className="sidebar-mobile-expand">
                <i className="icon-screen-full"></i>
                <i className="icon-screen-normal"></i>
              </a>
            </div>

            <div className="sidebar-content">
              <div
                className="card card-sidebar-mobile"
                style={{ paddingTop: 4 }}
              >
                <ul className="nav nav-sidebar" data-nav-type="accordion">
                  {profile.eh_vendedor && (
                    <li className="nav-item">
                      <NavLink
                        to="/dashboard"
                        activeClassName="active"
                        className="nav-link"
                      >
                        <i className="icon-home5"></i>
                        <span>Painel Geral</span>
                      </NavLink>
                    </li>
                  )}

                  {/* EVENTO */}
                  {(profile.tipo_eve === '1' || !profile.tipo_eve) && (
                    <>{renderEventMenus()}</>
                  )}

                  {/* CURSOS */}
                  {(profile.tipo_eve === '2' || !profile.tipo_eve) && (
                    <>{renderCourseMenus()}</>
                  )}

                  {/* PRODUTO */}
                  {(profile.tipo_eve === '3' || !profile.tipo_eve) && (
                    <>{renderProductMenus()}</>
                  )}

                  {profile.eh_comprador && !profile.eh_vendedor && (
                    <>
                      <li className="nav-item-header">
                        <div className="text-uppercase font-size-xs line-height-xs">
                          COMPRAS
                        </div>
                        <i className="icon-menu" title="Forms"></i>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to="/compras"
                          activeClassName="active"
                          className="nav-link"
                        >
                          <i className="icon-bag"></i>{' '}
                          <span>Minhas Compras</span>
                        </NavLink>
                      </li>
                    </>
                  )}

                  {renderConsumerMenu()}

                  {renderProductionMenus()}

                  {(profile.eh_vendedor || profile.eh_afiliado) && (
                    <>
                      <li className="nav-item-header">
                        <div className="text-uppercase font-size-xs line-height-xs">
                          Configurações
                        </div>
                        <i className="icon-menu" title="Main"></i>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to="/config/conta"
                          activeClassName="active"
                          className="nav-link"
                        >
                          <i className="icon-coin-dollar"></i>
                          <span>Conta Corrente</span>
                        </NavLink>
                      </li>
                    </>
                  )}

                  {profile.eh_afiliado && (
                    <>
                      <li className="nav-item-header">
                        <div className="text-uppercase font-size-xs line-height-xs">
                          AFILIAÇÕES
                        </div>
                        <i className="icon-menu" title="Forms"></i>
                      </li>
                      <li className="nav-item">
                        <a href="/afiliacoes" className="nav-link">
                          <i className="icon-user-tie"></i>{' '}
                          <span>Meus Afiliados</span>
                        </a>
                      </li>
                    </>
                  )}
                </ul>
              </div>
            </div>
          </div>
        ) : null}

        <div className="content  padding-tab-top-post">
          {!breadcrumb && { ...crumbs }}
          {children}
        </div>
      </div>

      <div>
        <a
          href="https://wa.me/34996792007"
          rel="noopener noreferrer"
          target="_blank"
        >
          <Whats>
            <i className="fa fa-whatsapp fa-3x"></i>
          </Whats>
        </a>
      </div>

      <CreateCourse show={showCourse} handleClose={handleCloseCourse} />
      <CreateProduct show={showProduct} handleClose={handleCloseProduct} />
    </>
  );
}

PsicoMart.propTypes = {
  children: Prototype.element.isRequired,
  breadcrumb: Prototype.bool.isRequired,
  crumbs: Prototype.element.isRequired,
  sidebar: Prototype.bool,
};

PsicoMart.defaultProps = {
  sidebar: true,
};

export default PsicoMart;
