import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import * as ClassesActions from './actions';

export function* classesGetByCourseRequest({ payload }) {
  try {
    const response = yield call(
      api.get,
      `/evento/listar-turma-curso/?q=(cod_evento:${payload})`
    );

    if (response.data.records.error) {
      toast.error(response.data.records.msg);
      yield put(ClassesActions.classesGetByCourseFailure());
    }
    yield put(ClassesActions.classesGetByCourseSuccess(response.data.records));
  } catch (err) {
    toast.error('Falha na comunicação com servidor!');
    yield put(ClassesActions.classesGetByCourseFailure());
  }
}

export function* classesGetRequest({ payload }) {
  try {
    const response = yield call(api.get, `/evento/get-turma-curso/${payload}`);
    if (response.data.records.error) {
      toast.error(response.data.records.msg);
      yield put(ClassesActions.classesGetFailure());
    }
    yield put(ClassesActions.classesGetSuccess(response.data.records));
  } catch (err) {
    toast.error('Falha na comunicação com servidor!');
    yield put(ClassesActions.classesGetFailure());
  }
}

export default all([
  takeLatest('@classes/GET_CLASSES_REQUEST', classesGetRequest),
  takeLatest(
    '@classes/GET_CLASSES_BY_COURSE_REQUEST',
    classesGetByCourseRequest
  ),
]);
