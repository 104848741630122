import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import { toast } from 'react-toastify';
import { Col, Button, Modal, Row, Spinner } from 'react-bootstrap';
import { Form } from '@unform/web';

import Input from '~/components/InputText';
import Select from '~/components/InputSelect';
import InputCurrency from '~/components/InputCurrency';

import api from '~/services/api';

import * as FreightsActions from '~/store/modules/freight/actions';

function CreateFreight({ show, handleClose }) {
  const formRef = useRef(null);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [fixedValue, setFixedValue] = useState(false);

  const freight = useSelector(state => state.freight.freight);

  useEffect(() => {
    if (freight) {
      if (freight.frt_cod_tipo === '1') setFixedValue(true);
    }
  }, [freight]);

  async function handleSubmit(data) {
    formRef.current.setErrors({});

    if (data.frt_cod_tipo === '') {
      toast.error('O campo tipo do frete é de preenchimento obrigatório!');
      return false;
    }

    if (
      data.frt_cep_mascara_1 === '' &&
      (data.frt_cep_mascara_2 !== '' || data.frt_cep_mascara_3 !== '')
    ) {
      toast.error('Entre com o primeiro CEP!');
      return false;
    }

    if (
      data.frt_cep_mascara_1 !== '' &&
      data.frt_cep_mascara_2 === '' &&
      data.frt_cep_mascara_3 !== ''
    ) {
      toast.error('Entre com o segundo CEP!');
      return false;
    }

    if (
      data.frt_cep_mascara_1 === '' &&
      data.frt_cep_mascara_2 === '' &&
      data.frt_cep_mascara_3 === ''
    ) {
      data.frt_cep_mascara_1 = '*';
    }

    setLoading(true);
    let response;
    let message;

    if (freight) {
      data.cod_frete = freight.cod_frete;
      response = await api.put(`/frete/${freight.cod_frete}`, data);
      message = 'Frete atualizado com sucesso!';
    } else {
      response = await api.post('/frete', data);
      message = 'Frete cadastrado com sucesso!';
    }

    if (response.data.records.error) {
      setLoading(false);
      toast.error(response.data.records.msg);
      return false;
    }
    setLoading(false);
    toast.success(message);
    setTimeout(() => {
      handleClose();
      dispatch(FreightsActions.freightsGetRequest());
    }, 1000);

    return false;
  }

  function populateForm() {
    if (freight) {
      return {
        frt_cod_tipo: parseInt(freight.frt_cod_tipo, 10),
        frt_vlr_doc_min: parseFloat(freight.frt_vlr_doc_min),
        frt_vlr_doc_max: parseFloat(freight.frt_vlr_doc_max),
        frt_cep_mascara_1:
          freight.frt_cep_mascara_1 === '*' ? '' : freight.frt_cep_mascara_1,
        frt_cep_mascara_2: freight.frt_cep_mascara_2,
        frt_cep_mascara_3: freight.frt_cep_mascara_3,
        frt_vlr_frete_fixo: parseFloat(freight.frt_vlr_frete_fixo),
        frt_dias_prazo_entrega: parseInt(freight.frt_dias_prazo_entrega, 10),
      };
    }
    return {};
  }

  return (
    <Modal size="sm" backdrop="static" show={show} onHide={handleClose}>
      <Form ref={formRef} onSubmit={handleSubmit} initialData={populateForm()}>
        <Modal.Header>
          <div className="media align-items-center align-items-md-start flex-column flex-md-row">
            <span className="text-teal mr-md-3 mb-3 mb-md-0">
              <i className="icon-truck text-info-400 border-info-400 border-2 rounded-round p-2 mt-1"></i>
            </span>
            <div className="media-body text-center text-md-left">
              <h3 className="media-title font-weight-semibold">
                Nova Regra de Frete
              </h3>
              <span className="text-muted">
                Preencha o formulário para adicionar uma nova regra de frete.
              </span>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={12}>
              <Row>
                <Col xl={12}>
                  <div className="form-group">
                    <label htmlFor="frt_cod_tipo">Tipo do Frete *</label>
                    <Select
                      id="frt_cod_tipo"
                      name="frt_cod_tipo"
                      placeholder="-- SELECIONE UM TIPO --"
                      onChange={e =>
                        e.value === 1
                          ? setFixedValue(true)
                          : setFixedValue(false)
                      }
                      options={[
                        { value: 1, label: 'Frete Fixo' },
                        { value: 2, label: 'Frete Grátis' },
                        { value: 3, label: 'Frete a Combinar' },
                      ]}
                    />
                  </div>
                </Col>

                <Col xl={12}>
                  <div className="form-group">
                    <label>CEP</label>
                    <Row>
                      <Col xl={4}>
                        <Input
                          id="frt_cep_mascara_1"
                          name="frt_cep_mascara_1"
                          maxLength="2"
                        />
                      </Col>
                      <Col xl={4}>
                        <Input
                          id="frt_cep_mascara_2"
                          name="frt_cep_mascara_2"
                          maxLength="3"
                        />
                      </Col>
                      <Col xl={4}>
                        <Input
                          id="frt_cep_mascara_3"
                          name="frt_cep_mascara_3"
                          maxLength="3"
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>

                {fixedValue && (
                  <Col xl={12}>
                    <div className="form-group">
                      <label htmlFor="frt_vlr_frete_fixo">
                        Valor do Frete Fixo
                      </label>
                      <InputCurrency
                        id="frt_vlr_frete_fixo"
                        name="frt_vlr_frete_fixo"
                      />
                    </div>
                  </Col>
                )}

                <Col xl={12}>
                  <div className="form-group">
                    <label className="mr-1">Valor de Venda</label>
                    <small className="text-muted">(de / até)</small>
                    <Row>
                      <Col xl={6}>
                        <InputCurrency
                          id="frt_vlr_doc_min"
                          name="frt_vlr_doc_min"
                        />
                      </Col>
                      <Col xl={6}>
                        <InputCurrency
                          id="frt_vlr_doc_max"
                          name="frt_vlr_doc_max"
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col xl={12}>
                  <div className="form-group">
                    <label className="mr-1">Prazo</label>
                    <small className="text-muted">(Em dias)</small>
                    <Input
                      id="frt_dias_prazo_entrega"
                      name="frt_dias_prazo_entrega"
                      maxLength="3"
                      type="number"
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button
            size="lg"
            block
            className="text-secondary"
            variant="link"
            onClick={handleClose}
          >
            <i className="icon-cross2 mr-1 font-weight-light"></i>
            Cancelar
          </Button>
          <Button size="lg" block disabled={!!loading} type="submit">
            {loading ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="mr-1"
                />
                Aguarde
              </>
            ) : (
              'Salvar'
            )}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

CreateFreight.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default CreateFreight;
