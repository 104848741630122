import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { signOut } from '~/store/modules/auth/actions';
import CreateEvent from '~/components/CreateEvent';
import CreateCourse from '~/components/CreateCourse';
import CreateProduct from '~/components/CreateProduct';

import { objIsNull } from '~/util';

import psicoMartLogo from '../../../assets/img/psicoMart22.png';

export default function Header() {
  const dispatch = useDispatch();

  const { profile } = useSelector(state => state.user);
  const { menus } = useSelector(state => state.layouts);

  const [show, setShow] = useState(false);
  const [showCourse, setShowCourse] = useState(false);
  const [showProduct, setShowProduct] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCloseCourse = () => setShowCourse(false);
  const handleShowCourse = () => setShowCourse(true);

  const handleCloseProduct = () => setShowProduct(false);
  const handleShowProduct = () => setShowProduct(true);

  function handleSignOut() {
    dispatch(signOut());
  }

  function renderShortcutButton() {
    if (objIsNull(menus)) {
      return (
        <button
          type="button"
          className="btn bg-teal-400 text-teal-400 border-teal-400 border-2 dropdown-toggle"
          data-toggle="dropdown"
          aria-expanded="false"
        >
          <i className="icon-plus3 mr-2"></i> Criar novo
        </button>
      );
    }
    return null;
  }

  const getRedirectUrl = () => {
    if (menus.eh_vendedor) {
      return '/';
    }
    if (menus.eh_comprador) {
      return '/inventario';
    }
    return '/';
  };

  return (
    <div
      className="navbar navbar-expand-md navbar-light fixed-top"
      style={{ height: 51 }}
    >
      <div className="d-flex justify-content-center align-items-center wmin-200 mr-3">
        <Link to={getRedirectUrl()}>
          <img src={psicoMartLogo} alt="PsicoMart" style={{ width: 130 }} />
        </Link>
      </div>
      <div className="d-md-none">
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbar-mobile"
        >
          <i className="icon-tree5"></i>
        </button>
        <button
          className="navbar-toggler sidebar-mobile-main-toggle"
          type="button"
        >
          <i className="icon-paragraph-justify3"></i>
        </button>
      </div>
      <div className="collapse navbar-collapse" id="navbar-mobile">
        <ul className="navbar-nav">
          <li className="nav-item">
            <div className="btn-group">
              {renderShortcutButton()}
              <div
                className="dropdown-menu dropdown-menu-right"
                x-placement="bottom-end"
              >
                <button
                  type="button"
                  onClick={handleShow}
                  className="btn btn-link dropdown-item"
                >
                  <i className="icon-presentation"></i> Evento
                </button>
                <button
                  type="button"
                  onClick={handleShowCourse}
                  className="btn btn-link dropdown-item"
                >
                  <i className="icon-graduation2"></i> Curso
                </button>
                <button
                  type="button"
                  onClick={handleShowProduct}
                  className="btn btn-link dropdown-item"
                >
                  <i className="icon-barcode2"></i> Produto
                </button>
              </div>
            </div>
          </li>
        </ul>
        <div className="header-elements bg-transparent py-0 border-0 mb-3 mb-md-0">
          <form action="#">
            <div className="form-group form-group-feedback form-group-feedback-right d-none">
              <input
                type="search"
                className="form-control bg-light-alpha border-transparent wmin-md-300"
                placeholder="Pesquisar"
              />
              <div className="form-control-feedback">
                <i className="icon-search4 font-size-sm"></i>
              </div>
            </div>
          </form>
        </div>

        <span className="ml-md-auto mr-md-3">&nbsp;</span>

        {/* <div className="d-flex justify-content-center">
          <Link to="" className="btn btn-link btn-float text-white">
            <i className="icon-compass4 text-white"></i>
            <span>Explorar</span>
          </Link>
          <Link to="/psicologos" className="btn btn-link btn-float text-white">
            <i className="icon-users2 text-white"></i>
            <span>Psicólogos</span>
          </Link>
          <Link to="/casos" className="btn btn-link btn-float text-white">
            <i className="icon-address-book2 text-white"></i>
            <span>Casos</span>
          </Link>
          <Link to="/documentos" className="btn btn-link btn-float text-white">
            <i className="icon-stack3 text-white"></i>
            <span>Documentos</span>
          </Link>
          <Link to="/shopping" className="btn btn-link btn-float text-white">
            <i className="icon-cart5 text-white"></i>
            <span>Shopping</span>
          </Link>
        </div> */}

        <span className="ml-md-auto mr-md-3">&nbsp;</span>

        <ul className="navbar-nav">
          {/* <li className="nav-item dropdown">
            <a
              href="#notifications"
              className="navbar-nav-link dropdown-toggle caret-0"
              data-toggle="dropdown"
            >
              <i className="icon-bell2"></i>
              <span className="d-md-none ml-2">Activity</span>
              <span className="badge badge-pill badge-mark bg-orange-400 border-orange-400 ml-auto ml-md-0"></span>
            </a>

            <div className="dropdown-menu dropdown-menu-right dropdown-content wmin-md-350">
              <div className="dropdown-content-header">
                <span className="font-weight-semibold">Latest activity</span>
                <a href="#1" className="text-default">
                  <i className="icon-search4 font-size-base"></i>
                </a>
              </div>

              <div className="dropdown-content-body dropdown-scrollable">
                <ul className="media-list">
                  <li className="media">
                    <div className="mr-3">
                      <a
                        href="#1"
                        className="btn bg-success-400 rounded-round btn-icon"
                      >
                        <i className="icon-mention"></i>
                      </a>
                    </div>

                    <div className="media-body">
                      <a href="#1">Taylor Swift</a> mentioned you in a post
                      <div className="font-size-sm text-muted mt-1">
                        4 minutes ago
                      </div>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="dropdown-content-footer bg-light">
                <a href="#1" className="text-grey mr-auto">
                  All activity
                </a>
                <div>
                  <a
                    href="#1"
                    className="text-grey"
                    data-popup="tooltip"
                    title="Clear list"
                  >
                    <i className="icon-checkmark3"></i>
                  </a>
                  <a
                    href="#1"
                    className="text-grey ml-2"
                    data-popup="tooltip"
                    title="Settings"
                  >
                    <i className="icon-gear"></i>
                  </a>
                </div>
              </div>
            </div>
          </li> */}

          <li className="nav-item dropdown dropdown-user">
            <a
              href="#user"
              className="navbar-nav-link d-flex align-items-center dropdown-toggle"
              data-toggle="dropdown"
            >
              {profile.img_perfil ? (
                <img
                  src={profile.img_perfil}
                  className="rounded-circle mr-2"
                  height="34"
                  alt=""
                />
              ) : (
                <div className="mr-1">
                  <div className="btn bg-teal-400 rounded-round btn-icon">
                    <span className="letter-icon">
                      {profile.nome_membro.substring(0, 1)}
                    </span>
                  </div>
                </div>
              )}
              <span>{profile.nome_membro}</span>
            </a>

            <div className="dropdown-menu dropdown-menu-right">
              <Link to="/profile" className="dropdown-item">
                <i className="icon-user-plus"></i> Meu Perfil
              </Link>
              <div className="dropdown-divider"></div>

              <Link to="" onClick={handleSignOut} className="dropdown-item">
                <i className="icon-switch2"></i> Sair
              </Link>
            </div>
          </li>
        </ul>
      </div>

      <CreateEvent show={show} handleClose={handleClose} />
      <CreateCourse show={showCourse} handleClose={handleCloseCourse} />
      <CreateProduct show={showProduct} handleClose={handleCloseProduct} />
    </div>
  );
}
