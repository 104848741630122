import React from 'react';
import ContentLoader from 'react-content-loader';

export default function LoaderEvent() {
  return (
    <div className="card-body">
      <ContentLoader height={590} width={1360} speed={2}>
        <rect x="0" y="0" rx="8" ry="8" width="250" height="220" />
        <rect x="270" y="0" rx="8" ry="8" width="250" height="220" />
        <rect x="540" y="0" rx="8" ry="8" width="250" height="220" />
        <rect x="810" y="0" rx="8" ry="8" width="250" height="220" />
        <rect x="1080" y="0" rx="8" ry="8" width="250" height="220" />
      </ContentLoader>
    </div>
  );
}
