import produce from 'immer';

const INITIAL_STATE = {
  step: 1,
  cart: [],
  configCep: {},
  installments: [],
  event: {},
  tickets: [],
  participants: [],
  total: 0,
  amount: 0,
  coupon: {},
  billet: '',
  affiliate: '',
  ticket: {},
  paid: false,
  loading: false,
};

export default function cart(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@cart/ADD_PRODUCT_IN_CART': {
        draft.cart = [action.payload];
        break;
      }
      case '@cart/SET_CONFIG_CEP_TO_CART': {
        draft.configCep = action.payload;
        break;
      }
      case '@cart/ADD_TICKET_IN_CART': {
        const { amount, ticket } = action.payload;
        const { tickets } = draft;

        const total = ticket.val > 0 && amount > 0 ? ticket.val * amount : 0;

        const obj = {
          ...ticket,
          total,
          amount,
          ticketAmount: {
            ticket,
            amount,
          },
        };

        /** Caso exista quantidade ele iria validar e caso seja novo add se não update  */
        if (amount && amount > 0) {
          const index = tickets.findIndex(e => e.id === ticket.id);
          index === -1 ? tickets.push(obj) : (tickets[index] = obj);
        }

        /** Recalcula o total quando é feito uma mudança de um select */
        if (tickets.length) {
          draft.total = 0;
          draft.amount = 0;
          tickets.forEach((val, i) => {
            draft.total += tickets[i].val * tickets[i].ticketAmount.amount;
            draft.amount += tickets[i].ticketAmount.amount;
          });
          tickets.forEach((val, i) => {
            tickets[i].total = draft.total;
            tickets[i].amount = draft.amount;
          });
        }

        break;
      }
      case '@cart/ADD_EVENT_IN_CART': {
        draft.event = action.payload;
        break;
      }
      case '@cart/ADD_PARTICIPANTS_IN_CART': {
        draft.participants = action.payload;
        break;
      }
      case '@cart/ADD_AFFILIATE_IN_CART': {
        draft.affiliate = action.payload;
        break;
      }
      case '@cart/ADD_STEP_IN_CART': {
        draft.step += 1;
        break;
      }
      case '@cart/GET_COUPON_SUCCESS': {
        draft.coupon = action.payload;
        const { coupon } = draft;
        coupon.val_desc /= 100;

        const { amount } = draft;
        const { total } = draft;
        const discount = total * coupon.val_desc;

        coupon.total = total - discount;
        coupon.amount = amount;
        coupon.discount = discount;

        draft.loading = false;
        break;
      }
      case '@cart/GET_COUPON_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@cart/GET_INSTALLMENTS_REQUEST': {
        draft.loading = false;
        break;
      }
      case '@cart/GET_INSTALLMENTS_SUCCESS': {
        const { payload } = action;

        draft.installments = payload.map(value => {
          return { value: value.id, label: value.desc };
        });
        draft.loading = false;
        break;
      }
      case '@cart/GET_PAYMENT_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@cart/GET_PAYMENT_SUCCESS': {
        const { payload } = action;

        if (payload.url_boleto) {
          draft.billet = payload.url_boleto;
          draft.step += draft.step;
        } else {
          draft.ticket = payload;
          draft.paid = true;
          draft.step += draft.step;
        }

        draft.loading = false;
        break;
      }

      case '@cart/GET_COUPON_FAILURE':
      case '@cart/GET_INSTALLMENTS_FAILURE':
      case '@cart/GET_PAYMENT_FAILURE': {
        draft.loading = false;
        break;
      }
      case '@cart/CLEAR_CART': {
        draft.step = 1;
        draft.cart = [];
        draft.configCep = {};
        draft.installments = [];
        draft.event = {};
        draft.tickets = [];
        draft.participants = [];
        draft.total = 0;
        draft.amount = 0;
        draft.coupon = {};
        draft.billet = '';
        draft.affiliate = '';
        draft.ticket = {};
        draft.paid = false;
        draft.loading = false;
        break;
      }
      case '@auth/SIGN_OUT': {
        draft.step = 1;
        draft.cart = [];
        draft.configCep = {};
        draft.installments = [];
        draft.event = {};
        draft.tickets = [];
        draft.participants = [];
        draft.total = 0;
        draft.amount = 0;
        draft.coupon = {};
        draft.billet = '';
        draft.affiliate = '';
        draft.ticket = {};
        draft.paid = false;
        draft.loading = false;
        break;
      }
      default: {
        break;
      }
    }
  });
}
