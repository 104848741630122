import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import * as ModulesActions from './actions';

export function* modulesGetByCourse({ payload }) {
  try {
    const response = yield call(
      api.get,
      `/evento/listar-modulo-curso/?q=(cod_evento:${payload})`
    );
    if (response.data.records.error) {
      toast.error(response.data.records.msg);
      yield put(ModulesActions.modulesGetByCourseFailure());
    }
    yield put(ModulesActions.modulesGetByCourseSuccess(response.data.records));
  } catch (err) {
    toast.error('Falha na comunicação com servidor!');
    yield put(ModulesActions.modulesGetByCourseFailure());
  }
}

export function* modulesGetRequest({ payload }) {
  try {
    const response = yield call(api.get, `/evento/get-modulo-curso/${payload}`);
    if (response.data.records.error) {
      toast.error(response.data.records.msg);
      yield put(ModulesActions.modulesGetFailure());
    }
    yield put(ModulesActions.modulesGetSuccess(response.data.records));
  } catch (err) {
    toast.error('Falha na comunicação com servidor!');
    yield put(ModulesActions.modulesGetFailure());
  }
}

export default all([
  takeLatest('@modules/GET_MODULE_REQUEST', modulesGetRequest),
  takeLatest('@modules/GET_MODULES_BY_COURSE_REQUEST', modulesGetByCourse),
]);
