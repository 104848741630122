import styled from 'styled-components';

export const UploadCurso = styled.div`
  .box-upload div {
    padding: 6px 0 !important;
  }

  .box-upload div p {
    margin: 1px !important;
    padding: 0 !important;
  }

  ul li {
    align-items: center;
    color: #444;
    border: 1px solid #ccc;
    padding: 10px;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    margin-bottom: 10px;
  }

  ul li div {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    align-items: center;
  }
`;

export const BoxCardHover = styled.div`
  .card:hover {
    border-color: #c9c9c9 !important;
    border-width: 2px;
    cursor: pointer;
  }
`;

export const VideoPlayer = styled.div`
  .embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
  }

  .embed-responsive-item {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
`;

export const TimeLineList = styled.div`
  ul {
    list-style: none;
    position: relative;
    margin: 0 0 20px;
  }

  ul li a {
    color: rgba(111, 108, 108, 0.85);
  }

  ul li.done,
  ul li.default,
  ul li.current {
    font-size: 18px;
    font-weight: 400;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    z-index: 5;
    color: rgb(255, 255, 255);
    border-color: #c8c7ce;
    border-style: solid none solid none;
    border-width: 1px;
    padding: 10px 20px;
  }

  ul li.done + li,
  ul li.default + li,
  ul li.current + li {
    border-style: none none solid none;
  }

  ul li.done button {
    position: relative;
    cursor: pointer;
    width: 10px;
    height: 10px;
    margin-right: 30px;
    flex-shrink: 0;
    z-index: 2;
    border-width: 0px;
    border-style: initial;
    border-color: initial;
    border-image: initial;
    border-radius: 50%;
    transition: all 0.2s ease 0s;
    background: #059847;
  }

  ul li.default button {
    position: relative;
    cursor: pointer;
    width: 10px;
    height: 10px;
    margin-right: 30px;
    flex-shrink: 0;
    z-index: 2;
    border-width: 0px;
    border-style: initial;
    border-color: initial;
    border-image: initial;
    background: rgb(165, 165, 167);
    border-radius: 50%;
    transition: all 0.2s ease 0s;
  }

  ul li.current button {
    position: relative;
    cursor: pointer;
    width: 10px;
    height: 10px;
    margin-right: 30px;
    flex-shrink: 0;
    z-index: 2;
    border-width: 0px;
    border-style: initial;
    border-color: initial;
    border-image: initial;
    background: #26a69a;
    border-radius: 50%;
    transition: all 0.2s ease 0s;
  }

  .ul li.default::before,
  .ul li.current::before {
    top: auto;
    bottom: 50%;
  }

  ul li.done a {
    color: rgb(165, 165, 167);
  }

  ul li.current a {
    color: #26a69a;
  }

  ul li.default a {
    color: rgb(0, 0, 0);
  }

  ul li.done button::before,
  ul li.default button::after,
  ul li.current button::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 250%;
    height: 250%;
    transform: translate(-50%, -50%);
  }

  ul li.default::before,
  ul li.done::before,
  ul li.current::before {
    top: auto;
    bottom: 50%;
  }

  ul li.done button:hover,
  ul li.default button:hover,
  ul li.current button:hover {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 0px 6px;
  }

  ul li.done:last-child::after,
  ul li.default:last-child::after,
  ul li.current:last-child::after {
    background-color: #fff;
  }

  ul li.done:first-child::before,
  ul li.default:first-child::before,
  ul li.current:first-child::before {
    background-color: #fff;
  }
`;
