/* eslint-disable no-nested-ternary */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from '@unform/web';

import { toast } from 'react-toastify';
import { Button, Card, Col, Row, Table } from 'react-bootstrap';

import * as FreightsActions from '~/store/modules/freight/actions';

import Loading from '~/components/Loading';
import BoxConfirm from '~/components/BoxConfirm';
import InputDecimal from '~/components/InputDecimal';
import Input from '~/components/InputText';
import CreateFreight from '~/components/CreateFreight';

import api from '~/services/api';

export default function Frete({ action, form, product }) {
  const dispatch = useDispatch();

  const loading = useSelector(state => state.freight.loading);
  const freights = useSelector(state => state.freight.freights);

  const [showBox, setShowBox] = useState(false);
  const [freightType, setFreightType] = useState(0);
  const [showModalFreight, setShowModalFreight] = useState(false);
  const [selectedFreight, setSelectedFreight] = useState({});

  const handleShowModal = () => setShowModalFreight(true);
  function handleCloseModal() {
    setShowModalFreight(false);
    dispatch(FreightsActions.freightClearRequest());
  }

  useEffect(() => {
    if (product && Object.keys(product).length) {
      setFreightType(parseInt(product.prod_tipo_frete, 10));
      dispatch(FreightsActions.freightsGetRequest());
    }
  }, [product, dispatch]);

  function onClose() {
    setShowBox(false);
  }

  function handleFreightType(type) {
    if (type === 2) {
      dispatch(FreightsActions.freightsGetRequest());
    }
    setFreightType(type);
  }

  function renderFreightForm() {
    return (
      <>
        <div className="page-header-content header-elements-md-inline p-0">
          <div className="page-title d-flex p-0">
            <h4 className="mb-0 font-weight-semibold">
              <i className="icon-cube mr-2 c-pointer"></i> Dados do Produto
              <span className="text-muted d-block" style={{ fontSize: 12 }}>
                Preencha o formulário abaixo para configurar o frete via
                Correios.
              </span>
            </h4>
          </div>
        </div>

        <Row className="mt-3">
          <Col xl={12}>
            <Row>
              <Col xl={3}>
                <div className="form-group">
                  <label htmlFor="prod_peso_bruto">Peso Bruto</label>
                  <InputDecimal id="prod_peso_bruto" name="prod_peso_bruto" />
                </div>
              </Col>
              <Col xl={3}>
                <div className="form-group">
                  <label htmlFor="prod_peso_liquido">Peso Líquido</label>
                  <InputDecimal
                    id="prod_peso_liquido"
                    name="prod_peso_liquido"
                  />
                </div>
              </Col>
            </Row>
          </Col>
          <Col xl={12}>
            <Row>
              <Col xl={2}>
                <div className="form-group">
                  <label htmlFor="prod_comprimento">Comprimento</label>
                  <InputDecimal id="prod_comprimento" name="prod_comprimento" />
                </div>
              </Col>
              <Col xl={2}>
                <div className="form-group">
                  <label htmlFor="prod_largura">Largura</label>
                  <InputDecimal id="prod_largura" name="prod_largura" />
                </div>
              </Col>
              <Col xl={2}>
                <div className="form-group">
                  <label htmlFor="prod_altura">Altura</label>
                  <div className="App">
                    <InputDecimal id="prod_altura" name="prod_altura" />
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }

  async function handleSaveMeasuresProduct(data) {
    data.prod_tipo_frete = freightType;
    const response = await api.post('/produto/salvar-medida-produto', data);
    if (response.data.records.error) {
      toast.error(response.data.records.msg);
      return;
    }
    toast.success('Dados cadastrados com sucesso!');
  }

  function handleRemove(item) {
    setShowBox(true);
    setSelectedFreight(item);
  }

  async function removeFreight() {
    const response = await api.delete(
      `/frete/cancelar/${selectedFreight.cod_frete}`
    );
    if (response.data.records.error) {
      toast.error(response.data.records.msg);
    } else {
      toast.success('Frete removido com sucesso!');
      dispatch(FreightsActions.freightsGetRequest());
    }

    onClose();
    setSelectedFreight({});
  }

  function handleEdit(id) {
    dispatch(FreightsActions.freightGetRequest(id));
    setTimeout(() => {
      setShowModalFreight(true);
    }, 500);
  }

  function getDescriptionFrete(type) {
    switch (type) {
      case '1':
        return 'Frete Fixo';
      case '2':
        return 'Frete Grátis';
      case '3':
        return 'Frete a Combinar';
      default:
        return '';
    }
  }

  function formatCurrency(value) {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  }

  function renderFreightTable() {
    return (
      <>
        <CreateFreight show={showModalFreight} handleClose={handleCloseModal} />
        <BoxConfirm
          title="Remover Frete"
          show={showBox}
          size="sm"
          onYesClick={removeFreight}
          onNoClick={onClose}
          onClose={onClose}
          message="Deseja realmente remover o frete escolhido?"
        />
        <div className="page-header-content header-elements-md-inline p-0">
          <div className="page-title d-flex p-0">
            <h4 className="mb-0 font-weight-semibold">
              <i className="icon-truck mr-2 c-pointer"></i> Regras para o Frete
              <span className="text-muted d-block" style={{ fontSize: 12 }}>
                Crie novas regras para o frete clicando no botão Nova Regra.
              </span>
            </h4>
          </div>
          <div className="header-elements d-none">
            <div className="d-flex justify-content-center">
              <Button className="ml-3" variant="info" onClick={handleShowModal}>
                <i className="icon-plus2 mr-1"></i> Nova Regra
              </Button>
            </div>
          </div>
        </div>
        <Table striped hover className="mt-2">
          <thead>
            <tr>
              <th>Tipo</th>
              <th>CEP</th>
              <th className="text-right">Valor Frete Fixo</th>
              <th className="text-right">
                <span>Valor Venda</span> <small>(de)</small>
              </th>
              <th className="text-right">
                <span>Valor Venda</span> <small>(até)</small>
              </th>
              <th className="text-right">
                <span>Prazo</span> <small>(até)</small>
              </th>
              <th className="text-right">Ações</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(freights).length === 0 ? (
              <tr className="text-center">
                <td colSpan="7">
                  <strong>Nenhum frete encontrado.</strong>
                </td>
              </tr>
            ) : (
              freights.map(item => (
                <tr key={item.cod_frete}>
                  <td>{getDescriptionFrete(item.frt_cod_tipo)}</td>
                  <td>
                    {item.frt_cep_mascara_1}
                    {item.frt_cep_mascara_2}
                    {item.frt_cep_mascara_3}
                  </td>
                  <td className="text-right">
                    <span>{formatCurrency(item.frt_vlr_frete_fixo)}</span>
                  </td>
                  <td className="text-right">
                    <span>{formatCurrency(item.frt_vlr_doc_min)}</span>
                  </td>
                  <td className="text-right">
                    <span>{formatCurrency(item.frt_vlr_doc_max)}</span>
                  </td>
                  <td className="text-right">{item.frt_dias_prazo_entrega}</td>
                  <td className="text-right">
                    <button
                      title="Remover"
                      type="button"
                      onClick={() => {
                        handleRemove(item);
                      }}
                      className="btn btn-sm btn-link btn-icon mr-1"
                    >
                      <i className="icon-trash"></i>
                    </button>
                    <button
                      title="Editar"
                      type="button"
                      onClick={() => {
                        handleEdit(item.cod_frete);
                      }}
                      className="btn btn-sm btn-link btn-icon pr-0"
                    >
                      <i className="icon-pencil5"></i>
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </>
    );
  }

  return (
    <Card.Body className="p-0 mb-3">
      {loading ? (
        <div className="mt-5">
          <Loading />
        </div>
      ) : action === 'new' ? (
        <div className="alert alert-info border-0 alert-dismissible mb-0">
          Faça o cadastro de um novo produto primeiramente.
        </div>
      ) : (
        <>
          <Row>
            <Col xl={12}>
              <p className="text-center">
                Escolha como será o cálculo do frete:
              </p>
              <Row className="justify-content-md-center">
                <Col xl={2} className="c-pointer">
                  <Card
                    onClick={() => handleFreightType(1)}
                    className={`border-light shadow-sm type-product ${freightType ===
                      1 && 'select-product'}`}
                  >
                    <Card.Body
                      style={{ minHeight: 122 }}
                      className="d-flex flex-column justify-content-center align-items-center"
                    >
                      <i className="icon-truck icon-2x"></i>
                      <p className="text-center">
                        Utilizar cálculo dos Correios
                      </p>
                    </Card.Body>
                  </Card>
                </Col>
                <Col xl={2} className="c-pointer">
                  <Card
                    onClick={() => handleFreightType(2)}
                    className={`border-light shadow-sm type-product ${freightType ===
                      2 && 'select-product'}`}
                  >
                    <Card.Body
                      style={{ minHeight: 122 }}
                      className="d-flex flex-column justify-content-center align-items-center"
                    >
                      <i className="icon-calculator icon-2x"></i>
                      <p className="text-center">
                        Definir minhas próprias regras
                      </p>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>

          {freightType === 2 && renderFreightTable()}
          <Form
            onSubmit={handleSaveMeasuresProduct}
            initialData={{
              cod_produto: product.cod_produto,
              prod_peso_bruto: product.prod_peso_bruto,
              prod_peso_liquido: product.prod_peso_liquido,
              prod_comprimento: product.prod_comprimento,
              prod_largura: product.prod_largura,
              prod_altura: product.prod_altura,
              prod_tipo_frete: product.prod_tipo_frete,
            }}
          >
            <Input type="hidden" name="cod_produto" />

            {freightType === 1 && renderFreightForm()}

            <Row className="justify-content-md-center">
              <Col xl={2}>
                <Button block size="lg" type="submit" variant="primary">
                  Salvar
                </Button>
              </Col>
            </Row>
          </Form>
        </>
      )}
    </Card.Body>
  );
}
