import produce from 'immer';

const INITIAL_STATE = {
  menus: {},
  loading: false,
};

export default function layouts(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@layouts/GET_MENUS_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@layouts/GET_MENUS_SUCCESS': {
        draft.menus = action.payload;
        break;
      }
      case '@layouts/GET_MENUS_FAILURE': {
        draft.loading = false;
        break;
      }
      case '@layouts/ACTIVE_MENU_REQUEST': {
        const entity = action.payload;
        draft.menus = {
          ...state,
          [entity]: true,
        };
        break;
      }
      default: {
        break;
      }
    }
  });
}
