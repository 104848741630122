import React, { useState, useEffect, useCallback } from 'react';
import { Card } from 'react-bootstrap';
import { formatDistance } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';

// import { toast } from 'react-toastify';
import api from '~/services/api';

export default function LatestNews() {
  const [news, setNews] = useState([]);

  const loadNews = useCallback(async () => {
    api.get('config/listar-novidades').then(response => {
      setNews(response.data.records);
    });
  }, []);

  useEffect(() => {
    loadNews();
  }, [loadNews]);

  // const handleRead = useCallback(
  //   data => {
  //     api.put(`/config/marcar-atualizacao-lida/${data}`).then(response => {
  //       if (response.data.records.error) {
  //         toast.error(response.data.records.msg);

  //         return;
  //       }
  //       loadNews();
  //     });
  //   },
  //   [loadNews]
  // );
  return (
    <Card>
      <Card.Header>
        <Card.Title>Últimas novidades</Card.Title>
      </Card.Header>
      <Card.Body>
        {Object.keys(news).length === 0 ? (
          <div className="alert alert-info alert-styled-left alert-dismissible">
            <button type="button" className="close" data-dismiss="alert">
              <span>×</span>
            </button>
            <span className="font-weight-semibold">Tudo Certo! </span>
            Sem novidades no momento.
          </div>
        ) : (
          <ul className="media-list">
            {news.map(value => (
              <li key={value.cod_log_atualizacao} className="media">
                <div className="mr-2 position-relative">
                  <img
                    src="https://rs10.s3-sa-east-1.amazonaws.com/redesocial/avatar/avatar.png"
                    className="rounded-circle"
                    width="36"
                    jeight="36"
                    alt=""
                  />
                </div>

                <div className="media-body">
                  <div className="d-flex justify-content-between">
                    {value.desc}
                  </div>
                  <div className="d-flex justify-content-end">
                    <span className="font-size-sm text-muted">
                      {formatDistance(new Date(value.dt_cadastro), new Date(), {
                        locale: pt,
                      })}
                    </span>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        )}
      </Card.Body>
    </Card>
  );
}
