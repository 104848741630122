import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Form, Input } from '@rocketseat/unform';

import api from '~/services/api';

export default function CommentPost({ post, loadPost }) {
  const [disabled, setDisabled] = useState(true);

  async function handleComment({ comment }, { resetForm }) {
    try {
      setDisabled(true);
      const response = await api.post('/post', {
        resp: '1',
        cod_post_parente: post.cod_post,
        cod_post_pai: post.cod_post_pai ? post.cod_post_pai : post.cod_post,
        desc_post: comment,
        onde_post: '6',
      });
      if (!response.data.records.error) {
        loadPost();
        resetForm();
      }
    } catch (error) {
      setDisabled(false);
      toast.error('Falha na comunicação com servidor');
    }
  }

  return (
    <Form className="form-label-group mb-1 mt-3" onSubmit={handleComment}>
      <Input
        style={{ backgroundColor: '#f5f5f5' }}
        multiline
        rows={2}
        name="comment"
        className="form-control"
        placeholder="Escreva aqui seu comentário"
        onChange={e =>
          e.target.value.length > 0 ? setDisabled(false) : setDisabled(true)
        }
      />
      <button
        disabled={disabled}
        type="submit"
        className="btn btn-sm btn-primary waves-effect waves-light mt-2"
      >
        Enviar Comentário
      </button>
    </Form>
  );
}

CommentPost.propTypes = {
  post: PropTypes.object.isRequired,
  loadPost: PropTypes.func.isRequired,
};

CommentPost.defaultProps = {
  post: {},
};
