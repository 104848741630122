import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import Form from 'react-bootstrap/Form';

export default function CheckBox({ name, options, onChange, checkedValue }) {
  const inputRefs = useRef([]);
  const { fieldName, registerField, defaultValue } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      path: 'value',
      ref: inputRefs.current,
      getValue(refs) {
        const checked = refs.find(ref => ref.checked);

        return checked ? checked.value : null;
      },
      setValue(refs, value) {
        const item = refs.find(ref => ref.value === value);

        if (item) {
          item.checked = true;
        }
      },
    });
  }, [fieldName, registerField]);

  function getDefaultValue(option) {
    if (checkedValue) {
      return checkedValue === option.id;
    }
    return defaultValue === option.id;
  }

  return (
    <>
      {options.map((option, index) => (
        <Form.Check key={option.id} type="checkbox" id={option.label} custom>
          <Form.Check.Input
            ref={elRef => (inputRefs.current[index] = elRef)}
            type="checkbox"
            name={fieldName}
            value={option.id}
            defaultChecked={getDefaultValue(option)}
            onChange={onChange}
          />
          <Form.Check.Label>{option.label}</Form.Check.Label>
          <Form.Text className="text-muted">{option.text}</Form.Text>
        </Form.Check>
      ))}
    </>
  );
}
