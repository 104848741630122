import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import * as CartActions from './actions';

export function* couponGetRequest({ payload }) {
  try {
    const { cod_evento, num_cupom } = payload;
    const response = yield call(
      api.get,
      `/cupom/?q=(cod_evento:${cod_evento},num_cupom:${num_cupom.toUpperCase()})`
    );
    const { records } = response.data;
    const { count } = response.data;
    if (records.error) {
      toast.error(records.msg);
      yield put(CartActions.getCouponFailure());
      return;
    }

    if (!count) {
      toast.error('Cupom não encontrado');
      yield put(CartActions.getCouponFailure());
      return;
    }

    yield put(CartActions.getCouponSuccess(records));
  } catch (err) {
    toast.error('Falha na comunicação com servidor!');
    yield put(CartActions.getCouponFailure());
  }
}

export function* installmentsGetRequest({ payload }) {
  try {
    const { event, ing, total, coupon } = payload;

    const response = yield call(
      api.get,
      `/inscricao/montar-combo-parcelamento/?q=(cod_evento:${
        event.cod_evento
      },ing:${ing},total:${coupon.total ? coupon.total : total},tipo_eve:${
        event.tipo_eve
      })`
    );
    const { records } = response.data;
    if (records.error) {
      toast.error(records.msg);
      yield put(CartActions.getInstallmentsFailure());
      return;
    }

    yield put(CartActions.getInstallmentsSuccess(records));
  } catch (err) {
    toast.error('Falha na comunicação com servidor!');
    yield put(CartActions.getInstallmentsFailure());
  }
}

export function* paymentGetRequest({ payload }) {
  try {
    const response = yield call(
      api.post,
      '/inscricao/inserir-inscricao',
      payload
    );
    const { records } = response.data;
    if (records.error) {
      toast.error(records.msg);
      yield put(CartActions.getPaymentFailure());
      return;
    }

    yield put(CartActions.getPaymentSuccess(records));
    toast.success(records.msg);
  } catch (err) {
    toast.error('Falha na comunicação com servidor!');
    yield put(CartActions.getPaymentFailure());
  }
}

export default all([
  takeLatest('@cart/GET_COUPON_REQUEST', couponGetRequest),
  takeLatest('@cart/GET_INSTALLMENTS_REQUEST', installmentsGetRequest),
  takeLatest('@cart/GET_PAYMENT_REQUEST', paymentGetRequest),
]);
