import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import * as StudentsActions from './actions';

export function* studentsGetByCourseAndClass({ payload }) {
  try {
    const response = yield call(
      api.get,
      `/participante/listar-part-evento/${payload}`
    );
    if (response.data.records.error) {
      toast.error(response.data.records.msg);
      yield put(StudentsActions.studentsGetByCourseAndClassFailure());
    }
    yield put(
      StudentsActions.studentsGetByCourseAndClassSuccess(response.data.records)
    );
  } catch (err) {
    toast.error('Falha na comunicação com servidor!');
    yield put(StudentsActions.studentsGetByCourseAndClassFailure());
  }
}

export default all([
  takeLatest(
    '@students/GET_STUDENTS_BY_COURSE_AND_CLASS_REQUEST',
    studentsGetByCourseAndClass
  ),
]);
