import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Table } from 'react-bootstrap';
import { Form } from '@unform/web';

import api from '~/services/api';

export default function ModalHistoricalLooting({
  modalShow,
  modalClose,
  data,
}) {
  const [withdraws, setWithdraws] = useState([]);

  const loadWithdraw = useCallback(() => {
    api
      .get(`/config/listar-historico-saque/?q=(iugu_accountid:${data})`)
      .then(response => {
        setWithdraws(response.data.records);
      });
  }, [data]);

  useEffect(() => {
    if (modalShow) {
      loadWithdraw();
    }
  }, [loadWithdraw, modalShow]);

  return (
    <Modal size="lg" centered show={modalShow} onHide={modalClose}>
      <Form>
        <Modal.Header closeButton>
          <Modal.Title>Histórico de saque</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped hover>
            <thead>
              <tr>
                <th>Status</th>
                <th>Data saque</th>
                <th>Valor do saque R$</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(withdraws).length === 0 ? (
                <tr>
                  <td colSpan="7" className="p-0 text-center">
                    <div className="alert alert-info border-0 alert-dismissible mb-0">
                      <i className="icon-info3 border-2 rounded-round p-1 mr-2"></i>
                      Caro usuário, nenhum saque foi efetuado nesta conta
                    </div>
                  </td>
                </tr>
              ) : (
                withdraws.map(withdraw => (
                  <tr>
                    <td>{withdraw.status}</td>
                    <td>{withdraw.dt_saque}</td>
                    <td>{withdraw.amount}</td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" type="button" onClick={modalClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
ModalHistoricalLooting.propTypes = {
  modalShow: PropTypes.bool.isRequired,
  modalClose: PropTypes.func.isRequired,
};
