import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import { isMobile } from 'react-device-detect';

import { MdExpandMore } from 'react-icons/md';
import { makeStyles } from '@material-ui/core/styles';
import { Row, Col, Button } from 'react-bootstrap';

import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';

import * as Yup from 'yup';
import PropTypes from 'prop-types';

import api from '~/services/api';
import Input from '~/components/InputText';
import Select from '~/components/InputSelect';
import InputRadio from '~/components/InputRadio';
import InputMask from '~/components/InputMask';
import BottomButton from '~/components/BottomButton';

import { objIsNull } from '~/util';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: 10,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexShrink: 0,
    paddingLeft: '12px',
    marginBottom: 10,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    marginLeft: 15,
    top: -3,
    position: 'relative',
  },
}));

const mount = [
  { value: '01', label: '01 - Janeiro' },
  { value: '02', label: '02 - Fevereiro' },
  { value: '03', label: '03 - Março' },
  { value: '04', label: '04 - Abril' },
  { value: '05', label: '05 - Maio' },
  { value: '06', label: '06 - Junho' },
  { value: '07', label: '07 - Julho' },
  { value: '08', label: '08 - Agosto' },
  { value: '09', label: '09 - Setembro' },
  { value: '10', label: '10 - Outubro' },
  { value: '11', label: '11 - Novembro' },
  { value: '12', label: '12 - Dezembro' },
];

const year = [
  { value: '2020', label: '2020' },
  { value: '2021', label: '2021' },
  { value: '2022', label: '2022' },
  { value: '2023', label: '2023' },
  { value: '2024', label: '2024' },
  { value: '2025', label: '2025' },
  { value: '2026', label: '2026' },
  { value: '2027', label: '2027' },
  { value: '2028', label: '2028' },
  { value: '2029', label: '2029' },
  { value: '2030', label: '2030' },
];

export default function ControlledExpansionPanels({
  next,
  products,
  freight,
  setBoleto,
}) {
  const classes = useStyles();
  const formRef = useRef(null);

  const [load, setLoad] = useState(false);
  const [card, setCard] = useState(true);
  const [method, setMethod] = useState(3);
  const [billet, setBillet] = useState(null);
  const [expanded, setExpanded] = useState('panel1');
  const [installments, setInstallments] = useState([]);

  const { profile } = useSelector(state => state.user);

  const loadInstallments = useCallback(async () => {
    if (objIsNull(products)) {
      api
        .get(
          `/inscricao/montar-combo-parcelamento/?q=(cod_produto:${products.cod_produto},total:${products.pro_valor_total},tipo_eve:3)`
        )
        .then(response => {
          const { records } = response.data;
          setInstallments(() => {
            return records.map(value => {
              return { value: value.id, label: value.desc };
            });
          });
        });
    }
  }, [products]);

  useEffect(() => {
    loadInstallments();
  }, [loadInstallments]);

  const handleChange = panel => isExpanded => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleMethod = value => {
    if (value === 3) {
      setCard(true);
      setMethod(value);
    } else {
      setCard(false);
      setMethod(value);
    }
  };

  function getFreightValue() {
    if (freight && objIsNull(freight)) {
      return freight.value;
    }
    return 0;
  }

  async function handleSubmit(data) {
    try {
      setLoad(true);
      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        qtdParc: Yup.string().required(
          'A quantidade de parcelas é de preenchimento obrigatório!'
        ),
        cartao_nro: Yup.string().required(
          'O número do cartão é de preenchimento obrigatório!'
        ),
        cartao_nome: Yup.string().required(
          'O nome do titular é de preenchimento obrigatório!'
        ),
        cartao_mes_exp: Yup.string().required(
          'O mês é de preenchimento obrigatório!'
        ),
        cartao_ano_exp: Yup.string().required(
          'O ano é de preenchimento obrigatório!'
        ),
        cartao_cod_seg: Yup.string().required('*'),
      });

      await schema.validate(data, {
        abortEarly: false,
        recursive: card,
      });

      /** const total = getFreightValue() + products.pro_valor_total; */

      const dataSend = {
        qtd: 1,
        qtdParc: 1,
        valor_a_pagar: products.pro_valor_total,
        valor_frete: getFreightValue(),
        qtd_item: products.pro_qtde,
        cod_produto: products.cod_produto,
        pro_url_afiliado: products.pro_url_afiliado,
        cod_membro: profile.cod_membro,
        ...data,
      };

      const response = await api.post(
        '/inscricao/inserir-pgto-produto',
        dataSend
      );
      if (response.data.records.error) {
        toast.error(response.data.records.msg);
        setLoad(false);
        return false;
      }

      setLoad(false);
      setTimeout(() => {
        if (dataSend.forma_pgto === '3') {
          localStorage.setItem(
            '@psicomart:product',
            JSON.stringify(response.data.records)
          );
          next();
        } else {
          setBillet(response.data.records.url_boleto);
          setBoleto(response.data.records.url_boleto);
          next();
        }
      }, 1000);
    } catch (err) {
      setLoad(false);
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
    return false;
  }

  async function handleBillet() {
    setLoad(true);

    /** const total = getFreightValue() + products.pro_valor_total; */
    const dataSend = {
      qtd: 1,
      valor_a_pagar: products.pro_valor_total,
      valor_frete: getFreightValue(),
      cod_membro: profile.cod_membro,
      qtd_item: products.pro_qtde,
      cod_produto: products.cod_produto,
      pro_url_afiliado: products.pro_url_afiliado,
    };

    const response = await api.post(
      '/inscricao/inserir-pgto-produto',
      dataSend
    );
    if (response.data.records.error) {
      toast.error(response.data.records.msg);
      setLoad(false);
      return false;
    }

    localStorage.setItem(
      '@psicomart:product',
      JSON.stringify(response.data.records)
    );
    next();
    setLoad(false);
    return false;
  }

  if (!objIsNull(products)) {
    return null;
  }

  return (
    <div className={classes.root}>
      {(products.eh_cobrado === 1 || products.eh_cobrado === '1') &&
      getFreightValue() + products.pro_valor_total > 0 ? (
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={{ forma_pgto: method }}
        >
          <ExpansionPanel
            expanded={expanded === 'panel1'}
            onChange={handleChange('panel1')}
            onClick={() => handleMethod(3)}
          >
            <ExpansionPanelSummary
              expandIcon={<MdExpandMore />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              style={{ padding: 0 }}
            >
              <Typography className={classes.heading} style={{ fontSize: 14 }}>
                <strong className="text-uppercase">Cartão de crédito</strong>
              </Typography>
              {!isMobile && (
                <Typography className={classes.secondaryHeading}>
                  <img
                    src={require('~/assets/img/visa.svg')}
                    alt="Visa"
                    width={35}
                    className="mr-1"
                  />
                  <img
                    src={require('~/assets/img/master.svg')}
                    alt="Master"
                    width={35}
                    className="mr-1"
                  />
                  <img
                    src={require('~/assets/img/elo.svg')}
                    alt="ELO"
                    width={35}
                    className="mr-1"
                  />
                  <img
                    src={require('~/assets/img/diners.svg')}
                    alt="Dinners"
                    width={35}
                    className="mr-1"
                  />
                  <img
                    src={require('~/assets/img/amex.svg')}
                    alt="AMEX"
                    width={35}
                    className="mr-1"
                  />
                </Typography>
              )}
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={`${!isMobile ? `` : `p-0`}`}>
              {card && (
                <Col xl={8}>
                  <div className="form-group">
                    <label htmlFor="qtdParc">Quantidade de Parcelas *</label>
                    <Select
                      name="qtdParc"
                      placeholder="Selecione..."
                      options={installments}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="cartao_nro">Número do cartão *</label>
                    <InputMask
                      id="cartao_nro"
                      name="cartao_nro"
                      mask="9999 9999 9999 9999"
                      placeholder="Entre com o número do cartão de crédito..."
                      style={{ fontSize: 13 }}
                    />
                  </div>
                  <Row>
                    <Col xl={5}>
                      <div className="form-group">
                        <label htmlFor="cartao_mes_exp">
                          Mês{' '}
                          <small className="text-muted">
                            <em>(de expiração)</em>
                          </small>{' '}
                          *
                        </label>
                        <Select
                          id="cartao_mes_exp"
                          name="cartao_mes_exp"
                          options={mount}
                          placeholder="Selecione..."
                        />
                      </div>
                    </Col>
                    <Col xl={5}>
                      <div className="form-group">
                        <label htmlFor="cartao_ano_exp">
                          Ano{' '}
                          <small className="text-muted">
                            <em>(de expiração)</em>
                          </small>{' '}
                          *
                        </label>
                        <Select
                          id="cartao_ano_exp"
                          name="cartao_ano_exp"
                          options={year}
                          placeholder="Selecione..."
                        />
                      </div>
                    </Col>
                    <Col xl={2}>
                      <div className="form-group">
                        <label htmlFor="cartao_cod_seg">
                          <abbr
                            title="Código de segurança"
                            className="initialism"
                          >
                            CVV *
                          </abbr>
                        </label>
                        <Input
                          id="cartao_cod_seg"
                          name="cartao_cod_seg"
                          type="text"
                          maxLength="4"
                          style={{ fontSize: 13, padding: 8 }}
                        />
                      </div>
                    </Col>
                  </Row>
                  <div className="form-group">
                    <label htmlFor="cartao_nome">
                      Nome do titular{' '}
                      <small className="text-muted">
                        <em>(como impresso no cartão)</em>
                      </small>
                    </label>
                    <Input
                      id="cartao_nome"
                      name="cartao_nome"
                      type="text"
                      maxLength="255"
                      placeholder="Entre com o nome impresso no cartão..."
                      style={{ fontSize: 13 }}
                    />
                  </div>
                </Col>
              )}
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel
            expanded={expanded === 'panel2'}
            onChange={handleChange('panel2')}
            onClick={() => handleMethod(8)}
          >
            <ExpansionPanelSummary
              expandIcon={<MdExpandMore />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
              style={{ padding: 0 }}
            >
              <Typography className={classes.heading} style={{ fontSize: 14 }}>
                <strong className="text-uppercase">Boleto Bancário</strong>
              </Typography>
              {!isMobile && (
                <Typography className={classes.secondaryHeading}>
                  <img
                    src={require('~/assets/img/bank-slip.svg')}
                    alt=""
                    width={35}
                  />
                </Typography>
              )}
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={`${!isMobile ? `` : `p-0`}`}>
              <div className="form-group ml-2">
                <InputRadio
                  name="forma_pgto"
                  options={[
                    {
                      id: method,
                      label: 'Emitir boleto',
                      text:
                        'O boleto será emitido em nome do sistema Psicomart',
                    },
                  ]}
                />
                {billet && (
                  <div>
                    <Button
                      onClick={() => {
                        window.open(billet);
                      }}
                      variant="primary"
                    >
                      Imprimir boleto
                    </Button>
                  </div>
                )}
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <div className="d-flex pt-4 float-right">
            {!isMobile ? (
              <Button
                size="lg"
                type="submit"
                variant="success"
                className="btn-block font-weight-bold btn-custom text-uppercase"
                style={{ width: '250px' }}
                disabled={!!load}
              >
                {load ? 'Aguarde...' : 'Efetuar Pagamento'}
              </Button>
            ) : (
              <BottomButton
                type="submit"
                disabled={!!load}
                text={load ? 'Aguarde...' : 'Efetuar Pagamento'}
              />
            )}
          </div>
        </Form>
      ) : (
        <div className="d-flex pt-4 float-right">
          {!isMobile ? (
            <Button
              size="lg"
              type="button"
              variant="success"
              className="btn-block font-weight-bold btn-custom text-uppercase"
              onClick={handleBillet}
              style={{ width: '250px' }}
              disabled={!!load}
            >
              {load ? 'Aguarde...' : 'Comprar'}
            </Button>
          ) : (
            <BottomButton
              type="submit"
              disabled={!!load}
              onClick={handleBillet}
              text={load ? 'Aguarde...' : 'Comprar'}
            />
          )}
        </div>
      )}
    </div>
  );
}

ControlledExpansionPanels.propTypes = {
  next: PropTypes.func.isRequired,
  freight: PropTypes.shape({
    value: PropTypes.number,
  }).isRequired,
  setBoleto: PropTypes.func.isRequired,
  products: PropTypes.shape({
    pro_qtde: PropTypes.number,
    pro_valor_total: PropTypes.number,
    cod_produto: PropTypes.string,
    eh_cobrado: PropTypes.string,
    pro_url_afiliado: PropTypes.string,
  }).isRequired,
};
