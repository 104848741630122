export function classesGetByCourseRequest(id) {
  return {
    type: '@classes/GET_CLASSES_BY_COURSE_REQUEST',
    payload: id,
  };
}

export function classesGetByCourseSuccess(data) {
  return {
    type: '@classes/GET_CLASSES_BY_COURSE_SUCCESS',
    payload: data,
  };
}

export function classesGetByCourseFailure() {
  return {
    type: '@classes/GET_CLASSES_BY_COURSE_FAILURE',
  };
}

export function classesGetRequest(id) {
  return {
    type: '@classes/GET_CLASSES_REQUEST',
    payload: id,
  };
}

export function classesGetSuccess(data) {
  return {
    type: '@classes/GET_CLASSES_SUCCESS',
    payload: data,
  };
}

export function classesGetFailure() {
  return {
    type: '@classes/GET_CLASSES_FAILURE',
  };
}

export function clearClassesRequest() {
  return {
    type: '@classes/CLEAR_CLASSES_REQUEST',
    payload: [],
  };
}
