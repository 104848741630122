import styled from 'styled-components';

export const Upload = styled.div`
  border-radius: 4px;
  width: 100%;
  padding: 30px;
  text-align: center;
  border: 1px dashed #ddd;
  color: #999;
  cursor: pointer;

  flex: 1;
  flex-direction: column;
`;
