import produce from 'immer';

const INITIAL_STATE = {
  token: null,
  modal: true,
  signed: false,
  loading: false,
  psicomart: false,
};

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@auth/SIGN_IN_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@auth/SIGN_IN_MODAL_REQUEST': {
        draft.loading = true;
        draft.modal = true;
        break;
      }
      case '@auth/SIGN_IN_SUCCESS': {
        draft.token = action.payload.token;
        draft.modal = false;
        draft.signed = true;
        draft.loading = false;
        draft.psicomart = action.payload.user.psicomart;
        break;
      }
      case '@auth/SIGN_FAILURE': {
        draft.loading = false;
        break;
      }
      case '@auth/SIGN_OUT': {
        draft.token = null;
        draft.signed = false;
        draft.psicomart = false;
        break;
      }
      case '@auth/RESET_MODAL': {
        draft.modal = true;
        break;
      }
      default:
    }
  });
}
