import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from 'react';

import { Link } from 'react-router-dom';
import { Row, Col, Card, Button } from 'react-bootstrap';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { toast } from 'react-toastify';
import api from '~/services/api';
import history from '~/services/history';
import Input from '~/components/InputText';
import UploadImg from '~/components/UploadImg';

import Material from '~/components/TypeProduct/material';
import Freight from '~/components/TypeProduct/freight';
import Send from '~/components/TypeProduct/send';
import Stock from '~/components/TypeProduct/stock';
import Affiliates from '~/components/Affiliates';
import DiscountCoupons from '~/components/DiscountCoupons';

import { replaceSpecialChars } from '~/util';
import { TextEditor } from './styles';

import Categories from '~/components/Categories';
import Loading from '~/components/Loading';

export default function EditProduct({ match }) {
  const formRef = useRef(null);

  const [load, setLoad] = useState(true);
  const [down, setDown] = useState(false);
  const [send, setSend] = useState(false);
  const [serv, setServ] = useState(false);
  const [type, setType] = useState('');
  const [status, setStatus] = useState(null);
  const [affiliate, setAffiliate] = useState(false);

  const { params } = match;

  const [product, setProduct] = useState({});
  const [htmlContent, setHtmlContent] = useState('');

  useMemo(() => {
    if (product) {
      setTimeout(() => {
        setHtmlContent(product.desc_produto);
      });
    }
  }, [product]);

  const loadProduct = useCallback(async () => {
    setLoad(true);
    api.get(`/produto/${params.id}`).then(response => {
      const { records } = response.data;

      // records.de_valor = records.de_valor
      //   ? records.de_valor.replace('.', ',')
      //   : null;
      records.por_valor = records.por_valor
        ? records.por_valor.replace('.', ',')
        : null;

      setProduct(response.data.records);
      setStatus(response.data.records.status_prod);
      switch (response.data.records.tipo_mkt_place) {
        case '1':
          setDown(true);
          setType(1);
          break;
        case '2':
          setSend(true);
          setType(2);
          break;
        case '3':
          setServ(true);
          setType(3);
          break;
        case '4':
          setType(4);
          break;
        default:
          break;
      }
      setLoad(false);
    });
  }, [params.id]);

  useEffect(() => {
    loadProduct();
  }, [loadProduct]);

  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current.setErrors({});
        const schema = Yup.object().shape({
          nome_produto: Yup.string().required('Nome do produto é obrigatório'),
        });

        if (!data.nome_produto) {
          formRef.current.getFieldRef('nome_produto').focus();
        }

        await schema.validate(data, {
          abortEarly: false,
        });

        data.url_amig = replaceSpecialChars(data.nome_produto);
        data.desc_produto = htmlContent;

        const response = await api.put(`/produto/${data.cod_produto}`, data);
        if (response.data.records.error) {
          toast.error(response.data.records.msg);
          return;
        }

        toast.success('Produto atualizado com sucesso');
        loadProduct();
      } catch (err) {
        const validationErrors = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            validationErrors[error.path] = error.message;
          });
          formRef.current.setErrors(validationErrors);
        }
      }
    },
    [htmlContent, loadProduct]
  );

  const statusProdutc = useCallback(async (data, action) => {
    if (!data.nome_produto) {
      toast.error('Produto não pode ser publicado sem um nome!');
      return;
    }

    await api.put(`/produto/${action}/${data.cod_produto}`).then(response => {
      if (response.data.records.error) {
        toast.error(response.data.records.msg);
        return;
      }
      if (action === 'ativar') {
        setStatus('1');
      } else {
        setStatus('0');
      }
    });
  }, []);

  return (
    <div className="content padding-tab-top-post">
      <Row>
        <Col>
          <div className="d-flex justify-content-between align-items-center">
            <div className="mb-3">
              <h3 className="mb-0 font-weight-semibold">
                <i
                  role="presentation"
                  style={{ fontSize: '1.5rem' }}
                  onClick={() => history.goBack()}
                  className="icon-arrow-left52 mr-2 c-pointer"
                ></i>
                Editar produto
              </h3>
              <span className="text-muted d-block">
                Editar informações do produto
              </span>
            </div>
            <div>
              {!load && (
                <>
                  {status === '1' && (
                    <Button
                      onClick={() => statusProdutc(product, 'desativar')}
                      variant="danger"
                      type="button"
                    >
                      <i className="icon-undo2 mr-2"></i> Despublicar
                    </Button>
                  )}
                  {status !== '1' && (
                    <Button
                      onClick={() => statusProdutc(product, 'ativar')}
                      variant="success"
                      type="button"
                    >
                      <i className="icon-redo2 mr-2"></i> Publicar
                    </Button>
                  )}

                  <Link
                    to="route"
                    target="_blank"
                    onClick={e => {
                      e.preventDefault();
                      window.open(`/detail/produto/${product.url_amig}`);
                    }}
                    className="ml-1 btn btn-primary"
                    variant="primary"
                  >
                    <i className="icon-earth mr-1"></i>
                    Link
                  </Link>
                </>
              )}
            </div>
          </div>
        </Col>
        <Col xl={12}>
          {load ? (
            <Loading />
          ) : (
            <>
              <ul className="nav nav-tabs mb-0">
                <li className="nav-item">
                  <a
                    id="first"
                    href="#product"
                    className="nav-link rounded-top active"
                    data-toggle="tab"
                  >
                    Produto
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    id="second"
                    href="#prince"
                    className="nav-link rounded-top"
                    data-toggle="tab"
                  >
                    Preço
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="#coupon"
                    className="nav-link rounded-top"
                    data-toggle="tab"
                  >
                    Cupons de desconto
                  </a>
                </li>
                {down && (
                  <li className="nav-item">
                    <a
                      href="#archive"
                      className="nav-link rounded-top"
                      data-toggle="tab"
                    >
                      Arquivos
                    </a>
                  </li>
                )}
                {send && (
                  <>
                    <li className="nav-item">
                      <a
                        href="#freight"
                        className="nav-link rounded-top"
                        data-toggle="tab"
                      >
                        Frete
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="#stock"
                        className="nav-link rounded-top"
                        data-toggle="tab"
                      >
                        Estoque
                      </a>
                    </li>
                  </>
                )}
                <li className="nav-item">
                  <a
                    href="#affiliate"
                    className="nav-link rounded-top"
                    data-toggle="tab"
                    onClick={() => setAffiliate(true)}
                  >
                    Afiliados
                  </a>
                </li>
              </ul>
              <div className="tab-content card card-body border-top-0 rounded-top-0 mb-0">
                <div className="tab-pane fade show active" id="product">
                  <Form
                    ref={formRef}
                    onSubmit={handleSubmit}
                    initialData={{
                      nome_produto: product.nome_produto,
                      cod_produto: product.cod_produto,
                      img_produto: product.img_produto,
                      desc_produto: product.desc_produto,
                      nome_empresa: product.nome_empresa,
                      cod_categoria: product.categoria_produto,
                      subcategoria: product.subcategoria_produto,
                      tipo_mkt_place: product.tipo_mkt_place,
                      prod_peso_bruto: product.prod_peso_bruto,
                      prod_peso_liquido: product.prod_peso_liquido,
                      prod_comprimento: product.prod_comprimento,
                      prod_largura: product.prod_largura,
                      prod_altura: product.prod_altura,
                      prod_tipo_frete: product.prod_tipo_frete,
                    }}
                  >
                    <Row>
                      <Col xl={12}>
                        <p className="text-center">
                          O produto que gostaria de cadastrar é um:
                        </p>
                        <Row className="justify-content-md-center">
                          <Col xl={2} className="c-pointer">
                            <Card
                              onClick={() =>
                                setDown(() => {
                                  setSend(false);
                                  setServ(false);
                                  setDown(true);
                                  setType(1);
                                })
                              }
                              className={`border-light shadow-sm type-product ${down &&
                                'select-product'}`}
                            >
                              <Card.Body
                                style={{ minHeight: 122 }}
                                className="d-flex flex-column justify-content-center align-items-center"
                              >
                                <i className="icon-cloud-download2 icon-2x"></i>
                                <p className="text-center">
                                  Material digital para download
                                </p>
                              </Card.Body>
                            </Card>
                          </Col>
                          <Col xl={2} className="c-pointer">
                            <Card
                              onClick={() =>
                                setDown(() => {
                                  setDown(false);
                                  setServ(false);
                                  setSend(true);
                                  setType(2);
                                })
                              }
                              className={`border-light shadow-sm type-product ${send &&
                                'select-product'}`}
                            >
                              <Card.Body
                                style={{ minHeight: 122 }}
                                className="d-flex flex-column justify-content-center align-items-center"
                              >
                                <i className="icon-cart icon-2x"></i>
                                <p className="text-center">
                                  Produto para envio
                                </p>
                              </Card.Body>
                            </Card>
                          </Col>
                          <Col xl={2} className="c-pointer">
                            <Card
                              onClick={() =>
                                setDown(() => {
                                  setDown(false);
                                  setSend(false);
                                  setServ(true);
                                  setType(3);
                                })
                              }
                              className={`border-light shadow-sm type-product ${serv &&
                                'select-product'}`}
                            >
                              <Card.Body
                                style={{ minHeight: 122 }}
                                className="d-flex flex-column justify-content-center align-items-center"
                              >
                                <i className="icon-rotate-cw2 icon-2x"></i>
                                <p className="text-center">
                                  Serviço de assinatura
                                </p>
                              </Card.Body>
                            </Card>
                          </Col>
                          {/* <Col xl={2} className="c-pointer">
                            <Card
                              onClick={() =>
                                setDown(() => {
                                  setDown(false);
                                  setSend(false);
                                  setServ(false);
                                  setType(4);
                                })
                              }
                              className={`border-light shadow-sm type-product ${othe &&
                                'select-product'}`}
                            >
                              <Card.Body
                                style={{ minHeight: 122 }}
                                className="d-flex flex-column justify-content-center align-items-center"
                              >
                                <i className="icon-box icon-2x"></i>
                                <p className="text-center">Outros</p>
                              </Card.Body>
                            </Card>
                          </Col> */}
                        </Row>
                        {serv && (
                          <Row>
                            <Col>
                              <div className="alert alert-danger border-0">
                                <button
                                  type="button"
                                  className="close"
                                  data-dismiss="alert"
                                >
                                  <span>×</span>
                                </button>
                                Caro usuário, o serviço por assinatura estará
                                ativo em breve.
                              </div>
                            </Col>
                          </Row>
                        )}
                        <Row>
                          <Col xl={12}>
                            <Categories
                              type={type}
                              category={product.categoria_produto}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={7}>
                        <div className="form-group">
                          <label htmlFor="">Nome do produto</label>
                          <Input type="hidden" name="cod_produto" />
                          <Input
                            type="hidden"
                            name="tipo_mkt_place"
                            defaultValue={type}
                          />
                          <Input
                            name="nome_produto"
                            placeholder="Digite o nome do produto"
                          />
                        </div>
                        <div className="form-group">
                          <label>Descrição</label>
                          <TextEditor>
                            <ReactQuill
                              theme="snow"
                              value={htmlContent}
                              onChange={setHtmlContent}
                              modules={EditProduct.modules}
                              formats={EditProduct.formats}
                            />
                          </TextEditor>
                        </div>
                      </Col>
                      <Col>
                        <div className="form-group">
                          <label>Imagens do produto</label>
                          <div className="d-flex justify-content-center align-items-center h-100">
                            <UploadImg
                              url="/produto/enviar-img-produto"
                              id={product.cod_produto}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row className="justify-content-md-center">
                      <Col xl={2}>
                        <Button size="lg" block type="submit" variant="primary">
                          Salvar
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </div>

                <div className="tab-pane fade" id="prince">
                  <Send data={product} />
                </div>

                <div className="tab-pane fade" id="coupon">
                  <DiscountCoupons data={product} />
                </div>

                <div className="tab-pane fade" id="archive">
                  <Form>
                    <Material />
                  </Form>
                </div>

                <div className="tab-pane fade" id="stock">
                  <Stock data={product} />
                </div>

                <div className="tab-pane fade" id="freight">
                  <Freight
                    action="edit"
                    form={formRef}
                    product={product}
                    setProduct={setProduct}
                  />
                </div>

                <div className="tab-pane fade" id="affiliate">
                  {affiliate && <Affiliates data={product} />}
                </div>
              </div>
            </>
          )}
        </Col>
      </Row>
    </div>
  );
}

EditProduct.modules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }, { font: [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link', 'image', 'video'],
    ['clean'],
  ],
  clipboard: {
    matchVisual: false,
  },
};

EditProduct.formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
];

EditProduct.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};
