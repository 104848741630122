const Config = {
  URL_PRODUCTION: 'https://api.psicomart.com.br/v1/1090/rs',
  URL_DEVELOPMENT: 'https://api.psicomart.com.br/v1/1090/rs',
  URL_APP_PRODUCTION: 'https://app.psicomart.com.br',
  URL_APP_DEVELOPMENT: 'http://localhost:3001',
  URL_APP_DOWNLOAD: 'https://apirs.virtux.com.br/v1/1090/rs/export/get-arq/',
  KEY_GOOGLE_MAPS: 'AIzaSyCLZg9PU2mW7VeyZhcaOjfX7vGGRaH9yXg',
  ENCRYPT: {
    algorithm: 'aes256',
    secret: 'f3bc51923f71174a2178aa867fc27fdc',
    type: 'hex',
  },
};

/** http://localhost:8081/apirs/v1/1090/rs */

export default Config;
