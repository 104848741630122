/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Row, Col, Card, Button, Table, Badge } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Form } from '@unform/web';

import { formatCurrencyValue } from '~/util';

import api from '~/services/api';
import history from '~/services/history';
import Select from '~/components/InputSelect';
import Loading from '~/components/Loading';
import ModalWithdraw from '~/components/ModalWithdraw';
import ModalHistorical from '~/components/ModalHistoricalLooting';
import ModalCreateAccount from '~/components/ModalCreateAccount';
import BoxConfirm from '~/components/BoxConfirm';

const banks = items => {
  const data = items.map(value => {
    return {
      value: value.iugu_accountid,
      label: `${value.bank} - ${value.bank_cc}`,
    };
  });
  return data;
};

export default function CurrentAccount() {
  const [show, setShow] = useState(false);
  const [showWithdraw, setShowWithdraw] = useState(false);
  const [showHistorical, setShowHistorical] = useState(false);
  const [openBoxConfirm, setOpenBoxConfirm] = useState(false);
  const [data, setData] = useState([]);
  const [load, setLoad] = useState(true);
  const [disp, setDisp] = useState(0);
  const [bloq, setBloq] = useState(0);
  const [total, setTotal] = useState(0);
  const [accountId, setAccountId] = useState(null);
  const [tokenToBeDeleted, setTokenToBeDeleted] = useState('0');
  const [newMainAccountToken, setNewMainAccountToken] = useState('0');
  const [openSetMainAccountBox, setOpenSetMainAccountBox] = useState(false);

  const loadAccount = useCallback(async () => {
    const response = await api.get('/config/get-dados-conta2');

    if (response.data.records.length === 0) {
      setData([]);
      setLoad(false);
      return;
    }
    setData(response.data.records);
    setLoad(false);
  }, []);

  const handleSetMainAccount = useCallback(token => {
    api
      .post('/config/salvar-editar-conta', {
        cod_token_pgto: token,
      })
      .then(response =>
        response.data.records.error
          ? toast.error(response.data.records.msg)
          : toast.success(response.data.records.msg)
      );
  }, []);

  useEffect(() => {
    loadAccount();
  }, [loadAccount, show, showWithdraw, openBoxConfirm, openSetMainAccountBox]);

  const handleShow = () => setShow(true);
  const handleExternalClose = () => {
    setShow(false);
  };
  const handleCloseWithdraw = () => setShowWithdraw(false);
  const handleCloseHistorical = () => setShowHistorical(false);

  const handleRemoveAccount = async ac => {
    await api
      .post(`/config/excluir-conta`, {
        cod_token_pgto: ac,
      })
      .then(response => {
        // eslint-disable-next-line no-unused-expressions
        response.data.records.error
          ? toast.error(response.data.records.msg)
          : toast.success(response.data.records.msg);
      });
  };

  const handleBank = useCallback(value => {
    api.get(`/config/get-dados-saldo/${value.value}`).then(response => {
      if (response.data.records.error) {
        toast.error(response.data.records.msg);
        return;
      }
      setDisp(response.data.records.disp_saq);
      setBloq(response.data.records.disp_bloq);
      setTotal(response.data.records.total);
      setAccountId(value.value);
    });
  }, []);

  useMemo(() => {
    if (data.length) {
      handleBank({
        value: data[data.length - 1].iugu_accountid,
      });
    }
  }, [handleBank, data]);

  return (
    <div className="content padding-tab-top-post">
      <Row>
        <Col>
          <div className="d-flex justify-content-between align-items-center">
            <div className="mb-3">
              <h3 className="mb-0 font-weight-semibold">
                <i
                  role="presentation"
                  style={{ fontSize: '1.5rem' }}
                  onClick={() => history.goBack()}
                  className="icon-arrow-left52 mr-2 c-pointer"
                ></i>
                Conta corrente
              </h3>
              <span className="text-muted d-block">
                Configurações da sua conta de recebimento
              </span>
            </div>
          </div>
        </Col>
        <Col xl={12}>
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <Card.Title>Conta cadastrada</Card.Title>
              <Button variant="info" onClick={handleShow}>
                <i className="icon-plus3 mr-1"></i>Cadastrar Conta
              </Button>
            </Card.Header>
            <Card.Body>
              {load ? (
                <Loading />
              ) : (
                <Table striped hover>
                  <thead>
                    <tr>
                      <th>Banco</th>
                      <th>Agência</th>
                      <th>Conta</th>
                      <th>Nome</th>
                      <th>Situação</th>
                      <th>Conta principal</th>
                      <th className="text-center">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(data).length === 0 ? (
                      <tr>
                        <td colSpan="7" className="p-0 text-center">
                          <div className="alert alert-info border-0 alert-dismissible mb-0">
                            <i className="icon-info3 border-2 rounded-round p-1 mr-2"></i>
                            <span>
                              Você ainda não cadastrou uma conta corrente para
                              receber seus valores!
                            </span>
                          </div>
                        </td>
                      </tr>
                    ) : (
                      data.map(value => (
                        <tr key={value.iugu_accountid}>
                          <td>{value.bank}</td>
                          <td>{value.bank_ag}</td>
                          <td>{value.bank_cc}</td>
                          <td>{value.name}</td>
                          <td>
                            <Badge
                              variant={value.is_verified ? 'success' : 'danger'}
                            >
                              {value.is_verified ? 'Ativo' : 'Pendente'}
                            </Badge>
                          </td>
                          <td>
                            <Badge
                              variant={
                                value.cod_token_pgto ===
                                value.cod_token_pgto_padrao
                                  ? 'success'
                                  : 'danger'
                              }
                            >
                              {value.cod_token_pgto ===
                              value.cod_token_pgto_padrao
                                ? 'Sim'
                                : 'Não'}
                            </Badge>
                          </td>
                          <td>
                            <div className="d-flex justify-content-center align-items-center">
                              <button
                                type="button"
                                className="btn btn-light"
                                title="Editar"
                                onClick={() => {
                                  setNewMainAccountToken(value.cod_token_pgto);
                                  setOpenSetMainAccountBox(true);
                                }}
                              >
                                <i className="icon-pencil5 text-primary"></i>
                              </button>
                              {value.pode_excluir && (
                                <button
                                  type="button"
                                  className="btn btn-light ml-2"
                                  title="Apagar"
                                  onClick={() => {
                                    setTokenToBeDeleted(value.cod_token_pgto);
                                    setOpenBoxConfirm(true);
                                  }}
                                >
                                  <i className="icon-trash text-primary"></i>
                                </button>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </Table>
              )}
            </Card.Body>
          </Card>
        </Col>
        {data.length > 0 && (
          <Col>
            <Card>
              <Card.Header className="d-flex justify-content-between">
                <Card.Title>Informações de saldo</Card.Title>
                <Button variant="info" onClick={() => setShowHistorical(true)}>
                  <i className="icon-history mr-1"></i> Histórico de saque
                </Button>
              </Card.Header>
              <Card.Body>
                <Card.Title>
                  <Row>
                    <Col md="4">
                      <Form
                        initialData={{
                          bank: data[data.length - 1].iugu_accountid,
                        }}
                      >
                        <Select
                          name="bank"
                          placeholder="Selecione a conta"
                          options={banks(data)}
                          onChange={handleBank}
                        />
                        <small>Selecione uma conta para visualizar</small>
                      </Form>
                    </Col>
                  </Row>
                </Card.Title>
                <Table striped hover className="mt-3">
                  <tbody>
                    <tr>
                      <td>
                        <strong>Saldo total:</strong>
                      </td>
                      <td>
                        <strong>{formatCurrencyValue(total)}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Saldo bloqueado:</strong>
                      </td>
                      <td>
                        <strong>{formatCurrencyValue(bloq)}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Saldo disponivel para saque:</strong>
                      </td>
                      <td>
                        <strong>{formatCurrencyValue(disp)}</strong>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
              <Card.Footer>
                <div className="d-flex justify-content-center">
                  <Button
                    type="button"
                    variant="success"
                    onClick={() => setShowWithdraw(true)}
                  >
                    <i className="icon-drawer-out mr-1"></i> Realizar saque
                  </Button>
                </div>
              </Card.Footer>
            </Card>
          </Col>
        )}
      </Row>

      <ModalCreateAccount
        showModal={show}
        handleExternalClose={handleExternalClose}
      />
      <ModalWithdraw
        modalShow={showWithdraw}
        modalClose={handleCloseWithdraw}
        data={accountId}
      />
      <ModalHistorical
        modalShow={showHistorical}
        modalClose={handleCloseHistorical}
        data={accountId}
      />
      <BoxConfirm
        show={openBoxConfirm}
        onClose={() => setOpenBoxConfirm(false)}
        size="sm"
        title="Aviso"
        message="Tem certeza que deseja excluir esta conta-corrente?"
        aliasBtnYes="Excluir"
        onNoClick={() => setOpenBoxConfirm(false)}
        onYesClick={async () => {
          await handleRemoveAccount(tokenToBeDeleted);
          setOpenBoxConfirm(false);
        }}
      />
      <BoxConfirm
        show={openSetMainAccountBox}
        onClose={() => setOpenSetMainAccountBox(false)}
        size="sm"
        title="Aviso"
        message="Deseja tornar esta conta sua conta principal para recebimentos?"
        aliasBtnYes="Sim"
        onNoClick={() => setOpenSetMainAccountBox(false)}
        onYesClick={async () => {
          await handleSetMainAccount(newMainAccountToken);
          setOpenSetMainAccountBox(false);
        }}
      />
    </div>
  );
}
