import React from 'react';

export default function Notfound() {
  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center mb-3"
      style={{ height: 'calc(100vh - 150px)' }}
    >
      <h1 className="error-title">404</h1>
      <h5>Opa! Ocorreu um erro. Página não encontrada!</h5>
    </div>
  );
}
