/* eslint-disable react/prop-types */

import React from 'react';

import { Link } from 'react-router-dom';

function Breadcrumbs({ crumbs }) {
  if (crumbs.length <= 1) {
    return null;
  }

  return (
    <div
      style={{ margin: '10px 10px 0 8px', padding: '8px 12px' }}
      className="breadcrumb-line breadcrumb-line-light bg-white breadcrumb-line-component"
    >
      {crumbs.map(({ name, path }, key) => (
        <span key={Math.random()}>
          {key + 1 === crumbs.length ? (
            <span className="bold">
              <strong>{name}</strong>
            </span>
          ) : (
            <span>
              <Link to={path}>{name}</Link>
              <span style={{ marginLeft: 10, marginRight: 10 }}>›</span>
            </span>
          )}
        </span>
      ))}
    </div>
  );
}

export default Breadcrumbs;
