import produce from 'immer';

const INITIAL_STATE = {
  id: 0,
  query: '',
  freight: null,
  freights: [],
  freightValue: [],
  loading: true,
};

export default function freight(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@freights/GET_FREIGHT_REQUEST': {
        draft.id = action.payload;
        draft.loading = true;
        break;
      }
      case '@freights/GET_FREIGHT_SUCCESS': {
        draft.loading = false;
        draft.freight = action.payload;
        break;
      }
      case '@freights/GET_FREIGHTS_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@freights/GET_FREIGHTS_SUCCESS': {
        draft.loading = false;
        draft.freights = action.payload;
        break;
      }
      case '@freights/GET_FREIGHT_VALUE_REQUEST': {
        draft.query = action.payload;
        draft.loading = true;
        break;
      }
      case '@freights/GET_FREIGHT_VALUE_SUCCESS': {
        draft.loading = false;
        draft.freightValue = action.payload;
        break;
      }
      case '@freights/GET_FREIGHT_FAILURE':
      case '@freights/GET_FREIGHTS_FAILURE':
      case '@freights/GET_FREIGHT_VALUE_FAILURE': {
        draft.loading = false;
        break;
      }
      case '@freights/CLEAR_FREIGHT_REQUEST': {
        draft.id = 0;
        draft.loading = false;
        draft.freight = null;
        break;
      }
      default: {
        break;
      }
    }
  });
}
