import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import api from '~/services/api';

export default function ButtonUpVote({ post, vote }) {
  const upVote = useCallback(async () => {
    try {
      const response = await api.post('/curt', {
        acao: 1,
        cod_post: post.cod_post,
        tipo_curt: 2,
      });
      if (!response.data.records.error) {
        vote();
      }
    } catch (error) {
      toast.error('Falha na comunicação com servidor');
    }
  }, [vote, post]);

  return (
    <span className="votes p-1 c-pointer" onClick={() => upVote()}>
      <i className="icon-arrow-up22 text-success"></i>
    </span>
  );
}

ButtonUpVote.propTypes = {
  post: PropTypes.object,
  vote: PropTypes.func,
};

ButtonUpVote.defaultProps = {
  post: {},
};
