import React, { useState, useCallback } from 'react';
import { toast } from 'react-toastify';

import api from '~/services/api';
import BoxConfirm from '~/components/BoxConfirm';

export default function DeleteProduct({ id, load }) {
  const [showBox, setShowBox] = useState(false);

  const onClose = useCallback(() => {
    setShowBox(false);
  }, []);

  const deleteProduct = useCallback(async () => {
    await api.put(`/produto/cancelar/${id}`).then(response => {
      if (response.data.records.error) {
        toast.error(response.data.records.msg);
        return;
      }
      toast.success('Produto apagado com sucesso');
    });
    load();
  }, [load, id]);

  return (
    <>
      <button
        className="btn btn-link dropdown-item"
        onClick={() => setShowBox(true)}
      >
        <i className="icon-bin"></i> Apagar Produto
      </button>
      <BoxConfirm
        title="Apagar"
        show={showBox}
        size="sm"
        onYesClick={deleteProduct}
        onNoClick={onClose}
        onClose={onClose}
        message={`Tem certeza de que deseja apagar esse Produto?`}
      />
    </>
  );
}
