import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import { interestSuccess, interestFailure } from './actions';

export function* interestRequest() {
  try {
    const response = yield call(api.get, `/interesse/?q=(list:1)`);
    if (response.data.records.error) {
      toast.error(response.data.records.msg);
      yield put(interestFailure());
      return;
    }

    yield put(interestSuccess(response.data.records));
  } catch (err) {
    toast.error('Falha ao buscar interesses, contate o administrador!');
    yield put(interestFailure());
  }
}

export default all([takeLatest('@interest/INTEREST_REQUEST', interestRequest)]);
