import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import { Card, Row, Col, Table } from 'react-bootstrap';

import { objIsNull, formatCurrencyValue } from '~/util';

import history from '~/services/history';

import Loading from '~/components/Loading';
import Select from '~/components/InputSelect';

import * as AffiliationsActions from '~/store/modules/affiliations/actions';

function MyAffiliations() {
  const dispatch = useDispatch();

  const loading = useSelector(state => state.affiliations.loading);
  const affiliation = useSelector(state => state.affiliations.affiliation);
  const affiliations = useSelector(state => state.affiliations.affiliations);

  const [affiliationSelected, setAffiliationSelected] = useState(0);
  const loadAffiliations = useCallback(() => {
    dispatch(AffiliationsActions.affiliationsGetRequest());
  }, [dispatch]);

  useEffect(() => {
    loadAffiliations();
  }, [loadAffiliations]);

  useMemo(() => {
    if (objIsNull(affiliations)) {
      const last = affiliations[affiliations.length - 1];
      setAffiliationSelected(last.cod_afiliacao);
    }
  }, [affiliations, setAffiliationSelected]);

  function getOptions() {
    const options = affiliations.map(item => {
      return {
        value: item.cod_afiliacao,
        label: item.desc,
      };
    });
    return options;
  }

  function handleChange(data) {
    if (data) {
      setAffiliationSelected(data.value);
      dispatch(AffiliationsActions.affiliationGetByIdRequest(data.value));
      console.log('Afiliação escolhida: ', data);
    } else {
      setAffiliationSelected(0);
      toast.info('Caro usuário, por gentileza selecione uma afiliação!');
    }
  }

  function getInitialData() {
    if (objIsNull(affiliations)) {
      const last = affiliations[affiliations.length - 1];
      return {
        cod_afiliacao: last.cod_afiliacao,
      };
    }
    return {};
  }

  return (
    <div className="content padding-tab-top-post">
      <Row>
        <Col>
          <div className="d-flex justify-content-between align-items-center">
            <div className="mb-3">
              <h3 className="mb-0 font-weight-semibold">
                <i
                  role="presentation"
                  style={{ fontSize: '1.5rem' }}
                  onClick={() => history.goBack()}
                  className="icon-arrow-left52 mr-2 c-pointer"
                ></i>
                Minhas Afiliações
              </h3>
              <span className="text-muted d-block">
                Aqui você pode gerenciar suas afiliações
              </span>
            </div>
          </div>
        </Col>
        <Col xl={12}>
          <Form initialData={getInitialData()}>
            {loading ? (
              <Loading />
            ) : (
              <Card>
                <Card.Body>
                  <Col xl={7}>
                    <label htmlFor="cod_afiliacao">
                      <strong>Afiliações</strong>
                    </label>
                    <Select
                      id="cod_afiliacao"
                      name="cod_afiliacao"
                      isClearable
                      placeholder="-- Por favor, selecione uma afiliação --"
                      onChange={handleChange}
                      options={getOptions()}
                    />
                  </Col>
                </Card.Body>
                {!!objIsNull(affiliation) && affiliationSelected ? (
                  <Card.Body style={{ marginTop: 10 }}>
                    <ul>
                      <li>
                        <strong>Comissão:</strong>{' '}
                        {formatCurrencyValue(affiliation.valor_comissao)}
                      </li>
                      <li>
                        <strong>Produto:</strong>{' '}
                        <span>{affiliation.nome_produto}</span>
                      </li>
                    </ul>
                    <Table striped hover className="mt-2">
                      <thead>
                        <tr>
                          <th>Cliente</th>
                          <th className="text-right">Valor Pago</th>
                          <th className="text-right">Comissão</th>
                          <th className="text-right">Data Ptgo.</th>
                          <th className="text-right">Data Liberação</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.keys(affiliation.vendas).length === 0 ? (
                          <tr className="text-center">
                            <td colSpan="5">
                              <strong>Nenhum registro encontrado!</strong>
                            </td>
                          </tr>
                        ) : (
                          affiliation.vendas.map(item => (
                            <tr key={item.cod_afiliacao}>
                              <td>{item.nome_membro_pagador}</td>
                              <td className="text-right">
                                {formatCurrencyValue(item.valor_pago)}
                              </td>
                              <td className="text-right">
                                {formatCurrencyValue(item.valor_transf_af)}
                              </td>
                              <td className="text-right">10/10/2020</td>
                              <td className="text-right">10/10/2020</td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </Table>
                  </Card.Body>
                ) : null}
              </Card>
            )}
          </Form>
        </Col>
      </Row>
    </div>
  );
}

export default MyAffiliations;
