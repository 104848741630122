import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';

export default function ModalProfile({ modalShow, modalClose, data }) {
  return (
    <Modal
      size="sm"
      show={modalShow}
      onHide={() => modalClose(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <div className="media align-items-center align-items-md-start flex-column flex-md-row">
          <span className="text-teal mr-md-3 mb-3 mb-md-0">
            <i className="icon-user text-success-400 border-success-400 border-2 rounded-round p-2 mt-1"></i>
          </span>

          <div className="media-body text-center text-md-left">
            <h3 className="media-title font-weight-semibold">
              {data.nome_membro}
            </h3>
            <span className="text-muted">{data.email_membro}</span>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <ul className="list-group list-group-flush border-top">
          <li className="list-group-item">
            <span className="font-weight-semibold text-muted">Nome:</span>
            <div className="ml-auto">{data.nome_membro}</div>
          </li>
          <li className="list-group-item">
            <span className="font-weight-semibold text-muted">Telefone:</span>
            <div className="ml-auto">{data.telefone}</div>
          </li>
          <li className="list-group-item">
            <span className="font-weight-semibold text-muted">E-mail:</span>
            <div className="ml-auto">
              <a href="#/">{data.email_membro}</a>
            </div>
          </li>
        </ul>
      </Modal.Body>
      <Modal.Footer>
        <Button size="lg" variant="danger" onClick={() => modalClose(false)}>
          <i className="icon-cross2 mr-1 font-weight-light"></i>
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
ModalProfile.propTypes = {
  modalShow: PropTypes.bool.isRequired,
  modalClose: PropTypes.func.isRequired,
  data: PropTypes.objectOf(PropTypes.any),
};

ModalProfile.defaultProps = {
  data: {},
};
