import axios from 'axios';

import Config from '~/config';

const api = axios.create({
  baseURL: Config.URL_PRODUCTION,
  // process.env.NODE_ENV === 'development'
  //   ? Config.URL_DEVELOPMENT
  //   : Config.URL_DEVELOPMENT,
});

export default api;
