import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';

import noImage from '~/assets/img/sem.png';

import Loading from '../Loading';

export default function RecentEvents() {
  const { events } = useSelector(state => state.event);
  const loading = useSelector(state => state.event.loading);

  return (
    <div className="card">
      <div className="card-header bg-transparent header-elements-inline">
        <span className="card-title font-weight-semibold">
          Cursos e Eventos em Destaque
        </span>
        <div className="header-elements">
          <div className="list-icons"></div>
        </div>
      </div>

      <div className="card-body">
        {loading ? (
          <Loading />
        ) : (
          <ul className="media-list">
            {events.map(event => (
              <li className="media" key={event.cod_evento}>
                <Link to={`/evento/${event.cod_evento}`} className="mr-3">
                  {event.img_eve ? (
                    <img
                      src={event.img_eve}
                      width="50"
                      height="46"
                      className=""
                      alt=""
                    />
                  ) : (
                    <img
                      src={noImage}
                      width="50"
                      height="46"
                      className=""
                      alt=""
                    />
                  )}
                </Link>

                <div className="media-body" title={event.titulo_eve}>
                  <div className="font-weight-semibold">
                    {event.titulo_eve
                      ? event.titulo_eve.substring(0, 25)
                      : 'TITULO NÃO DEFINIDO'}
                  </div>
                  <span className="font-size-sm text-muted">
                    {event.local_eve ? event.local_eve : 'Local a definir'}
                  </span>
                </div>

                <div className="margin-2">
                  <h5 className="text-center text-success mb-0">
                    {format(new Date(event.dt_eve), 'dd', {
                      locale: pt,
                    })}
                  </h5>
                  <div className="font-size-sm text-muted line-height-1 text-uppercase">
                    {format(new Date(event.dt_eve), 'MMM', {
                      locale: pt,
                    })}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}
