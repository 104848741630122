import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import { Button, Row, Col, Card, Table } from 'react-bootstrap';

import api from '~/services/api';
import history from '~/services/history';

import Loading from '~/components/Loading';
import Select from '~/components/InputSelect';
import BoxConfirm from '~/components/BoxConfirm';
import CreateModule from '~/components/CreateModule';

import * as CoursesActions from '~/store/modules/courses/actions';
import * as ModulesActions from '~/store/modules/modules/actions';
import * as ClassesActions from '~/store/modules/classes/actions';

import { buildQueryString, objIsNull } from '~/util';

function Modules() {
  const dispatch = useDispatch();
  const courses = useSelector(state => state.course.courses);
  const modules = useSelector(state => state.modules.modules);

  const loadingCourse = useSelector(state => state.course.loading);

  const [showBox, setShowBox] = useState(false);
  const [handleCourse, setHandleCourse] = useState(0);
  const [showModalModule, setShowModalModule] = useState(false);
  const [handleCourseName, setHandleCourseName] = useState('');
  const [selectedModule, setSelectedModule] = useState({});

  const handleShowModalModule = () => setShowModalModule(true);
  function handleCloseModalModule() {
    setShowModalModule(false);
    dispatch(ModulesActions.moduleClearRequest());
  }

  const loadCourses = useCallback(() => {
    dispatch(ModulesActions.moduleClearRequest());
    dispatch(CoursesActions.coursesListRequest());
  }, [dispatch]);

  const loadSettings = useCallback(
    lastCourse => {
      setHandleCourse(lastCourse.cod_evento);
      dispatch(ModulesActions.modulesGetByCourseRequest(lastCourse.cod_evento));
      dispatch(ClassesActions.classesGetByCourseRequest(lastCourse.cod_evento));
    },
    [setHandleCourse, dispatch]
  );

  useEffect(() => {
    loadCourses();
  }, [loadCourses]);

  useMemo(() => {
    if (objIsNull(courses)) {
      const lastCourse = courses[courses.length - 1];
      loadSettings(lastCourse);
    }
  }, [courses, loadSettings]);

  function onClose() {
    setShowBox(false);
  }

  function getOptionsCourses() {
    const options = courses.map(item => {
      return {
        value: item.cod_evento,
        label: item.titulo_eve,
      };
    });
    return options;
  }

  function handleChangeCurso(data) {
    if (data) {
      setHandleCourse(data.value);
      setHandleCourseName(data.label);
      dispatch(ModulesActions.modulesGetByCourseRequest(data.value));
      dispatch(ClassesActions.classesGetByCourseRequest(data.value));
    } else {
      setHandleCourse(0);
      setHandleCourseName('');
      toast.info('Caro usuário, por gentileza selecione um curso!');
    }
  }

  async function removeModule() {
    const data = {
      cod_evento: handleCourse,
      cod_modulo_curso: selectedModule.cod_modulo_curso,
    };

    const response = await api.put(
      `/evento/cancelar-modulo-curso/${data.cod_modulo_curso}`,
      data
    );
    if (response.data.records.error) {
      toast.error(response.data.records.msg);
    } else {
      toast.success('Módulo removido com sucesso!');
      dispatch(ModulesActions.modulesGetByCourseRequest(handleCourse));
    }

    onClose();
    setSelectedModule({});
  }

  function getInitialData() {
    if (objIsNull(courses)) {
      const lastCourse = courses[courses.length - 1];
      return {
        cod_evento: lastCourse.cod_evento,
      };
    }
    return {};
  }

  function handleEdit(id) {
    const query = buildQueryString({
      cod_evento: handleCourse,
      cod_modulo_curso: id,
    });
    dispatch(ModulesActions.modulesGetRequest(query));
    setTimeout(() => {
      handleShowModalModule();
    }, 500);
  }

  function handleContent(item) {
    history.push(
      `/modulos/${item.cod_evento}/item/${item.cod_modulo_curso}/conteudo`
    );
  }

  function handleRemove(item) {
    setShowBox(true);
    setSelectedModule(item);
  }

  return (
    <div className="content padding-tab-top-post">
      <CreateModule
        course={handleCourse}
        show={showModalModule}
        handleClose={handleCloseModalModule}
      />
      <BoxConfirm
        title="Remover Módulo"
        show={showBox}
        size="sm"
        onYesClick={removeModule}
        onNoClick={onClose}
        onClose={onClose}
        message="Deseja realmente remover o módulo escolhido?"
      />
      <Row>
        <Col>
          <div className="d-flex justify-content-between align-items-center">
            <div className="mb-3">
              <h3 className="mb-0 font-weight-semibold">
                <i
                  role="presentation"
                  style={{ fontSize: '1.5rem' }}
                  onClick={() => history.goBack()}
                  className="icon-arrow-left52 mr-2 c-pointer"
                ></i>
                Meus Módulos
              </h3>
              <span className="text-muted d-block">
                Gerenciar módulo(s) de(s) curso(s).
              </span>
            </div>
            {handleCourse > 0 && (
              <div className="header-elements mb-2">
                <div className="d-flex justify-content-center">
                  <Button
                    className="ml-3"
                    variant="info"
                    onClick={handleShowModalModule}
                  >
                    <i className="icon-plus2 mr-1"></i> Criar Módulo
                  </Button>
                </div>
              </div>
            )}
          </div>
        </Col>
        <Col xl={12}>
          <Card>
            <Card.Body>
              <Form initialData={getInitialData()}>
                {loadingCourse ? (
                  <Loading />
                ) : (
                  <Col xl={7}>
                    <label htmlFor="cod_evento">
                      <strong>Cursos</strong>
                    </label>
                    <Select
                      id="cod_evento"
                      name="cod_evento"
                      isClearable
                      placeholder="-- Por favor, selecione um curso --"
                      onChange={handleChangeCurso}
                      options={getOptionsCourses()}
                    />
                  </Col>
                )}

                {handleCourse > 0 && (
                  <Col xl={12} className="mt-4">
                    <div className="page-header-content header-elements-md-inline p-0">
                      <div className="page-title d-flex p-0">
                        <h4 className="mb-0 font-weight-semibold">
                          <i className="icon-drawer3 mr-2 c-pointer"></i>{' '}
                          Listagem dos Módulos
                          <span
                            className="text-muted d-block"
                            style={{ fontSize: 12 }}
                          >
                            Segue abaixo todos os módulos do curso{' '}
                            {handleCourseName}.
                          </span>
                        </h4>
                      </div>
                    </div>
                    <Table striped hover className="mt-2">
                      <thead>
                        <tr>
                          <th>Nome</th>
                          <th style={{ width: 200 }} className="text-center">
                            Ações
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.keys(modules).length === 0 ? (
                          <tr>
                            <td colSpan="2" className="p-0 text-center">
                              <div className="alert alert-info border-0 alert-dismissible mb-0">
                                <i className="icon-info3 border-2 rounded-round p-1 mr-2"></i>
                                <span>
                                  O curso escolhido ainda não possui módulos
                                  cadastrados!
                                </span>
                              </div>
                            </td>
                          </tr>
                        ) : (
                          modules.map(item => (
                            <tr key={item.cod_modulo_curso}>
                              <td>{item.nome_modulo}</td>
                              <td className="text-right">
                                <button
                                  title="Editar"
                                  type="button"
                                  className="btn btn-light"
                                  onClick={() =>
                                    handleEdit(item.cod_modulo_curso)
                                  }
                                >
                                  <i className="icon-pencil5 text-primary"></i>
                                </button>

                                <button
                                  title="Conteúdo do Curso"
                                  type="button"
                                  className="btn btn-light ml-2"
                                  onClick={() => handleContent(item)}
                                >
                                  <i className="icon-book text-primary"></i>
                                </button>

                                <button
                                  title="Remover"
                                  type="button"
                                  className="btn btn-light ml-2"
                                  onClick={() => handleRemove(item)}
                                >
                                  <i className="icon-trash text-primary"></i>
                                </button>
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </Table>
                  </Col>
                )}
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default Modules;
