import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import { Button, Modal, Spinner } from 'react-bootstrap';

import * as Yup from 'yup';
import { signInModalRequest } from '~/store/modules/auth/actions';

import Input from '~/components/InputText';

import api from '~/services/api';

export default function CreateUser({ show, handleClose }) {
  const formRef = useRef(null);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  async function handleSubmit(data) {
    try {
      setLoading(true);

      formRef.current.setErrors({});
      const schema = Yup.object().shape({
        nome_membro: Yup.string()
          .min(5, 'Ops! Nome muito pequeno!')
          .required('Nome é um campo de preenchimento obrigatório!'),
        email_membro: Yup.string()
          .min(5, 'Ops! E-mail muito pequeno!')
          .email('E-mail inválido!')
          .required('E-mail é um campo de preenchimento obrigatório!'),
        senha: Yup.string()
          .min(5, 'Ops! Senha muita pequeno!')
          .required('Senha é um campo de preenchimento obrigatório!'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const response = await api.post('/auth/salvar-membro', data);
      if (response.data.records.error) {
        toast.error(response.data.records.msg);
        setLoading(false);
        return false;
      }
      toast.success('Seu cadastro foi criado com sucesso!');
      setTimeout(() => {
        setLoading(false);
        dispatch(signInModalRequest(data.email_membro, data.senha, 1));
        handleClose();
      }, 4000);
    } catch (err) {
      setLoading(false);
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  return (
    <Modal
      centered
      className="modal-new-event"
      dialogClassName="modal-event"
      show={show}
      onHide={handleClose}
    >
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Modal.Header>
          <div className="media align-items-center align-items-md-start flex-column flex-md-row">
            <span className="text-teal mr-md-3 mb-3 mb-md-0">
              <i className="icon-plus3 text-info-400 border-info-400 border-2 rounded-round p-2 mt-1"></i>
            </span>
            <div className="media-body text-center text-md-left">
              <h3 className="media-title font-weight-semibold">
                Formulário de Cadastro
              </h3>
              <span className="text-muted">
                Preencha o formulário abaixo para efetuar o cadastro em nossa
                plataforma.
              </span>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label htmlFor="nome_membro">Nome *</label>
            <Input
              required
              name="nome_membro"
              id="nome_membro"
              type="text"
              maxLength="255"
              placeholder="Entre com o seu nome..."
            />
          </div>
          <div className="form-group">
            <label htmlFor="email_membro">E-mail *</label>
            <Input
              required
              name="email_membro"
              id="email_membro"
              type="email"
              maxLength="255"
              placeholder="Entre com o seu melhor e-mail..."
            />
          </div>
          <div className="form-group">
            <label htmlFor="senha">Senha *</label>
            <Input
              required
              name="senha"
              id="senha"
              type="password"
              maxLength="20"
              placeholder="Entre com a sua senha..."
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button
            size="lg"
            block
            className="text-secondary"
            variant="link"
            onClick={handleClose}
          >
            <i className="icon-cross2 mr-1 font-weight-light"></i>
            Cancelar
          </Button>
          <Button size="lg" block disabled={!!loading} type="submit">
            {loading ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="mr-1"
                />
                Aguarde
              </>
            ) : (
              'Enviar Cadastro'
            )}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
