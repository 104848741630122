import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import * as CasesActions from './actions';

export function* casesListRequest() {
  try {
    const response = yield call(api.get, `/post/?q=(onde_post:6)`);

    if (response.data.records.error) {
      toast.error(response.data.records.msg);
      yield put(CasesActions.casesListFailure());
      return;
    }

    yield put(CasesActions.casesListSuccess(response.data.records));
  } catch (err) {
    toast.error('Falha na comunicação com servidor!');
    yield put(CasesActions.casesListFailure());
  }
}

export function* casesGetRequest({ payload }) {
  try {
    const response = yield call(
      api.get,
      `/post/get/?q=(id:${payload},onde_post:6)`
    );

    if (response.data.records.error) {
      toast.error(response.data.records.msg);
      yield put(CasesActions.casesGetFailure());
      return;
    }

    yield put(CasesActions.casesGetSuccess(response.data.records));
  } catch (err) {
    toast.error('Falha na comunicação com servidor!');
    yield put(CasesActions.casesGetFailure());
  }
}

export function* casesLoadListRequest() {
  try {
    const response = yield call(api.get, `/post/?q=(onde_post:6)`);

    if (response.data.records.error) {
      toast.error(response.data.records.msg);
      yield put(CasesActions.casesListFailure());
      return;
    }

    yield put(CasesActions.casesLoadListSuccess(response.data.records));
  } catch (err) {
    toast.error('Falha na comunicação com servidor!');
    yield put(CasesActions.casesListFailure());
  }
}

export default all([
  takeLatest('@cases/LIST_CASES_REQUEST', casesListRequest),
  takeLatest('@cases/GET_CASES_REQUEST', casesGetRequest),
  takeLatest('@cases/LOAD_CASES_REQUEST', casesGetRequest),
  takeLatest('@cases/LOAD_LIST_REQUEST', casesLoadListRequest),
]);
