import produce from 'immer';

const INITIAL_STATE = {
  shopping: {},
  shoppings: [],
  loading: true,
};

export default function shopping(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@shopping/GET_SHOPPING_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@shopping/GET_SHOPPING_SUCCESS': {
        draft.loading = false;
        draft.shoppings = action.payload;
        break;
      }
      case '@shopping/GET_SHOPPING_FAILURE': {
        draft.loading = false;
        break;
      }
      default: {
        break;
      }
    }
  });
}
