export function groupListRequest() {
  return {
    type: '@group/LIST_GROUP_REQUEST',
  };
}

export function groupGetRequest(id) {
  return {
    type: '@group/GET_GROUP_REQUEST',
    payload: id,
  };
}

export function groupListSuccess(data) {
  return {
    type: '@group/LIST_GROUP_SUCCESS',
    payload: data,
  };
}

export function groupListFailure() {
  return {
    type: '@group/LIST_GROUP_REQUEST',
  };
}
