import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, Button, Table, Badge, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Form } from '@unform/web';
import { parseISO, formatDistance } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';

import api from '~/services/api';

import Loading from '~/components/Loading';
import Input from '~/components/InputText';
import InputGroup from '~/components/InputGroup';
import InputSelect from '~/components/InputSelect';
import DatePicker from '~/components/DatePicker';
import Confirm from '~/components/BoxConfirm';

export default function DiscountCoupons({ data }) {
  const [dados] = useState(data);
  const [coupons, setCoupons] = useState([]);
  const [coupon, setCoupon] = useState({});
  const [load, setLoad] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [deleted, setDeleted] = useState(null);

  const loadCupons = useCallback(async value => {
    setLoad(true);
    const search = value.cod_evento
      ? `cod_evento:${value.cod_evento}`
      : `cod_produto:${value.cod_produto}`;

    await api.get(`/cupom/?q=(${search})`).then(response => {
      const { records } = response.data;

      if (records.error) {
        toast.error(records.msg);
        setLoad(false);
        return;
      }

      records.map(key => {
        if (key.dt_fim) {
          key.dt_fim = parseISO(key.dt_fim);
        }
        return key;
      });

      setCoupons(records);
      setLoad(false);
    });
  }, []);

  useEffect(() => {
    loadCupons(data);
  }, [loadCupons, data]);

  const modalClose = useCallback(() => {
    setModalShow(false);
    setCoupon({});
  }, []);

  const handleSubmit = useCallback(
    async value => {
      const datafc = new Date(value.dt_fim);
      const datafe = new Date(dados.dt_eve_fim);

      if (!value.nome_cupom || value.nome_cupom.length < 5) {
        toast.error('Campo obrigatório: Descrição!');
      } else if (!value.num_cupom || value.num_cupom.length < 5) {
        toast.error('Campo obrigatório: Código do Cupom!');
      } else if (!value.val_desc) {
        toast.error('Campo obrigatório: Valor desconto!');
      } else if (value.val_desc > 100) {
        toast.error('Não é possível criar cupom com mais de 100% de desconto.');
      } else if (value.val_desc < 1) {
        toast.error('Não é possível criar cupom com menos de 1% de desconto.');
      } else if (!value.qtd_max || value.qtd_max < 1) {
        toast.error('Campo obrigatório: Quantidade disponível');
      } else if (!value.dt_fim) {
        toast.error('Campo obrigatório: Validade');
      } else if (data && data.dt_eve_fim && datafc > datafe) {
        toast.error(
          'Campo obrigatório: Data de validade do Cupom não pode ser maior que a data final do Evento/Curso.'
        );
      } else if (!value.ativo) {
        toast.error('Campo obrigatório: Status');
      } else {
        if (value.cod_cupom) {
          await api.put(`/cupom/${value.cod_cupom}`, value).then(response => {
            const { records } = response.data;
            if (records.error) {
              toast.error(records.msg);
              return;
            }
            toast.success('Cupom editado com sucesso');
          });
        } else {
          await api.post('/cupom', value).then(response => {
            const { records } = response.data;
            if (records.error) {
              toast.error(records.msg);
              return;
            }
            toast.success('Cupom criado com sucesso');
          });
        }
        loadCupons(data);
        modalClose();
      }
    },
    [dados.dt_eve_fim, data, loadCupons, modalClose]
  );

  const handleStatus = useCallback(
    async (id, status) => {
      await api.put(`/cupom/${id}`, { ativo: status }).then(response => {
        const { records } = response.data;
        if (records.error) {
          toast.error(records.msg);
          return;
        }
        loadCupons(data);
      });
    },
    [loadCupons, data]
  );

  const handleConfirm = useCallback(async id => {
    setDeleted(id);
    setConfirm(true);
  }, []);

  const handleDelete = useCallback(async () => {
    if (deleted) {
      await api.delete(`/cupom/${deleted}`).then(response => {
        const { records } = response.data;
        if (records.error) {
          toast.error(records.msg);
          return;
        }
        toast.success('Cupom apagado com sucesso');
        setDeleted(null);
        loadCupons(data);
        setConfirm(false);
      });
    }
  }, [loadCupons, data, deleted]);

  const handleUpdate = useCallback(value => {
    setCoupon(value);
    setModalShow(true);
  }, []);

  const handleForm = useCallback(
    value => {
      return (
        <Form onSubmit={handleSubmit} initialData={value}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {value.cod_cupom ? 'Editar cupom' : 'Criar cupom'}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md={6}>
                <div className="form-group">
                  <label>Descrição</label>
                  <Input name="nome_cupom" placeholder="Digite a descrição" />
                  <Input name="cod_cupom" type="hidden" />
                  <Input
                    name={data.cod_evento ? 'cod_evento' : 'cod_produto'}
                    type="hidden"
                    defaultValue={
                      data.cod_evento ? data.cod_evento : data.cod_produto
                    }
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group">
                  <label>Código do cupom</label>
                  <Input
                    name="num_cupom"
                    placeholder="Digite o código"
                    style={{ textTransform: 'uppercase' }}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group">
                  <label>Desconto(%)</label>
                  <InputGroup
                    name="val_desc"
                    type="number"
                    label="%"
                    placeholder="Digite o desconto"
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group">
                  <label>Quantidade disponível</label>
                  <Input
                    name="qtd_max"
                    type="number"
                    placeholder="Digite a quantidade"
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group">
                  <label>Validade</label>
                  <DatePicker
                    name="dt_fim"
                    minDate={new Date()}
                    maxDate={
                      dados.dt_eve_fim ? new Date(dados.dt_eve_fim) : null
                    }
                    placeholder="Selecione uma data"
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group">
                  <label>Status</label>
                  <InputSelect
                    name="ativo"
                    placeholder="Selecione"
                    options={[
                      { value: '1', label: 'Ativo' },
                      { value: '0', label: 'Inativo' },
                    ]}
                  />
                </div>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-center">
            <Button
              size="lg"
              block
              className="text-secondary"
              variant="link"
              onClick={modalClose}
            >
              <i className="icon-cross2 mr-1 font-weight-light"></i>
              Cancelar
            </Button>
            <Button size="lg" block type="submit">
              {value.cod_cupom ? 'Salvar cupom' : 'Criar cupom'}
            </Button>
          </Modal.Footer>
        </Form>
      );
    },
    [
      dados.dt_eve_fim,
      data.cod_evento,
      data.cod_produto,
      handleSubmit,
      modalClose,
    ]
  );

  return (
    <Row>
      <Col>
        {load ? (
          <Loading />
        ) : (
          <Card>
            <Card.Header>
              <Button
                variant="success"
                size="sm"
                onClick={() => setModalShow(true)}
              >
                <i className="icon-plus2"></i> Criar cupom
              </Button>
            </Card.Header>
            <Card.Body>
              <Table striped hover>
                <thead>
                  <tr>
                    <th>Descrição</th>
                    <th>Código do cupom</th>
                    <th>Desconto (%)</th>
                    <th>Quantidade disponível</th>
                    <th>Validade</th>
                    <th>Status</th>
                    <th className="text-center">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(coupons).length === 0 ? (
                    <tr>
                      <td colSpan="7" className="p-0 text-center">
                        <div className="alert alert-info border-0 alert-dismissible mb-0">
                          <i className="icon-info3 border-2 rounded-round p-1 mr-2"></i>
                          <span>
                            Você ainda não criou nenhum cupom de desconto
                          </span>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    coupons.map(value => (
                      <tr key={value.cod_cupom}>
                        <td>{value.nome_cupom}</td>
                        <td>{value.num_cupom}</td>
                        <td>{value.val_desc}%</td>
                        <td>{value.qtd_max}</td>
                        <td>
                          {value.dt_fim &&
                            formatDistance(value.dt_fim, new Date(), {
                              locale: pt,
                            })}
                        </td>
                        <td>
                          {value.ativo === '1' ? (
                            <Badge
                              className="c-pointer text-decoration-none"
                              variant="success"
                              onClick={() => handleStatus(value.cod_cupom, 0)}
                            >
                              Ativo
                            </Badge>
                          ) : (
                            <Badge
                              className="c-pointer text-decoration-none"
                              variant="secondary"
                              onClick={() => handleStatus(value.cod_cupom, 1)}
                            >
                              Inativo
                            </Badge>
                          )}
                        </td>
                        <td className="text-center">
                          <button
                            title="Editar"
                            type="button"
                            className="btn btn-sm btn-light btn-icon"
                            onClick={() => handleUpdate(value)}
                          >
                            <i className="icon-pencil5 text-primary"></i>
                          </button>
                          <button
                            title="Apagar"
                            type="button"
                            className="btn btn-sm btn-light btn-icon mr-1"
                            onClick={() => handleConfirm(value.cod_cupom)}
                          >
                            <i className="icon-trash text-primary"></i>
                          </button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        )}
      </Col>
      <Modal
        show={modalShow}
        onHide={modalClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {handleForm(coupon)}
      </Modal>
      <Confirm
        title="Apagar"
        show={confirm}
        onClose={() => setConfirm(false)}
        size="sm"
        onYesClick={handleDelete}
        onNoClick={() => setConfirm(false)}
        message="Tem certeza de que deseja apagar esse cupom?"
      />
    </Row>
  );
}
DiscountCoupons.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
};

DiscountCoupons.defaultProps = {
  data: {},
};
