import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { formatDistance } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';

import {
  casesLoadRequest,
  casesLoadListRequest,
} from '~/store/modules/case/actions';

import PostComment from '~/components/PostComment';
import CommentPost from '~/components/CommentPost';
import ButtonLinke from '~/components/ButtonLike';
import LoaderPost from '~/components/LoaderPost';
import EditPost from '~/components/EditPost';
import DeletePost from '~/components/DeletePost';

export default function Post({ post, loading }) {
  const dispatch = useDispatch();

  const [showEdit, setShowEdit] = useState(false);

  function loadPost() {
    dispatch(casesLoadRequest(post.cod_post));
    dispatch(casesLoadListRequest());
  }

  return (
    <div className="card">
      {loading ? (
        <LoaderPost />
      ) : (
        <div className="card-body">
          <div className="d-flex justify-content-start align-items-center mb-1">
            {post.midia_post ? (
              <div className="mr-2">
                <img
                  src="global_assets/images/demo/users/face1.jpg"
                  className="rounded-round"
                  alt="avtar img holder"
                  height="45"
                  width="45"
                />
              </div>
            ) : (
              <div className="mr-2">
                <a
                  href="#1"
                  className="btn bg-teal-400 rounded-round btn-icon btn-lg"
                >
                  <span className="letter-icon">
                    {post.nome_membro.substring(0, 1)}
                  </span>
                </a>
              </div>
            )}
            <div className="user-page-info">
              <ul className="list-inline list-inline-dotted font-size-sm mb-0">
                <li className="list-inline-item">
                  <span className="font-weight-semibold text-primary c-pointer">
                    {post.nome_membro}
                  </span>
                </li>
                <li className="list-inline-item">
                  <span className="font-small-2 text-grey-300">
                    {formatDistance(new Date(post.dt_cadastro), new Date(), {
                      locale: pt,
                    })}
                  </span>
                </li>
              </ul>
              Psicanalista em Porto Alegre(RS)
            </div>
            <ButtonLinke post={post} curtPost={loadPost} />
          </div>
          {showEdit ? (
            <EditPost post={post} loadPost={loadPost} showEdit={setShowEdit} />
          ) : (
            <>
              <div className="font-weight-semibold mb-2 mt-2">
                {post.tit_post}
              </div>
              <p>{post.desc_post}</p>
            </>
          )}
          <div className="d-flex justify-content-start align-items-center mb-1 ">
            <div className="mr-2">
              <span className="text-primary">
                <i className="icon-heart6 font-medium-2 mr-1"></i>
                <span>{post.qtd_cur} Curtidas</span>
              </span>
            </div>
            <ul className="list-inline list-inline-dotted font-size-sm mb-0">
              <li className="list-inline-item">
                <span className="text-primary">
                  <i className="icon-file-text font-medium-2 mr-1"></i>
                  <span>{post.qtd_com} Comentários</span>
                </span>
              </li>
              <li className="list-inline-item">
                <span
                  className="text-muted c-pointer"
                  onClick={() => setShowEdit(true)}
                >
                  Editar
                </span>
              </li>
              <DeletePost id={post.cod_post} loadPost={loadPost} />
            </ul>
          </div>
          {post.filhos.length > 0 ? (
            <div className="card-body">
              <ul className="media-list">
                {post.filhos.map(filho => (
                  <PostComment
                    comments={filho}
                    loadPost={loadPost}
                    key={filho.cod_post}
                  />
                ))}
              </ul>
            </div>
          ) : (
            ''
          )}
          <CommentPost post={post} loadPost={loadPost} />
        </div>
      )}
    </div>
  );
}

Post.propTypes = {
  post: PropTypes.object,
  loading: PropTypes.bool,
};

Post.defaultProps = {
  post: {},
  loading: true,
};
