export function productsGetByTagRequest(tag) {
  return {
    type: '@products/GET_PRODUCTS_BY_TAG_REQUEST',
    payload: tag,
  };
}

export function productsGetByTagSuccess(data) {
  return {
    type: '@products/GET_PRODUCTS_BY_TAG_SUCCESS',
    payload: data,
  };
}

export function productsGetByTagFailure() {
  return {
    type: '@products/GET_PRODUCTS_BY_TAG_FAILURE',
  };
}
