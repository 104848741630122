import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Card, Table, Button } from 'react-bootstrap';
import { Form } from '@unform/web';

import history from '~/services/history';
import api from '~/services/api';
import Select from '~/components/InputSelect';
import InputCheck from '~/components/InputCheck';
import ButtonCheckin from '~/components/ButtonCheckin';
import { objIsNull } from '~/util/';

import { eventsListRequest } from '~/store/modules/event/actions';
import Loading from '~/components/Loading';

export default function Participants() {
  const dispatch = useDispatch();
  const [parts, setParts] = useState([]);
  const [load, setLoad] = useState(false);

  const { events } = useSelector(state => state.event);
  const loading = useSelector(state => state.event.loading);

  useEffect(() => {
    dispatch(eventsListRequest('meus-eventos/?q=(tipo_eve:1)'));
  }, [dispatch]);

  async function handleChange(data) {
    if (!data.status) {
      data.status = 0;
    }
    if (data) {
      setLoad(true);
      const response = await api.get(
        `/participante/listar-part-evento/?q=(cod_evento:${data.value},somente_pagos:${data.status})`
      );
      if (response.data.count < 1) {
        setParts([]);
        setLoad(false);
        return;
      }
      setParts(response.data.records);
      setLoad(false);
    } else {
      setParts([]);
    }
  }

  useEffect(() => {
    if (objIsNull(events)) {
      handleChange({ value: events[events.length - 1].cod_evento, status: 1 });
    }
  }, [events]);

  const options = itens => {
    const data = itens.map(event => {
      return {
        value: event.cod_evento,
        label: event.titulo_eve,
      };
    });
    return data;
  };

  return (
    <div className="content padding-tab-top-post">
      <Row>
        <Col>
          <div className="d-flex justify-content-between align-items-center">
            <div className="mb-3">
              <h3 className="mb-0 font-weight-semibold">
                <i
                  role="presentation"
                  style={{ fontSize: '1.5rem' }}
                  onClick={() => history.goBack()}
                  className="icon-arrow-left52 mr-2 c-pointer"
                ></i>
                Participantes
              </h3>
              <span className="text-muted d-block">
                Gerenciar participantes de cada evento
              </span>
            </div>
          </div>
        </Col>
        <Col xl={12}>
          <Card>
            {loading ? (
              <Card.Body>
                <Loading />
              </Card.Body>
            ) : (
              <Card.Body>
                <Form
                  onSubmit={handleChange}
                  initialData={{
                    value: events.length
                      ? events[events.length - 1].cod_evento
                      : null,
                    status: '1',
                  }}
                >
                  <Row>
                    <Col>
                      <div className="form-group">
                        <label>Evento</label>
                        <Select
                          name="value"
                          placeholder="Selecione o evento"
                          options={options(events)}
                          // onChange={handleChange}
                        />
                      </div>
                    </Col>
                    <Col className="d-flex justify-content-start align-items-center pt-3">
                      <div className="form-group">
                        <InputCheck
                          name="status"
                          options={[
                            {
                              id: '1',
                              label: 'Somente Pagos',
                              text: '',
                            },
                          ]}
                        />
                      </div>
                    </Col>
                    <Col xl={12}>
                      <Button type="submit" variant="primary">
                        Filtrar
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            )}
          </Card>
          {load && <Loading />}
          {parts.length > 0 ? (
            <Card>
              <Card.Header className="text-left">
                <span>Total de participantes: {parts.length}</span>
              </Card.Header>
              <Card.Body>
                <Table striped hover>
                  <tbody>
                    {parts.map(part => (
                      <tr key={part.cod_evento_cad_part}>
                        <td>
                          <div className="d-flex flex-column">
                            <span className="text-dark">{part.nome_part}</span>
                            <small className="text-muted">
                              {part.email_part}
                            </small>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex flex-column">
                            <small className="text-muted">N° do ingresso</small>
                            {part.nro_ingresso}
                            <small className="text-muted">N° do ingresso</small>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex flex-column">
                            <small className="text-muted">Tipo ingresso</small>
                            {part.tipo_ingresso}
                          </div>
                        </td>
                        <td>
                          <div className="d-flex flex-column">
                            <small className="text-muted">Comprador</small>
                            {part.nome_membro_pagador}
                          </div>
                        </td>
                        <td>
                          <div className="d-flex flex-column">
                            <small className="text-muted">Comprado em</small>
                            {part.comprado_em}
                          </div>
                        </td>
                        <td>
                          <ButtonCheckin data={part} check={part.checkin} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          ) : (
            <Card>
              <Card.Body>
                <div className="alert alert-info border-0 alert-dismissible mb-0">
                  Você ainda não possui participantes para este evento.
                  Publique-o e divulgue o link para suas redes.
                </div>
              </Card.Body>
            </Card>
          )}
        </Col>
      </Row>
    </div>
  );
}
