/* eslint-disable no-prototype-builtins */

import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';

import PropTypes from 'prop-types';
import history from '~/services/history';
import MethodPayment from './methodPayment';

import { formatCurrencyValue, objIsNull } from '~/util';

export default function Payment({ next, product, setBoleto }) {
  const [freight, setFreight] = useState({});
  const [products, setProducts] = useState({});

  useEffect(() => {
    if (localStorage.hasOwnProperty('@psicomart:products')) {
      setFreight(JSON.parse(localStorage.getItem('@psicomart:address')));
      setProducts(JSON.parse(localStorage.getItem('@psicomart:products')));
    } else {
      history.goBack();
    }
  }, []);

  function getFreightValue() {
    if (freight && objIsNull(freight)) {
      return freight.value;
    }
    return 0;
  }

  return (
    <>
      <h5 className="font-weight-bold text-muted">DETALHES DO PAGAMENTO</h5>
      <Table striped hover responsive className="table-bordered">
        <thead>
          <tr>
            <th>Produto</th>
            <th>Quantidade</th>
            <th className="text-right">Valor Unitário</th>
            <th className="text-right">Valor Total</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{products.nome_produto}</td>
            <td>{products.pro_qtde}</td>
            <td className="text-right">
              {formatCurrencyValue(products.por_valor)}
            </td>
            <td className="text-right">
              {formatCurrencyValue(products.pro_valor_total)}
            </td>
          </tr>
        </tbody>
        {product.eh_cobrado === '1' && (
          <tfoot>
            <tr>
              <th colSpan="3" className="text-right">
                Frete
              </th>
              <th className="text-right">
                {formatCurrencyValue(getFreightValue())}
              </th>
            </tr>
            <tr>
              <th colSpan="3" className="text-right">
                Totalização
              </th>
              <th className="text-right">
                {formatCurrencyValue(
                  getFreightValue() + products.pro_valor_total
                )}
              </th>
            </tr>
          </tfoot>
        )}
      </Table>
      {product.eh_cobrado === '1' && (
        <>
          <h5 className="font-weight-bold text-muted mt-3">
            TIPO DE PAGAMENTO
          </h5>
          <span>
            Seus dados serão transferidos com conexão segura SSL e nenhuma
            informação sensível do pagamento será mantida em nossos servidores.
          </span>
        </>
      )}

      <MethodPayment
        next={next}
        freight={freight}
        products={products}
        setBoleto={setBoleto}
      />
    </>
  );
}

Payment.propTypes = {
  next: PropTypes.func.isRequired,
  setBoleto: PropTypes.func.isRequired,
  product: PropTypes.shape({
    eh_cobrado: PropTypes.string,
  }).isRequired,
};
