import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import api from '~/services/api';

import { Form, Input } from '@rocketseat/unform';

export default function EditComment({ post, loadPost, showEdit }) {
  const [disabled, setDisabled] = useState(false);
  const [editComment, setEditComment] = useState(post.desc_post);

  async function handleComment({ comment }, { resetForm }) {
    try {
      setDisabled(true);
      const response = await api.post('/post', {
        desc_post: comment,
        cod_post: post.cod_post,
      });
      if (!response.data.records.error) {
        loadPost();
        resetForm();
        showEdit(false);
      }
    } catch (error) {
      setDisabled(false);
      toast.error('Falha na comunicação com servidor');
    }
  }

  return (
    <Form className="form-label-group mb-2" onSubmit={handleComment}>
      <Input
        style={{ backgroundColor: '#f5f5f5' }}
        multiline
        rows={2}
        name="comment"
        className="form-control"
        placeholder="Edite aqui seu comentário"
        value={editComment}
        onChange={e => {
          e.target.value.length > 0 ? setDisabled(false) : setDisabled(true);
          setEditComment(e.target.value);
        }}
      />
      <button
        disabled={disabled}
        type="submit"
        className="btn btn-sm btn-primary waves-effect waves-light mt-1"
      >
        Editar Comentário
      </button>
      <button
        onClick={() => showEdit(false)}
        type="button"
        className="btn btn-link waves-effect waves-light mt-2"
      >
        Cancelar
      </button>
    </Form>
  );
}
EditComment.propTypes = {
  post: PropTypes.object.isRequired,
  loadPost: PropTypes.func.isRequired,
};

EditComment.defaultProps = {
  post: {},
};
