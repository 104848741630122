import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import * as ProductsActions from './actions';

export function* productsGetByTagRequest({ payload }) {
  try {
    const response = yield call(api.get, `/pagina/get-produto/${payload}`);
    if (response.data.records.error) {
      toast.error(response.data.records.msg);
      yield put(ProductsActions.productsGetByTagFailure());
      return;
    }
    yield put(ProductsActions.productsGetByTagSuccess(response.data.records));
  } catch (err) {
    toast.error('Falha na comunicação com servidor!');
    yield put(ProductsActions.productsGetByTagFailure());
  }
}

export default all([
  takeLatest('@products/GET_PRODUCTS_BY_TAG_REQUEST', productsGetByTagRequest),
]);
