export function casesGetRequest(id) {
  return {
    type: '@cases/GET_CASES_REQUEST',
    payload: id,
  };
}

export function casesGetSuccess(data) {
  return {
    type: '@cases/GET_CASES_SUCCESS',
    payload: data,
  };
}

export function casesGetFailure() {
  return {
    type: '@cases/GET_CASES_FAILURE',
  };
}

export function casesListRequest() {
  return {
    type: '@cases/LIST_CASES_REQUEST',
  };
}

export function casesListSuccess(data) {
  return {
    type: '@cases/LIST_CASES_SUCCESS',
    payload: data,
  };
}

export function casesListFailure() {
  return {
    type: '@cases/LIST_CASES_FAILURE',
  };
}

export function casesLoadRequest(id) {
  return {
    type: '@cases/LOAD_CASES_REQUEST',
    payload: id,
  };
}

export function casesLoadListRequest() {
  return {
    type: '@cases/LOAD_LIST_REQUEST',
  };
}

export function casesLoadListSuccess(data) {
  return {
    type: '@cases/LOAD_LIST_SUCCESS',
    payload: data,
  };
}
