import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import api from '~/services/api';

export default function ButtonLike({ post, curtPost }) {
  const [like, setLike] = useState(post.mem_curt);

  const handleLike = useCallback(
    async value => {
      if (!value) {
        try {
          const response = await api.post('/curt', {
            acao: '1',
            cod_post: post.cod_post,
            tipo_curt: '1',
          });
          if (!response.data.records.error) {
            setLike(true);
            curtPost();
          }
        } catch (error) {
          toast.error('Falha na comunicação com servidor');
        }
      } else {
        try {
          const response = await api.post('/curt', {
            acao: '2',
            cod_post: post.cod_post,
            tipo_curt: '1',
          });
          if (!response.data.records.error) {
            setLike(false);
            curtPost();
          }
        } catch (error) {
          toast.error('Falha na comunicação com servidor!');
        }
      }
    },
    [setLike, curtPost, post]
  );

  return (
    <div
      className="ml-auto user-like text-danger c-pointer"
      onClick={() => handleLike(like)}
    >
      {like ? (
        <i className="icon-heart5 font-size-icon3"></i>
      ) : (
        <i className="icon-heart6 font-size-icon3"></i>
      )}
    </div>
  );
}

ButtonLike.propTypes = {
  post: PropTypes.object,
  curtPost: PropTypes.func,
};

ButtonLike.defaultProps = {
  post: {},
};
