/* eslint-disable react/forbid-prop-types */

import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Form } from '@unform/web';
import { isMobile } from 'react-device-detect';
import { Row, Col, Button } from 'react-bootstrap';
import * as Yup from 'yup';

import { MdExpandMore } from 'react-icons/md';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';

import Input from '~/components/InputText';
import InputMask from '~/components/InputMask';
import Select from '~/components/InputSelect';
import InputRadio from '~/components/InputRadio';
import BottomButton from '~/components/BottomButton';

import { months, years, join } from '~/util';

import {
  getInstallmentsRequest,
  addStep,
  getPaymentRequest,
} from '~/store/modules/cart/actions';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: 10,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexShrink: 0,
    paddingLeft: '12px',
    marginBottom: 10,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    marginLeft: 15,
    top: -3,
    position: 'relative',
  },
}));

export default function MethodPayment() {
  const classes = useStyles();
  const formRef = useRef(null);
  const dispatch = useDispatch();

  const [card, setCard] = useState(true);
  const [method, setMethod] = useState(3);
  const [expanded, setExpanded] = useState('panel1');

  const { profile } = useSelector(state => state.user);
  const { tickets } = useSelector(state => state.cart);
  const { loading } = useSelector(state => state.cart);
  const { event } = useSelector(state => state.cart);
  const { total } = useSelector(state => state.cart);
  const { coupon } = useSelector(state => state.cart);
  const { participants } = useSelector(state => state.cart);
  const { installments } = useSelector(state => state.cart);
  const { paid } = useSelector(state => state.cart);
  const { billet } = useSelector(state => state.cart);
  const { affiliate } = useSelector(state => state.cart);

  const handleChange = useCallback(
    panel => isExpanded => {
      setExpanded(isExpanded ? panel : false);
    },
    []
  );

  const handleMethod = useCallback(value => {
    if (value === 3) {
      setCard(true);
      setMethod(value);
    } else {
      setCard(false);
      setMethod(value);
    }
  }, []);

  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current.setErrors({});

        const schema = Yup.object().shape({
          cartao_nro: Yup.string().required(
            'O número do cartão é de preenchimento obrigatório!'
          ),
          cartao_nome: Yup.string().required(
            'O nome do titular é de preenchimento obrigatório!'
          ),
          cartao_mes_exp: Yup.string().required(
            'O mês é de preenchimento obrigatório!'
          ),
          cartao_ano_exp: Yup.string().required(
            'O ano é de preenchimento obrigatório!'
          ),
          cartao_cod_seg: Yup.string().required('*'),
        });

        await schema.validate(data, {
          abortEarly: false,
          recursive: card,
        });

        const dataSend = {
          part: participants,
          qtd_item: participants.length ? participants.length : 1,
          valor_a_pagar: coupon.total >= 0 ? coupon.total : total,
          cod_evento: event.cod_evento,
          cod_membro: profile.cod_membro,
          url_afiliacao: affiliate,
          cod_cupom: coupon.cod_cupom ? coupon.cod_cupom : null,
          ...data,
        };

        dispatch(getPaymentRequest(dataSend));

        if (paid || billet) {
          dispatch(addStep());
        }
      } catch (err) {
        const validationErrors = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            validationErrors[error.path] = error.message;
          });
          formRef.current.setErrors(validationErrors);
        }
      }

      return false;
    },
    [
      card,
      event,
      dispatch,
      participants,
      profile,
      total,
      coupon,
      billet,
      paid,
      affiliate,
    ]
  );

  const handleBillet = useCallback(async () => {
    const dataSend = {
      part: participants,
      cod_evento: event.cod_evento,
      qtd_item: participants.length ? participants.length : 1,
      url_afiliacao: affiliate,
      cod_cupom: coupon.cod_cupom,
    };

    dispatch(getPaymentRequest(dataSend));
  }, [event, dispatch, participants, affiliate, coupon]);

  useEffect(() => {
    const ing = join(tickets);
    dispatch(getInstallmentsRequest({ event, ing, total, coupon }));
  }, [dispatch, total, event, tickets, coupon]);

  return (
    <div className={classes.root}>
      {(event.eh_cobrado === 1 || event.eh_cobrado === '1') &&
      (coupon.total >= 0 ? coupon.total : total) > 0 ? (
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={{ forma_pgto: method }}
        >
          <ExpansionPanel
            expanded={expanded === 'panel1'}
            onChange={handleChange('panel1')}
            onClick={() => handleMethod(3)}
          >
            <ExpansionPanelSummary
              expandIcon={<MdExpandMore />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              style={{ padding: 0 }}
            >
              <Typography className={classes.heading} style={{ fontSize: 14 }}>
                <strong className="text-uppercase">Cartão de crédito</strong>
              </Typography>
              {!isMobile && (
                <Typography className={classes.secondaryHeading}>
                  <>
                    <img
                      src={require('~/assets/img/visa.svg')}
                      alt="Visa"
                      width={35}
                      className="mr-1"
                    />
                    <img
                      src={require('~/assets/img/master.svg')}
                      alt="Master"
                      width={35}
                      className="mr-1"
                    />
                    <img
                      src={require('~/assets/img/elo.svg')}
                      alt="Elo"
                      width={35}
                      className="mr-1"
                    />
                    <img
                      src={require('~/assets/img/diners.svg')}
                      alt="Diners"
                      width={35}
                      className="mr-1"
                    />
                    <img
                      src={require('~/assets/img/amex.svg')}
                      alt="Amex"
                      width={35}
                    />
                  </>
                </Typography>
              )}
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={`${!isMobile ? `` : `p-0`}`}>
              {card && (
                <Col xl={8}>
                  <div className="form-group">
                    <label htmlFor="qtdParc">Quantidade de Parcelas *</label>
                    <Select
                      name="qtdParc"
                      placeholder="Selecione..."
                      options={installments}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="cartao_nro">Número do cartão *</label>
                    <InputMask
                      id="cartao_nro"
                      name="cartao_nro"
                      mask="9999 9999 9999 9999"
                      placeholder="Entre com o número do cartão de crédito..."
                      style={{ fontSize: 12 }}
                    />
                  </div>
                  <Row>
                    <Col xl={5}>
                      <div className="form-group">
                        <label htmlFor="cartao_mes_exp">
                          Mês{' '}
                          <small className="text-muted">
                            <em>(de expiração)</em>
                          </small>{' '}
                          *
                        </label>
                        <Select
                          id="cartao_mes_exp"
                          name="cartao_mes_exp"
                          options={months()}
                          placeholder="Selecione..."
                        />
                      </div>
                    </Col>
                    <Col xl={5}>
                      <div className="form-group">
                        <label htmlFor="cartao_ano_exp">
                          Ano{' '}
                          <small className="text-muted">
                            <em>(de expiração)</em>
                          </small>{' '}
                          *
                        </label>
                        <Select
                          id="cartao_ano_exp"
                          name="cartao_ano_exp"
                          options={years()}
                          placeholder="Selecione..."
                        />
                      </div>
                    </Col>
                    <Col xl={2}>
                      <div className="form-group">
                        <label htmlFor="cartao_cod_seg">
                          <abbr
                            title="Código de segurança"
                            className="initialism"
                          >
                            CVV *
                          </abbr>
                        </label>
                        <Input
                          id="cartao_cod_seg"
                          name="cartao_cod_seg"
                          type="text"
                          maxLength="4"
                          style={{ fontSize: 13, padding: 8 }}
                        />
                      </div>
                    </Col>
                  </Row>
                  <div className="form-group">
                    <label htmlFor="cartao_nome">
                      Nome do titular{' '}
                      <small className="text-muted">
                        <em>(como impresso no cartão)</em>
                      </small>
                    </label>
                    <Input
                      id="cartao_nome"
                      name="cartao_nome"
                      type="text"
                      maxLength="255"
                      placeholder="Entre com o nome impresso no cartão..."
                      style={{ fontSize: 13 }}
                    />
                  </div>
                </Col>
              )}
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel
            expanded={expanded === 'panel2'}
            onChange={handleChange('panel2')}
            onClick={() => handleMethod(8)}
          >
            <ExpansionPanelSummary
              expandIcon={<MdExpandMore />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
              style={{ padding: 0 }}
            >
              <Typography className={classes.heading} style={{ fontSize: 14 }}>
                <strong className="text-uppercase">Boleto Bancário</strong>
              </Typography>
              {!isMobile && (
                <Typography className={classes.secondaryHeading}>
                  <img
                    src={require('~/assets/img/bank-slip.svg')}
                    alt="Boleto Bancário"
                    width={35}
                  />
                </Typography>
              )}
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={`${!isMobile ? `` : `p-0`}`}>
              <div className="w-100 d-flex justify-content-between">
                <InputRadio
                  name="forma_pgto"
                  options={[
                    {
                      id: method,
                      label: 'Boleto',
                      text:
                        'O boleto será emitido em nome do sistema Psicomart',
                    },
                  ]}
                />
                {billet && (
                  <div>
                    <Button
                      onClick={() => {
                        window.open(billet);
                      }}
                      variant="primary"
                    >
                      Imprimir boleto
                    </Button>
                  </div>
                )}
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <div className="d-flex pt-4 float-right">
            {!isMobile ? (
              <>
                {method === 8 ? (
                  <Button
                    size="lg"
                    type="submit"
                    variant="success"
                    className="btn-block font-weight-bold btn-custom text-uppercase"
                    style={{ width: '250px' }}
                    disabled={loading ? true : !!billet}
                  >
                    {loading ? 'Aguarde...' : 'Gerar boleto'}
                  </Button>
                ) : (
                  <Button
                    size="lg"
                    type="submit"
                    variant="success"
                    className="btn-block font-weight-bold btn-custom text-uppercase"
                    style={{ width: '250px' }}
                    disabled={loading}
                  >
                    {loading ? 'Aguarde...' : 'Efetuar Pagamento'}
                  </Button>
                )}
              </>
            ) : (
              <BottomButton
                type="submit"
                disabled={loading}
                text={loading ? 'Aguarde...' : 'Efetuar Pagamento'}
              />
            )}
          </div>
        </Form>
      ) : (
        <div className="d-flex pt-4 float-right">
          {!isMobile ? (
            <Button
              size="lg"
              type="button"
              variant="success"
              className="btn-block font-weight-bold btn-custom text-uppercase"
              onClick={handleBillet}
              style={{ width: '250px' }}
              disabled={loading}
            >
              {loading ? 'Aguarde...' : 'Gerar Ingressos'}
            </Button>
          ) : (
            <BottomButton
              type="submit"
              disabled={loading}
              onClick={handleBillet}
              text={loading ? 'Aguarde...' : 'Gerar Ingressos'}
            />
          )}
        </div>
      )}
    </div>
  );
}
