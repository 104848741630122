/* eslint-disable no-sequences */
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Form } from '@unform/web';
import { isMobile } from 'react-device-detect';
import { Row, Col, Card, Container, Button, ListGroup } from 'react-bootstrap';

import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import api from '~/services/api';
import history from '~/services/history';
import Select from '~/components/InputSelect';
import Loading from '~/components/Loading';
import BottomButton from '~/components/BottomButton';

import * as EventActions from '~/store/modules/event/actions';
import * as CartActions from '~/store/modules/cart/actions';

import { objIsNull, adjustTopApplication } from '~/util';

export default function Ticket(props) {
  const { match } = props;

  const [tickets, setTickets] = useState([]);

  const dispatch = useDispatch();

  const loading = useSelector(state => state.event.loading);
  const { event } = useSelector(state => state.event);
  const { total, amount, coupon } = useSelector(state => state.cart);

  const loadTickets = useCallback(async params => {
    await api
      .get(`pagina/listar-ingresso-passo1/${params.tag}`)
      .then(response => {
        const { records } = response.data;
        if (records.error) {
          toast.error(records.msg);
          return;
        }
        setTickets(records);
      });
  }, []);

  useEffect(() => {
    const { params } = match;

    adjustTopApplication();
    dispatch(CartActions.clearCart());
    dispatch(EventActions.eventsGetByTagRequest(params.tag));
    loadTickets(params);
  }, [dispatch, match, loadTickets]);

  useEffect(() => {
    if (objIsNull(event)) {
      if (event.status_eve === '2') {
        history.push('/notfound');
      }
    }
  }, [event]);

  const getAffiliate = useCallback(() => {
    if (match.params.afiliado) {
      dispatch(CartActions.addAffiliate(match.params.afiliado));
      return match.params.afiliado;
    }
    return '';
  }, [match, dispatch]);

  const handleAmountTicket = useCallback(
    (value, ticket) => {
      dispatch(CartActions.addTicket(ticket, value));
      dispatch(CartActions.addEvent(event));
      if (Object.keys(coupon).length) {
        const { num_cupom } = coupon;
        const { cod_evento } = event;
        dispatch(CartActions.getCouponRequest({ num_cupom, cod_evento }));
      }
    },
    [dispatch, event, coupon]
  );

  return (
    <div className="box-ticket content padding-tab-top-post mt-5">
      {loading ? (
        <Loading />
      ) : (
        <Container>
          <Row>
            <Col xl={12}>
              <div className="d-flex justify-content-between align-items-center">
                <div className="mb-4">
                  <h1 className="mb-0 font-weight-semibold">
                    <i
                      role="presentation"
                      style={{ fontSize: '1.5rem' }}
                      onClick={() => history.goBack()}
                      className="icon-arrow-left52 mr-2 c-pointer"
                    ></i>
                    {event.titulo_eve}
                  </h1>
                  <span
                    className="text-muted d-block"
                    style={{ marginLeft: 35 }}
                  >
                    Selecione a quantidade e o tipo do ingresso
                  </span>
                </div>
              </div>
            </Col>
            <Col xl={12}>
              <Card>
                <Form initialData={{ amount }}>
                  <Card.Body>
                    <Col xl={12}>
                      <Row>
                        <Col xl={4}>
                          <h5 className="font-weight-bold text-muted">
                            {event.tipo_eve === '1' ? 'EVENTO' : 'CURSO'}
                          </h5>
                          <h2>{event.titulo_eve}</h2>
                          <ListGroup variant="flush">
                            {event.dt_eve ? (
                              <ListGroup.Item>
                                <i className="icon-calendar mr-2"></i>
                                <span>{event.dt_eve_desc}</span>
                              </ListGroup.Item>
                            ) : null}

                            {event.eh_online === '1' ? (
                              <ListGroup.Item>
                                <span className="text-information d-flex">
                                  <i className="icon-location4 mr-2"></i>
                                  <span>Online</span>
                                </span>
                              </ListGroup.Item>
                            ) : (
                              <>
                                {event.local_eve_desc ? (
                                  <>
                                    <ListGroup.Item>
                                      <span className="text-information d-flex">
                                        <i className="icon-location4 mr-2"></i>
                                        <span>{event.local_eve_desc}</span>
                                      </span>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                      <span className="text-information d-flex">
                                        <i className="icon-city mr-2"></i>
                                        <span>
                                          {event.end_eve}, {event.nro_eve},{' '}
                                          {event.nome_cidade_eve}
                                        </span>
                                      </span>
                                    </ListGroup.Item>
                                  </>
                                ) : (
                                  <ListGroup.Item>
                                    <span className="text-information d-flex">
                                      <i className="icon-location4 mr-2"></i>
                                      <span>Não definido</span>
                                    </span>
                                  </ListGroup.Item>
                                )}
                              </>
                            )}
                          </ListGroup>
                        </Col>
                        <Col xl={8}>
                          <Col xl={12}>
                            <Row>
                              <h5 className="text-uppercase text-muted d-block w-100 mb-2 font-weight-bold">
                                Selecionar Ingressos
                              </h5>
                            </Row>
                          </Col>
                          {tickets.map((ticket, i) => (
                            <Card
                              key={ticket.id}
                              className="box-card-ticket border-left-3 border-left-slate rounded-left-0"
                            >
                              <Card.Body>
                                <Col xl={12}>
                                  <Row>
                                    <Col xl={8}>
                                      <h6 className="font-weight-semibold">
                                        {ticket.nome_ing === null ||
                                        ticket.nome_ing === ''
                                          ? `Ingresso ${i + 1}`
                                          : ticket.nome_ing}
                                      </h6>
                                      <span>{ticket.desc_val}</span>
                                    </Col>
                                    <Col xl={4}>
                                      <div className="text-sm-right mb-0 mt-3 mt-sm-0 w-100">
                                        <h6 className="font-weight-semibold">
                                          {ticket.ing_grat === '1' ? (
                                            'Grátis'
                                          ) : (
                                            <>
                                              {new Intl.NumberFormat('pt-BR', {
                                                style: 'currency',
                                                currency: 'BRL',
                                              }).format(ticket.val)}
                                            </>
                                          )}
                                        </h6>
                                        <Select
                                          className="select-field"
                                          placeholder="SELECIONE"
                                          name="amount"
                                          onChange={e =>
                                            handleAmountTicket(e.value, ticket)
                                          }
                                          defaultValue={{
                                            value: 0,
                                            label: '0',
                                          }}
                                          options={[
                                            { value: 0, label: '0' },
                                            { value: 1, label: '1' },
                                            { value: 2, label: '2' },
                                            { value: 3, label: '3' },
                                            { value: 4, label: '4' },
                                            { value: 5, label: '5' },
                                            { value: 6, label: '6' },
                                            { value: 7, label: '7' },
                                            { value: 8, label: '8' },
                                            { value: 9, label: '9' },
                                            { value: 10, label: '10' },
                                          ]}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                              </Card.Body>
                            </Card>
                          ))}

                          <div className="alert alert-info alert-styled-left alert-arrow-left">
                            <div className="d-flex justify-content-between">
                              <h6 className="mb-0">Valor Total:</h6>
                              <h6 className="mb-0 alert-link">
                                {event.eh_cobrado === '0' ? (
                                  'Grátis'
                                ) : (
                                  <>
                                    {new Intl.NumberFormat('pt-BR', {
                                      style: 'currency',
                                      currency: 'BRL',
                                    }).format(total)}{' '}
                                  </>
                                )}
                              </h6>
                            </div>
                          </div>

                          <div className="d-flex justify-content-center mb-2">
                            {!isMobile ? (
                              <Button
                                variant="success"
                                size="lg"
                                className="btn btn-lg btn-success btn-block font-weight-bold btn-custom text-uppercase mb-2"
                                onClick={() => {
                                  history.push(
                                    `/purchase/${match.params.type}/${
                                      event.url_amig
                                    }/${getAffiliate()}`
                                  );
                                }}
                              >
                                Continuar Inscrição
                              </Button>
                            ) : (
                              <BottomButton
                                type="link"
                                url={`/purchase/${match.params.type}/${
                                  event.url_amig
                                }/${getAffiliate()}`}
                                text="Continuar Inscrição"
                              />
                            )}
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Card.Body>
                </Form>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
}

Ticket.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      tag: PropTypes.string,
      type: PropTypes.string,
      afiliado: PropTypes.string,
    }).isRequired,
  }).isRequired,
};
