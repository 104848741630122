import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Card, Table, Badge, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';

import Loading from '~/components/Loading';
import CreateAffiliate from '~/components/CreateAffiliate';
import ApagarAffiliate from '~/components/DeleteAffiliate';
import EditAffiliate from '~/components/CreateAffiliate/edit';

import api from '~/services/api';

export default function Affiliates({ data }) {
  const { cod_evento, cod_produto, url_amig, tipo_eve } = data;
  const [affiliates, setAffiliates] = useState([]);
  const [load, setLoad] = useState(false);

  const loadAffiliates = useCallback(async () => {
    setLoad(true);
    api
      .get(
        `/afiliacao/listar-afiliados/?q=(mga:1,${
          cod_evento ? 'cod_evento' : 'cod_produto'
        }:${cod_evento || cod_produto})`
      )
      .then(response => {
        if (response.data.records.error) {
          toast.error(response.data.records.msg);
          setLoad(false);
          return;
        }
        setAffiliates(response.data.records);
        setLoad(false);
      });
  }, [cod_evento, cod_produto]);

  useEffect(() => {
    loadAffiliates();
  }, [cod_evento, cod_produto, loadAffiliates]);

  return (
    <Card>
      <Card.Body>
        <Card.Title>
          <CreateAffiliate data={data} loading={loadAffiliates} />
        </Card.Title>
        {load ? (
          <Loading />
        ) : (
          <Table striped hover>
            <thead>
              <tr>
                <th>Nome do afiliado</th>
                <th>Link</th>
                <th>Status</th>
                <th>Comissão %</th>
                <th className="text-center">Ações</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(affiliates).length === 0 ? (
                <tr>
                  <td colSpan="5" className="p-0 text-center">
                    <div className="alert alert-info border-0 alert-dismissible mb-0">
                      <i className="icon-info3 border-2 rounded-round p-1 mr-2"></i>
                      <span>Este evento não possui afiliados cadastrados!</span>
                    </div>
                  </td>
                </tr>
              ) : (
                affiliates.map(affiliate => (
                  <tr key={affiliate.cod_afiliacao}>
                    <td>{affiliate.nome_membro}</td>
                    <td>
                      {tipo_eve === '1' && (
                        <Link
                          to="route"
                          target="_blank"
                          onClick={e => {
                            e.preventDefault();
                            window.open(
                              `/evento/${url_amig}/${affiliate.url_afiliacao}`
                            );
                          }}
                        >
                          Link
                        </Link>
                      )}
                      {tipo_eve === '2' && (
                        <Link
                          to="route"
                          target="_blank"
                          onClick={e => {
                            e.preventDefault();
                            window.open(
                              `/curso/${url_amig}/${affiliate.url_afiliacao}`
                            );
                          }}
                        >
                          Link
                        </Link>
                      )}
                      {cod_produto && (
                        <Link
                          to="route"
                          target="_blank"
                          onClick={e => {
                            e.preventDefault();
                            window.open(
                              `/detail/produto/${url_amig}/${affiliate.url_afiliacao}`
                            );
                          }}
                        >
                          Link
                        </Link>
                      )}
                    </td>
                    <td>
                      {affiliate.status === '1' && (
                        <Badge variant="success">Ativo</Badge>
                      )}
                      {affiliate.status === '2' && (
                        <Badge variant="danger">Inativo</Badge>
                      )}
                      {!affiliate.status && (
                        <Badge variant="secondary">Pendente</Badge>
                      )}
                    </td>
                    <td>
                      {affiliate.valor_comissao &&
                        `${affiliate.valor_comissao}%`}
                    </td>
                    <td>
                      <div className="d-flex justify-content-center align-items-center">
                        <EditAffiliate
                          data={affiliate}
                          loading={loadAffiliates}
                        />

                        <div className="list-icons text-primary ml-2">
                          <div className="dropdown">
                            <Button
                              variant="light"
                              className="dropdown-toggle"
                              data-toggle="dropdown"
                            >
                              <i className="icon-cog6 text-primary"></i>
                            </Button>

                            <div className="dropdown-menu dropdown-menu-right">
                              <ApagarAffiliate
                                id={affiliate.cod_afiliacao}
                                load={loadAffiliates}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        )}
      </Card.Body>
    </Card>
  );
}

Affiliates.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
};

Affiliates.defaultProps = {
  data: {},
};
