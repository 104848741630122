import React, { useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Card, Button } from 'react-bootstrap';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { toast } from 'react-toastify';
import history from '~/services/history';
import api from '~/services/api';
import Input from '~/components/InputText';

export default function Recover() {
  const formRef = useRef(null);
  const { token } = useParams();

  async function handleSubmit(data) {
    try {
      formRef.current.setErrors({});
      const schema = Yup.object().shape({
        nova_senha: Yup.string()
          .min('6', 'Senha muito curta.')
          .required('Nova senha é obrigatório.'),
        repetir: Yup.string()
          .oneOf(
            [Yup.ref('nova_senha'), null],
            'Nova senha e seu campo de repetir são diferentes.'
          )
          .required('Repetir nova senha é obrigatório.'),
      });
      await schema.validate(data, {
        abortEarly: false,
      });

      delete data.repetir;

      const response = await api.post(
        '/auth/salvar-redefinir-senha-membro',
        data
      );

      if (response.data.records.error) {
        toast.error(response.data.records.msg);
        return;
      }
      toast.success(response.data.records.msg);
      setTimeout(() => {
        history.push('/');
      }, '2000');
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  return (
    <Form ref={formRef} onSubmit={handleSubmit} className="login-form">
      <Card className="mb-0">
        <Card.Body>
          <div className="text-center mb-3">
            <h5 className="mb-0">Recuperar senha</h5>
            <span className="d-block text-muted">
              Digite sua nova senha abaixo
            </span>
          </div>

          <div className="form-group form-group-feedback form-group-feedback-right">
            <Input type="hidden" name="h" defaultValue={token} />
            <Input type="password" name="nova_senha" placeholder="Nova senha" />
            <div className="form-control-feedback">
              <i className="icon-mail5 text-muted"></i>
            </div>
          </div>
          <div className="form-group form-group-feedback form-group-feedback-right">
            <Input
              type="password"
              name="repetir"
              placeholder="Repetir nova senha"
            />
            <div className="form-control-feedback">
              <i className="icon-lock2 text-muted"></i>
            </div>
          </div>

          <Button type="submit" block variant="primary">
            Redefinir senha
          </Button>
        </Card.Body>
        <Link to="/" className="btn btn-link">
          Login
        </Link>
      </Card>
    </Form>
  );
}
