/* eslint-disable import/no-cycle */

import { all } from 'redux-saga/effects';

import auth from './auth/sagas';
import user from './user/sagas';
import mods from './mods/sagas';
import group from './group/sagas';
import interest from './interest/sagas';
import cases from './case/sagas';
import event from './event/sagas';
import cart from './cart/sagas';
import product from './product/sagas';
import shopping from './shopping/sagas';
import freight from './freight/sagas';
import course from './courses/sagas';
import classes from './classes/sagas';
import students from './students/sagas';
import modules from './modules/sagas';
import layouts from './layout/sagas';
import inventory from './inventory/sagas';
import affiliations from './affiliations/sagas';

export default function* rootSaga() {
  return yield all([
    auth,
    user,
    mods,
    group,
    interest,
    cases,
    event,
    cart,
    product,
    shopping,
    freight,
    course,
    classes,
    students,
    modules,
    layouts,
    inventory,
    affiliations,
  ]);
}
